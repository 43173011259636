<template>
    <canvas id="powderoutput-chart" ></canvas>
</template>

<script>
import Chart from "chart.js/auto";
import eventBus from "../utils/eventBus";


export default {
  name: "PowderOutputChart",
  data() {
    return {
      chart: {
        labels: [],
        datasets: [],
      },
    };
  },
  mounted() {
    eventBus.on('draw-powderoutput-chart', this.drawChart);
  },
  beforeUnmount() {
    eventBus.off('draw-powderoutput-chart', this.drawChart);
  },
  methods: {
    createDatasetConfig(label, data, pointBackgroundColor, borderColor, gradientStroke) {
      return {
        label: label,
        tension: 0.4,
        borderWidth: 3,
        pointRadius: 0,
        pointBackgroundColor: pointBackgroundColor,
        borderColor: borderColor,
        backgroundColor: gradientStroke,
        data: data,
        maxBarThickness: 6,
      };
    },
    createChartConfig(labels, datasets, yTitle, xTitle) {
      return {
        type: "line",
        data: {
          labels: labels,
          datasets: datasets,
        },
        options: {
          responsive: true,
          maintainAspectRatio: true,
          interaction: {
            intersect: false,
            mode: "index",
          },
          scales: {
            y: {
              grid: {
                drawBorder: false,
                display: true,
                drawOnChartArea: true,
                drawTicks: false,
                borderDash: [5, 5],
              },
              title: {
                display: true,
                text: yTitle,
              },
              ticks: {
                display: true,
                padding: 10,
                color: "#9ca2b7",
              },
            },
            x: {
              grid: {
                drawBorder: false,
                display: true,
                drawOnChartArea: true,
                drawTicks: true,
                borderDash: [5, 5],
              },
              title: {
                display: true,
                text: xTitle,
              },
              ticks: {
                display: true,
                color: "#9ca2b7",
                padding: 10,
              },
            },
          },
        },
      };
    },
    drawChart() {
      this.chart = this.$store.state.powderOutputChart;

      var canvas = document.getElementById("powderoutput-chart").getContext("2d");
      var gradientStroke2 = canvas.createLinearGradient(0, 230, 0, 50);

      gradientStroke2.addColorStop(1, "rgba(20,23,39,0.2)");
      gradientStroke2.addColorStop(0.2, "rgba(72,72,176,0.0)");
      gradientStroke2.addColorStop(0, "rgba(20,23,39,0)");

      let chartStatus = Chart.getChart("powderoutput-chart");
      if (chartStatus != undefined) {
        chartStatus.destroy();
      }
      const colors = [
        "#3A416F",
        "#17c1e8",
        "#f64e60",
        "#f1b44c",
        "#20c997",
        "#5c7cfa",
        "#6c757d",
        "#495057",
        "#dee2e6",
      ];

      const chartDatasets = this.chart.datasets.map((dataset, index) => {
        return this.createDatasetConfig(
          dataset.label,
          dataset.data,
          colors[index % colors.length],
          colors[index % colors.length],
          gradientStroke2,
        );
      });

      new Chart(canvas, this.createChartConfig(this.chart.labels, chartDatasets, "Weight", "Setting"));
    },
  },
};
</script>

<style scoped>
.chart-canvas {
  max-height: 400px;
}
</style>
