<template>
  <div class="container-fluid">
    <div
      class="mt-4 page-header min-height-250 border-radius-xl"
      :style="{
        backgroundImage: 'url(' + require('@/assets/img/curved-images/curved14.jpg') + ')',
        backgroundPositionY: '10%',
      }"
    >
      <span class="mask opacity-5" style="background-color: rgb(44, 222, 44)"></span>
      <div style="display: flex; align-items: center; justify-content: center">
        <div style="position: absolute; left: 50%; transform: translateX(-50%); top: 30%">
          <h3 style="color: rgb(255, 255, 255); text-align: center">{{ $t("Workflows for Line") }}</h3>
          <h5 style="color: rgb(255, 255, 255); text-align: center">{{ line_name }}</h5>
        </div>
      </div>
    </div>
    <div class="mx-4 mt-n6 overflow-hidden card card-body blur shadow-blur">
      <div class="row gx-4">
        <div class="row text-center">
          <div class="col p-3" style="min-width: 100px; height: 150px">
            <button
              class="w-60px mt-2 mb-0 btn ms-lg-auto me-lg-0 me-auto mt-lg-0"
              type="button"
              style="width: 200px; height: 100px; background-color: rgb(231, 245, 221); font-size: 0.9rem"
              @click="createNewVisit"
            >
              {{ $t("New Workflow") }}
            </button>
          </div>
        </div>
        <div class="row">
          <div class="col-12">
            <div class="table-responsive" style="margin: 0px">
              <table id="order-list" class="table table-flush">
                <thead class="thead-light">
                  <tr>
                    <th class="text-center">Date</th>
                    <th class="text-center">Benchmark &amp; Target</th>
                    <th class="text-center">Health Check</th>
                    <th class="text-center">Guns Equalization</th>
                    <th class="text-center">Spray Pattern</th>
                    <th class="text-center">Reciprocator Optimization</th>
                    <th class="text-center">Powder Saving</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="visit in visitsList" :key="visit.date">
                    <visit-row
                      :date-time="visit ? formattedDate(visit.created_at) : formattedDate(new Date())"
                      :benchmark-checked="visit.benchmarkstage ? visit.benchmarkstage.progress_status : 'Pending'"
                      :health-checked="visit.healthcheckstage ? visit.healthcheckstage.progress_status : 'Pending'"
                      :powder-output-checked="
                        visit.powderoutputstage ? visit.powderoutputstage.progress_status : 'Pending'
                      "
                      :spray-pattern-checked="
                        visit.spraypatternstage ? visit.spraypatternstage.progress_status : 'Pending'
                      "
                      :reciprocator-checked="
                        visit.reciprocatorstage ? visit.reciprocatorstage.progress_status : 'Pending'
                      "
                      :powdersaving-checked="
                        visit.powdersavingstage ? visit.powdersavingstage.progress_status : 'Pending'
                      "
                      :line-id="lineId"
                      :visit-id="visit.id"
                      :powder-id="visit.benchmarkstage.powder"
                    ></visit-row>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import logo from "@/assets/img/logo-ct-white.png";
import setTooltip from "@/assets/js/tooltip.js";
import VisitRow from "../components/VisitRow.vue";

export default {
  name: "LineVisits",
  components: {
    VisitRow,
  },
  props: {
    lineId: {
      type: String,
      default: "",
    },
  },
  data() {
    this.$i18n.locale = this.$store.state.user_data.language;
    return {
      logo,
      line_name: "",
      visitsList: [],
    };
  },
  computed: {},
  watch: {
    "$store.state.user_data.language"() {
      this.$i18n.locale = this.$store.state.user_data.language;
    },
  },
  mounted() {
    this.$store.state.isAbsolute = true;
    setTooltip(this.$store.state.bootstrap);
    this.getLineName();
    this.getLineVisits();
  },
  beforeUnmount() {
    this.$store.state.isAbsolute = false;
  },
  methods: {
    async getLineName() {
      if (this.lineId) {
        try {
          let response = await axios.get("/api/v1/fp/line/" + this.lineId);
          this.line_name = response.data.name;
        } catch (error) {
          console.error(error);
        }
      }
    },
    async getLineVisits() {
      if (this.lineId) {
        try {
          let response = await axios.get("/api/v1/onsite/visits/" + this.lineId);
          this.visitsList = response.data;
          this.visitsList.sort((a, b) => new Date(b.created_at) - new Date(a.created_at));
        } catch (error) {
          console.error(error);
        }
      }
    },
    createNewVisit() {
      if (this.lineId) {
        try {
          axios
            .post("/api/v1/onsite/visits/", {
              line: this.lineId,
            })
            .then(response => {
              console.log(response.data);
              this.getLineVisits();
            })
            .catch(error => {
              console.error(error);
            });
        } catch (error) {
          console.error(error);
        }
      }
    },
    formattedDate(date_str) {
      return new Date(date_str).toLocaleString("en-US", {
        hour: "2-digit",
        minute: "2-digit",
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
      });
    },
  },
};
</script>

<i18n>
  {
    "EN": {
      "Workflows for Line": "Workflows for Line",
      "New Workflow": "New Workflow",
    },
    "DE": {
      "Workflows for Line": "Workflows for Line",
      "New Workflow": "New Workflowlowlow",
    },
    "ES": {
      "Workflows for Line": "Workflows for Line",
      "New Workflowlow": "New Workflowlowlowlow",
    },
    "FR": {
      "Workflows for Line": "Workflows for Line",
      "New Workflow": "New Workflow",
    },
    "IT": {
      "Workflows for Line": "Workflows for Line",
      "New Workflow": "New Workflowlowlow",
    },
    "PL": {
      "Workflows for Line": "Workflows for Line",
      "New Workflowlowlowlowlowlowlowlowlow": "New Workflowlow",
    },
    "TR": {
      "Workflows for Line": "Workflows for Line",
      "New Workflow": "New Workflow",
    },
    "ZH": {
      "Workflows for Line": "Workflows for Line",
      "New Workflow": "New Workflow",
    }
  }
</i18n>
