<template>
  <div class="text-center">
    <h5 class="">{{ title }}</h5>
    <canvas
      :id="chartId"
      class="chart-canvas"
    ></canvas>
  </div>
</template>

<script>
import annotationPlugin from "chartjs-plugin-annotation";
import Chart from "chart.js/auto";
import eventBus from "../utils/eventBus";

Chart.register(annotationPlugin);

export default {
  name: "GaussianChart",
  props: {
    title: {
      type: String,
      required: false,
      default: "",
    },
    chartName: {
      type: String,
      required: false,
      default: "",
    },
  },
  data() {
    return {
      chart: {
        labels: [],
        datasets: [],
      },
    };
  },
  computed: {
    chartId() {
      return `chart-${this.title.replace(/\s+/g, "-").toLowerCase()}`;
    },
  },
  mounted() {
    eventBus.on("draw-gaussian-chart", this.drawGaussianChart);
  },
  beforeUnmount() {
    eventBus.off("draw-gaussian-chart", this.drawGaussianChart);
  },
  methods: {
    createChartConfig(labels, datasets, yTitle, xTitle, min = null, max = null) {
      const config = {
        type: "line",
        data: {
          labels: labels,
          datasets: datasets,
        },
        options: {
          responsive: true,
          maintainAspectRatio: true,
          interaction: {
            intersect: false,
            mode: "index",
          },
          scales: {
            y: {
              grid: {
                drawBorder: false,
                display: true,
                drawOnChartArea: true,
                drawTicks: false,
                borderDash: [5, 5],
              },
              title: {
                display: true,
                text: yTitle,
              },
              ticks: {
                display: true,
                padding: 10,
                color: "#9ca2b7",
              },
            },
            x: {
              grid: {
                drawBorder: false,
                display: true,
                drawOnChartArea: true,
                drawTicks: true,
                borderDash: [5, 5],
              },
              title: {
                display: true,
                text: xTitle,
              },
              ticks: {
                display: true,
                color: "#9ca2b7",
                padding: 10,
              },
            },
          },
        },
      };

      if (min !== null || max !== null) {
        config.options.plugins = {
          annotation: {
            annotations: {
              ...(min !== null && {
                minThicknessLine: {
                  type: "line",
                  xMin: min,
                  xMax: min,
                  borderColor: "red",
                  borderWidth: 2,
                  borderDash: [6, 6],
                  label: {
                    content: "Min",
                    enabled: true,
                    position: "end",
                  },
                },
              }),
              ...(max !== null && {
                maxThicknessLine: {
                  type: "line",
                  xMin: max,
                  xMax: max,
                  borderColor: "red",
                  borderWidth: 2,
                  borderDash: [6, 6],
                  label: {
                    content: "Max",
                    enabled: true,
                    position: "end",
                  },
                },
              }),
            },
          },
        };
      }

      return config;
    },
    createDatasetConfig(label, data, pointBackgroundColor, borderColor, gradientStroke) {
      return {
        tension: 0.4,
        borderWidth: 3,
        pointRadius: 0,
        pointBackgroundColor: pointBackgroundColor,
        borderColor: borderColor,
        backgroundColor: gradientStroke,
        data: data,
        label: label,
        maxBarThickness: 6,
      };
    },
    drawGaussianChart() {
      if (this.chartName === "gaussianBenchmarkChart") {
        this.chart = this.$store.state.gaussianBenchmarkChart;
      } else if (this.chartName === "gaussianPowderOutputChart") {
        this.chart = this.$store.state.gaussianPowderOutputChart;
      } else if (this.chartName === "gaussianPowderSavingChart") {
        this.chart = this.$store.state.gaussianPowderSavingChart;
      } else if (this.chartName === "gaussianReciprocatorChart") {
        this.chart = this.$store.state.gaussianReciprocatorChart;
      }

      var canvas = document.getElementById(this.chartId).getContext("2d");
      var gradientStroke2 = canvas.createLinearGradient(0, 230, 0, 50);

      gradientStroke2.addColorStop(1, "rgba(20,23,39,0.2)");
      gradientStroke2.addColorStop(0.2, "rgba(72,72,176,0.0)");
      gradientStroke2.addColorStop(0, "rgba(20,23,39,0)");

      let chartStatus = Chart.getChart(this.chartId);
      if (chartStatus != undefined) {
        chartStatus.destroy();
      }

      const colors = [
        "#17c1e8",
        "#3A416F",
        "#f64e60",
        "#f1b44c",
        "#20c997",
        "#5c7cfa",
        "#6c757d",
        "#495057",
        "#dee2e6",
      ];

      const chartDatasets = this.chart.datasets.map((dataset, index) => {
        return this.createDatasetConfig(
          dataset.label,
          dataset.data,
          colors[index % colors.length],
          colors[index % colors.length],
          gradientStroke2,
        );
      });

      const yTitle = "Probability density";
      const xTitle = "Thickness";

      const chartConfig = this.createChartConfig(
        this.chart.labels,
        chartDatasets,
        yTitle,
        xTitle,
        this.$store.state.minTargetThickness,
        this.$store.state.maxTargetThickness,
      );

      new Chart(
        canvas,
        chartConfig,
      );
    },
  },
};
</script>

<style scoped>
/* Your existing styles */
</style>
