<template>
  <div>
    <div
      id="modal-overlay"
      style="
        width: 100%;
        height: 100%;
        position: fixed;
        top: 0;
        left: 0;
        background: rgba(0, 0, 0, 0.7);
        display: none;
        align-items: center;
        justify-content: center;
        z-index: 1;
      "
    >
      <div
        id="modal"
        class="card rounded-xl"
        style="max-width: 650px; width: 100%; background: rgba(255, 247, 247, 0.959); height: 300px"
      >
        <button
          style="position: absolute; top: 10px; right: 10px; background: transparent; border: none"
          @click="dismissPopUp"
        >
          <i
            class="fa fa-close fa-2x"
            alt="close"
          ></i>
        </button>
        <div class="modal-header justify-content-center">
          <h4>{{ $t("Which type of Powder Model do you want to create?") }}</h4>
        </div>
        <div class="row mt-6">
          <div class="col-4 d-flex justify-content-center">
            <button
              type="button"
              class="btn bg-gradient-success h6"
              style="width: 80%; height: 150%"
              @click="$router.push('/applications/flightpath/powdermodelslibrary/powdermodeladd/' + lineId)"
            >
              {{ $t("Simple") }}
            </button>
          </div>
          <div class="col-4 d-flex justify-content-center">
            <button
              type="button"
              class="btn bg-gradient-success h6"
              style="width: 80%; height: 150%"
              @click="$router.push('/applications/flightpath/powdermodelslibrary/staticpowdermodeladd/' + lineId)"
            >
              {{ $t("Static Calibration") }}
            </button>
          </div>
          <div class="col-4 d-flex justify-content-center">
            <button
              type="button"
              class="btn bg-gradient-success h6"
              style="width: 80%; height: 150%"
              @click="$router.push('/applications/flightpath/powdermodelslibrary/advancedpowdermodeladd/' + lineId)"
            >
              {{ $t("Advanced") }}
            </button>
          </div>
        </div>
      </div>
    </div>
    <div class="py-1 container-fluid">
      <div id="topinfo">
        <div class="row mt-4">
          <div class="col">
            <div class="card">
              <div
                v-if="!LineIsCreated"
                class="card-header pb-0"
              >
                <h4 class="font-weight-bolder text-left">{{ $t("Add new Line") }}</h4>
              </div>
              <div
                v-else-if="$store.state.flightpath_pro_license"
                class="card-header pb-0"
              >
                <h5 class="font-weight-bolder text-left">{{ $t("Edit Line") }}</h5>
              </div>
              <div
                v-else-if="$store.state.flightpath_standard_license"
                class="card-header pb-0"
              >
                <h5 class="font-weight-bolder text-left">{{ $t("Line Details") }}</h5>
              </div>
              <div
                class="card-body"
                style="padding-top: 0.5rem"
              >
                <div class="row">
                  <div class="col-12 col-sm-6 mb-3">
                    <label>{{ $t("Name") }}</label>
                    <input
                      v-model="line_form.name"
                      :class="line_input_error ? `form-control is-invalid` : `form-control`"
                      type="text"
                      :error="line_input_error"
                      :disabled="$store.state.flightpath_standard_license"
                      @input="line_input_error = false"
                      @change="changedFieldCheckInitialFillCanvas"
                    />
                  </div>
                </div>
                <div class="row">
                  <div class="col">
                    <label class="mt-2 mb-2 h5 font-weight-bolder">
                      {{ LineIsCreated ? "" : $t("Select") }} {{ $t("Line Type") }}
                      <i
                        :title="
                          $t(
                            'Select moving or fixed line. A fixed line has a fixed reciprocator that does not move, hence guns have 0 movement range and 0 velocity.',
                          )
                        "
                        class="fa fa-info-circle text-sm"
                        aria-hidden="true"
                        data-bs-toggle="tooltip"
                        data-bs-placement="top"
                        data-container="body"
                        data-animation="true"
                      >
                      </i>
                    </label>
                    <div
                      v-if="!LineIsCreated"
                      class="col-12 ms-auto"
                    >
                      <div class="d-flex justify-content-left gap-4">
                        <button
                          type="button"
                          :class="`mt-2 mb-0 btn bg-gradient-${isFixedLayout ? 'gray' : 'success'}`"
                          style="width: 300px; height: 60px; background-color: rgb(241, 241, 241); word-break: keep-all"
                          @click="
                            previous_fixed_line_pistols_layout = JSON.parse(JSON.stringify(line_form.pistols_layout));
                            line_form.pistols_layout = previous_dynamic_line_pistols_layout;
                            pistol_to_pistol_distance_column_1 = previous_pistol_to_pistol_distance;
                            CheckDynamicLineMaxPistols();
                            UpdateLineForm();
                            fillCanvas();
                          "
                        >
                          {{ $t("Dynamic") }}
                        </button>
                        <button
                          type="button"
                          :class="`mt-2 mb-0 btn bg-gradient-${isFixedLayout ? 'success' : 'gray'}`"
                          style="width: 300px; height: 60px; background-color: rgb(241, 241, 241); word-break: keep-all"
                          @click="
                            previous_dynamic_line_pistols_layout = JSON.parse(JSON.stringify(line_form.pistols_layout));
                            previous_pistol_to_pistol_distance = JSON.parse(
                              JSON.stringify(pistol_to_pistol_distance_column_1),
                            );
                            line_form.pistols_layout = previous_fixed_line_pistols_layout;
                          "
                        >
                          {{ $t("Fixed") }}
                        </button>
                      </div>
                    </div>
                    <div v-else>
                      <h6 class="mx-2">{{ $t(isFixedLayout ? "Fixed" : "Dynamic") }}</h6>
                    </div>
                  </div>
                </div>
                <div class="mt-4 mb-4 row">
                  <h5 class="font-weight-bolder">{{ $t("Line Properties") }}</h5>
                  <div class="col-sm-4">
                    <label class="mt-3">{{ $t("Line Manufacturer") }}</label>
                    <select
                      id="choices-category"
                      v-model="line_form.line_manufacturer"
                      :class="line_manufacturer_input_error ? `form-control is-invalid` : `form-control`"
                      name="line-manufacturer-choices"
                      :disabled="$store.state.flightpath_standard_license"
                      style="appearance: listbox"
                      @change="
                        changedFieldCheckInitialFillCanvas();
                        line_manufacturer_input_error = false;
                      "
                    >
                      <option
                        disabled
                        value="Please select one"
                      >
                        {{ $t("Please select one") }}
                      </option>
                      <option
                        v-for="line_manufacturer_option in line_manufacturer"
                        :key="line_manufacturer_option.value"
                        selected=""
                        :value="line_manufacturer_option.value"
                      >
                        {{ $t(line_manufacturer_option.name) }}
                      </option>
                    </select>
                  </div>
                  <div class="col-sm-4">
                    <label class="mt-3"> {{ $t("Air Parameter Name e.g. [DosageAir]") }} </label>
                    <input
                      v-model="line_form.air_parameter_name"
                      :class="air_name_input_error ? `form-control is-invalid` : `form-control`"
                      type="text"
                      :disabled="$store.state.flightpath_standard_license"
                      @input="air_name_input_error = false"
                      @change="changedFieldCheckInitialFillCanvas"
                    />
                  </div>
                  <div class="col-sm-4">
                    <label class="mt-3">{{ $t("Air Parameter Units e.g. [bar]") }}</label>
                    <input
                      v-model="line_form.air_parameter_units"
                      :class="air_units_input_error ? `form-control is-invalid` : `form-control`"
                      type="text"
                      :disabled="$store.state.flightpath_standard_license"
                      @input="air_units_input_error = false"
                      @change="changedFieldCheckInitialFillCanvas"
                    />
                  </div>
                  <div class="col-sm-4">
                    <label class="mt-3">{{ $t("Pump Type") }}</label>
                    <select
                      id="choices-category"
                      v-model="line_form.pump_type"
                      :class="pump_type_input_error ? `form-control is-invalid` : `form-control`"
                      name="pump-type-choices"
                      :disabled="$store.state.flightpath_standard_license"
                      style="appearance: listbox"
                      @change="
                        changedFieldCheckInitialFillCanvas();
                        pump_type_input_error = false;
                      "
                    >
                      <option
                        disabled
                        value="Please select one"
                      >
                        {{ $t("Please select one") }}
                      </option>
                      <option
                        v-for="pump_type_option in pump_type"
                        :key="pump_type_option.value"
                        selected=""
                        :value="pump_type_option.value"
                      >
                        {{ $t(pump_type_option.name) }}
                      </option>
                    </select>
                  </div>
                  <div class="col-sm-4">
                    <label class="mt-3">{{ $t("Do you have a recycling powder system?") }}</label>
                    <select
                      id="choices-category"
                      v-model="line_form.recycling_system"
                      class="form-control"
                      name="line-manufacturer-choices"
                      style="appearance: listbox"
                    >
                      <option :value="true">{{ $t("Yes") }}</option>
                      <option :value="false">{{ $t("No") }}</option>
                    </select>
                  </div>
                  <div
                    v-if="line_form.recycling_system"
                    class="col-sm-4"
                  >
                    <label class="mt-3"> {{ $t("Recycling System Efficiency") }} [%] </label>
                    <input
                      v-model="line_form.powder_recycling_efficiency_percentage"
                      class="form-control"
                      type="number"
                      min="0"
                      max="100"
                      @blur="AdjustRecycledPercentage()"
                    />
                  </div>
                </div>
                <div v-if="!AdvancedConfiguration">
                  <h5 class="font-weight-bolder">{{ $t("Gun Layout") }}</h5>
                  <div class="row mb-3">
                    <div class="col-3">
                      <label>
                        {{ $t("Number of Columns") }}
                        <i
                          class="fa fa-info-circle"
                          aria-hidden="true"
                          data-bs-toggle="tooltip"
                          data-bs-placement="top"
                          :title="
                            $t('Number of guns or vertical stacks of guns, next to each other.') +
                            ' ' +
                            $t('The minimum is') +
                            ' ' +
                            minColumnsAllowed +
                            ' ' +
                            $t('and the maximum is') +
                            ' ' +
                            maxColumnsAllowed +
                            '.'
                          "
                          data-container="body"
                          data-animation="true"
                        >
                        </i>
                      </label>
                      <input
                        id="columns"
                        v-model.lazy="numberOfColumns"
                        :class="number_of_columns_input_error ? 'form-control is-invalid' : 'form-control'"
                        type="number"
                        :min="minColumnsAllowed"
                        :max="maxColumnsAllowed"
                        @input="number_of_columns_input_error = false"
                        @blur="
                          AdjustNumberOfColumns();
                          MaxNumberOfColumnsAllowed();
                        "
                      />
                    </div>
                    <div class="col-3">
                      <label>
                        {{ $t("Column Distance") }} [{{
                          $store.state.units_system[$store.state.user_data.unit_system].distance
                        }}]
                        <i
                          class="fa fa-info-circle"
                          aria-hidden="true"
                          data-bs-toggle="tooltip"
                          data-bs-placement="top"
                          :title="
                            $t('Horizontal distance between two adjacent guns or stacks of guns.') +
                            ' ' +
                            $t('The minimum is') +
                            ' ' +
                            minColumnToColumnDistanceAllowed +
                            ' ' +
                            this.$store.state.units_system[this.$store.state.user_data.unit_system].distance +
                            ' ' +
                            $t('and the maximum is') +
                            ' ' +
                            maxColumnToColumnDistanceAllowed +
                            ' ' +
                            this.$store.state.units_system[this.$store.state.user_data.unit_system].distance +
                            '.'
                          "
                          data-container="body"
                          data-animation="true"
                        >
                        </i>
                      </label>
                      <input
                        id="col-distance"
                        v-model="columnDistance"
                        :class="column_distance_input_error ? 'form-control is-invalid' : 'form-control'"
                        type="number"
                        :disabled="grid_form.number_of_columns == 1"
                        :min="minColumnToColumnDistanceAllowed"
                        :max="maxColumnToColumnDistanceAllowed"
                        @input="column_distance_input_error = false"
                        @blur="WithinRangeColumnToColumnDistanceAlertCheck()"
                      />
                    </div>
                    <div class="col-3">
                      <label>
                        {{ $t("Column Shift") }} [{{
                          $store.state.units_system[$store.state.user_data.unit_system].distance
                        }}]
                        <i
                          class="fa fa-info-circle"
                          aria-hidden="true"
                          data-bs-toggle="tooltip"
                          data-bs-placement="top"
                          :title="
                            $t('Height offset between two adjacent guns or stacks of guns.') +
                            ' ' +
                            $t('The minimum is') +
                            ' ' +
                            '0' +
                            ' ' +
                            this.$store.state.units_system[this.$store.state.user_data.unit_system].distance +
                            ' ' +
                            $t('and the maximum is') +
                            ' ' +
                            maxColumnShiftAllowed +
                            ' ' +
                            this.$store.state.units_system[this.$store.state.user_data.unit_system].distance +
                            '.'
                          "
                          data-container="body"
                          data-animation="true"
                        >
                        </i>
                      </label>
                      <input
                        id="col-shift"
                        v-model="columnShift"
                        :class="column_shift_input_error ? 'form-control is-invalid' : 'form-control'"
                        type="number"
                        :disabled="grid_form.row_shift != 0 || gunLayout == 'v'"
                        min="0"
                        :max="maxColumnShiftAllowed"
                        @input="column_shift_input_error = false"
                        @blur="
                          WithinRangeColumnShiftAlertCheck();
                          AdjustColumnShift();
                        "
                      />
                    </div>
                  </div>
                  <div class="container">
                    <div class="main-area col-12">
                      <div class="grid-container">
                        <div id="grid">
                          <div
                            v-for="(circle, index) in circles"
                            :key="index"
                            class="circle"
                            :style="circle.style"
                          ></div>
                        </div>
                      </div>
                      <div class="col-3 mx-4">
                        <label>
                          {{ $t("Number of Rows") }}
                          <i
                            class="fa fa-info-circle"
                            aria-hidden="true"
                            data-bs-toggle="tooltip"
                            data-bs-placement="top"
                            :title="
                              $t('Number of guns on top of each other.') +
                              ' ' +
                              $t('The minimum is') +
                              ' ' +
                              minRowsAllowed +
                              ' ' +
                              $t('and the maximum is') +
                              ' ' +
                               + $store.state.constraints.max_number_of_rows_allowed +
                              '.'
                            "
                            data-container="body"
                            data-animation="true"
                          >
                          </i>
                        </label>
                        <div class="row">
                          <div class="col">
                            <input
                              id="rows"
                              v-model.lazy="grid_form.number_of_rows"
                              :class="number_of_rows_input_error ? 'form-control is-invalid' : 'form-control'"
                              type="number"
                              :min="minRowsAllowed"
                              :max="$store.state.constraints.max_number_of_rows_allowed"
                              @input="number_of_rows_input_error = false"
                              @blur="
                                AdjustNumberOfRows();
                                MaxNumberOfRowsAllowedCheck();
                              "
                            />
                          </div>
                        </div>
                        <label class="mt-3">
                          {{ $t("Row Distance") }}
                          [{{ $store.state.units_system[$store.state.user_data.unit_system].distance }}]
                          <i
                            class="fa fa-info-circle"
                            aria-hidden="true"
                            data-bs-toggle="tooltip"
                            data-bs-placement="top"
                            :title="
                              $t('Vertical distance between two guns on top of each other.') +
                              ' ' +
                              $t('The minimum is') +
                              ' ' +
                              minRowToRowDistanceAllowed +
                              ' ' +
                              this.$store.state.units_system[this.$store.state.user_data.unit_system].distance +
                              ' ' +
                              $t('and the maximum is') +
                              ' ' +
                              maxRowToRowDistanceAllowed +
                              ' ' +
                              this.$store.state.units_system[this.$store.state.user_data.unit_system].distance +
                              '.'
                            "
                            data-container="body"
                            data-animation="true"
                          >
                          </i>
                        </label>
                        <div class="row">
                          <div class="col">
                            <input
                              id="row-distance"
                              v-model="rowDistance"
                              :class="row_distance_input_error ? 'form-control is-invalid' : 'form-control'"
                              type="number"
                              :min="minRowToRowDistanceAllowed"
                              :max="maxRowToRowDistanceAllowed"
                              :disabled="grid_form.number_of_rows == 1"
                              @input="row_distance_input_error = false"
                              @blur="WithinRangeRowToRowDistAlertCheck()"
                            />
                          </div>
                        </div>
                        <label class="mt-3">
                          {{ $t("Row Shift") }}
                          [{{ $store.state.units_system[$store.state.user_data.unit_system].distance }}]
                          <i
                            class="fa fa-info-circle"
                            aria-hidden="true"
                            data-bs-toggle="tooltip"
                            data-bs-placement="top"
                            :title="
                              $t('Horizontal distance offset between even and odd rows.') +
                              ' ' +
                              $t('The minimum is') +
                              ' ' +
                              '0' +
                              ' ' +
                              this.$store.state.units_system[this.$store.state.user_data.unit_system].distance +
                              ' ' +
                              $t('and the maximum is') +
                              ' ' +
                              maxRowShiftAllowed +
                              ' ' +
                              this.$store.state.units_system[this.$store.state.user_data.unit_system].distance +
                              '.'
                            "
                            data-container="body"
                            data-animation="true"
                          >
                          </i>
                        </label>
                        <div class="row">
                          <div class="col">
                            <input
                              id="row-shift"
                              v-model="rowShift"
                              :class="row_shift_input_error ? 'form-control is-invalid' : 'form-control'"
                              type="number"
                              :disabled="grid_form.column_shift != 0 || gunLayout == 'h'"
                              :min="0"
                              :max="maxRowShiftAllowed"
                              @input="row_shift_input_error = false"
                              @blur="
                                WithinRangeRowShiftAlertCheck();
                                AdjustRowShift();
                              "
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div v-if="AdvancedConfiguration">
                  <div class="row mt-4 mb-2">
                    <h5 class="font-weight-bolder">{{ $t("Advanced Gun Layout") }}</h5>
                    <div class="col-sm-6">
                      <label class="mt-3">
                        {{ $t("Gun Layout") }}
                        &nbsp;
                        <i
                          class="fa fa-info-circle"
                          :title="
                            $t(
                              'Select Vertical if the guns are arranged in one on top of each other in a single column. Select Horizontal if they are arranged one next to each other in a single row. Select Mixed for more complex arrangements that combine multiple vertical stacks or columns.',
                            )
                          "
                          aria-hidden="true"
                          data-bs-toggle="tooltip"
                          data-bs-placement="top"
                          data-container="body"
                          data-animation="true"
                        >
                        </i>
                        &nbsp;
                      </label>
                      <select
                        id="choices-category"
                        v-model="line_form.pistols_layout"
                        :class="pistols_layout_input_error ? `form-control is-invalid` : `form-control`"
                        :disabled="$store.state.flightpath_standard_license"
                        name="pistol-layout-choices"
                        style="appearance: listbox"
                        @input="pistols_layout_input_error = false"
                        @change="fillCanvas()"
                      >
                        <option
                          disabled
                          value=""
                        >
                          {{ $t("Please select one") }}
                        </option>
                        <option
                          v-if="!isFixedLayout"
                          value="v"
                          :selected="true"
                        >
                          {{ $t("Vertical") }}
                        </option>
                        <option
                          v-if="!isFixedLayout"
                          value="h"
                        >
                          {{ $t("Horizontal") }}
                        </option>
                        <option
                          v-if="!isFixedLayout"
                          value="m"
                        >
                          {{ $t("Mixed (Vertical and Horizontal Hybrid)") }}
                        </option>
                        <option
                          v-if="isFixedLayout"
                          value="s"
                          :selected="true"
                        >
                          {{ $t("Fixed") + " " + $t("Vertical") }}
                        </option>
                        <option
                          v-if="isFixedLayout"
                          value="fixedmixed"
                        >
                          {{ $t("Fixed Mixed (non moving with multiple columns of guns)") }}
                        </option>
                      </select>
                    </div>
                  </div>
                  <div class="row mb-2">
                    <div
                      v-if="isVerticalOrHorizontalLayout || isStaticVerticalLayout"
                      class="col-sm-6"
                    >
                      <label class="mt-3">
                        {{ $t("Total Guns") }}
                      </label>
                      <select
                        v-model="total_pistols_vertical_or_horizontal"
                        :class="total_pistols_input_error ? `form-control is-invalid` : `form-control`"
                        name="total-pistols-choices"
                        :disabled="$store.state.flightpath_standard_license"
                        style="appearance: listbox"
                        @input="total_pistols_input_error = false"
                        @change="
                          UpdateLineForm();
                          fillCanvas();
                        "
                      >
                        <option
                          v-for="n in AvailablePistolsByLineLayout"
                          :key="n + 1"
                          :value="parseInt(n + 1)"
                        >
                          {{ n + 1 }} {{ $t("guns") }}
                        </option>
                      </select>
                    </div>
                    <div
                      v-if="isVerticalOrHorizontalLayout"
                      class="col-sm-6"
                    >
                      <label class="mt-3">
                        {{ $t("Gun to gun distance") }} [{{
                          $store.state.units_system[$store.state.user_data.unit_system].distance
                        }}]
                      </label>
                      <input
                        v-model.lazy="pistolToPistolDistanceColumn1"
                        :class="pistol_to_pistol_input_error ? `form-control is-invalid` : `form-control`"
                        type="number"
                        :step="$store.state.user_data.unit_system === 'metric' ? 1 : 0.01"
                        :min="minPistolToPistolDistanceAllowed"
                        :disabled="$store.state.flightpath_standard_license"
                        @input="pistol_to_pistol_input_error = false"
                        @change="
                          UpdateLineForm();
                          WithinRangePistolToPistolDistAlertCheck();
                          fillCanvas();
                        "
                        @keydown="numericOnly"
                      />
                    </div>
                    <div
                      v-if="isMixedLayout || isStaticMixedLayout"
                      class="col-12"
                    >
                      <label class="mt-3">
                        {{ $t("Total number of vertical gun stacks or columns") }}
                        &nbsp;
                        <i
                          class="fa fa-info-circle"
                          aria-hidden="true"
                          data-bs-toggle="tooltip"
                          data-bs-placement="top"
                          :title="
                            $t(
                              'A mixed hybrid line is composed of multiple vertical stacks or columns of guns. If your Line has only 1 vertical gun column, then select Vertical instead of Mixed on the Gun Layout option.',
                            )
                          "
                          data-container="body"
                          data-animation="true"
                        >
                        </i>
                        &nbsp;
                      </label>
                      <select
                        v-model="num_pistol_columns_mixed_layout_form"
                        :class="
                          num_pistol_columns_mixed_layout_input_error ? `form-control is-invalid` : `form-control`
                        "
                        name="mixed-line-total-columns-choices"
                        :disabled="$store.state.flightpath_standard_license"
                        style="appearance: listbox"
                        @input="num_pistol_columns_mixed_layout_input_error = false"
                        @change="
                          fillCanvas();
                          UpdateLineForm();
                        "
                      >
                        <option
                          disabled
                          value=""
                        >
                          {{ $t("Please select one") }}
                        </option>
                        <option
                          v-for="n in [2, 3, 4, 5, 6, 7, 8, 9, 10]"
                          :key="n"
                          :value="parseInt(n)"
                        >
                          {{ n }} {{ $t("columns of guns") }}
                        </option>
                      </select>
                    </div>
                  </div>
                  <div
                    v-if="(isMixedLayout || isStaticMixedLayout) && num_pistol_columns_mixed_layout != null"
                    class="row mt-3"
                  >
                    <h6 class="font-weight-bolder">{{ $t("1st guns column") }}</h6>
                    <div class="col-sm-6">
                      <label>
                        {{ $t("Total number of guns of the 1st vertical stack") }}
                      </label>
                      <select
                        v-model="total_pistols_column_1"
                        :class="total_pistols_column_1_input_error ? `form-control is-invalid` : `form-control`"
                        name="total-pistols-column-1-choices"
                        :disabled="$store.state.flightpath_standard_license"
                        style="appearance: listbox"
                        @input="total_pistols_column_1_input_error = false"
                        @change="
                          fillCanvas();
                          UpdateLineForm();
                        "
                      >
                        <option
                          disabled
                          value=""
                        >
                          {{ $t("Please select one") }}
                        </option>
                        <option
                          v-for="n in 19"
                          :key="n + 1"
                          :value="parseInt(n + 1)"
                        >
                          {{ n + 1 }} {{ $t("guns") }}
                        </option>
                      </select>
                    </div>
                    <div class="col-sm-6">
                      <div v-if="!isFixedLayout">
                        <label>
                          {{ $t("Gun to gun distance of the 1st vertical stack") }} [{{
                            $store.state.units_system[$store.state.user_data.unit_system].distance
                          }}] &nbsp;
                          <i
                            class="fa fa-info-circle"
                            aria-hidden="true"
                            data-bs-toggle="tooltip"
                            data-bs-placement="top"
                            :title="
                              $t(
                                'Input the vertical distance between guns of the 1st column of guns, the column that is most to the left.',
                              )
                            "
                            data-container="body"
                            data-animation="true"
                          >
                          </i>
                          &nbsp;
                        </label>
                        <input
                          v-model.lazy="pistolToPistolDistanceColumn1"
                          :class="
                            pistol_to_pistol_distance_column_1_input_error ? `form-control is-invalid` : `form-control`
                          "
                          type="number"
                          :step="$store.state.user_data.unit_system === 'metric' ? 1 : 0.01"
                          :min="total_pistols_column_1 > 1 ? minPistolToPistolDistanceAllowed : 0"
                          :disabled="$store.state.flightpath_standard_license"
                          @input="pistol_to_pistol_distance_column_1_input_error = false"
                          @change="
                            UpdateLineForm();
                            WithinRangePistolToPistolDistAlertCheck();
                            fillCanvas();
                          "
                          @keydown="numericOnly"
                        />
                      </div>
                    </div>
                  </div>
                  <div
                    v-if="(isMixedLayout || isStaticMixedLayout) && num_pistol_columns_mixed_layout != null"
                    class="row mt-3"
                  >
                    <h6 class="font-weight-bolder">{{ $t("2nd guns column") }}</h6>
                    <div class="col-sm-6">
                      <label>
                        {{ $t("Total number of guns of the 2nd vertical stack") }}
                      </label>
                      <select
                        v-model="total_pistols_column_2"
                        :class="total_pistols_column_2_input_error ? `form-control is-invalid` : `form-control`"
                        name="total-pistols-column-2-choices"
                        :disabled="$store.state.flightpath_standard_license"
                        style="appearance: listbox"
                        @input="total_pistols_column_2_input_error = false"
                        @change="
                          UpdateLineForm();
                          fillCanvas();
                        "
                      >
                        <option
                          disabled
                          value=""
                        >
                          {{ $t("Please select one") }}
                        </option>
                        <option
                          v-for="n in 20"
                          :key="n + 1"
                          :value="parseInt(n + 1)"
                        >
                          {{ n + 1 }} {{ $t("guns") }}
                        </option>
                      </select>
                    </div>
                    <div class="col-sm-6">
                      <div v-if="!isFixedLayout">
                        <label>
                          {{ $t("Gun to gun distance of the 2nd vertical stack") }} [{{
                            $store.state.units_system[$store.state.user_data.unit_system].distance
                          }}] &nbsp;
                          <i
                            class="fa fa-info-circle"
                            aria-hidden="true"
                            data-bs-toggle="tooltip"
                            data-bs-placement="top"
                            :title="
                              $t(
                                'Input the vertical distance between guns of the second column of guns, the second column from the left.',
                              )
                            "
                            data-container="body"
                            data-animation="true"
                          >
                          </i>
                          &nbsp;
                        </label>
                        <input
                          v-model.lazy="pistolToPistolDistanceColumn2"
                          :class="
                            pistol_to_pistol_distance_column_2_input_error ? `form-control is-invalid` : `form-control`
                          "
                          type="number"
                          :step="$store.state.user_data.unit_system === 'metric' ? 1 : 0.01"
                          :min="total_pistols_column_2 > 1 ? minPistolToPistolDistanceAllowed : 0"
                          :disabled="$store.state.flightpath_standard_license"
                          @input="pistol_to_pistol_distance_column_2_input_error = false"
                          @change="
                            UpdateLineForm();
                            WithinRangePistolToPistolDistAlertCheck();
                            fillCanvas();
                          "
                          @keydown="numericOnly"
                        />
                      </div>
                    </div>
                    <div class="col-sm-6 mt-4">
                      <label>
                        {{ $t("Horizontal distance between 1st and 2nd vertical stacks") }} [{{
                          $store.state.units_system[$store.state.user_data.unit_system].distance
                        }}] &nbsp;
                        <i
                          class="fa fa-info-circle"
                          aria-hidden="true"
                          data-bs-toggle="tooltip"
                          data-bs-placement="top"
                          :title="
                            $t(
                              'Input the horizontal distance measured between the first column and the second column of guns.',
                            )
                          "
                          data-container="body"
                          data-animation="true"
                        >
                        </i>
                        &nbsp;
                      </label>
                      <input
                        v-model.lazy="pistolColumnDistance2"
                        :class="pistol_column_distance_2_input_error ? `form-control is-invalid` : `form-control`"
                        type="number"
                        :step="$store.state.user_data.unit_system === 'metric' ? 1 : 0.01"
                        :min="minPistolToPistolDistanceAllowed"
                        :disabled="$store.state.flightpath_standard_license"
                        @input="pistol_column_distance_2_input_error = false"
                        @change="
                          UpdateLineForm();
                          WithinRangeColumnToColumnDistAlertCheck(pistolColumnDistance2, 1).then(fillCanvas());
                        "
                        @keydown="numericOnly"
                      />
                    </div>
                    <div class="col-sm-6 mt-4">
                      <div v-if="!isFixedLayout">
                        <label>
                          {{ $t("2nd vertical guns stack height offset") }} [{{
                            $store.state.units_system[$store.state.user_data.unit_system].distance
                          }}] &nbsp;
                          <i
                            class="fa fa-info-circle"
                            aria-hidden="true"
                            data-bs-toggle="tooltip"
                            data-bs-placement="top"
                            :title="
                              $t(
                                'Input the vertical guns stack height offset as the difference in respect to the previous one on the left. If the gun stacks are at equal height leave the default 0 offset. A positive value means the column is higher than the previous one, and a negative means the second stack is shorter than the first one.',
                              )
                            "
                            data-container="body"
                            data-animation="true"
                          >
                          </i>
                          &nbsp;
                        </label>
                        <input
                          v-model.lazy="pistolColumnVerticalOffset2"
                          :class="
                            pistol_column_vertical_offset_2_input_error ? `form-control is-invalid` : `form-control`
                          "
                          type="number"
                          :step="$store.state.user_data.unit_system === 'metric' ? 1 : 0.01"
                          :disabled="$store.state.flightpath_standard_license"
                          @input="pistol_column_vertical_offset_2_input_error = false"
                          @change="
                            UpdateLineForm();
                            fillCanvas();
                          "
                          @keydown="numericOnly"
                        />
                      </div>
                    </div>
                  </div>
                  <div
                    v-if="(isMixedLayout || isStaticMixedLayout) && num_pistol_columns_mixed_layout >= 3"
                    class="row mt-3"
                  >
                    <h6 class="font-weight-bolder">{{ $t("3rd guns column") }}</h6>
                    <div class="col-sm-6">
                      <label>
                        {{ $t("Total number of guns of the 3rd vertical stack") }}
                      </label>
                      <select
                        v-model="total_pistols_column_3"
                        :class="total_pistols_column_3_input_error ? `form-control is-invalid` : `form-control`"
                        name="total-pistols-column-3-choices"
                        :disabled="$store.state.flightpath_standard_license"
                        style="appearance: listbox"
                        @input="total_pistols_column_3_input_error = false"
                        @change="
                          UpdateLineForm();
                          fillCanvas();
                        "
                      >
                        <option
                          disabled
                          value=""
                        >
                          {{ $t("Please select one") }}
                        </option>
                        <option
                          v-for="n in 20"
                          :key="n + 1"
                          :value="parseInt(n + 1)"
                        >
                          {{ n + 1 }} {{ $t("guns") }}
                        </option>
                      </select>
                    </div>
                    <div class="col-sm-6">
                      <div v-if="!isFixedLayout">
                        <label>
                          {{ $t("Gun to gun distance of the 3rd vertical stack") }} [{{
                            $store.state.units_system[$store.state.user_data.unit_system].distance
                          }}] &nbsp;
                          <i
                            class="fa fa-info-circle"
                            aria-hidden="true"
                            data-bs-toggle="tooltip"
                            data-bs-placement="top"
                            :title="
                              $t(
                                'Input the vertical distance between guns of the third column of guns, the third column from the left.',
                              )
                            "
                            data-container="body"
                            data-animation="true"
                          >
                          </i>
                          &nbsp;
                        </label>
                        <input
                          v-model.lazy="pistolToPistolDistanceColumn3"
                          :class="
                            pistol_to_pistol_distance_column_3_input_error ? `form-control is-invalid` : `form-control`
                          "
                          type="number"
                          :step="$store.state.user_data.unit_system === 'metric' ? 1 : 0.01"
                          :min="total_pistols_column_3 > 1 ? minPistolToPistolDistanceAllowed : 0"
                          :disabled="$store.state.flightpath_standard_license"
                          @input="pistol_to_pistol_distance_column_3_input_error = false"
                          @change="
                            UpdateLineForm();
                            WithinRangePistolToPistolDistAlertCheck();
                            fillCanvas();
                          "
                          @keydown="numericOnly"
                        />
                      </div>
                    </div>
                    <div class="col-sm-6 mt-4">
                      <label>
                        {{ $t("Horizontal distance between 2nd and 3rd vertical stacks") }} [{{
                          $store.state.units_system[$store.state.user_data.unit_system].distance
                        }}] &nbsp;
                        <i
                          class="fa fa-info-circle"
                          aria-hidden="true"
                          data-bs-toggle="tooltip"
                          data-bs-placement="top"
                          :title="
                            $t(
                              'Input the horizontal distance measured between the second column and the third column of guns',
                            )
                          "
                          data-container="body"
                          data-animation="true"
                        >
                        </i>
                        &nbsp;
                      </label>
                      <input
                        v-model.lazy="pistolColumnDistance3"
                        :class="pistol_column_distance_3_input_error ? `form-control is-invalid` : `form-control`"
                        type="number"
                        :step="$store.state.user_data.unit_system === 'metric' ? 1 : 0.01"
                        :min="minPistolToPistolDistanceAllowed"
                        :disabled="$store.state.flightpath_standard_license"
                        @input="pistol_column_distance_3_input_error = false"
                        @change="
                          UpdateLineForm();
                          WithinRangeColumnToColumnDistAlertCheck(pistolColumnDistance3, 2).then(fillCanvas());
                        "
                        @keydown="numericOnly"
                      />
                    </div>
                    <div class="col-sm-6 mt-4">
                      <div v-if="!isFixedLayout">
                        <label>
                          {{ $t("3rd vertical guns stack height offset") }} [{{
                            $store.state.units_system[$store.state.user_data.unit_system].distance
                          }}] &nbsp;
                          <i
                            class="fa fa-info-circle"
                            aria-hidden="true"
                            data-bs-toggle="tooltip"
                            data-bs-placement="top"
                            :title="
                              $t(
                                'Input the vertical guns stack height offset as the difference in respect to the previous one on the left. If the gun stacks are at equal height leave the default 0 offset. A positive value means the column is higher than the previous one, and a negative means the second stack is shorter than the first one.',
                              )
                            "
                            data-container="body"
                            data-animation="true"
                          >
                          </i>
                          &nbsp;
                        </label>
                        <input
                          v-model.lazy="pistolColumnVerticalOffset3"
                          :class="
                            pistol_column_vertical_offset_3_input_error ? `form-control is-invalid` : `form-control`
                          "
                          type="number"
                          :step="$store.state.user_data.unit_system === 'metric' ? 1 : 0.01"
                          :disabled="$store.state.flightpath_standard_license"
                          @input="pistol_column_vertical_offset_3_input_error = false"
                          @change="
                            UpdateLineForm();
                            fillCanvas();
                          "
                          @keydown="numericOnly"
                        />
                      </div>
                    </div>
                  </div>
                  <div
                    v-if="(isMixedLayout || isStaticMixedLayout) && num_pistol_columns_mixed_layout >= 4"
                    class="row mt-3"
                  >
                    <h6 class="font-weight-bolder">{{ $t("4th guns column") }}</h6>
                    <div class="col-sm-6">
                      <label> {{ $t("Total number of guns of the 4th vertical stack") }} </label>
                      <select
                        v-model="total_pistols_column_4"
                        :class="total_pistols_column_4_input_error ? `form-control is-invalid` : `form-control`"
                        name="total-pistols-column-4-choices"
                        :disabled="$store.state.flightpath_standard_license"
                        style="appearance: listbox"
                        @input="total_pistols_column_4_input_error = false"
                        @change="
                          UpdateLineForm();
                          fillCanvas();
                        "
                      >
                        <option
                          disabled
                          value=""
                        >
                          {{ $t("Please select one") }}
                        </option>
                        <option
                          v-for="n in 20"
                          :key="n + 1"
                          :value="parseInt(n + 1)"
                        >
                          {{ n + 1 }} {{ $t("guns") }}
                        </option>
                      </select>
                    </div>
                    <div class="col-sm-6">
                      <div v-if="!isFixedLayout">
                        <label>
                          {{ $t("Gun to gun distance of the 4th vertical stack") }} [{{
                            $store.state.units_system[$store.state.user_data.unit_system].distance
                          }}] &nbsp;
                          <i
                            class="fa fa-info-circle"
                            aria-hidden="true"
                            data-bs-toggle="tooltip"
                            data-bs-placement="top"
                            :title="
                              $t(
                                'Input the vertical distance between guns of the fourth column of guns, i.e the fourth column from the left.',
                              )
                            "
                            data-container="body"
                            data-animation="true"
                          >
                          </i>
                          &nbsp;
                        </label>
                        <input
                          v-model.lazy="pistolToPistolDistanceColumn4"
                          :class="
                            pistol_to_pistol_distance_column_4_input_error ? `form-control is-invalid` : `form-control`
                          "
                          type="number"
                          :step="$store.state.user_data.unit_system === 'metric' ? 1 : 0.01"
                          :min="total_pistols_column_4 > 1 ? minPistolToPistolDistanceAllowed : 0"
                          :disabled="$store.state.flightpath_standard_license"
                          @input="pistol_to_pistol_distance_column_4_input_error = false"
                          @change="
                            UpdateLineForm();
                            WithinRangePistolToPistolDistAlertCheck();
                            fillCanvas();
                          "
                          @keydown="numericOnly"
                        />
                      </div>
                    </div>
                    <div class="col-sm-6 mt-4">
                      <label>
                        {{ $t("Horizontal distance between 3rd and 4th vertical stacks") }} [{{
                          $store.state.units_system[$store.state.user_data.unit_system].distance
                        }}] &nbsp;
                        <i
                          class="fa fa-info-circle"
                          aria-hidden="true"
                          data-bs-toggle="tooltip"
                          data-bs-placement="top"
                          :title="
                            $t(
                              'Input the horizontal distance measured between the third column and the fourth column of guns',
                            )
                          "
                          data-container="body"
                          data-animation="true"
                        >
                        </i>
                        &nbsp;
                      </label>
                      <input
                        v-model.lazy="pistolColumnDistance4"
                        :class="pistol_column_distance_4_input_error ? `form-control is-invalid` : `form-control`"
                        type="number"
                        :step="$store.state.user_data.unit_system === 'metric' ? 1 : 0.01"
                        :min="minPistolToPistolDistanceAllowed"
                        :disabled="$store.state.flightpath_standard_license"
                        @input="pistol_column_distance_4_input_error = false"
                        @change="
                          WithinRangeColumnToColumnDistAlertCheck(pistolColumnDistance3, 2).then(fillCanvas());
                          UpdateLineForm();
                        "
                        @keydown="numericOnly"
                      />
                    </div>
                    <div class="col-sm-6 mt-4">
                      <div v-if="!isFixedLayout">
                        <label>
                          {{ $t("4th vertical guns stack height offset") }} [{{
                            $store.state.units_system[$store.state.user_data.unit_system].distance
                          }}] &nbsp;
                          <i
                            class="fa fa-info-circle"
                            aria-hidden="true"
                            data-bs-toggle="tooltip"
                            data-bs-placement="top"
                            :title="
                              $t(
                                'Input the vertical guns stack height offset as the difference in respect to the previous one on the left. If the gun stacks are at equal height leave the default 0 offset. A positive value means the column is higher than the previous one, and a negative means the second stack is shorter than the first one.',
                              )
                            "
                            data-container="body"
                            data-animation="true"
                          >
                          </i>
                          &nbsp;
                        </label>
                        <input
                          v-model.lazy="pistolColumnVerticalOffset4"
                          :class="
                            pistol_column_vertical_offset_4_input_error ? `form-control is-invalid` : `form-control`
                          "
                          type="number"
                          :step="$store.state.user_data.unit_system === 'metric' ? 1 : 0.01"
                          :disabled="$store.state.flightpath_standard_license"
                          @input="pistol_column_vertical_offset_4_input_error = false"
                          @change="
                            fillCanvas();
                            UpdateLineForm();
                          "
                          @keydown="numericOnly"
                        />
                      </div>
                    </div>
                  </div>
                  <div
                    v-if="(isMixedLayout || isStaticMixedLayout) && num_pistol_columns_mixed_layout >= 5"
                    class="row mt-3"
                  >
                    <h6 class="font-weight-bolder">{{ $t("5th guns column") }}</h6>
                    <div class="col-sm-6">
                      <label class="mt-3">
                        {{ $t("Total number of guns of the 5th vertical stack") }}
                      </label>
                      <select
                        v-model="total_pistols_column_5"
                        :class="total_pistols_column_5_input_error ? `form-control is-invalid` : `form-control`"
                        name="total-pistols-column-5-choices"
                        :disabled="$store.state.flightpath_standard_license"
                        style="appearance: listbox"
                        @input="total_pistols_column_5_input_error = false"
                        @change="
                          fillCanvas();
                          UpdateLineForm();
                        "
                      >
                        <option
                          disabled
                          value=""
                        >
                          {{ $t("Please select one") }}
                        </option>
                        <option
                          v-for="n in 20"
                          :key="n + 1"
                          :value="parseInt(n + 1)"
                        >
                          {{ n + 1 }} {{ $t("guns") }}
                        </option>
                      </select>
                    </div>
                    <div class="col-sm-6 mt-2">
                      <div v-if="!isFixedLayout">
                        <label>
                          {{ $t("Gun to gun distance of the 5th vertical stack") }} [{{
                            $store.state.units_system[$store.state.user_data.unit_system].distance
                          }}] &nbsp;
                          <i
                            class="fa fa-info-circle"
                            aria-hidden="true"
                            data-bs-toggle="tooltip"
                            data-bs-placement="top"
                            :title="
                              $t(
                                'Input the vertical distance between guns of the fifth column of guns, i.e the fifth column from the left',
                              )
                            "
                            data-container="body"
                            data-animation="true"
                          >
                          </i>
                          &nbsp;
                        </label>
                        <input
                          v-model.lazy="pistolToPistolDistanceColumn5"
                          :class="
                            pistol_to_pistol_distance_column_5_input_error ? `form-control is-invalid` : `form-control`
                          "
                          type="number"
                          :step="$store.state.user_data.unit_system === 'metric' ? 1 : 0.01"
                          :min="total_pistols_column_5 > 1 ? minPistolToPistolDistanceAllowed : 0"
                          :disabled="$store.state.flightpath_standard_license"
                          @input="pistol_to_pistol_distance_column_5_input_error = false"
                          @change="
                            UpdateLineForm();
                            WithinRangePistolToPistolDistAlertCheck();
                            fillCanvas();
                          "
                          @keydown="numericOnly"
                        />
                      </div>
                    </div>
                    <div class="col-sm-6 mt-4">
                      <label>
                        {{ $t("Horizontal distance between the 4th and 5th stacks") }} [{{
                          $store.state.units_system[$store.state.user_data.unit_system].distance
                        }}] &nbsp;
                        <i
                          class="fa fa-info-circle"
                          aria-hidden="true"
                          data-bs-toggle="tooltip"
                          data-bs-placement="top"
                          :title="
                            $t(
                              'Input the horizontal distance measured between the fourth column and the fifth column of guns',
                            )
                          "
                          data-container="body"
                          data-animation="true"
                        >
                        </i>
                        &nbsp;
                      </label>
                      <input
                        v-model.lazy="pistolColumnDistance5"
                        :class="pistol_column_distance_5_input_error ? `form-control is-invalid` : `form-control`"
                        type="number"
                        :step="$store.state.user_data.unit_system === 'metric' ? 1 : 0.01"
                        :min="minPistolToPistolDistanceAllowed"
                        :disabled="$store.state.flightpath_standard_license"
                        @input="pistol_column_distance_5_input_error = false"
                        @change="
                          WithinRangeColumnToColumnDistAlertCheck(pistolColumnDistance5, 4).then(fillCanvas());
                          UpdateLineForm();
                        "
                        @keydown="numericOnly"
                      />
                    </div>
                    <div
                      v-if="!isFixedLayout"
                      class="col-sm-6 mt-4"
                    >
                      <label>
                        {{ $t("5th vertical guns stack height offset") }} [{{
                          $store.state.units_system[$store.state.user_data.unit_system].distance
                        }}] &nbsp;
                        <i
                          class="fa fa-info-circle"
                          aria-hidden="true"
                          data-bs-toggle="tooltip"
                          data-bs-placement="top"
                          :title="
                            $t(
                              'Input the vertical guns stack height offset as the difference in respect to the previous one on the left. If the gun stacks are at equal height leave the default 0 offset. A positive value means the column is higher than the previous one, and a negative means the second stack is shorter than the first one.',
                            )
                          "
                          data-container="body"
                          data-animation="true"
                        >
                        </i>
                        &nbsp;
                      </label>
                      <input
                        v-model.lazy="pistolColumnVerticalOffset5"
                        :class="
                          pistol_column_vertical_offset_5_input_error ? `form-control is-invalid` : `form-control`
                        "
                        type="number"
                        :step="$store.state.user_data.unit_system === 'metric' ? 1 : 0.01"
                        :disabled="$store.state.flightpath_standard_license"
                        @input="pistol_column_vertical_offset_5_input_error = false"
                        @change="
                          fillCanvas();
                          UpdateLineForm();
                        "
                        @keydown="numericOnly"
                      />
                    </div>
                    <div
                      v-if="(isMixedLayout || isStaticMixedLayout) && num_pistol_columns_mixed_layout >= 6"
                      class="row mt-3"
                    >
                      <h6 class="font-weight-bolder">{{ $t("6th guns column") }}</h6>
                      <div class="col-sm-6">
                        <label class="mt-3">
                          {{ $t("Total number of guns of the 6th vertical stack") }}
                        </label>
                        <select
                          v-model="total_pistols_column_6"
                          :class="total_pistols_column_6_input_error ? `form-control is-invalid` : `form-control`"
                          name="total-pistols-column-6-choices"
                          :disabled="$store.state.flightpath_standard_license"
                          style="appearance: listbox"
                          @input="total_pistols_column_6_input_error = false"
                          @change="
                            fillCanvas();
                            UpdateLineForm();
                          "
                        >
                          <option
                            disabled
                            value=""
                          >
                            {{ $t("Please select one") }}
                          </option>
                          <option
                            v-for="n in 20"
                            :key="n + 1"
                            :value="parseInt(n + 1)"
                          >
                            {{ n + 1 }} {{ $t("guns") }}
                          </option>
                        </select>
                      </div>
                      <div class="col-sm-6 mt-2">
                        <div v-if="!isFixedLayout">
                          <label>
                            {{ $t("Gun to gun distance of the 6th vertical stack") }} [{{
                              $store.state.units_system[$store.state.user_data.unit_system].distance
                            }}] &nbsp;
                            <i
                              class="fa fa-info-circle"
                              aria-hidden="true"
                              data-bs-toggle="tooltip"
                              data-bs-placement="top"
                              :title="
                                $t(
                                  'Input the vertical distance between guns of the sixth column of guns, i.e the sixth column from the left',
                                )
                              "
                              data-container="body"
                              data-animation="true"
                            >
                            </i>
                            &nbsp;
                          </label>
                          <input
                            v-model.lazy="pistolToPistolDistanceColumn6"
                            :class="
                              pistol_to_pistol_distance_column_6_input_error
                                ? `form-control is-invalid`
                                : `form-control`
                            "
                            type="number"
                            :step="$store.state.user_data.unit_system === 'metric' ? 1 : 0.01"
                            :min="total_pistols_column_6 > 1 ? minPistolToPistolDistanceAllowed : 0"
                            :disabled="$store.state.flightpath_standard_license"
                            @input="pistol_to_pistol_distance_column_6_input_error = false"
                            @change="
                              UpdateLineForm();
                              WithinRangePistolToPistolDistAlertCheck();
                              fillCanvas();
                            "
                            @keydown="numericOnly"
                          />
                        </div>
                      </div>
                      <div class="col-sm-6 mt-4">
                        <label>
                          {{ $t("Horizontal distance between the 5th and 6th stacks") }} [{{
                            $store.state.units_system[$store.state.user_data.unit_system].distance
                          }}] &nbsp;
                          <i
                            class="fa fa-info-circle"
                            aria-hidden="true"
                            data-bs-toggle="tooltip"
                            data-bs-placement="top"
                            :title="
                              $t(
                                'Input the horizontal distance measured between the fifth column and the sixth column of guns',
                              )
                            "
                            data-container="body"
                            data-animation="true"
                          >
                          </i>
                          &nbsp;
                        </label>
                        <input
                          v-model.lazy="pistolColumnDistance6"
                          :class="pistol_column_distance_6_input_error ? `form-control is-invalid` : `form-control`"
                          type="number"
                          :step="$store.state.user_data.unit_system === 'metric' ? 1 : 0.01"
                          :min="minPistolToPistolDistanceAllowed"
                          :disabled="$store.state.flightpath_standard_license"
                          @input="pistol_column_distance_6_input_error = false"
                          @change="
                            WithinRangeColumnToColumnDistAlertCheck(pistolColumnDistance6, 4).then(fillCanvas());
                            UpdateLineForm();
                          "
                          @keydown="numericOnly"
                        />
                      </div>
                      <div
                        v-if="!isFixedLayout"
                        class="col-sm-6 mt-4"
                      >
                        <label>
                          {{ $t("6th vertical guns stack height offset") }} [{{
                            $store.state.units_system[$store.state.user_data.unit_system].distance
                          }}] &nbsp;
                          <i
                            class="fa fa-info-circle"
                            aria-hidden="true"
                            data-bs-toggle="tooltip"
                            data-bs-placement="top"
                            :title="
                              $t(
                                'Input the vertical guns stack height offset as the difference in respect to the previous one on the left. If the gun stacks are at equal height leave the default 0 offset. A positive value means the column is higher than the previous one, and a negative means the second stack is shorter than the first one.',
                              )
                            "
                            data-container="body"
                            data-animation="true"
                          >
                          </i>
                          &nbsp;
                        </label>
                        <input
                          v-model.lazy="pistolColumnVerticalOffset6"
                          :class="
                            pistol_column_vertical_offset_6_input_error ? `form-control is-invalid` : `form-control`
                          "
                          type="number"
                          :step="$store.state.user_data.unit_system === 'metric' ? 1 : 0.01"
                          :disabled="$store.state.flightpath_standard_license"
                          @input="pistol_column_vertical_offset_6_input_error = false"
                          @change="
                            fillCanvas();
                            UpdateLineForm();
                          "
                          @keydown="numericOnly"
                        />
                      </div>
                    </div>
                    <div
                      v-if="(isMixedLayout || isStaticMixedLayout) && num_pistol_columns_mixed_layout >= 7"
                      class="row mt-3"
                    >
                      <h6 class="font-weight-bolder">{{ $t("7th guns column") }}</h6>
                      <div class="col-sm-6">
                        <label class="mt-3">
                          {{ $t("Total number of guns of the 7th vertical stack") }}
                        </label>
                        <select
                          v-model="total_pistols_column_7"
                          :class="total_pistols_column_7_input_error ? `form-control is-invalid` : `form-control`"
                          name="total-pistols-column-7-choices"
                          :disabled="$store.state.flightpath_standard_license"
                          style="appearance: listbox"
                          @input="total_pistols_column_7_input_error = false"
                          @change="
                            fillCanvas();
                            UpdateLineForm();
                          "
                        >
                          <option
                            disabled
                            value=""
                          >
                            {{ $t("Please select one") }}
                          </option>
                          <option
                            v-for="n in 20"
                            :key="n + 1"
                            :value="parseInt(n + 1)"
                          >
                            {{ n + 1 }} {{ $t("guns") }}
                          </option>
                        </select>
                      </div>
                      <div class="col-sm-6 mt-2">
                        <div v-if="!isFixedLayout">
                          <label>
                            {{ $t("Gun to gun distance of the 7th vertical stack") }} [{{
                              $store.state.units_system[$store.state.user_data.unit_system].distance
                            }}] &nbsp;
                            <i
                              class="fa fa-info-circle"
                              aria-hidden="true"
                              data-bs-toggle="tooltip"
                              data-bs-placement="top"
                              :title="
                                $t(
                                  'Input the vertical distance between guns of the seventh column of guns, i.e the seventh column from the left',
                                )
                              "
                              data-container="body"
                              data-animation="true"
                            >
                            </i>
                            &nbsp;
                          </label>
                          <input
                            v-model.lazy="pistolToPistolDistanceColumn7"
                            :class="
                              pistol_to_pistol_distance_column_7_input_error
                                ? `form-control is-invalid`
                                : `form-control`
                            "
                            type="number"
                            :step="$store.state.user_data.unit_system === 'metric' ? 1 : 0.01"
                            :min="total_pistols_column_7 > 1 ? minPistolToPistolDistanceAllowed : 0"
                            :disabled="$store.state.flightpath_standard_license"
                            @input="pistol_to_pistol_distance_column_7_input_error = false"
                            @change="
                              UpdateLineForm();
                              WithinRangePistolToPistolDistAlertCheck();
                              fillCanvas();
                            "
                            @keydown="numericOnly"
                          />
                        </div>
                      </div>
                      <div class="col-sm-6 mt-4">
                        <label>
                          {{ $t("Horizontal distance between the 6th and 7th stacks") }} [{{
                            $store.state.units_system[$store.state.user_data.unit_system].distance
                          }}] &nbsp;
                          <i
                            class="fa fa-info-circle"
                            aria-hidden="true"
                            data-bs-toggle="tooltip"
                            data-bs-placement="top"
                            :title="
                              $t(
                                'Input the horizontal distance measured between the sixth column and the seventh column of guns',
                              )
                            "
                            data-container="body"
                            data-animation="true"
                          >
                          </i>
                          &nbsp;
                        </label>
                        <input
                          v-model.lazy="pistolColumnDistance7"
                          :class="pistol_column_distance_7_input_error ? `form-control is-invalid` : `form-control`"
                          type="number"
                          :step="$store.state.user_data.unit_system === 'metric' ? 1 : 0.01"
                          :min="minPistolToPistolDistanceAllowed"
                          :disabled="$store.state.flightpath_standard_license"
                          @input="pistol_column_distance_7_input_error = false"
                          @change="
                            WithinRangeColumnToColumnDistAlertCheck(pistolColumnDistance7, 6).then(fillCanvas());
                            UpdateLineForm();
                          "
                          @keydown="numericOnly"
                        />
                      </div>
                      <div
                        v-if="!isFixedLayout"
                        class="col-sm-6 mt-4"
                      >
                        <label>
                          {{ $t("7th vertical guns stack height offset") }} [{{
                            $store.state.units_system[$store.state.user_data.unit_system].distance
                          }}] &nbsp;
                          <i
                            class="fa fa-info-circle"
                            aria-hidden="true"
                            data-bs-toggle="tooltip"
                            data-bs-placement="top"
                            :title="
                              $t(
                                'Input the vertical guns stack height offset as the difference in respect to the previous one on the left. If the gun stacks are at equal height leave the default 0 offset. A positive value means the column is higher than the previous one, and a negative means the second stack is shorter than the first one.',
                              )
                            "
                            data-container="body"
                            data-animation="true"
                          >
                          </i>
                          &nbsp;
                        </label>
                        <input
                          v-model.lazy="pistolColumnVerticalOffset7"
                          :class="
                            pistol_column_vertical_offset_7_input_error ? `form-control is-invalid` : `form-control`
                          "
                          type="number"
                          :step="$store.state.user_data.unit_system === 'metric' ? 1 : 0.01"
                          :disabled="$store.state.flightpath_standard_license"
                          @input="pistol_column_vertical_offset_7_input_error = false"
                          @change="
                            fillCanvas();
                            UpdateLineForm();
                          "
                          @keydown="numericOnly"
                        />
                      </div>
                    </div>
                    <div
                      v-if="(isMixedLayout || isStaticMixedLayout) && num_pistol_columns_mixed_layout >= 8"
                      class="row mt-3"
                    >
                      <h6 class="font-weight-bolder">{{ $t("8th guns column") }}</h6>
                      <div class="col-sm-6">
                        <label class="mt-3">
                          {{ $t("Total number of guns of the 8th vertical stack") }}
                        </label>
                        <select
                          v-model="total_pistols_column_8"
                          :class="total_pistols_column_8_input_error ? `form-control is-invalid` : `form-control`"
                          name="total-pistols-column-8-choices"
                          :disabled="$store.state.flightpath_standard_license"
                          style="appearance: listbox"
                          @input="total_pistols_column_8_input_error = false"
                          @change="
                            fillCanvas();
                            UpdateLineForm();
                          "
                        >
                          <option
                            disabled
                            value=""
                          >
                            {{ $t("Please select one") }}
                          </option>
                          <option
                            v-for="n in 20"
                            :key="n + 1"
                            :value="parseInt(n + 1)"
                          >
                            {{ n + 1 }} {{ $t("guns") }}
                          </option>
                        </select>
                      </div>
                      <div class="col-sm-6 mt-2">
                        <div v-if="!isFixedLayout">
                          <label>
                            {{ $t("Gun to gun distance of the 8th vertical stack") }} [{{
                              $store.state.units_system[$store.state.user_data.unit_system].distance
                            }}] &nbsp;
                            <i
                              class="fa fa-info-circle"
                              aria-hidden="true"
                              data-bs-toggle="tooltip"
                              data-bs-placement="top"
                              :title="
                                $t(
                                  'Input the vertical distance between guns of the eighth column of guns, i.e the eighth column from the left',
                                )
                              "
                              data-container="body"
                              data-animation="true"
                            >
                            </i>
                            &nbsp;
                          </label>
                          <input
                            v-model.lazy="pistolToPistolDistanceColumn8"
                            :class="
                              pistol_to_pistol_distance_column_8_input_error
                                ? `form-control is-invalid`
                                : `form-control`
                            "
                            type="number"
                            :step="$store.state.user_data.unit_system === 'metric' ? 1 : 0.01"
                            :min="total_pistols_column_8 > 1 ? minPistolToPistolDistanceAllowed : 0"
                            :disabled="$store.state.flightpath_standard_license"
                            @input="pistol_to_pistol_distance_column_8_input_error = false"
                            @change="
                              UpdateLineForm();
                              WithinRangePistolToPistolDistAlertCheck();
                              fillCanvas();
                            "
                            @keydown="numericOnly"
                          />
                        </div>
                      </div>
                      <div class="col-sm-6 mt-4">
                        <label>
                          {{ $t("Horizontal distance between the 7th and 8th stacks") }} [{{
                            $store.state.units_system[$store.state.user_data.unit_system].distance
                          }}] &nbsp;
                          <i
                            class="fa fa-info-circle"
                            aria-hidden="true"
                            data-bs-toggle="tooltip"
                            data-bs-placement="top"
                            :title="
                              $t(
                                'Input the horizontal distance measured between the seventh column and the eighth column of guns',
                              )
                            "
                            data-container="body"
                            data-animation="true"
                          >
                          </i>
                          &nbsp;
                        </label>
                        <input
                          v-model.lazy="pistolColumnDistance8"
                          :class="pistol_column_distance_8_input_error ? `form-control is-invalid` : `form-control`"
                          type="number"
                          :step="$store.state.user_data.unit_system === 'metric' ? 1 : 0.01"
                          :min="minPistolToPistolDistanceAllowed"
                          :disabled="$store.state.flightpath_standard_license"
                          @input="pistol_column_distance_8_input_error = false"
                          @change="
                            WithinRangeColumnToColumnDistAlertCheck(pistolColumnDistance8, 6).then(fillCanvas());
                            UpdateLineForm();
                          "
                          @keydown="numericOnly"
                        />
                      </div>
                      <div
                        v-if="!isFixedLayout"
                        class="col-sm-6 mt-4"
                      >
                        <label>
                          {{ $t("8th vertical guns stack height offset") }} [{{
                            $store.state.units_system[$store.state.user_data.unit_system].distance
                          }}] &nbsp;
                          <i
                            class="fa fa-info-circle"
                            aria-hidden="true"
                            data-bs-toggle="tooltip"
                            data-bs-placement="top"
                            :title="
                              $t(
                                'Input the vertical guns stack height offset as the difference in respect to the previous one on the left. If the gun stacks are at equal height leave the default 0 offset. A positive value means the column is higher than the previous one, and a negative means the second stack is shorter than the first one.',
                              )
                            "
                            data-container="body"
                            data-animation="true"
                          >
                          </i>
                          &nbsp;
                        </label>
                        <input
                          v-model.lazy="pistolColumnVerticalOffset8"
                          :class="
                            pistol_column_vertical_offset_8_input_error ? `form-control is-invalid` : `form-control`
                          "
                          type="number"
                          :step="$store.state.user_data.unit_system === 'metric' ? 1 : 0.01"
                          :disabled="$store.state.flightpath_standard_license"
                          @input="pistol_column_vertical_offset_8_input_error = false"
                          @change="
                            fillCanvas();
                            UpdateLineForm();
                          "
                          @keydown="numericOnly"
                        />
                      </div>
                    </div>
                    <div
                      v-if="(isMixedLayout || isStaticMixedLayout) && num_pistol_columns_mixed_layout >= 9"
                      class="row mt-3"
                    >
                      <h6 class="font-weight-bolder">{{ $t("9th guns column") }}</h6>
                      <div class="col-sm-6">
                        <label class="mt-3">
                          {{ $t("Total number of guns of the 9th vertical stack") }}
                        </label>
                        <select
                          v-model="total_pistols_column_9"
                          :class="total_pistols_column_9_input_error ? `form-control is-invalid` : `form-control`"
                          name="total-pistols-column-9-choices"
                          :disabled="$store.state.flightpath_standard_license"
                          style="appearance: listbox"
                          @input="total_pistols_column_9_input_error = false"
                          @change="
                            fillCanvas();
                            UpdateLineForm();
                          "
                        >
                          <option
                            disabled
                            value=""
                          >
                            {{ $t("Please select one") }}
                          </option>
                          <option
                            v-for="n in 20"
                            :key="n + 1"
                            :value="parseInt(n + 1)"
                          >
                            {{ n + 1 }} {{ $t("guns") }}
                          </option>
                        </select>
                      </div>
                      <div class="col-sm-6 mt-2">
                        <div v-if="!isFixedLayout">
                          <label>
                            {{ $t("Gun to gun distance of the 9th vertical stack") }} [{{
                              $store.state.units_system[$store.state.user_data.unit_system].distance
                            }}] &nbsp;
                            <i
                              class="fa fa-info-circle"
                              aria-hidden="true"
                              data-bs-toggle="tooltip"
                              data-bs-placement="top"
                              :title="
                                $t(
                                  'Input the vertical distance between guns of the ninth column of guns, i.e the ninth column from the left',
                                )
                              "
                              data-container="body"
                              data-animation="true"
                            >
                            </i>
                            &nbsp;
                          </label>
                          <input
                            v-model.lazy="pistolToPistolDistanceColumn9"
                            :class="
                              pistol_to_pistol_distance_column_9_input_error
                                ? `form-control is-invalid`
                                : `form-control`
                            "
                            type="number"
                            :step="$store.state.user_data.unit_system === 'metric' ? 1 : 0.01"
                            :min="total_pistols_column_9 > 1 ? minPistolToPistolDistanceAllowed : 0"
                            :disabled="$store.state.flightpath_standard_license"
                            @input="pistol_to_pistol_distance_column_9_input_error = false"
                            @change="
                              UpdateLineForm();
                              WithinRangePistolToPistolDistAlertCheck();
                              fillCanvas();
                            "
                            @keydown="numericOnly"
                          />
                        </div>
                      </div>
                      <div class="col-sm-6 mt-4">
                        <label>
                          {{ $t("Horizontal distance between the 8th and 9th stacks") }} [{{
                            $store.state.units_system[$store.state.user_data.unit_system].distance
                          }}] &nbsp;
                          <i
                            class="fa fa-info-circle"
                            aria-hidden="true"
                            data-bs-toggle="tooltip"
                            data-bs-placement="top"
                            :title="
                              $t(
                                'Input the horizontal distance measured between the eighth column and the ninth column of guns',
                              )
                            "
                            data-container="body"
                            data-animation="true"
                          >
                          </i>
                          &nbsp;
                        </label>
                        <input
                          v-model.lazy="pistolColumnDistance9"
                          :class="pistol_column_distance_9_input_error ? `form-control is-invalid` : `form-control`"
                          type="number"
                          :step="$store.state.user_data.unit_system === 'metric' ? 1 : 0.01"
                          :min="minPistolToPistolDistanceAllowed"
                          :disabled="$store.state.flightpath_standard_license"
                          @input="pistol_column_distance_9_input_error = false"
                          @change="
                            WithinRangeColumnToColumnDistAlertCheck(pistolColumnDistance9, 8).then(fillCanvas());
                            UpdateLineForm();
                          "
                          @keydown="numericOnly"
                        />
                      </div>
                      <div
                        v-if="!isFixedLayout"
                        class="col-sm-6 mt-4"
                      >
                        <label>
                          {{ $t("9th vertical guns stack height offset") }} [{{
                            $store.state.units_system[$store.state.user_data.unit_system].distance
                          }}] &nbsp;
                          <i
                            class="fa fa-info-circle"
                            aria-hidden="true"
                            data-bs-toggle="tooltip"
                            data-bs-placement="top"
                            :title="
                              $t(
                                'Input the vertical guns stack height offset as the difference in respect to the previous one on the left. If the gun stacks are at equal height leave the default 0 offset. A positive value means the column is higher than the previous one, and a negative means the second stack is shorter than the first one.',
                              )
                            "
                            data-container="body"
                            data-animation="true"
                          >
                          </i>
                          &nbsp;
                        </label>
                        <input
                          v-model.lazy="pistolColumnVerticalOffset9"
                          :class="
                            pistol_column_vertical_offset_9_input_error ? `form-control is-invalid` : `form-control`
                          "
                          type="number"
                          :step="$store.state.user_data.unit_system === 'metric' ? 1 : 0.01"
                          :disabled="$store.state.flightpath_standard_license"
                          @input="pistol_column_vertical_offset_9_input_error = false"
                          @change="
                            fillCanvas();
                            UpdateLineForm();
                          "
                          @keydown="numericOnly"
                        />
                      </div>
                    </div>
                    <div
                      v-if="(isMixedLayout || isStaticMixedLayout) && num_pistol_columns_mixed_layout >= 10"
                      class="row mt-3"
                    >
                      <h6 class="font-weight-bolder">{{ $t("10th guns column") }}</h6>
                      <div class="col-sm-6">
                        <label class="mt-3">
                          {{ $t("Total number of guns of the 10th vertical stack") }}
                        </label>
                        <select
                          v-model="total_pistols_column_10"
                          :class="total_pistols_column_10_input_error ? `form-control is-invalid` : `form-control`"
                          name="total-pistols-column-10-choices"
                          :disabled="$store.state.flightpath_standard_license"
                          style="appearance: listbox"
                          @input="total_pistols_column_10_input_error = false"
                          @change="
                            fillCanvas();
                            UpdateLineForm();
                          "
                        >
                          <option
                            disabled
                            value=""
                          >
                            {{ $t("Please select one") }}
                          </option>
                          <option
                            v-for="n in 20"
                            :key="n + 1"
                            :value="parseInt(n + 1)"
                          >
                            {{ n + 1 }} {{ $t("guns") }}
                          </option>
                        </select>
                      </div>
                      <div class="col-sm-6 mt-2">
                        <div v-if="!isFixedLayout">
                          <label>
                            {{ $t("Gun to gun distance of the 10th vertical stack") }} [{{
                              $store.state.units_system[$store.state.user_data.unit_system].distance
                            }}] &nbsp;
                            <i
                              class="fa fa-info-circle"
                              aria-hidden="true"
                              data-bs-toggle="tooltip"
                              data-bs-placement="top"
                              :title="
                                $t(
                                  'Input the vertical distance between guns of the tenth column of guns, i.e the tenth column from the left',
                                )
                              "
                              data-container="body"
                              data-animation="true"
                            >
                            </i>
                            &nbsp;
                          </label>
                          <input
                            v-model.lazy="pistolToPistolDistanceColumn10"
                            :class="
                              pistol_to_pistol_distance_column_10_input_error
                                ? `form-control is-invalid`
                                : `form-control`
                            "
                            type="number"
                            :step="$store.state.user_data.unit_system === 'metric' ? 1 : 0.01"
                            :min="total_pistols_column_10 > 1 ? minPistolToPistolDistanceAllowed : 0"
                            :disabled="$store.state.flightpath_standard_license"
                            @input="pistol_to_pistol_distance_column_10_input_error = false"
                            @change="
                              UpdateLineForm();
                              WithinRangePistolToPistolDistAlertCheck();
                              fillCanvas();
                            "
                            @keydown="numericOnly"
                          />
                        </div>
                      </div>
                      <div class="col-sm-6 mt-4">
                        <label>
                          {{ $t("Horizontal distance between the 9th and 10th stacks") }} [{{
                            $store.state.units_system[$store.state.user_data.unit_system].distance
                          }}] &nbsp;
                          <i
                            class="fa fa-info-circle"
                            aria-hidden="true"
                            data-bs-toggle="tooltip"
                            data-bs-placement="top"
                            :title="
                              $t(
                                'Input the horizontal distance measured between the ninth column and the tenth column of guns',
                              )
                            "
                            data-container="body"
                            data-animation="true"
                          >
                          </i>
                          &nbsp;
                        </label>
                        <input
                          v-model.lazy="pistolColumnDistance10"
                          :class="pistol_column_distance_10_input_error ? `form-control is-invalid` : `form-control`"
                          type="number"
                          :step="$store.state.user_data.unit_system === 'metric' ? 1 : 0.01"
                          :min="minPistolToPistolDistanceAllowed"
                          :disabled="$store.state.flightpath_standard_license"
                          @input="pistol_column_distance_10_input_error = false"
                          @change="
                            WithinRangeColumnToColumnDistAlertCheck(pistolColumnDistance10, 8).then(fillCanvas());
                            UpdateLineForm();
                          "
                          @keydown="numericOnly"
                        />
                      </div>
                      <div
                        v-if="!isFixedLayout"
                        class="col-sm-6 mt-4"
                      >
                        <label>
                          {{ $t("10th vertical guns stack height offset") }} [{{
                            $store.state.units_system[$store.state.user_data.unit_system].distance
                          }}] &nbsp;
                          <i
                            class="fa fa-info-circle"
                            aria-hidden="true"
                            data-bs-toggle="tooltip"
                            data-bs-placement="top"
                            :title="
                              $t(
                                'Input the vertical guns stack height offset as the difference in respect to the previous one on the left. If the gun stacks are at equal height leave the default 0 offset. A positive value means the column is higher than the previous one, and a negative means the second stack is shorter than the first one.',
                              )
                            "
                            data-container="body"
                            data-animation="true"
                          >
                          </i>
                          &nbsp;
                        </label>
                        <input
                          v-model.lazy="pistolColumnVerticalOffset10"
                          :class="
                            pistol_column_vertical_offset_10_input_error ? `form-control is-invalid` : `form-control`
                          "
                          type="number"
                          :step="$store.state.user_data.unit_system === 'metric' ? 1 : 0.01"
                          :disabled="$store.state.flightpath_standard_license"
                          @input="pistol_column_vertical_offset_10_input_error = false"
                          @change="
                            fillCanvas();
                            UpdateLineForm();
                          "
                          @keydown="numericOnly"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <!-- Advanced Parameters -->
                <div
                  v-if="!isFixedLayout"
                  class="row mb-4 mt-4"
                >
                  <div class="col">
                    <h5 class="font-weight-bolder mb-0">
                      {{ $t("Advanced Gun Parameters") }}
                    </h5>
                  </div>
                  <!-- Advanced Parameters Row-->
                  <div class="row mt-2">
                    <div class="col">
                      <label class="mt-1">
                        {{ $t("Gun max movement range") }} [{{
                          $store.state.units_system[$store.state.user_data.unit_system].distance
                        }}] &nbsp;
                        <i
                          class="fa fa-info-circle"
                          aria-hidden="true"
                          data-bs-toggle="tooltip"
                          data-bs-placement="top"
                          :title="
                            $t(
                              'The maximum movement range in the vertical axis that one gun can move, i.e. distance that one gun can move from its highest to its lowest position. By default is set to the gun to gun distance.',
                            )
                          "
                          data-container="body"
                          data-animation="true"
                        >
                        </i>
                        &nbsp;
                      </label>
                      <input
                        v-model.lazy="pistolsMaxMovementRange"
                        :class="pistols_max_movement_range_input_error ? `form-control is-invalid` : `form-control`"
                        type="number"
                        :step="$store.state.user_data.unit_system === 'metric' ? 1 : 0.01"
                        :min="minPistolsMaxMovementRangeAllowed"
                        :max="maxPistolsMaxMovementRangeAllowed"
                        :disabled="$store.state.flightpath_standard_license"
                        @input="
                          pistols_max_movement_range_input_error = false;
                          user_input_max_movement_range = true;
                        "
                        @change="
                          ZeroMaxMovementRangeAlertCheck();
                          changedFieldCheckInitialFillCanvas();
                        "
                        @keydown="numericOnly"
                      />
                    </div>
                    <div class="col">
                      <label class="mt-1">
                        {{ $t("Gun min velocity") }} [{{
                          $store.state.units_system[$store.state.user_data.unit_system].velocity_seconds
                        }}]
                        <i
                          class="fa fa-info-circle"
                          aria-hidden="true"
                          data-bs-toggle="tooltip"
                          data-bs-placement="top"
                          :title="
                            $t('Minimum gun velocity with activated reciprocator') +
                            ' ' +
                            $t('The minimum is') +
                            ' ' +
                            minPistolsVelocityAllowed +
                            ' ' +
                            this.$store.state.units_system[this.$store.state.user_data.unit_system].velocity_minutes +
                            ' ' +
                            $t('and the maximum is') +
                            ' ' +
                            maxAllowedPistolsMinVelocity +
                            ' ' +
                            this.$store.state.units_system[this.$store.state.user_data.unit_system].velocity_minutes +
                            '.'
                          "
                          data-container="body"
                          data-animation="true"
                        >
                        </i>
                      </label>
                      <input
                        v-model.lazy="pistolsMinVelocity"
                        :class="pistols_min_velocity_input_error ? `form-control is-invalid` : `form-control`"
                        type="number"
                        step="0.01"
                        :min="minPistolsVelocityAllowed"
                        :max="maxAllowedPistolsMinVelocity"
                        :disabled="$store.state.flightpath_standard_license"
                        @input="pistols_min_velocity_input_error = false"
                        @change="
                          ZeroPistolsMinVelAlertCheck();
                          checkMinMaxPistolsVelocity();
                        "
                        @keydown="numericOnly"
                      />
                    </div>
                    <div class="col">
                      <label class="mt-1">
                        {{ $t("Gun max velocity") }} [{{
                          $store.state.units_system[$store.state.user_data.unit_system].velocity_seconds
                        }}]
                        <i
                          class="fa fa-info-circle"
                          aria-hidden="true"
                          data-bs-toggle="tooltip"
                          data-bs-placement="top"
                          :title="
                            $t('Maximum gun velocity of the reciprocator') +
                            ' ' +
                            $t('The minimum is') +
                            ' ' +
                            minPistolsVelocityAllowed +
                            ' ' +
                            this.$store.state.units_system[this.$store.state.user_data.unit_system].velocity_minutes +
                            ' ' +
                            $t('and the maximum is') +
                            ' ' +
                            maxAllowedPistolsMinVelocity +
                            ' ' +
                            this.$store.state.units_system[this.$store.state.user_data.unit_system].velocity_minutes +
                            '.'
                          "
                          data-container="body"
                          data-animation="true"
                        >
                        </i>
                      </label>
                      <input
                        v-model.lazy="pistolsMaxVelocity"
                        :class="pistols_max_velocity_input_error ? `form-control is-invalid` : `form-control`"
                        type="number"
                        step="0.01"
                        :min="minAllowedPistolsMaxVelocity"
                        :max="maxPistolsVelocityAllowed"
                        :disabled="$store.state.flightpath_standard_license"
                        @input="pistols_max_velocity_input_error = false"
                        @change="
                          ZeroPistolsMaxVelAlertCheck();
                          checkMinMaxPistolsVelocity();
                          changedFieldCheckInitialFillCanvas();
                        "
                        @keydown="numericOnly"
                      />
                    </div>
                    <div class="col">
                      <label class="mt-1">
                        {{ $t("Gun max acceleration") }}
                        [{{ $store.state.units_system[$store.state.user_data.unit_system].acceleration }}]
                        <i
                          class="fa fa-info-circle"
                          aria-hidden="true"
                          data-bs-toggle="tooltip"
                          data-bs-placement="top"
                          :title="
                            $t('Maximum gun acceleration of the reciprocator') +
                            ' ' +
                            $t('The minimum is') +
                            ' ' +
                            minPistolsMaxAccelerationAllowed +
                            ' ' +
                            this.$store.state.units_system[this.$store.state.user_data.unit_system].acceleration +
                            ' ' +
                            $t('and the maximum is') +
                            ' ' +
                            maxPistolsMaxAccelerationAllowed +
                            ' ' +
                            this.$store.state.units_system[this.$store.state.user_data.unit_system].acceleration +
                            '.'
                          "
                          data-container="body"
                          data-animation="true"
                        >
                        </i>
                      </label>
                      <input
                        v-model.lazy="pistolsMaxAcceleration"
                        :class="pistols_max_acceleration_input_error ? `form-control is-invalid` : `form-control`"
                        type="number"
                        step="0.01"
                        :min="minPistolsMaxAccelerationAllowed"
                        :max="maxPistolsMaxAccelerationAllowed"
                        :disabled="$store.state.flightpath_standard_license"
                        @input="pistols_max_acceleration_input_error = false"
                        @change="changedFieldCheckInitialFillCanvas"
                        @blur="AccelerationMinMaxAlertCheck"
                        @keydown="numericOnly"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              v-if="$store.state.flightpath_standard_license"
              class="mt-4 row"
            >
              <div style="font-weight: bold">
                {{ $t("Line Details are not editable. If you want to make changes to your line, contact us at") }}
                <a href="mailto:info@coatingai.com"> info@coatingai.com </a>
              </div>
            </div>
            <div
              v-if="$store.state.flightpath_pro_license && !isFixedLayout"
              class="text-left d-flex flex-column justify-content-left position-relative mt-2"
            >
              <button
                type="button"
                class="mt-4 mb-0 btn position-absolute bg-gradient-info"
                style="left: 0"
                @click="changeConfigurationMenu()"
              >
                <div v-if="!AdvancedConfiguration">
                  {{ $t("Switch to advanced Line configurator") }}
                </div>
                <div v-else>
                  {{ $t("Switch to standard Line configurator") }}
                </div>
              </button>
            </div>
            <div
              v-if="$store.state.flightpath_pro_license"
              class="text-right d-flex flex-column justify-content-end position-relative mt-8"
            >
              <button
                type="button"
                :class="`mb-6 btn position-absolute ${
                  (LineFormUpdated && LineIsCreated) || (isFormFilled && !LineIsCreated)
                    ? 'bg-gradient-success'
                    : 'bg-gradient-secondary'
                }`"
                style="right: 0"
                @click="createUpdateLine()"
              >
                <div v-if="!LineIsCreated">
                  {{ $t("Create Line") }}
                </div>
                <div v-else>
                  {{ LineFormUpdated ? $t("Save Changes") : $t("No Changes to Save") }}
                </div>
              </button>
            </div>
            <div
              class="card-body pt-0"
              style="text-align: center"
            >
              <h6
                v-if="isFormFilled && !isFixedLayout && advanced_line_definition"
                class="font-weight-bolder pt-1"
              >
                {{ $t("Line Preview") }}
              </h6>
              <canvas
                id="linecanvas"
                class="col-12 mb-4 justify-content-center"
                width="400"
                height="300"
                :style="`max-width: 700px; ${
                  isFormFilled && !isFixedLayout && advanced_line_definition ? '' : 'display: none;'
                }`"
              >
              </canvas>
            </div>
            <div
              v-if="isFormFilled && LineIsCreated"
              class="col-12 ms-auto"
            >
              <div class="d-flex justify-content-center gap-5">
                <button
                  type="button"
                  class="mb-0 btn bg-gradient-success"
                  style="width: 260px; height: 60px"
                  @click="createPowderModel"
                >
                  {{ $t("New Powder Model") }}
                </button>
                <button
                  type="button"
                  class="btn bg-gradient-success"
                  style="width: 100px; height: 60px"
                  @click="$router.push('/')"
                >
                  <i class="fa fa-home fa-2x px-1"></i>
                </button>

                <button
                  type="button"
                  class="mb-0 btn bg-gradient-success"
                  style="width: 260px; height: 60px"
                  @click="
                    this.$store.state.isLoading = true;
                    goToNewOptimization();
                  "
                >
                  {{ $t("New Optimization") }}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import setTooltip from "@/assets/js/tooltip.js";
import { useGetLine } from "@/views/composables.js";

export default {
  name: "LineDetail",
  components: {},
  props: {
    lineIdProp: {
      type: String,
      default: null,
    },
  },
  setup() {
    const { line, getLine } = useGetLine();
    return { line, getLine };
  },
  data() {
    this.$i18n.locale = this.$store.state.user_data.language;
    return {
      lineId: null,
      advanced_line_definition: false,
      line_form: {
        name: null,
        line_manufacturer: "Please select one",
        pump_type: "Please select one",
        pistols_layout: null,
        total_pistols: null,
        air_parameter_name: "Dosage Air",
        air_parameter_units: "[bar]",
        pistol_to_pistol_distance: [],
        pistols_min_velocity: 0.05,
        pistols_max_velocity: 0.5,
        pistols_max_acceleration: 0.5,
        pistols_max_movement_range: 30,
        recycling_system: false,
        powder_recycling_efficiency_percentage: 100,
      },
      grid_form: {
        number_of_columns: 1,
        column_distance: 0,
        column_shift: 0,
        number_of_rows: 5,
        row_distance: 30,
        row_shift: 0,
      },
      received_grid_form: {},
      line_manufacturer: [
        {
          name: "Wagner",
          value: "wagner",
        },
        {
          name: "MS-Carlisle",
          value: "ms_carlisle",
        },
        {
          name: "GEMA",
          value: "gema",
        },
        {
          name: "Nordson",
          value: "nordson",
        },
        {
          name: "Other",
          value: "other",
        },
      ],
      pump_type: [
        {
          name: "Dense Phase",
          value: "dense_phase",
        },
        {
          name: "Injector / Venturi",
          value: "injector_venturi",
        },
        {
          name: "Other",
          value: "other",
        },
      ],
      num_pistol_columns_mixed_layout_form: null,
      total_pistols_vertical_or_horizontal_input: null,
      total_pistols_column_1: null,
      total_pistols_column_2: null,
      total_pistols_column_3: null,
      total_pistols_column_4: null,
      total_pistols_column_5: null,
      total_pistols_column_6: null,
      total_pistols_column_7: null,
      total_pistols_column_8: null,
      total_pistols_column_9: null,
      total_pistols_column_10: null,
      pistol_to_pistol_distance_column_1: null,
      pistol_to_pistol_distance_column_2: null,
      pistol_to_pistol_distance_column_3: null,
      pistol_to_pistol_distance_column_4: null,
      pistol_to_pistol_distance_column_5: null,
      pistol_to_pistol_distance_column_6: null,
      pistol_to_pistol_distance_column_7: null,
      pistol_to_pistol_distance_column_8: null,
      pistol_to_pistol_distance_column_9: null,
      pistol_to_pistol_distance_column_10: null,
      pistol_column_distance_1: 0,
      pistol_column_distance_2: null,
      pistol_column_distance_3: null,
      pistol_column_distance_4: null,
      pistol_column_distance_5: null,
      pistol_column_distance_6: null,
      pistol_column_distance_7: null,
      pistol_column_distance_8: null,
      pistol_column_distance_9: null,
      pistol_column_distance_10: null,
      pistol_column_vertical_offset_1: 0,
      pistol_column_vertical_offset_2: 0,
      pistol_column_vertical_offset_3: 0,
      pistol_column_vertical_offset_4: 0,
      pistol_column_vertical_offset_5: 0,
      pistol_column_vertical_offset_6: 0,
      pistol_column_vertical_offset_7: 0,
      pistol_column_vertical_offset_8: 0,
      pistol_column_vertical_offset_9: 0,
      pistol_column_vertical_offset_10: 0,
      line_input_error: false,
      line_manufacturer_input_error: false,
      pump_type_input_error: false,
      pistols_layout_input_error: false,
      total_pistols_input_error: false,
      air_name_input_error: false,
      air_units_input_error: false,
      pistol_to_pistol_input_error: false,
      pistols_max_movement_range_input_error: false,
      pistols_min_velocity_input_error: false,
      pistols_max_velocity_input_error: false,
      num_pistol_columns_mixed_layout_input_error: false,
      total_pistols_column_1_input_error: false,
      total_pistols_column_2_input_error: false,
      total_pistols_column_3_input_error: false,
      total_pistols_column_4_input_error: false,
      total_pistols_column_5_input_error: false,
      total_pistols_column_6_input_error: false,
      total_pistols_column_7_input_error: false,
      total_pistols_column_8_input_error: false,
      total_pistols_column_9_input_error: false,
      total_pistols_column_10_input_error: false,
      pistol_to_pistol_distance_column_1_input_error: false,
      pistol_to_pistol_distance_column_2_input_error: false,
      pistol_to_pistol_distance_column_3_input_error: false,
      pistol_to_pistol_distance_column_4_input_error: false,
      pistol_to_pistol_distance_column_5_input_error: false,
      pistol_to_pistol_distance_column_6_input_error: false,
      pistol_to_pistol_distance_column_7_input_error: false,
      pistol_to_pistol_distance_column_8_input_error: false,
      pistol_to_pistol_distance_column_9_input_error: false,
      pistol_to_pistol_distance_column_10_input_error: false,
      pistol_column_distance_1_input_error: false,
      pistol_column_distance_2_input_error: false,
      pistol_column_distance_3_input_error: false,
      pistol_column_distance_4_input_error: false,
      pistol_column_distance_5_input_error: false,
      pistol_column_distance_6_input_error: false,
      pistol_column_distance_7_input_error: false,
      pistol_column_distance_8_input_error: false,
      pistol_column_distance_9_input_error: false,
      pistol_column_distance_10_input_error: false,
      pistol_column_vertical_offset_2_input_error: false,
      pistol_column_vertical_offset_3_input_error: false,
      pistol_column_vertical_offset_4_input_error: false,
      pistol_column_vertical_offset_5_input_error: false,
      pistol_column_vertical_offset_6_input_error: false,
      pistol_column_vertical_offset_7_input_error: false,
      pistol_column_vertical_offset_8_input_error: false,
      pistol_column_vertical_offset_9_input_error: false,
      pistol_column_vertical_offset_10_input_error: false,
      number_of_columns_input_error: false,
      column_distance_input_error: false,
      column_shift_input_error: false,
      number_of_rows_input_error: false,
      row_distance_input_error: false,
      row_shift_input_error: false,
      pistols_max_acceleration_input_error: false,
      linecanvas: null,
      line_image: null,
      max_metric_decimals: 2,
      max_imperial_decimals: 2,
      previous_dynamic_line_pistols_layout: null,
      previous_fixed_line_pistols_layout: "f",
      previous_pistol_to_pistol_distance: null,
      image_preview_not_computed: true,
      gridElementStatus: null,
      gridElementDimensions: { width: null, height: null },
      user_input_max_movement_range: false,
    };
  },
  computed: {
    minColumnsAllowed() {
      if (this.grid_form.number_of_rows == 1) {
        return 2;
      }
      return 1;
    },
    maxColumnsAllowed() {
      if (this.grid_form.number_of_rows == 1) {
        return this.$store.state.constraints.max_horizontal_line_guns;
      }
      return this.$store.state.constraints.max_number_of_columns_allowed;
    },
    minRowToRowDistanceAllowed() {
      if (this.$store.state.user_data.unit_system === "imperial") {
        return (
          this.$store.state.constraints.min_row_to_row_distance_allowed * this.$store.state.conversion_factors.cm_to_in
        ).toFixed(this.max_imperial_decimals);
      } else if (this.$store.state.user_data.unit_system === "metric") {
        return this.$store.state.constraints.min_row_to_row_distance_allowed;
      } else {
        return null;
      }
    },
    maxRowToRowDistanceAllowed() {
      if (this.$store.state.user_data.unit_system === "imperial") {
        return (
          this.$store.state.constraints.max_row_to_row_distance_allowed * this.$store.state.conversion_factors.cm_to_in
        ).toFixed(this.max_imperial_decimals);
      } else if (this.$store.state.user_data.unit_system === "metric") {
        return this.$store.state.constraints.max_row_to_row_distance_allowed;
      } else {
        return null;
      }
    },
    minRowsAllowed() {
      if (this.grid_form.number_of_columns == 1) {
        return 2;
      }
      return 1;
    },
    isMixedLayout() {
      return this.line_form.pistols_layout === "m" || this.gunLayout === "m";
    },
    isVerticalOrHorizontalLayout() {
      return (
        this.line_form.pistols_layout === "v" ||
        this.line_form.pistols_layout === "h" ||
        this.gunLayout === "v" ||
        this.gunLayout === "h"
      );
    },
    isFixedLayout() {
      return this.line_form.pistols_layout === "f" || this.line_form.pistols_layout === "fixedmixed";
    },
    isStaticVerticalLayout() {
      return this.line_form.pistols_layout === "f";
    },
    isStaticMixedLayout() {
      return this.line_form.pistols_layout === "fixedmixed";
    },
    LineIsCreated() {
      return this.line.id !== null;
    },
    AdvancedConfiguration() {
      return this.advanced_line_definition || this.isFixedLayout;
    },
    NumberOfReciprocators() {
      let number_of_reciprocators = 0;
      if (this.gunLayout === "m" || this.gunLayout === "fixedmixed") {
        let grid_coordinates = this.createCirclesCoordinates();
        let x_dictionary = {};
        for (let i = 0; i < grid_coordinates.length; i++) {
          let x = grid_coordinates[i].x;
          let y = grid_coordinates[i].y;
          if (x_dictionary[x] === undefined) {
            x_dictionary[x] = [];
          }
          x_dictionary[x].push(y);
        }

        let x_keys = Object.keys(x_dictionary);
        number_of_reciprocators = x_keys.length;
      }
      return number_of_reciprocators;
    },
    TotalNumberOfGunsMixed() {
      let total_number_of_guns_mixed = 0;
      if (this.gunLayout === "m" || this.gunLayout === "fixedmixed") {
        total_number_of_guns_mixed = this.grid_form.number_of_columns * this.grid_form.number_of_rows;
      }
      return total_number_of_guns_mixed;
    },
    MixedGunsPerColumnArray() {
      let total_pistols_array = this.GridFormToLineFormTransform().total_reciprocator_array;
      return total_pistols_array;
    },
    num_pistol_columns_mixed_layout: {
      get() {
        if (this.line_form.pistols_layout === "m" || this.line_form.pistols_layout === "fixedmixed") {
          return this.num_pistol_columns_mixed_layout_form;
        } else if (this.line_form.pistols_layout === "v" || this.line_form.pistols_layout === "h") {
          return 1;
        }
        return this.num_pistol_columns_mixed_layout_form;
      },
      set(value) {
        this.num_pistol_columns_mixed_layout_form = value;
      },
    },
    total_pistols_vertical_or_horizontal: {
      get() {
        return this.total_pistols_vertical_or_horizontal_input;
      },
      set(value) {
        this.total_pistols_vertical_or_horizontal_input = value;
        if (this.line_form.total_pistols == null) {
          this.line_form.total_pistols = [];
        }
        this.line_form.total_pistols[0] = value;
      },
    },
    pistolToPistolDistanceColumn1: {
      get() {
        if (this.pistol_to_pistol_distance_column_1 === null || this.pistol_to_pistol_distance_column_1 === "") {
          return this.pistol_to_pistol_distance_column_1;
        }
        if (this.$store.state.user_data.unit_system === "imperial") {
          return parseFloat(
            (this.pistol_to_pistol_distance_column_1 * this.$store.state.conversion_factors.cm_to_in).toFixed(
              this.max_imperial_decimals,
            ),
          );
        } else if (this.$store.state.user_data.unit_system === "metric") {
          return parseFloat(this.pistol_to_pistol_distance_column_1.toFixed(this.max_metric_decimals));
        } else {
          return null;
        }
      },
      set(value) {
        if (value == null || value == "") {
          this.pistol_to_pistol_distance_column_1 = value;
        } else {
          if (this.$store.state.user_data.unit_system === "imperial") {
            this.pistol_to_pistol_distance_column_1 = value / this.$store.state.conversion_factors.cm_to_in;
          } else if (this.$store.state.user_data.unit_system === "metric") {
            this.pistol_to_pistol_distance_column_1 = value;
          } else {
            this.pistol_to_pistol_distance_column_1 = null;
          }
        }
      },
    },
    pistolToPistolDistanceColumn2: {
      get() {
        if (this.pistol_to_pistol_distance_column_2 === null || this.pistol_to_pistol_distance_column_2 === "") {
          return this.pistol_to_pistol_distance_column_2;
        }
        if (this.$store.state.user_data.unit_system === "imperial") {
          return parseFloat(
            (this.pistol_to_pistol_distance_column_2 * this.$store.state.conversion_factors.cm_to_in).toFixed(
              this.max_imperial_decimals,
            ),
          );
        } else if (this.$store.state.user_data.unit_system === "metric") {
          return parseFloat(this.pistol_to_pistol_distance_column_2.toFixed(this.max_metric_decimals));
        } else {
          return null;
        }
      },
      set(value) {
        if (value == null || value == "") {
          this.pistol_to_pistol_distance_column_2 = value;
        } else {
          if (this.$store.state.user_data.unit_system === "imperial") {
            this.pistol_to_pistol_distance_column_2 = value / this.$store.state.conversion_factors.cm_to_in;
          } else if (this.$store.state.user_data.unit_system === "metric") {
            this.pistol_to_pistol_distance_column_2 = value;
          } else {
            this.pistol_to_pistol_distance_column_2 = null;
          }
        }
      },
    },
    pistolToPistolDistanceColumn3: {
      get() {
        if (this.pistol_to_pistol_distance_column_3 === null || this.pistol_to_pistol_distance_column_3 === "") {
          return this.pistol_to_pistol_distance_column_3;
        }
        if (this.$store.state.user_data.unit_system === "imperial") {
          return parseFloat(
            (this.pistol_to_pistol_distance_column_3 * this.$store.state.conversion_factors.cm_to_in).toFixed(
              this.max_imperial_decimals,
            ),
          );
        } else if (this.$store.state.user_data.unit_system === "metric") {
          return parseFloat(this.pistol_to_pistol_distance_column_3.toFixed(this.max_metric_decimals));
        } else {
          return null;
        }
      },
      set(value) {
        if (value == null || value == "") {
          this.pistol_to_pistol_distance_column_3 = value;
        } else {
          if (this.$store.state.user_data.unit_system === "imperial") {
            this.pistol_to_pistol_distance_column_3 = value / this.$store.state.conversion_factors.cm_to_in;
          } else if (this.$store.state.user_data.unit_system === "metric") {
            this.pistol_to_pistol_distance_column_3 = value;
          } else {
            this.pistol_to_pistol_distance_column_3 = null;
          }
        }
      },
    },
    pistolToPistolDistanceColumn4: {
      get() {
        if (this.pistol_to_pistol_distance_column_4 === null || this.pistol_to_pistol_distance_column_4 === "") {
          return this.pistol_to_pistol_distance_column_4;
        }
        if (this.$store.state.user_data.unit_system === "imperial") {
          return parseFloat(
            (this.pistol_to_pistol_distance_column_4 * this.$store.state.conversion_factors.cm_to_in).toFixed(
              this.max_imperial_decimals,
            ),
          );
        } else if (this.$store.state.user_data.unit_system === "metric") {
          return parseFloat(this.pistol_to_pistol_distance_column_4.toFixed(this.max_metric_decimals));
        } else {
          return null;
        }
      },
      set(value) {
        if (value == null || value == "") {
          this.pistol_to_pistol_distance_column_4 = value;
        } else {
          if (this.$store.state.user_data.unit_system === "imperial") {
            this.pistol_to_pistol_distance_column_4 = value / this.$store.state.conversion_factors.cm_to_in;
          } else if (this.$store.state.user_data.unit_system === "metric") {
            this.pistol_to_pistol_distance_column_4 = value;
          } else {
            this.pistol_to_pistol_distance_column_4 = null;
          }
        }
      },
    },
    pistolToPistolDistanceColumn5: {
      get() {
        if (this.pistol_to_pistol_distance_column_5 === null || this.pistol_to_pistol_distance_column_5 === "") {
          return this.pistol_to_pistol_distance_column_5;
        }
        if (this.$store.state.user_data.unit_system === "imperial") {
          return parseFloat(
            (this.pistol_to_pistol_distance_column_5 * this.$store.state.conversion_factors.cm_to_in).toFixed(
              this.max_imperial_decimals,
            ),
          );
        } else if (this.$store.state.user_data.unit_system === "metric") {
          return parseFloat(this.pistol_to_pistol_distance_column_5.toFixed(this.max_metric_decimals));
        } else {
          return null;
        }
      },
      set(value) {
        if (value == null || value == "") {
          this.pistol_to_pistol_distance_column_5 = value;
        } else {
          if (this.$store.state.user_data.unit_system === "imperial") {
            this.pistol_to_pistol_distance_column_5 = value / this.$store.state.conversion_factors.cm_to_in;
          } else if (this.$store.state.user_data.unit_system === "metric") {
            this.pistol_to_pistol_distance_column_5 = value;
          } else {
            this.pistol_to_pistol_distance_column_5 = null;
          }
        }
      },
    },
    pistolToPistolDistanceColumn6: {
      get() {
        if (this.pistol_to_pistol_distance_column_6 === null || this.pistol_to_pistol_distance_column_6 === "") {
          return this.pistol_to_pistol_distance_column_6;
        }
        if (this.$store.state.user_data.unit_system === "imperial") {
          return parseFloat(
            (this.pistol_to_pistol_distance_column_6 * this.$store.state.conversion_factors.cm_to_in).toFixed(
              this.max_imperial_decimals,
            ),
          );
        } else if (this.$store.state.user_data.unit_system === "metric") {
          return parseFloat(this.pistol_to_pistol_distance_column_6.toFixed(this.max_metric_decimals));
        } else {
          return null;
        }
      },
      set(value) {
        if (value == null || value == "") {
          this.pistol_to_pistol_distance_column_6 = value;
        } else {
          if (this.$store.state.user_data.unit_system === "imperial") {
            this.pistol_to_pistol_distance_column_6 = value / this.$store.state.conversion_factors.cm_to_in;
          } else if (this.$store.state.user_data.unit_system === "metric") {
            this.pistol_to_pistol_distance_column_6 = value;
          } else {
            this.pistol_to_pistol_distance_column_6 = null;
          }
        }
      },
    },
    pistolToPistolDistanceColumn7: {
      get() {
        if (this.pistol_to_pistol_distance_column_7 === null || this.pistol_to_pistol_distance_column_7 === "") {
          return this.pistol_to_pistol_distance_column_7;
        }
        if (this.$store.state.user_data.unit_system === "imperial") {
          return parseFloat(
            (this.pistol_to_pistol_distance_column_7 * this.$store.state.conversion_factors.cm_to_in).toFixed(
              this.max_imperial_decimals,
            ),
          );
        } else if (this.$store.state.user_data.unit_system === "metric") {
          return parseFloat(this.pistol_to_pistol_distance_column_7.toFixed(this.max_metric_decimals));
        } else {
          return null;
        }
      },
      set(value) {
        if (value == null || value == "") {
          this.pistol_to_pistol_distance_column_7 = value;
        } else {
          if (this.$store.state.user_data.unit_system === "imperial") {
            this.pistol_to_pistol_distance_column_7 = value / this.$store.state.conversion_factors.cm_to_in;
          } else if (this.$store.state.user_data.unit_system === "metric") {
            this.pistol_to_pistol_distance_column_7 = value;
          } else {
            this.pistol_to_pistol_distance_column_7 = null;
          }
        }
      },
    },
    pistolToPistolDistanceColumn8: {
      get() {
        if (this.pistol_to_pistol_distance_column_8 === null || this.pistol_to_pistol_distance_column_8 === "") {
          return this.pistol_to_pistol_distance_column_8;
        }
        if (this.$store.state.user_data.unit_system === "imperial") {
          return parseFloat(
            (this.pistol_to_pistol_distance_column_8 * this.$store.state.conversion_factors.cm_to_in).toFixed(
              this.max_imperial_decimals,
            ),
          );
        } else if (this.$store.state.user_data.unit_system === "metric") {
          return parseFloat(this.pistol_to_pistol_distance_column_8.toFixed(this.max_metric_decimals));
        } else {
          return null;
        }
      },
      set(value) {
        if (value == null || value == "") {
          this.pistol_to_pistol_distance_column_8 = value;
        } else {
          if (this.$store.state.user_data.unit_system === "imperial") {
            this.pistol_to_pistol_distance_column_8 = value / this.$store.state.conversion_factors.cm_to_in;
          } else if (this.$store.state.user_data.unit_system === "metric") {
            this.pistol_to_pistol_distance_column_8 = value;
          } else {
            this.pistol_to_pistol_distance_column_8 = null;
          }
        }
      },
    },
    pistolToPistolDistanceColumn9: {
      get() {
        if (this.pistol_to_pistol_distance_column_9 === null || this.pistol_to_pistol_distance_column_9 === "") {
          return this.pistol_to_pistol_distance_column_9;
        }
        if (this.$store.state.user_data.unit_system === "imperial") {
          return parseFloat(
            (this.pistol_to_pistol_distance_column_9 * this.$store.state.conversion_factors.cm_to_in).toFixed(
              this.max_imperial_decimals,
            ),
          );
        } else if (this.$store.state.user_data.unit_system === "metric") {
          return parseFloat(this.pistol_to_pistol_distance_column_9.toFixed(this.max_metric_decimals));
        } else {
          return null;
        }
      },
      set(value) {
        if (value == null || value == "") {
          this.pistol_to_pistol_distance_column_9 = value;
        } else {
          if (this.$store.state.user_data.unit_system === "imperial") {
            this.pistol_to_pistol_distance_column_9 = value / this.$store.state.conversion_factors.cm_to_in;
          } else if (this.$store.state.user_data.unit_system === "metric") {
            this.pistol_to_pistol_distance_column_9 = value;
          } else {
            this.pistol_to_pistol_distance_column_9 = null;
          }
        }
      },
    },
    pistolToPistolDistanceColumn10: {
      get() {
        if (this.pistol_to_pistol_distance_column_10 === null || this.pistol_to_pistol_distance_column_10 === "") {
          return this.pistol_to_pistol_distance_column_10;
        }
        if (this.$store.state.user_data.unit_system === "imperial") {
          return parseFloat(
            (this.pistol_to_pistol_distance_column_10 * this.$store.state.conversion_factors.cm_to_in).toFixed(
              this.max_imperial_decimals,
            ),
          );
        } else if (this.$store.state.user_data.unit_system === "metric") {
          return parseFloat(this.pistol_to_pistol_distance_column_10.toFixed(this.max_metric_decimals));
        } else {
          return null;
        }
      },
      set(value) {
        if (value == null || value == "") {
          this.pistol_to_pistol_distance_column_10 = value;
        } else {
          if (this.$store.state.user_data.unit_system === "imperial") {
            this.pistol_to_pistol_distance_column_10 = value / this.$store.state.conversion_factors.cm_to_in;
          } else if (this.$store.state.user_data.unit_system === "metric") {
            this.pistol_to_pistol_distance_column_10 = value;
          } else {
            this.pistol_to_pistol_distance_column_10 = null;
          }
        }
      },
    },
    pistolColumnDistance2: {
      get() {
        if (this.pistol_column_distance_2 == null || this.pistol_column_distance_2 == "") {
          return this.pistol_column_distance_2;
        }
        if (this.$store.state.user_data.unit_system === "imperial") {
          return parseFloat(
            (this.pistol_column_distance_2 * this.$store.state.conversion_factors.cm_to_in).toFixed(
              this.max_imperial_decimals,
            ),
          );
        } else if (this.$store.state.user_data.unit_system === "metric") {
          return parseFloat(this.pistol_column_distance_2.toFixed(this.max_metric_decimals));
        } else {
          return null;
        }
      },
      set(value) {
        if (value == null || value == "") {
          this.pistol_column_distance_2 = value;
        } else {
          if (this.$store.state.user_data.unit_system === "imperial") {
            this.pistol_column_distance_2 = value / this.$store.state.conversion_factors.cm_to_in;
          } else if (this.$store.state.user_data.unit_system === "metric") {
            this.pistol_column_distance_2 = value;
          } else {
            this.pistol_column_distance_2 = null;
          }
        }
      },
    },
    pistolColumnDistance3: {
      get() {
        if (this.pistol_column_distance_3 == null || this.pistol_column_distance_3 == "") {
          return this.pistol_column_distance_3;
        }
        if (this.$store.state.user_data.unit_system === "imperial") {
          return parseFloat(
            (this.pistol_column_distance_3 * this.$store.state.conversion_factors.cm_to_in).toFixed(
              this.max_imperial_decimals,
            ),
          );
        } else if (this.$store.state.user_data.unit_system === "metric") {
          return parseFloat(this.pistol_column_distance_3.toFixed(this.max_metric_decimals));
        } else {
          return null;
        }
      },
      set(value) {
        if (value == null || value == "") {
          this.pistol_column_distance_3 = value;
        } else {
          if (this.$store.state.user_data.unit_system === "imperial") {
            this.pistol_column_distance_3 = value / this.$store.state.conversion_factors.cm_to_in;
          } else if (this.$store.state.user_data.unit_system === "metric") {
            this.pistol_column_distance_3 = value;
          } else {
            this.pistol_column_distance_3 = null;
          }
        }
      },
    },
    pistolColumnDistance4: {
      get() {
        if (this.pistol_column_distance_4 == null || this.pistol_column_distance_4 == "") {
          return this.pistol_column_distance_4;
        }
        if (this.$store.state.user_data.unit_system === "imperial") {
          return parseFloat(
            (this.pistol_column_distance_4 * this.$store.state.conversion_factors.cm_to_in).toFixed(
              this.max_imperial_decimals,
            ),
          );
        } else if (this.$store.state.user_data.unit_system === "metric") {
          return parseFloat(this.pistol_column_distance_4.toFixed(this.max_metric_decimals));
        } else {
          return null;
        }
      },
      set(value) {
        if (value == null || value == "") {
          this.pistol_column_distance_4 = value;
        } else {
          if (this.$store.state.user_data.unit_system === "imperial") {
            this.pistol_column_distance_4 = value / this.$store.state.conversion_factors.cm_to_in;
          } else if (this.$store.state.user_data.unit_system === "metric") {
            this.pistol_column_distance_4 = value;
          } else {
            this.pistol_column_distance_4 = null;
          }
        }
      },
    },
    pistolColumnDistance5: {
      get() {
        if (this.pistol_column_distance_5 == null || this.pistol_column_distance_5 == "") {
          return this.pistol_column_distance_5;
        }
        if (this.$store.state.user_data.unit_system === "imperial") {
          return parseFloat(
            (this.pistol_column_distance_5 * this.$store.state.conversion_factors.cm_to_in).toFixed(
              this.max_imperial_decimals,
            ),
          );
        } else if (this.$store.state.user_data.unit_system === "metric") {
          return parseFloat(this.pistol_column_distance_5.toFixed(this.max_metric_decimals));
        } else {
          return null;
        }
      },
      set(value) {
        if (value == null || value == "") {
          this.pistol_column_distance_5 = value;
        } else {
          if (this.$store.state.user_data.unit_system === "imperial") {
            this.pistol_column_distance_5 = value / this.$store.state.conversion_factors.cm_to_in;
          } else if (this.$store.state.user_data.unit_system === "metric") {
            this.pistol_column_distance_5 = value;
          } else {
            this.pistol_column_distance_5 = null;
          }
        }
      },
    },
    pistolColumnDistance6: {
      get() {
        if (this.pistol_column_distance_6 == null || this.pistol_column_distance_6 == "") {
          return this.pistol_column_distance_6;
        }
        if (this.$store.state.user_data.unit_system === "imperial") {
          return parseFloat(
            (this.pistol_column_distance_6 * this.$store.state.conversion_factors.cm_to_in).toFixed(
              this.max_imperial_decimals,
            ),
          );
        } else if (this.$store.state.user_data.unit_system === "metric") {
          return parseFloat(this.pistol_column_distance_6.toFixed(this.max_metric_decimals));
        } else {
          return null;
        }
      },
      set(value) {
        if (value == null || value == "") {
          this.pistol_column_distance_6 = value;
        } else {
          if (this.$store.state.user_data.unit_system === "imperial") {
            this.pistol_column_distance_6 = value / this.$store.state.conversion_factors.cm_to_in;
          } else if (this.$store.state.user_data.unit_system === "metric") {
            this.pistol_column_distance_6 = value;
          } else {
            this.pistol_column_distance_6 = null;
          }
        }
      },
    },
    pistolColumnDistance7: {
      get() {
        if (this.pistol_column_distance_7 == null || this.pistol_column_distance_7 == "") {
          return this.pistol_column_distance_7;
        }
        if (this.$store.state.user_data.unit_system === "imperial") {
          return parseFloat(
            (this.pistol_column_distance_7 * this.$store.state.conversion_factors.cm_to_in).toFixed(
              this.max_imperial_decimals,
            ),
          );
        } else if (this.$store.state.user_data.unit_system === "metric") {
          return parseFloat(this.pistol_column_distance_7.toFixed(this.max_metric_decimals));
        } else {
          return null;
        }
      },
      set(value) {
        if (value == null || value == "") {
          this.pistol_column_distance_7 = value;
        } else {
          if (this.$store.state.user_data.unit_system === "imperial") {
            this.pistol_column_distance_7 = value / this.$store.state.conversion_factors.cm_to_in;
          } else if (this.$store.state.user_data.unit_system === "metric") {
            this.pistol_column_distance_7 = value;
          } else {
            this.pistol_column_distance_7 = null;
          }
        }
      },
    },
    pistolColumnDistance8: {
      get() {
        if (this.pistol_column_distance_8 == null || this.pistol_column_distance_8 == "") {
          return this.pistol_column_distance_8;
        }
        if (this.$store.state.user_data.unit_system === "imperial") {
          return parseFloat(
            (this.pistol_column_distance_8 * this.$store.state.conversion_factors.cm_to_in).toFixed(
              this.max_imperial_decimals,
            ),
          );
        } else if (this.$store.state.user_data.unit_system === "metric") {
          return parseFloat(this.pistol_column_distance_8.toFixed(this.max_metric_decimals));
        } else {
          return null;
        }
      },
      set(value) {
        if (value == null || value == "") {
          this.pistol_column_distance_8 = value;
        } else {
          if (this.$store.state.user_data.unit_system === "imperial") {
            this.pistol_column_distance_8 = value / this.$store.state.conversion_factors.cm_to_in;
          } else if (this.$store.state.user_data.unit_system === "metric") {
            this.pistol_column_distance_8 = value;
          } else {
            this.pistol_column_distance_8 = null;
          }
        }
      },
    },
    pistolColumnDistance9: {
      get() {
        if (this.pistol_column_distance_9 == null || this.pistol_column_distance_9 == "") {
          return this.pistol_column_distance_9;
        }
        if (this.$store.state.user_data.unit_system === "imperial") {
          return parseFloat(
            (this.pistol_column_distance_9 * this.$store.state.conversion_factors.cm_to_in).toFixed(
              this.max_imperial_decimals,
            ),
          );
        } else if (this.$store.state.user_data.unit_system === "metric") {
          return parseFloat(this.pistol_column_distance_9.toFixed(this.max_metric_decimals));
        } else {
          return null;
        }
      },
      set(value) {
        if (value == null || value == "") {
          this.pistol_column_distance_9 = value;
        } else {
          if (this.$store.state.user_data.unit_system === "imperial") {
            this.pistol_column_distance_9 = value / this.$store.state.conversion_factors.cm_to_in;
          } else if (this.$store.state.user_data.unit_system === "metric") {
            this.pistol_column_distance_9 = value;
          } else {
            this.pistol_column_distance_9 = null;
          }
        }
      },
    },
    pistolColumnDistance10: {
      get() {
        if (this.pistol_column_distance_10 == null || this.pistol_column_distance_10 == "") {
          return this.pistol_column_distance_10;
        }
        if (this.$store.state.user_data.unit_system === "imperial") {
          return parseFloat(
            (this.pistol_column_distance_10 * this.$store.state.conversion_factors.cm_to_in).toFixed(
              this.max_imperial_decimals,
            ),
          );
        } else if (this.$store.state.user_data.unit_system === "metric") {
          return parseFloat(this.pistol_column_distance_10.toFixed(this.max_metric_decimals));
        } else {
          return null;
        }
      },
      set(value) {
        if (value == null || value == "") {
          this.pistol_column_distance_10 = value;
        } else {
          if (this.$store.state.user_data.unit_system === "imperial") {
            this.pistol_column_distance_10 = value / this.$store.state.conversion_factors.cm_to_in;
          } else if (this.$store.state.user_data.unit_system === "metric") {
            this.pistol_column_distance_10 = value;
          } else {
            this.pistol_column_distance_10 = null;
          }
        }
      },
    },
    pistolColumnVerticalOffset2: {
      get() {
        if (this.pistol_column_vertical_offset_2 == null || this.pistol_column_vertical_offset_2 == "") {
          return this.pistol_column_vertical_offset_2;
        }
        if (this.$store.state.user_data.unit_system === "imperial") {
          return parseFloat(
            (this.pistol_column_vertical_offset_2 * this.$store.state.conversion_factors.cm_to_in).toFixed(
              this.max_imperial_decimals,
            ),
          );
        } else if (this.$store.state.user_data.unit_system === "metric") {
          return parseFloat(this.pistol_column_vertical_offset_2.toFixed(this.max_metric_decimals));
        } else {
          return null;
        }
      },
      set(value) {
        if (value == null || value == "") {
          this.pistol_column_vertical_offset_2 = value;
        } else {
          if (this.$store.state.user_data.unit_system === "imperial") {
            this.pistol_column_vertical_offset_2 = value / this.$store.state.conversion_factors.cm_to_in;
          } else if (this.$store.state.user_data.unit_system === "metric") {
            this.pistol_column_vertical_offset_2 = value;
          } else {
            this.pistol_column_vertical_offset_2 = null;
          }
        }
      },
    },
    pistolColumnVerticalOffset3: {
      get() {
        if (this.pistol_column_vertical_offset_3 == null || this.pistol_column_vertical_offset_3 == "") {
          return this.pistol_column_vertical_offset_3;
        }
        if (this.$store.state.user_data.unit_system === "imperial") {
          return parseFloat(
            (this.pistol_column_vertical_offset_3 * this.$store.state.conversion_factors.cm_to_in).toFixed(
              this.max_imperial_decimals,
            ),
          );
        } else if (this.$store.state.user_data.unit_system === "metric") {
          return parseFloat(this.pistol_column_vertical_offset_3.toFixed(this.max_metric_decimals));
        } else {
          return null;
        }
      },
      set(value) {
        if (value == null || value == "") {
          this.pistol_column_vertical_offset_3 = value;
        } else {
          if (this.$store.state.user_data.unit_system === "imperial") {
            this.pistol_column_vertical_offset_3 = value / this.$store.state.conversion_factors.cm_to_in;
          } else if (this.$store.state.user_data.unit_system === "metric") {
            this.pistol_column_vertical_offset_3 = value;
          } else {
            this.pistol_column_vertical_offset_3 = null;
          }
        }
      },
    },
    pistolColumnVerticalOffset4: {
      get() {
        if (this.pistol_column_vertical_offset_4 == null || this.pistol_column_vertical_offset_4 == "") {
          return this.pistol_column_vertical_offset_4;
        }
        if (this.$store.state.user_data.unit_system === "imperial") {
          return parseFloat(
            (this.pistol_column_vertical_offset_4 * this.$store.state.conversion_factors.cm_to_in).toFixed(
              this.max_imperial_decimals,
            ),
          );
        } else if (this.$store.state.user_data.unit_system === "metric") {
          return parseFloat(this.pistol_column_vertical_offset_4.toFixed(this.max_metric_decimals));
        } else {
          return null;
        }
      },
      set(value) {
        if (value == null || value == "") {
          this.pistol_column_vertical_offset_4 = value;
        } else {
          if (this.$store.state.user_data.unit_system === "imperial") {
            this.pistol_column_vertical_offset_4 = value / this.$store.state.conversion_factors.cm_to_in;
          } else if (this.$store.state.user_data.unit_system === "metric") {
            this.pistol_column_vertical_offset_4 = value;
          } else {
            this.pistol_column_vertical_offset_4 = null;
          }
        }
      },
    },
    pistolColumnVerticalOffset5: {
      get() {
        if (this.pistol_column_vertical_offset_5 == null || this.pistol_column_vertical_offset_5 == "") {
          return this.pistol_column_vertical_offset_5;
        }
        if (this.$store.state.user_data.unit_system === "imperial") {
          return parseFloat(
            (this.pistol_column_vertical_offset_5 * this.$store.state.conversion_factors.cm_to_in).toFixed(
              this.max_imperial_decimals,
            ),
          );
        } else if (this.$store.state.user_data.unit_system === "metric") {
          return parseFloat(this.pistol_column_vertical_offset_5.toFixed(this.max_metric_decimals));
        } else {
          return null;
        }
      },
      set(value) {
        if (value == null || value == "") {
          this.pistol_column_vertical_offset_5 = value;
        } else {
          if (this.$store.state.user_data.unit_system === "imperial") {
            this.pistol_column_vertical_offset_5 = value / this.$store.state.conversion_factors.cm_to_in;
          } else if (this.$store.state.user_data.unit_system === "metric") {
            this.pistol_column_vertical_offset_5 = value;
          } else {
            this.pistol_column_vertical_offset_5 = null;
          }
        }
      },
    },
    pistolColumnVerticalOffset6: {
      get() {
        if (this.pistol_column_vertical_offset_6 == null || this.pistol_column_vertical_offset_6 == "") {
          return this.pistol_column_vertical_offset_6;
        }
        if (this.$store.state.user_data.unit_system === "imperial") {
          return parseFloat(
            (this.pistol_column_vertical_offset_6 * this.$store.state.conversion_factors.cm_to_in).toFixed(
              this.max_imperial_decimals,
            ),
          );
        } else if (this.$store.state.user_data.unit_system === "metric") {
          return parseFloat(this.pistol_column_vertical_offset_6.toFixed(this.max_metric_decimals));
        } else {
          return null;
        }
      },
      set(value) {
        if (value == null || value == "") {
          this.pistol_column_vertical_offset_6 = value;
        } else {
          if (this.$store.state.user_data.unit_system === "imperial") {
            this.pistol_column_vertical_offset_6 = value / this.$store.state.conversion_factors.cm_to_in;
          } else if (this.$store.state.user_data.unit_system === "metric") {
            this.pistol_column_vertical_offset_6 = value;
          } else {
            this.pistol_column_vertical_offset_6 = null;
          }
        }
      },
    },
    pistolColumnVerticalOffset7: {
      get() {
        if (this.pistol_column_vertical_offset_7 == null || this.pistol_column_vertical_offset_7 == "") {
          return this.pistol_column_vertical_offset_7;
        }
        if (this.$store.state.user_data.unit_system === "imperial") {
          return parseFloat(
            (this.pistol_column_vertical_offset_7 * this.$store.state.conversion_factors.cm_to_in).toFixed(
              this.max_imperial_decimals,
            ),
          );
        } else if (this.$store.state.user_data.unit_system === "metric") {
          return parseFloat(this.pistol_column_vertical_offset_7.toFixed(this.max_metric_decimals));
        } else {
          return null;
        }
      },
      set(value) {
        if (value == null || value == "") {
          this.pistol_column_vertical_offset_7 = value;
        } else {
          if (this.$store.state.user_data.unit_system === "imperial") {
            this.pistol_column_vertical_offset_7 = value / this.$store.state.conversion_factors.cm_to_in;
          } else if (this.$store.state.user_data.unit_system === "metric") {
            this.pistol_column_vertical_offset_7 = value;
          } else {
            this.pistol_column_vertical_offset_7 = null;
          }
        }
      },
    },
    pistolColumnVerticalOffset8: {
      get() {
        if (this.pistol_column_vertical_offset_8 == null || this.pistol_column_vertical_offset_8 == "") {
          return this.pistol_column_vertical_offset_8;
        }
        if (this.$store.state.user_data.unit_system === "imperial") {
          return parseFloat(
            (this.pistol_column_vertical_offset_8 * this.$store.state.conversion_factors.cm_to_in).toFixed(
              this.max_imperial_decimals,
            ),
          );
        } else if (this.$store.state.user_data.unit_system === "metric") {
          return parseFloat(this.pistol_column_vertical_offset_8.toFixed(this.max_metric_decimals));
        } else {
          return null;
        }
      },
      set(value) {
        if (value == null || value == "") {
          this.pistol_column_vertical_offset_8 = value;
        } else {
          if (this.$store.state.user_data.unit_system === "imperial") {
            this.pistol_column_vertical_offset_8 = value / this.$store.state.conversion_factors.cm_to_in;
          } else if (this.$store.state.user_data.unit_system === "metric") {
            this.pistol_column_vertical_offset_8 = value;
          } else {
            this.pistol_column_vertical_offset_8 = null;
          }
        }
      },
    },
    pistolColumnVerticalOffset9: {
      get() {
        if (this.pistol_column_vertical_offset_9 == null || this.pistol_column_vertical_offset_9 == "") {
          return this.pistol_column_vertical_offset_9;
        }
        if (this.$store.state.user_data.unit_system === "imperial") {
          return parseFloat(
            (this.pistol_column_vertical_offset_9 * this.$store.state.conversion_factors.cm_to_in).toFixed(
              this.max_imperial_decimals,
            ),
          );
        } else if (this.$store.state.user_data.unit_system === "metric") {
          return parseFloat(this.pistol_column_vertical_offset_9.toFixed(this.max_metric_decimals));
        } else {
          return null;
        }
      },
      set(value) {
        if (value == null || value == "") {
          this.pistol_column_vertical_offset_9 = value;
        } else {
          if (this.$store.state.user_data.unit_system === "imperial") {
            this.pistol_column_vertical_offset_9 = value / this.$store.state.conversion_factors.cm_to_in;
          } else if (this.$store.state.user_data.unit_system === "metric") {
            this.pistol_column_vertical_offset_9 = value;
          } else {
            this.pistol_column_vertical_offset_9 = null;
          }
        }
      },
    },
    pistolColumnVerticalOffset10: {
      get() {
        if (this.pistol_column_vertical_offset_10 == null || this.pistol_column_vertical_offset_10 == "") {
          return this.pistol_column_vertical_offset_10;
        }
        if (this.$store.state.user_data.unit_system === "imperial") {
          return parseFloat(
            (this.pistol_column_vertical_offset_10 * this.$store.state.conversion_factors.cm_to_in).toFixed(
              this.max_imperial_decimals,
            ),
          );
        } else if (this.$store.state.user_data.unit_system === "metric") {
          return parseFloat(this.pistol_column_vertical_offset_10.toFixed(this.max_metric_decimals));
        } else {
          return null;
        }
      },
      set(value) {
        if (value == null || value == "") {
          this.pistol_column_vertical_offset_10 = value;
        } else {
          if (this.$store.state.user_data.unit_system === "imperial") {
            this.pistol_column_vertical_offset_10 = value / this.$store.state.conversion_factors.cm_to_in;
          } else if (this.$store.state.user_data.unit_system === "metric") {
            this.pistol_column_vertical_offset_10 = value;
          } else {
            this.pistol_column_vertical_offset_10 = null;
          }
        }
      },
    },
    pistolsMinVelocity: {
      get() {
        if (this.line_form.pistols_min_velocity == null || this.line_form.pistols_min_velocity == "") {
          return this.line_form.pistols_min_velocity;
        }
        if (this.$store.state.user_data.unit_system === "imperial") {
          return parseFloat(
            (this.line_form.pistols_min_velocity * this.$store.state.conversion_factors.m_to_ft).toFixed(
              this.max_imperial_decimals,
            ),
          );
        } else if (this.$store.state.user_data.unit_system === "metric") {
          return parseFloat(this.line_form.pistols_min_velocity.toFixed(this.max_metric_decimals));
        } else {
          return null;
        }
      },
      set(value) {
        if (value == null || value == "") {
          this.line_form.pistols_min_velocity = value;
        } else {
          if (this.$store.state.user_data.unit_system === "imperial") {
            this.line_form.pistols_min_velocity = value / this.$store.state.conversion_factors.m_to_ft;
          } else {
            this.line_form.pistols_min_velocity = value;
          }
        }
      },
    },
    pistolsMaxVelocity: {
      get() {
        if (this.line_form.pistols_max_velocity == null || this.line_form.pistols_max_velocity == "") {
          return this.line_form.pistols_max_velocity;
        }
        if (this.$store.state.user_data.unit_system === "imperial") {
          return parseFloat(
            (this.line_form.pistols_max_velocity * this.$store.state.conversion_factors.m_to_ft).toFixed(
              this.max_imperial_decimals,
            ),
          );
        } else if (this.$store.state.user_data.unit_system === "metric") {
          return parseFloat(this.line_form.pistols_max_velocity.toFixed(this.max_metric_decimals));
        } else {
          return null;
        }
      },
      set(value) {
        if (value == null || value == "") {
          this.line_form.pistols_max_velocity = value;
        } else {
          if (this.$store.state.user_data.unit_system === "imperial") {
            this.line_form.pistols_max_velocity = value / this.$store.state.conversion_factors.m_to_ft;
          } else {
            this.line_form.pistols_max_velocity = value;
          }
        }
      },
    },
    pistolsMaxAcceleration: {
      get() {
        if (this.line_form.pistols_max_acceleration == null || this.line_form.pistols_max_acceleration == "") {
          return this.line_form.pistols_max_acceleration;
        }
        if (this.$store.state.user_data.unit_system === "imperial") {
          return parseFloat(
            (this.line_form.pistols_max_acceleration * this.$store.state.conversion_factors.m_to_ft).toFixed(
              this.max_imperial_decimals,
            ),
          );
        } else if (this.$store.state.user_data.unit_system === "metric") {
          return parseFloat(this.line_form.pistols_max_acceleration.toFixed(this.max_metric_decimals));
        } else {
          return null;
        }
      },
      set(value) {
        if (this.$store.state.user_data.unit_system === "imperial") {
          this.line_form.pistols_max_acceleration = value / this.$store.state.conversion_factors.m_to_ft;
        } else {
          this.line_form.pistols_max_acceleration = value;
        }
      },
    },
    pistolsMaxMovementRange: {
      get() {
        if (this.line_form.pistols_max_movement_range == null || this.line_form.pistols_max_movement_range == "") {
          return this.line_form.pistols_max_movement_range;
        }
        if (this.$store.state.user_data.unit_system === "imperial") {
          return parseFloat(
            (this.line_form.pistols_max_movement_range * this.$store.state.conversion_factors.cm_to_in).toFixed(
              this.max_imperial_decimals,
            ),
          );
        } else if (this.$store.state.user_data.unit_system === "metric") {
          return parseFloat(this.line_form.pistols_max_movement_range.toFixed(this.max_metric_decimals));
        } else {
          return null;
        }
      },
      set(value) {
        if (value == null || value == "") {
          this.line_form.pistols_max_movement_range = value;
        } else {
          if (this.$store.state.user_data.unit_system === "imperial") {
            this.line_form.pistols_max_movement_range = value / this.$store.state.conversion_factors.cm_to_in;
          } else if (this.$store.state.user_data.unit_system === "metric") {
            this.line_form.pistols_max_movement_range = value;
          } else {
            this.line_form.pistols_max_movement_range = value;
          }
        }
      },
    },
    numberOfColumns: {
      get() {
        return this.grid_form.number_of_columns;
      },
      set(value) {
        if (value > 1 && !this.user_input_max_movement_range) {
          this.line_form.pistols_max_movement_range = 150;
        }
        if (value == 1 && !this.user_input_max_movement_range) {
          this.line_form.pistols_max_movement_range = this.grid_form.row_distance;
        }

        this.grid_form.number_of_columns = value;
      },
    },
    minPistolToPistolDistanceAllowed: function () {
      if (this.$store.state.user_data.unit_system === "imperial") {
        return (
          this.$store.state.constraints.min_pistol_to_pistol_distance_allowed *
          this.$store.state.conversion_factors.cm_to_in
        ).toFixed(this.max_imperial_decimals);
      } else if (this.$store.state.user_data.unit_system === "metric") {
        return this.$store.state.constraints.min_pistol_to_pistol_distance_allowed;
      } else {
        return null;
      }
    },
    maxPistolToPistolDistanceAllowed: function () {
      if (this.$store.state.user_data.unit_system === "imperial") {
        return (
          this.$store.state.constraints.max_pistol_to_pistol_distance_allowed *
          this.$store.state.conversion_factors.cm_to_in
        ).toFixed(this.max_imperial_decimals);
      } else if (this.$store.state.user_data.unit_system === "metric") {
        return this.$store.state.constraints.max_pistol_to_pistol_distance_allowed;
      } else {
        return null;
      }
    },
    minColumnToColumnDistanceAllowed: function () {
      if (this.$store.state.user_data.unit_system === "imperial") {
        return (
          this.$store.state.constraints.min_column_to_column_distance_allowed *
          this.$store.state.conversion_factors.cm_to_in
        ).toFixed(this.max_imperial_decimals);
      } else if (this.$store.state.user_data.unit_system === "metric") {
        return this.$store.state.constraints.min_column_to_column_distance_allowed;
      } else {
        return null;
      }
    },
    maxColumnToColumnDistanceAllowed: function () {
      if (this.$store.state.user_data.unit_system === "imperial") {
        return (
          this.$store.state.constraints.max_column_to_column_distance_allowed *
          this.$store.state.conversion_factors.cm_to_in
        ).toFixed(this.max_imperial_decimals);
      } else if (this.$store.state.user_data.unit_system === "metric") {
        return this.$store.state.constraints.max_column_to_column_distance_allowed;
      } else {
        return null;
      }
    },
    minPistolsMaxMovementRangeAllowed: function () {
      if (this.$store.state.user_data.unit_system === "imperial") {
        return (
          this.$store.state.constraints.min_pistols_max_movement_range_allowed *
          this.$store.state.conversion_factors.cm_to_in
        ).toFixed(this.max_imperial_decimals);
      } else if (this.$store.state.user_data.unit_system === "metric") {
        return this.$store.state.constraints.min_pistols_max_movement_range_allowed;
      } else {
        return null;
      }
    },
    maxPistolsMaxMovementRangeAllowed: function () {
      if (this.$store.state.user_data.unit_system === "imperial") {
        return (
          this.$store.state.constraints.max_pistols_max_movement_range_allowed *
          this.$store.state.conversion_factors.cm_to_in
        ).toFixed(this.max_imperial_decimals);
      } else if (this.$store.state.user_data.unit_system === "metric") {
        return this.$store.state.constraints.max_pistols_max_movement_range_allowed;
      } else {
        return null;
      }
    },
    minPistolsVelocityAllowed: function () {
      if (this.$store.state.user_data.unit_system === "imperial") {
        return (
          this.$store.state.constraints.min_pistols_velocity_allowed * this.$store.state.conversion_factors.m_to_ft
        ).toFixed(this.max_imperial_decimals);
      } else if (this.$store.state.user_data.unit_system === "metric") {
        return this.$store.state.constraints.min_pistols_velocity_allowed;
      } else {
        return null;
      }
    },
    maxAllowedPistolsMinVelocity: function () {
      let maxVelocity = null;

      if (this.$store.state.user_data.unit_system === "imperial") {
        maxVelocity = (
          this.$store.state.constraints.max_pistols_velocity_allowed * this.$store.state.conversion_factors.m_to_ft
        ).toFixed(this.max_imperial_decimals);
      } else if (this.$store.state.user_data.unit_system === "metric") {
        maxVelocity = this.$store.state.constraints.max_pistols_velocity_allowed;
      }
      return Math.min(maxVelocity, this.pistolsMaxVelocity);
    },
    minPistolsMaxAccelerationAllowed: function () {
      if (this.$store.state.user_data.unit_system === "imperial") {
        return (
          this.$store.state.constraints.min_pistols_max_acceleration_allowed *
          this.$store.state.conversion_factors.m_to_ft
        ).toFixed(this.max_imperial_decimals);
      } else if (this.$store.state.user_data.unit_system === "metric") {
        return this.$store.state.constraints.min_pistols_max_acceleration_allowed;
      } else {
        return null;
      }
    },
    maxPistolsMaxAccelerationAllowed: function () {
      if (this.$store.state.user_data.unit_system === "imperial") {
        return (
          this.$store.state.constraints.max_pistols_max_acceleration_allowed *
          this.$store.state.conversion_factors.m_to_ft
        ).toFixed(this.max_imperial_decimals);
      } else if (this.$store.state.user_data.unit_system === "metric") {
        return this.$store.state.constraints.max_pistols_max_acceleration_allowed;
      } else {
        return null;
      }
    },
    minAllowedPistolsMaxVelocity: function () {
      let minVelocityAllowed = null;

      if (this.$store.state.user_data.unit_system === "imperial") {
        minVelocityAllowed = (
          this.$store.state.constraints.min_pistols_velocity_allowed * this.$store.state.conversion_factors.m_to_ft
        ).toFixed(this.max_imperial_decimals);
      } else if (this.$store.state.user_data.unit_system === "metric") {
        minVelocityAllowed = this.$store.state.constraints.min_pistols_velocity_allowed;
      }
      return Math.max(minVelocityAllowed, this.pistolsMinVelocity);
    },
    maxPistolsVelocityAllowed: function () {
      if (this.$store.state.user_data.unit_system === "imperial") {
        return (
          this.$store.state.constraints.max_pistols_velocity_allowed * this.$store.state.conversion_factors.m_to_ft
        ).toFixed(this.max_imperial_decimals);
      } else if (this.$store.state.user_data.unit_system === "metric") {
        return this.$store.state.constraints.max_pistols_velocity_allowed;
      } else {
        return null;
      }
    },
    AvailablePistolsByLineLayout: function () {
      if (this.isVerticalOrHorizontalLayout || this.isMixedLayout) {
        return 19;
      }
      if (this.isFixedLayout) {
        return 19;
      }
      return 9;
    },
    LineFormUpdated: function () {
      let compare_line_form = { ...this.line_form };
      delete compare_line_form.recycling_system;
      return (
        JSON.stringify(compare_line_form) !== JSON.stringify(this.line) ||
        JSON.stringify(this.grid_form) !== JSON.stringify(this.received_grid_form)
      );
    },
    isFormFilled() {
      let isformfilled =
        (this.isMixedLayout && this.MixedLineFormFilled) ||
        (this.isVerticalOrHorizontalLayout && this.isVerticalOrHorizontalLineFormFilled) ||
        (this.isStaticVerticalLayout && this.isStaticVerticalLineFormFilled) ||
        (this.isStaticMixedLayout && this.isStaticMixedLineFormFilled) ||
        (!this.AdvancedConfiguration && this.isGridFormFilled);

      return isformfilled;
    },
    isGridFormFilled() {
      let properly_filled = true;
      if (this.line_form == null || this.grid_form == null) {
        properly_filled = false;
      }
      if (this.line_form.name == null || this.line_form.name == "") {
        properly_filled = false;
      } else if (this.line_form.line_manufacturer == "Please select one") {
        properly_filled = false;
      } else if (this.line_form.pump_type == "Please select one") {
        properly_filled = false;
      } else if (this.line_form.air_parameter_name == null || this.line_form.air_parameter_name == "") {
        properly_filled = false;
      } else if (this.line_form.air_parameter_units == null || this.line_form.air_parameter_units == "") {
        properly_filled = false;
      } else if (this.line_form.pistols_max_movement_range == null) {
        properly_filled = false;
      } else if (this.line_form.pistols_min_velocity == null) {
        properly_filled = false;
      } else if (this.line_form.pistols_max_velocity == null) {
        properly_filled = false;
      } else if (this.grid_form.number_of_columns == null) {
        properly_filled = false;
      } else if (this.grid_form.number_of_rows == null) {
        properly_filled = false;
      } else if (this.grid_form.column_distance == null) {
        properly_filled = false;
      } else if (this.grid_form.row_distance == null) {
        properly_filled = false;
      } else if (this.grid_form.column_shift == null) {
        properly_filled = false;
      } else if (this.grid_form.row_shift == null) {
        properly_filled = false;
      }
      return properly_filled;
    },
    isStaticVerticalLineFormFilled() {
      let properly_filled = true;
      if (this.isMixedLayout || this.isVerticalOrHorizontalLayout || this.isStaticMixedLayout) {
        properly_filled = false;
      } else if (this.isStaticVerticalLayout) {
        if (this.line_form == null) {
          properly_filled = false;
        } else if (this.line_form.name == null || this.line_form.name == "") {
          properly_filled = false;
        } else if (this.line_form.line_manufacturer == "Please select one") {
          properly_filled = false;
        } else if (this.line_form.pump_type == "Please select one") {
          properly_filled = false;
        } else if (this.line_form.air_parameter_name == null || this.line_form.air_parameter_name == "") {
          properly_filled = false;
        } else if (this.line_form.air_parameter_units == null || this.line_form.air_parameter_units == "") {
          properly_filled = false;
        } else if (this.line_form.total_pistols == null || this.line_form.total_pistols == []) {
          properly_filled = false;
        } else if (this.line_form.total_pistols.length == 0 || this.total_pistols_vertical_or_horizontal == null) {
          properly_filled = false;
        }
      }
      return properly_filled;
    },
    isStaticMixedLineFormFilled() {
      let properly_filled = true;
      if (
        this.isMixedLayout ||
        this.isVerticalOrHorizontalLayout ||
        this.isStaticVerticalLayout ||
        !this.isStaticMixedLayout
      ) {
        properly_filled = false;
      }
      if (this.line_form == null) {
        properly_filled = false;
      } else if (this.line_form.name == null || this.line_form.name === "") {
        properly_filled = false;
      } else if (this.line_form.line_manufacturer == "Please select one") {
        properly_filled = false;
      } else if (this.line_form.pump_type == "Please select one") {
        properly_filled = false;
      } else if (this.line_form.air_parameter_name == null || this.line_form.air_parameter_name === "") {
        properly_filled = false;
      } else if (this.line_form.air_parameter_units == null || this.line_form.air_parameter_units === "") {
        properly_filled = false;
      } else if (
        this.num_pistol_columns_mixed_layout_form == null ||
        this.num_pistol_columns_mixed_layout_form == null
      ) {
        properly_filled = false;
      } else if (
        this.total_pistols_column_1 == null ||
        this.total_pistols_column_2 == null ||
        this.pistol_column_distance_1 == null ||
        this.pistol_column_distance_1 === "" ||
        this.pistol_column_distance_2 == null ||
        this.pistol_column_distance_2 === ""
      ) {
        properly_filled = false;
      } else if (
        this.num_pistol_columns_mixed_layout > 2 &&
        (this.total_pistols_column_3 == null ||
          this.pistol_column_distance_3 == null ||
          this.pistol_column_distance_3 === "")
      ) {
        properly_filled = false;
        console.log("3");
      } else if (
        this.num_pistol_columns_mixed_layout > 3 &&
        (this.total_pistols_column_4 == null ||
          this.pistol_column_distance_4 == null ||
          this.pistol_column_distance_4 === "")
      ) {
        properly_filled = false;
        console.log("4");
      } else if (
        this.num_pistol_columns_mixed_layout > 4 &&
        (this.total_pistols_column_5 == null ||
          this.pistol_column_distance_5 == null ||
          this.pistol_column_distance_5 === "")
      ) {
        properly_filled = false;
        console.log("5");
      } else if (
        this.num_pistol_columns_mixed_layout > 5 &&
        (this.total_pistols_column_6 == null ||
          this.pistol_column_distance_6 == null ||
          this.pistol_column_distance_6 === "")
      ) {
        properly_filled = false;
        console.log("6");
      } else if (
        this.num_pistol_columns_mixed_layout > 6 &&
        (this.total_pistols_column_7 == null ||
          this.pistol_column_distance_7 == null ||
          this.pistol_column_distance_7 === "")
      ) {
        properly_filled = false;
        console.log("7");
      } else if (
        this.num_pistol_columns_mixed_layout > 7 &&
        (this.total_pistols_column_8 == null ||
          this.pistol_column_distance_8 == null ||
          this.pistol_column_distance_8 === "")
      ) {
        properly_filled = false;
        console.log("8");
      } else if (
        this.num_pistol_columns_mixed_layout > 8 &&
        (this.total_pistols_column_9 == null ||
          this.pistol_column_distance_9 == null ||
          this.pistol_column_distance_9 === "")
      ) {
        properly_filled = false;
        console.log("9");
      } else if (
        this.num_pistol_columns_mixed_layout > 9 &&
        (this.total_pistols_column_10 == null ||
          this.pistol_column_distance_10 == null ||
          this.pistol_column_distance_10 === "")
      ) {
        properly_filled = false;
        console.log("10");
      }
      return properly_filled;
    },
    MixedLineFormFilled() {
      if (this.isMixedLayout) {
        if (
          this.num_pistol_columns_mixed_layout != null &&
          this.total_pistols_column_1 != null &&
          this.total_pistols_column_2 != null &&
          this.pistol_to_pistol_distance_column_1 != null &&
          this.pistol_to_pistol_distance_column_2 != null &&
          this.pistol_column_distance_1 != null &&
          this.pistol_column_distance_2 != null &&
          this.pistol_column_vertical_offset_2 != null
        ) {
          if (this.num_pistol_columns_mixed_layout < 3) {
            return true;
          } else if (
            this.num_pistol_columns_mixed_layout < 4 &&
            this.total_pistols_column_3 != null &&
            this.pistol_to_pistol_distance_column_3 != null &&
            this.pistol_column_distance_3 != null &&
            this.pistol_column_vertical_offset_3 != null
          ) {
            return true;
          } else if (
            this.num_pistol_columns_mixed_layout < 5 &&
            this.total_pistols_column_3 != null &&
            this.total_pistols_column_4 != null &&
            this.pistol_to_pistol_distance_column_3 != null &&
            this.pistol_to_pistol_distance_column_4 != null &&
            this.pistol_column_distance_3 != null &&
            this.pistol_column_distance_4 != null &&
            this.pistol_column_vertical_offset_3 != null &&
            this.pistol_column_vertical_offset_4 != null
          ) {
            return true;
          } else if (
            this.num_pistol_columns_mixed_layout < 6 &&
            this.total_pistols_column_3 != null &&
            this.total_pistols_column_4 != null &&
            this.total_pistols_column_5 != null &&
            this.pistol_to_pistol_distance_column_3 != null &&
            this.pistol_to_pistol_distance_column_4 != null &&
            this.pistol_to_pistol_distance_column_5 != null &&
            this.pistol_column_distance_3 != null &&
            this.pistol_column_distance_4 != null &&
            this.pistol_column_distance_5 != null &&
            this.pistol_column_vertical_offset_3 != null &&
            this.pistol_column_vertical_offset_4 != null &&
            this.pistol_column_vertical_offset_5 != null
          ) {
            return true;
          } else if (
            this.num_pistol_columns_mixed_layout < 7 &&
            this.total_pistols_column_3 != null &&
            this.total_pistols_column_4 != null &&
            this.total_pistols_column_5 != null &&
            this.total_pistols_column_6 != null &&
            this.pistol_to_pistol_distance_column_3 != null &&
            this.pistol_to_pistol_distance_column_4 != null &&
            this.pistol_to_pistol_distance_column_5 != null &&
            this.pistol_to_pistol_distance_column_6 != null &&
            this.pistol_column_distance_3 != null &&
            this.pistol_column_distance_4 != null &&
            this.pistol_column_distance_5 != null &&
            this.pistol_column_distance_6 != null &&
            this.pistol_column_vertical_offset_3 != null &&
            this.pistol_column_vertical_offset_4 != null &&
            this.pistol_column_vertical_offset_5 != null &&
            this.pistol_column_vertical_offset_6 != null
          ) {
            return true;
          } else if (
            this.num_pistol_columns_mixed_layout < 8 &&
            this.total_pistols_column_3 != null &&
            this.total_pistols_column_4 != null &&
            this.total_pistols_column_5 != null &&
            this.total_pistols_column_6 != null &&
            this.total_pistols_column_7 != null &&
            this.pistol_to_pistol_distance_column_3 != null &&
            this.pistol_to_pistol_distance_column_4 != null &&
            this.pistol_to_pistol_distance_column_5 != null &&
            this.pistol_to_pistol_distance_column_6 != null &&
            this.pistol_to_pistol_distance_column_7 != null &&
            this.pistol_column_distance_3 != null &&
            this.pistol_column_distance_4 != null &&
            this.pistol_column_distance_5 != null &&
            this.pistol_column_distance_6 != null &&
            this.pistol_column_distance_7 != null &&
            this.pistol_column_vertical_offset_3 != null &&
            this.pistol_column_vertical_offset_4 != null &&
            this.pistol_column_vertical_offset_5 != null &&
            this.pistol_column_vertical_offset_6 != null &&
            this.pistol_column_vertical_offset_7 != null
          ) {
            return true;
          } else if (
            this.num_pistol_columns_mixed_layout < 9 &&
            this.total_pistols_column_3 != null &&
            this.total_pistols_column_4 != null &&
            this.total_pistols_column_5 != null &&
            this.total_pistols_column_6 != null &&
            this.total_pistols_column_7 != null &&
            this.total_pistols_column_8 != null &&
            this.pistol_to_pistol_distance_column_3 != null &&
            this.pistol_to_pistol_distance_column_4 != null &&
            this.pistol_to_pistol_distance_column_5 != null &&
            this.pistol_to_pistol_distance_column_6 != null &&
            this.pistol_to_pistol_distance_column_7 != null &&
            this.pistol_to_pistol_distance_column_8 != null &&
            this.pistol_column_distance_3 != null &&
            this.pistol_column_distance_4 != null &&
            this.pistol_column_distance_5 != null &&
            this.pistol_column_distance_6 != null &&
            this.pistol_column_distance_7 != null &&
            this.pistol_column_distance_8 != null &&
            this.pistol_column_vertical_offset_3 != null &&
            this.pistol_column_vertical_offset_4 != null &&
            this.pistol_column_vertical_offset_5 != null &&
            this.pistol_column_vertical_offset_6 != null &&
            this.pistol_column_vertical_offset_7 != null &&
            this.pistol_column_vertical_offset_8 != null
          ) {
            return true;
          } else if (
            this.num_pistol_columns_mixed_layout < 10 &&
            this.total_pistols_column_3 != null &&
            this.total_pistols_column_4 != null &&
            this.total_pistols_column_5 != null &&
            this.total_pistols_column_6 != null &&
            this.total_pistols_column_7 != null &&
            this.total_pistols_column_8 != null &&
            this.total_pistols_column_9 != null &&
            this.pistol_to_pistol_distance_column_3 != null &&
            this.pistol_to_pistol_distance_column_4 != null &&
            this.pistol_to_pistol_distance_column_5 != null &&
            this.pistol_to_pistol_distance_column_6 != null &&
            this.pistol_to_pistol_distance_column_7 != null &&
            this.pistol_to_pistol_distance_column_8 != null &&
            this.pistol_to_pistol_distance_column_9 != null &&
            this.pistol_column_distance_3 != null &&
            this.pistol_column_distance_4 != null &&
            this.pistol_column_distance_5 != null &&
            this.pistol_column_distance_6 != null &&
            this.pistol_column_distance_7 != null &&
            this.pistol_column_distance_8 != null &&
            this.pistol_column_distance_9 != null &&
            this.pistol_column_vertical_offset_3 != null &&
            this.pistol_column_vertical_offset_4 != null &&
            this.pistol_column_vertical_offset_5 != null &&
            this.pistol_column_vertical_offset_6 != null &&
            this.pistol_column_vertical_offset_7 != null &&
            this.pistol_column_vertical_offset_8 != null &&
            this.pistol_column_vertical_offset_9 != null
          ) {
            return true;
          } else if (
            this.num_pistol_columns_mixed_layout < 11 &&
            this.total_pistols_column_3 != null &&
            this.total_pistols_column_4 != null &&
            this.total_pistols_column_5 != null &&
            this.total_pistols_column_6 != null &&
            this.total_pistols_column_7 != null &&
            this.total_pistols_column_8 != null &&
            this.total_pistols_column_9 != null &&
            this.total_pistols_column_10 != null &&
            this.pistol_to_pistol_distance_column_3 != null &&
            this.pistol_to_pistol_distance_column_4 != null &&
            this.pistol_to_pistol_distance_column_5 != null &&
            this.pistol_to_pistol_distance_column_6 != null &&
            this.pistol_to_pistol_distance_column_7 != null &&
            this.pistol_to_pistol_distance_column_8 != null &&
            this.pistol_to_pistol_distance_column_9 != null &&
            this.pistol_to_pistol_distance_column_10 != null &&
            this.pistol_column_distance_3 != null &&
            this.pistol_column_distance_4 != null &&
            this.pistol_column_distance_5 != null &&
            this.pistol_column_distance_6 != null &&
            this.pistol_column_distance_7 != null &&
            this.pistol_column_distance_8 != null &&
            this.pistol_column_distance_9 != null &&
            this.pistol_column_distance_10 != null &&
            this.pistol_column_vertical_offset_3 != null &&
            this.pistol_column_vertical_offset_4 != null &&
            this.pistol_column_vertical_offset_5 != null &&
            this.pistol_column_vertical_offset_6 != null &&
            this.pistol_column_vertical_offset_7 != null &&
            this.pistol_column_vertical_offset_8 != null &&
            this.pistol_column_vertical_offset_9 != null &&
            this.pistol_column_vertical_offset_10 != null
          ) {
            return true;
          }
        }
      }
      return false;
    },
    isVerticalOrHorizontalLineFormFilled() {
      let properly_filled = true;
      if (this.isMixedLayout || this.isFixedLayout) {
        properly_filled = false;
      } else if (this.line_form == null) {
        properly_filled = false;
      } else if (this.isVerticalOrHorizontalLayout) {
        if (this.line_form.name == null || this.line_form.name == "") {
          properly_filled = false;
        } else if (this.line_form.line_manufacturer == "Please select one") {
          properly_filled = false;
        } else if (this.line_form.pump_type == "Please select one") {
          properly_filled = false;
        } else if (this.line_form.pistols_layout == null) {
          properly_filled = false;
        } else if (this.line_form.air_parameter_name == null || this.line_form.air_parameter_name == "") {
          properly_filled = false;
        } else if (this.line_form.air_parameter_units == null || this.line_form.air_parameter_units == "") {
          properly_filled = false;
        } else if (this.line_form.pistols_max_movement_range == null) {
          properly_filled = false;
        } else if (this.line_form.pistols_min_velocity == null) {
          properly_filled = false;
        } else if (this.line_form.pistols_max_velocity == null) {
          properly_filled = false;
        } else if (this.line_form.total_pistols == null) {
          properly_filled = false;
        } else if (this.line_form.total_pistols.length == 0 || this.total_pistols_vertical_or_horizontal == null) {
          properly_filled = false;
        } else if (this.line_form.pistol_to_pistol_distance == null) {
          properly_filled = false;
        } else if (
          this.line_form.pistol_to_pistol_distance.length == 0 ||
          this.line_form.pistol_to_pistol_distance[0] == 0
        ) {
          properly_filled = false;
        }
      }
      return properly_filled;
    },
    circles() {
      return this.createCirclesLayout();
    },
    gunLayout: function () {
      let reciprocator_layout = "";
      if (this.grid_form.number_of_columns == 1 && this.grid_form.row_shift == 0) {
        if (this.isFixedLayout) {
          reciprocator_layout = "s";
        } else {
          reciprocator_layout = "v";
        }
      } else if (this.grid_form.number_of_rows == 1 && this.grid_form.column_shift == 0) {
        reciprocator_layout = "h";
      } else {
        if (this.isFixedLayout) {
          reciprocator_layout = "fixedmixed";
        } else {
          reciprocator_layout = "m";
        }
      }
      return reciprocator_layout;
    },
    columnDistance: {
      get() {
        if (this.grid_form.column_distance == null || this.grid_form.column_distance == "") {
          return this.grid_form.column_distance;
        }
        if (this.$store.state.user_data.unit_system === "imperial") {
          return parseFloat(
            (this.grid_form.column_distance * this.$store.state.conversion_factors.cm_to_in).toFixed(
              this.max_imperial_decimals,
            ),
          );
        } else if (this.$store.state.user_data.unit_system === "metric") {
          return parseFloat(this.grid_form.column_distance.toFixed(this.max_metric_decimals));
        } else {
          return null;
        }
      },
      set(value) {
        if (value == null || value == "") {
          this.grid_form.column_distance = value;
        } else {
          if (this.$store.state.user_data.unit_system === "imperial") {
            this.grid_form.column_distance = value / this.$store.state.conversion_factors.cm_to_in;
          } else if (this.$store.state.user_data.unit_system === "metric") {
            this.grid_form.column_distance = value;
          } else {
            this.grid_form.column_distance = null;
          }
        }
      },
    },
    rowDistance: {
      get() {
        if (this.grid_form.row_distance == null || this.grid_form.row_distance == "") {
          return this.grid_form.row_distance;
        }
        if (this.$store.state.user_data.unit_system === "imperial") {
          return parseFloat(
            (this.grid_form.row_distance * this.$store.state.conversion_factors.cm_to_in).toFixed(
              this.max_imperial_decimals,
            ),
          );
        } else if (this.$store.state.user_data.unit_system === "metric") {
          return parseFloat(this.grid_form.row_distance.toFixed(this.max_metric_decimals));
        } else {
          return null;
        }
      },
      set(value) {
        let coupled_with_max_movement_range = false;
        if (
          this.grid_form.row_distance == this.line_form.pistols_max_movement_range &&
          !this.user_input_max_movement_range
        ) {
          coupled_with_max_movement_range = true;
        }

        if (value == null || value == "") {
          this.grid_form.row_distance = value;
        } else {
          if (this.$store.state.user_data.unit_system === "imperial") {
            this.grid_form.row_distance = value / this.$store.state.conversion_factors.cm_to_in;
          } else if (this.$store.state.user_data.unit_system === "metric") {
            this.grid_form.row_distance = value;
          } else {
            this.grid_form.row_distance = null;
          }
          if (coupled_with_max_movement_range) {
            this.line_form.pistols_max_movement_range = this.grid_form.row_distance;
          }
        }
      },
    },
    columnShift: {
      get() {
        if (this.grid_form.column_shift == null || this.grid_form.column_shift == "") {
          return this.grid_form.column_shift;
        }
        if (this.$store.state.user_data.unit_system === "imperial") {
          return parseFloat(
            (this.grid_form.column_shift * this.$store.state.conversion_factors.cm_to_in).toFixed(
              this.max_imperial_decimals,
            ),
          );
        } else if (this.$store.state.user_data.unit_system === "metric") {
          return parseFloat(this.grid_form.column_shift.toFixed(this.max_metric_decimals));
        } else {
          return null;
        }
      },
      set(value) {
        if (value == null || value == "") {
          this.grid_form.column_shift = value;
        } else {
          if (this.$store.state.user_data.unit_system === "imperial") {
            this.grid_form.column_shift = value / this.$store.state.conversion_factors.cm_to_in;
          } else if (this.$store.state.user_data.unit_system === "metric") {
            this.grid_form.column_shift = value;
          } else {
            this.grid_form.column_shift = null;
          }
        }
      },
    },
    rowShift: {
      get() {
        if (this.grid_form.row_shift === null || this.grid_form.row_shift === "") {
          return this.grid_form.row_shift;
        }
        if (this.$store.state.user_data.unit_system === "imperial") {
          return parseFloat(
            (this.grid_form.row_shift * this.$store.state.conversion_factors.cm_to_in).toFixed(
              this.max_imperial_decimals,
            ),
          );
        } else if (this.$store.state.user_data.unit_system === "metric") {
          return parseFloat(this.grid_form.row_shift.toFixed(this.max_metric_decimals));
        } else {
          return null;
        }
      },
      set(value) {
        if (value === null || value === "") {
          this.grid_form.row_shift = value;
        } else {
          if (this.$store.state.user_data.unit_system === "imperial") {
            this.grid_form.row_shift = value / this.$store.state.conversion_factors.cm_to_in;
          } else if (this.$store.state.user_data.unit_system === "metric") {
            this.grid_form.row_shift = value;
          } else {
            this.grid_form.row_shift = null;
          }
        }
      },
    },
    maxColumnShiftAllowed: function () {
      if (this.$store.state.user_data.unit_system === "imperial") {
        return (
          this.$store.state.constraints.max_column_shift_allowed * this.$store.state.conversion_factors.cm_to_in
        ).toFixed(this.max_imperial_decimals);
      } else if (this.$store.state.user_data.unit_system === "metric") {
        return this.$store.state.constraints.max_column_shift_allowed;
      } else {
        return null;
      }
    },
    maxRowShiftAllowed: function () {
      if (this.$store.state.user_data.unit_system === "imperial") {
        return (
          this.$store.state.constraints.max_row_shift_allowed * this.$store.state.conversion_factors.cm_to_in
        ).toFixed(this.max_imperial_decimals);
      } else if (this.$store.state.user_data.unit_system === "metric") {
        return this.$store.state.constraints.max_row_shift_allowed;
      } else {
        return null;
      }
    },
  },
  watch: {
    "$store.state.user_data.language"() {
      this.$i18n.locale = this.$store.state.user_data.language;
    },
    gridElementStatus(newStatus) {
      if (newStatus) {
        this.gridElementDimensions = { ...newStatus };
      }
    },
    isFixedLayout(newLayout) {
      if (newLayout && this.gunLayout == "h") {
        this.$swal({
          title: this.$t("Invalid Layout"),
          text: `${this.$t("Horizontal layout is not allowed for Fixed Lines")}`,
          icon: "warning",
          confirmButtonText: "OK",
        });
        this.grid_form.number_of_rows = 2;
      }
    },
    gunLayout(newLayout) {
      if (newLayout == "h" && this.isFixedLayout) {
        this.$swal({
          title: this.$t("Invalid Layout"),
          text: `${this.$t("Horizontal layout is not allowed for Fixed Lines")}`,
          icon: "warning",
          confirmButtonText: "OK",
        });
        this.grid_form.number_of_rows = 2;
      } else if (newLayout == "v") {
        this.grid_form.column_distance = 0;
        if (this.grid_form.row_distance < 10) {
          this.grid_form.row_distance = 10;
        }
        this.grid_form.column_shift = 0;
        this.grid_form.row_shift = 0;
      } else if (newLayout == "h") {
        this.grid_form.row_distance = 0;
        this.grid_form.column_shift = 0;
        this.grid_form.row_shift = 0;
        if (this.grid_form.column_distance < 10) {
          this.grid_form.column_distance = 10;
        }
      } else {
        if (this.grid_form.column_distance < 10 && this.grid_form.number_of_columns > 1) {
          this.grid_form.column_distance = 10;
        }
        if (this.grid_form.row_distance < 10 && this.grid_form.number_of_rows > 1) {
          this.grid_form.row_distance = 10;
        }
      }
    },
    NumberOfReciprocators(newNumberOfReciprocators) {
      if (newNumberOfReciprocators > 7 && this.gunLayout != "v" && this.gunLayout != "h" && this.gunLayout != "s") {
        this.$swal({
          title: this.$t("Input value out of range"),
          text: `${this.$t("For Mixed Lines the number of gun columns must be smaller than")} 7`,
          icon: "warning",
          confirmButtonText: "OK",
        });
        this.grid_form.number_of_columns = Math.min(7, this.grid_form.number_of_columns);
      }
    },
    TotalNumberOfGunsMixed(newNumberOfGuns) {
      if (
        newNumberOfGuns > this.$store.state.constraints.max_total_line_guns_allowed &&
        this.gunLayout != "v" &&
        this.gunLayout != "h" &&
        this.gunLayout != "s"
      ) {
        this.$swal({
          title: this.$t("Input value out of range"),
          text:
            `${this.$t("The number of guns must be smaller than")} ` +
            this.$store.state.constraints.max_total_line_guns_allowed,
          icon: "warning",
          confirmButtonText: "OK",
        });
        this.grid_form.number_of_rows = Math.min(4, this.grid_form.number_of_rows);
        this.grid_form.number_of_columns = Math.min(4, this.grid_form.number_of_columns);
      }
    },
    MixedGunsPerColumnArray(newMixedGunsPerColumnArray) {
      // THIS CHECK NEEDS TO BE REMOVED IF CORE ALLOWS FOR 1 GUN RECIPROCATORS
      if (newMixedGunsPerColumnArray.includes(1)) {
        this.$swal({
          title: this.$t("Input value out of range"),
          text: `${this.$t("Lines with 1 gun per column are not allowed")}`,
          icon: "warning",
          confirmButtonText: "OK",
        });
        this.grid_form.number_of_rows += 1;
      }
    },
  },
  mounted() {
    this.lineId = JSON.parse(JSON.stringify(this.lineIdProp));
    var canvas = document.getElementById("linecanvas");
    var ctx = canvas.getContext("2d");
    this.canvas_width = canvas.width;
    this.canvas_height = canvas.height;
    this.linecanvas = ctx;

    this.getData();
    setTooltip(this.$store.state.bootstrap);
    window.addEventListener("resize", this.onWindowResize);
    this.checkForGridElement();
  },
  updated() {
    setTooltip(this.$store.state.bootstrap);
  },
  beforeUnmount() {
    window.removeEventListener("resize", this.onWindowResize);
  },
  methods: {
    async getData() {
      if (this.lineId !== null && this.lineId !== undefined && this.lineId !== "") {
        await this.getLine(this.lineId);

        this.num_pistol_columns_mixed_layout = this.line.total_pistols.length;

        this.total_pistols_column_1 = this.line.total_pistols[0];
        this.total_pistols_vertical_or_horizontal_input = this.line.total_pistols[0];
        this.total_pistols_column_2 = this.line.total_pistols[1];
        this.total_pistols_column_3 = this.line.total_pistols[2];
        this.total_pistols_column_4 = this.line.total_pistols[3];
        this.total_pistols_column_5 = this.line.total_pistols[4];
        this.total_pistols_column_6 = this.line.total_pistols[5];
        this.total_pistols_column_7 = this.line.total_pistols[6];
        this.total_pistols_column_8 = this.line.total_pistols[7];
        this.total_pistols_column_9 = this.line.total_pistols[8];
        this.total_pistols_column_10 = this.line.total_pistols[9];

        if (this.line.pistol_to_pistol_distance != null) {
          this.pistol_to_pistol_distance_column_1 = this.line.pistol_to_pistol_distance[0] * 100;
          this.pistol_to_pistol_distance_column_2 =
            this.line.pistol_to_pistol_distance[1] != undefined ? this.line.pistol_to_pistol_distance[1] * 100 : null;
          this.pistol_to_pistol_distance_column_3 =
            this.line.pistol_to_pistol_distance[2] != undefined ? this.line.pistol_to_pistol_distance[2] * 100 : null;
          this.pistol_to_pistol_distance_column_4 =
            this.line.pistol_to_pistol_distance[3] != undefined ? this.line.pistol_to_pistol_distance[3] * 100 : null;
          this.pistol_to_pistol_distance_column_5 =
            this.line.pistol_to_pistol_distance[4] != undefined ? this.line.pistol_to_pistol_distance[4] * 100 : null;
          this.pistol_to_pistol_distance_column_6 =
            this.line.pistol_to_pistol_distance[5] != undefined ? this.line.pistol_to_pistol_distance[5] * 100 : null;
          this.pistol_to_pistol_distance_column_7 =
            this.line.pistol_to_pistol_distance[6] != undefined ? this.line.pistol_to_pistol_distance[6] * 100 : null;
          this.pistol_to_pistol_distance_column_8 =
            this.line.pistol_to_pistol_distance[7] != undefined ? this.line.pistol_to_pistol_distance[7] * 100 : null;
          this.pistol_to_pistol_distance_column_9 =
            this.line.pistol_to_pistol_distance[8] != undefined ? this.line.pistol_to_pistol_distance[8] * 100 : null;
          this.pistol_to_pistol_distance_column_10 =
            this.line.pistol_to_pistol_distance[9] != undefined ? this.line.pistol_to_pistol_distance[9] * 100 : null;
        }

        if (this.line.pistol_columns_distances != null) {
          this.pistol_column_distance_1 =
            this.line.pistol_columns_distances[0] != undefined ? this.line.pistol_columns_distances[0] * 100 : null;
          this.pistol_column_distance_2 =
            this.line.pistol_columns_distances[1] != undefined ? this.line.pistol_columns_distances[1] * 100 : null;
          this.pistol_column_distance_3 =
            this.line.pistol_columns_distances[2] != undefined ? this.line.pistol_columns_distances[2] * 100 : null;
          this.pistol_column_distance_4 =
            this.line.pistol_columns_distances[3] != undefined ? this.line.pistol_columns_distances[3] * 100 : null;
          this.pistol_column_distance_5 =
            this.line.pistol_columns_distances[4] != undefined ? this.line.pistol_columns_distances[4] * 100 : null;
          this.pistol_column_distance_6 =
            this.line.pistol_columns_distances[5] != undefined ? this.line.pistol_columns_distances[5] * 100 : null;
          this.pistol_column_distance_7 =
            this.line.pistol_columns_distances[6] != undefined ? this.line.pistol_columns_distances[6] * 100 : null;
          this.pistol_column_distance_8 =
            this.line.pistol_columns_distances[7] != undefined ? this.line.pistol_columns_distances[7] * 100 : null;
          this.pistol_column_distance_9 =
            this.line.pistol_columns_distances[8] != undefined ? this.line.pistol_columns_distances[8] * 100 : null;
          this.pistol_column_distance_10 =
            this.line.pistol_columns_distances[9] != undefined ? this.line.pistol_columns_distances[9] * 100 : null;
        }

        if (this.line.pistol_columns_vertical_offsets != null) {
          this.pistol_column_vertical_offset_2 =
            this.line.pistol_columns_vertical_offsets[1] != undefined
              ? this.line.pistol_columns_vertical_offsets[1] * 100
              : 0;
          this.pistol_column_vertical_offset_3 =
            this.line.pistol_columns_vertical_offsets[2] != undefined
              ? this.line.pistol_columns_vertical_offsets[2] * 100
              : 0;
          this.pistol_column_vertical_offset_4 =
            this.line.pistol_columns_vertical_offsets[3] != undefined
              ? this.line.pistol_columns_vertical_offsets[3] * 100
              : 0;
          this.pistol_column_vertical_offset_5 =
            this.line.pistol_columns_vertical_offsets[4] != undefined
              ? this.line.pistol_columns_vertical_offsets[4] * 100
              : 0;
          this.pistol_column_vertical_offset_6 =
            this.line.pistol_columns_vertical_offsets[5] != undefined
              ? this.line.pistol_columns_vertical_offsets[5] * 100
              : 0;
          this.pistol_column_vertical_offset_7 =
            this.line.pistol_columns_vertical_offsets[6] != undefined
              ? this.line.pistol_columns_vertical_offsets[6] * 100
              : 0;
          this.pistol_column_vertical_offset_8 =
            this.line.pistol_columns_vertical_offsets[7] != undefined
              ? this.line.pistol_columns_vertical_offsets[7] * 100
              : 0;
          this.pistol_column_vertical_offset_9 =
            this.line.pistol_columns_vertical_offsets[8] != undefined
              ? this.line.pistol_columns_vertical_offsets[8] * 100
              : 0;
          this.pistol_column_vertical_offset_10 =
            this.line.pistol_columns_vertical_offsets[9] != undefined
              ? this.line.pistol_columns_vertical_offsets[9] * 100
              : 0;
        }

        this.line_form = this.line;
        if (this.line.powder_recycling_efficiency_percentage == 0) {
          this.line_form.recycling_system = false;
        } else {
          this.line_form.recycling_system = true;
        }

        this.advanced_line_definition = true;
        this.UpdateStandardLineForm();
        this.received_grid_form = { ...this.grid_form };
        this.fillCanvas();

        this.$store.state.isLoading = false;
      }
    },

    numericOnly(event) {
      let key = event.key;
      if (
        [
          "Delete",
          "Backspace",
          "Tab",
          "Enter",
          "ArrowUp",
          "ArrowRight",
          "ArrowDown",
          "ArrowLeft",
          ".",
          "Shift",
          "Control",
          ",",
          "-",
        ].includes(key) ||
        ((event.ctrlKey || event.metaKey) && ["a", "c", "v", "x", "z", "y"].includes(key.toLowerCase()))
      ) {
        return;
      }
      if (!/\d/.test(key)) {
        event.preventDefault();
      }
    },
    goToNewOptimization() {
      if (this.isFixedLayout) {
        this.$router.push({
          name: "FixedLineOptimizationDetail",
          params: { lineId: this.lineId, optimizationTypeProp: null, flightpathIdProp: null },
        });
      } else {
        this.$router.push({
          name: "OptimizationDetail",
          params: { lineId: this.lineId, optimizationTypeProp: null, flightpathIdProp: null },
        });
      }
    },
    AdjustRecycledPercentage() {
      if (this.line_form.powder_recycling_efficiency_percentage < 0) {
        this.line_form.powder_recycling_efficiency_percentage = 0;
      } else if (this.line_form.powder_recycling_efficiency_percentage > 100) {
        this.line_form.powder_recycling_efficiency_percentage = 100;
      }
    },
    AccelerationMinMaxAlertCheck() {
      const accelerationUnit = this.$store.state.units_system[this.$store.state.user_data.unit_system].acceleration;

      if (
        this.line_form.pistols_max_acceleration > this.$store.state.constraints.max_pistols_max_acceleration_allowed ||
        this.line_form.pistols_max_acceleration < this.$store.state.constraints.min_pistols_max_acceleration_allowed
      ) {
        this.$swal({
          title: this.$t("Input value out of range"),
          text: `${this.$t(
            "The gun's maximum acceleration value is outside the permissible range. Please enter a value within the allowed limits.",
          )}  [${accelerationUnit}]`,
          icon: "warning",
          confirmButtonText: "OK",
        });
        this.line_form.pistols_max_acceleration = 0.5;
      }
    },
    WithinRangePistolToPistolDistAlertCheck() {
      const distanceUnit = this.$store.state.units_system[this.$store.state.user_data.unit_system].distance;

      this.line_form.pistol_to_pistol_distance.forEach((pistol_to_pistol_distance, index) => {
        let invalid_input_clean_columns = false;
        if (this.MixedGunsPerColumnArray[index] > 1) {
          if (pistol_to_pistol_distance == 0) {
            this.$swal({
              title: this.$t("Input value out of range"),
              text: `${this.$t("Gun to gun distance cannot be 0")} [${distanceUnit}]`,
              icon: "warning",
              confirmButtonText: "OK",
            });
            invalid_input_clean_columns = true;
          } else if (
            pistol_to_pistol_distance * 100 <
            this.$store.state.constraints.min_pistol_to_pistol_distance_allowed
          ) {
            this.$swal({
              title: this.$t("Input value out of range"),
              text: `${this.$t("Gun to Gun distance must be greater than")} ${
                this.minPistolToPistolDistanceAllowed
              } [${distanceUnit}]`,
              icon: "warning",
              confirmButtonText: "OK",
            });
            invalid_input_clean_columns = true;
          } else if (
            pistol_to_pistol_distance * 100 >
            this.$store.state.constraints.max_pistol_to_pistol_distance_allowed
          ) {
            this.$swal({
              title: this.$t("Input value out of range"),
              text: `${this.$t("Gun to Gun distance must be smaller than")} ${
                this.maxPistolToPistolDistanceAllowed
              } [${distanceUnit}]`,
              icon: "warning",
              confirmButtonText: "OK",
            });
            invalid_input_clean_columns = true;
          }
        }
        if (invalid_input_clean_columns) {
          if (this.LineIsCreated) {
            if (index == 0) {
              this.pistol_to_pistol_distance_column_1 = this.line.pistol_to_pistol_distance[index] * 100;
            } else if (index == 1) {
              this.pistol_to_pistol_distance_column_2 = this.line.pistol_to_pistol_distance[index] * 100;
            } else if (index == 2) {
              this.pistol_to_pistol_distance_column_3 = this.line.pistol_to_pistol_distance[index] * 100;
            } else if (index == 3) {
              this.pistol_to_pistol_distance_column_4 = this.line.pistol_to_pistol_distance[index] * 100;
            } else if (index == 4) {
              this.pistol_to_pistol_distance_column_5 = this.line.pistol_to_pistol_distance[index] * 100;
            } else if (index == 5) {
              this.pistol_to_pistol_distance_column_6 = this.line.pistol_to_pistol_distance[index] * 100;
            } else if (index == 6) {
              this.pistol_to_pistol_distance_column_7 = this.line.pistol_to_pistol_distance[index] * 100;
            } else if (index == 7) {
              this.pistol_to_pistol_distance_column_8 = this.line.pistol_to_pistol_distance[index] * 100;
            } else if (index == 8) {
              this.pistol_to_pistol_distance_column_9 = this.line.pistol_to_pistol_distance[index] * 100;
            } else if (index == 9) {
              this.pistol_to_pistol_distance_column_10 = this.line.pistol_to_pistol_distance[index] * 100;
            }
          } else {
            if (index == 0) {
              this.pistol_to_pistol_distance_column_1 = null;
            } else if (index == 1) {
              this.pistol_to_pistol_distance_column_2 = null;
            } else if (index == 2) {
              this.pistol_to_pistol_distance_column_3 = null;
            } else if (index == 3) {
              this.pistol_to_pistol_distance_column_4 = null;
            } else if (index == 4) {
              this.pistol_to_pistol_distance_column_5 = null;
            } else if (index == 5) {
              this.pistol_to_pistol_distance_column_6 = null;
            } else if (index == 6) {
              this.pistol_to_pistol_distance_column_7 = null;
            } else if (index == 7) {
              this.pistol_to_pistol_distance_column_8 = null;
            } else if (index == 8) {
              this.pistol_to_pistol_distance_column_9 = null;
            } else if (index == 9) {
              this.pistol_to_pistol_distance_column_10 = null;
            }
          }
          this.UpdateLineForm();
        }
      });
    },
    async WithinRangeColumnToColumnDistAlertCheck(pistolColumnDistance, index) {
      if (pistolColumnDistance == null || pistolColumnDistance == "") {
        return new Promise(resolve => resolve());
      }

      const distanceUnit = this.$store.state.units_system[this.$store.state.user_data.unit_system].distance;
      let isOutOfRange = false;
      let message = "";
      if (pistolColumnDistance == 0) {
        isOutOfRange = true;
        message = this.$t("Gun to Gun distance cannot be 0");
      } else if (pistolColumnDistance < this.minColumnToColumnDistanceAllowed) {
        isOutOfRange = true;
        message = `${this.$t("Gun to Gun distance must be greater than")} ${this.minColumnToColumnDistanceAllowed}`;
      } else if (pistolColumnDistance > this.maxColumnToColumnDistanceAllowed) {
        isOutOfRange = true;
        message = `${this.$t("Gun to Gun distance must be smaller than")} ${this.maxColumnToColumnDistanceAllowed}`;
      }

      if (isOutOfRange) {
        this["pistolColumnDistance" + (index + 1)] = this.line.pistol_columns_distances[index] * 100;
        this.$swal({
          title: this.$t("Input value out of range"),
          text: `${message} [${distanceUnit}]`,
          icon: "warning",
          confirmButtonText: "OK",
        });
      }
      return new Promise(resolve => resolve());
    },
    ZeroMaxMovementRangeAlertCheck() {
      const distanceUnit = this.$store.state.units_system[this.$store.state.user_data.unit_system].distance;
      if (this.line_form.pistols_max_movement_range == 0) {
        this.$swal({
          title: this.$t("Input value out of range"),
          text: `${this.$t("Gun max movement range cannot be 0")} [${distanceUnit}]`,
          icon: "warning",
          confirmButtonText: "OK",
        });
        this.line_form.pistols_max_movement_range = this.line.pistols_max_movement_range;
      } else if (
        this.line_form.pistols_max_movement_range * 100 <
        this.$store.state.constraints.min_pistols_max_movement_range_allowed
      ) {
        this.$swal({
          title: this.$t("Input value out of range"),
          text: `${this.$t("Gun max movement range must be greater than")} ${
            this.minPistolsMaxMovementRangeAllowed
          } [${distanceUnit}]`,
          icon: "warning",
          confirmButtonText: "OK",
        });
        this.line_form.pistols_max_movement_range =
          this.$store.state.constraints.min_pistols_max_movement_range_allowed;
      } else if (
        this.line_form.pistols_max_movement_range > this.$store.state.constraints.max_pistols_max_movement_range_allowed
      ) {
        this.$swal({
          title: this.$t("Input value out of range"),
          text: `${this.$t("Gun max movement range must be smaller than")} ${
            this.maxPistolsMaxMovementRangeAllowed
          } [${distanceUnit}]`,
          icon: "warning",
          confirmButtonText: "OK",
        });
        this.line_form.pistols_max_movement_range =
          this.$store.state.constraints.max_pistols_max_movement_range_allowed;
      }
    },
    ZeroPistolsMinVelAlertCheck() {
      const velocityUnit = this.$store.state.units_system[this.$store.state.user_data.unit_system].velocity_seconds;
      if (this.line_form.pistols_layout == "h" && this.line_form.pistols_min_velocity == 0) {
        this.$swal({
          title: this.$t("Input value out of range"),
          text: this.$t("Gun Min Velocity cannot be 0 for Horizontal Gun Layout."),
          icon: "warning",
          confirmButtonText: "OK",
        });
        this.line_form.pistols_min_velocity = this.line.pistols_min_velocity;
      } else if (this.line_form.pistols_min_velocity < this.$store.state.constraints.min_pistols_velocity_allowed) {
        this.$swal({
          title: this.$t("Input value out of range"),
          text: `${this.$t("Gun Min Velocity must be greater than")} ${
            this.minPistolsVelocityAllowed
          } [${velocityUnit}].`,
          icon: "warning",
          confirmButtonText: "OK",
        });
        this.line_form.pistols_min_velocity = this.line.pistols_min_velocity;
      }
    },
    checkMinMaxPistolsVelocity() {
      const minMaxVelocityDeltaRequired = this.$store.state.user_data.unit_system == "metric" ? "0.3m/2" : "0.98ft/s";

      if (this.line_form.pistols_max_velocity - 0.3 <= this.line_form.pistols_min_velocity) {
        this.$swal({
          title: this.$t("Input value out of range"),
          text: `${this.$t("Gun Max Velocity must be greater than Gun Min Velocity by at least")} ${minMaxVelocityDeltaRequired}.`,
          icon: "warning",
          confirmButtonText: "OK",
        });
        this.pistolsMaxVelocity = this.pistolsMinVelocity + 0.3;
      }
    },
    ZeroPistolsMaxVelAlertCheck() {
      const velocityUnit = this.$store.state.units_system[this.$store.state.user_data.unit_system].velocity_seconds;
      if (this.line_form.pistols_max_velocity == 0) {
        this.$swal({
          title: this.$t("Input value out of range"),
          text: `${this.$t("Gun Max Velocity cannot be 0")} [${velocityUnit}].`,
          icon: "warning",
          confirmButtonText: "OK",
        });
        this.line_form.pistols_max_velocity = this.line.pistols_max_velocity;
      }
    },
    changeConfigurationMenu() {
      if (this.AdvancedConfiguration) {
        this.$swal({
          title: this.$t("Attention!"),
          text: this.$t("Changing to standard configuration might modify your line. Do you want to continue?"),
          icon: "warning",
          showCancelButton: true,
          cancelButtonText: this.$t("Cancel"),
          confirmButtonText: "OK",
          customClass: {
            confirmButton: "btn bg-gradient-success w-40",
            cancelButton: "btn bg-gradient-danger w-40",
          },
          reverseButtons: true,
          buttonsStyling: false,
        }).then(result => {
          if (result.isConfirmed) {
            this.UpdateStandardLineForm();
            this.advanced_line_definition = false;
          } else if (result.dismiss === this.$swal.DismissReason.cancel) {
            this.$swal.dismiss;
          }
        });
      } else {
        this.UpdateAdvancedLineForm();
        this.advanced_line_definition = true;
        this.changedFieldCheckInitialFillCanvas();
        this.fillCanvas();
      }
    },
    changedFieldCheckInitialFillCanvas() {
      if (
        this.image_preview_not_computed &&
        ((this.isMixedLayout && this.MixedLineFormFilled) ||
          (this.isVerticalOrHorizontalLayout && this.isVerticalOrHorizontalLineFormFilled))
      ) {
        this.fillCanvas();
      }
    },
    LineFormProperlyFilled() {
      let properly_filled = true;
      let missing_fields = [];
      if (this.line_form.name == null || this.line_form.name === "") {
        this.line_input_error = true;
        properly_filled = false;
        missing_fields.push(this.$t("Name"));
      }
      if (this.line_form.line_manufacturer == "Please select one") {
        this.line_manufacturer_input_error = true;
        properly_filled = false;
        missing_fields.push(this.$t("Line Manufacturer"));
      }
      if (this.line_form.pump_type == "Please select one") {
        this.pump_type_input_error = true;
        properly_filled = false;
        missing_fields.push(this.$t("Pump Type"));
      }
      if (this.line_form.air_parameter_name == null || this.line_form.air_parameter_name === "") {
        this.air_name_input_error = true;
        properly_filled = false;
        missing_fields.push(this.$t("Air Parameter Name"));
      }
      if (this.line_form.air_parameter_units == null || this.line_form.air_parameter_units === "") {
        this.air_units_input_error = true;
        properly_filled = false;
        missing_fields.push(this.$t("Air Parameter Units"));
      }
      if (this.isVerticalOrHorizontalLayout || this.isMixedLayout) {
        if (this.line_form.pistols_max_movement_range == null || this.line_form.pistols_max_movement_range === "") {
          this.pistols_max_movement_range_input_error = true;
          properly_filled = false;
          missing_fields.push(this.$t("Gun max movement range"));
        }
        if (this.line_form.pistols_min_velocity == null || this.line_form.pistols_min_velocity === "") {
          this.pistols_min_velocity_input_error = true;
          properly_filled = false;
          missing_fields.push(this.$t("Gun min velocity"));
        }
        if (this.line_form.pistols_max_velocity == null || this.line_form.pistols_max_velocity === "") {
          this.pistols_max_velocity_input_error = true;
          properly_filled = false;
          missing_fields.push(this.$t("Gun max velocity"));
        }
        if (this.line_form.pistols_max_acceleration == null || this.line_form.pistols_max_acceleration === "") {
          this.pistols_max_acceleration_input_error = true;
          properly_filled = false;
          missing_fields.push(this.$t("Gun max acceleration"));
        }
      }
      return [properly_filled, missing_fields];
    },
    AdvancedFormProperlyFilled() {
      let properly_filled = true;
      let missing_fields = [];
      if (this.line_form.pistols_layout == null) {
        this.pistols_layout_input_error = true;
        properly_filled = false;
        missing_fields.push(this.$t("Gun Layout"));
      }
      if (this.isVerticalOrHorizontalLayout || this.isMixedLayout) {
        if (
          this.line_form.pistol_to_pistol_distance == null ||
          this.line_form.pistol_to_pistol_distance === "" ||
          this.line_form.pistol_to_pistol_distance == []
        ) {
          this.pistol_to_pistol_input_error = true;
          properly_filled = false;
          missing_fields.push(this.$t("Gun to gun distance"));
        } else if (
          this.line_form.pistol_to_pistol_distance.length == 0 ||
          (this.line_form.pistol_to_pistol_distance[0] == 0 && this.total_pistols_column_1 != 1)
        ) {
          this.pistol_to_pistol_input_error = true;
          properly_filled = false;
          missing_fields.push(this.$t("Gun to gun distance"));
        }
      }
      if (
        (this.isVerticalOrHorizontalLayout || this.isStaticVerticalLayout) &&
        (this.line_form.total_pistols.length == 0 || this.line_form.total_pistols[0] == 0)
      ) {
        this.total_pistols_input_error = true;
        properly_filled = false;
        missing_fields.push(this.$t("Total Guns"));
      }
      if (this.isMixedLayout || this.isStaticMixedLayout) {
        if (this.num_pistol_columns_mixed_layout == null) {
          this.num_pistol_columns_mixed_layout_input_error = true;
          properly_filled = false;
          missing_fields.push(this.$t("Number of Gun Columns"));
        }
        if (this.total_pistols_column_1 == null) {
          this.total_pistols_column_1_input_error = true;
          properly_filled = false;
          missing_fields.push(this.$t("Total Guns Column 1"));
        }
        if (this.total_pistols_column_2 == null) {
          this.total_pistols_column_2_input_error = true;
          properly_filled = false;
          missing_fields.push(this.$t("Total Guns Column 2"));
        }
        if (this.num_pistol_columns_mixed_layout >= 3 && this.total_pistols_column_3 == null) {
          this.total_pistols_column_3_input_error = true;
          properly_filled = false;
          missing_fields.push(this.$t("Total Guns Column 3"));
        }
        if (this.num_pistol_columns_mixed_layout >= 4 && this.total_pistols_column_4 == null) {
          this.total_pistols_column_4_input_error = true;
          properly_filled = false;
          missing_fields.push(this.$t("Total Guns Column 4"));
        }
        if (this.num_pistol_columns_mixed_layout >= 5 && this.total_pistols_column_5 == null) {
          this.total_pistols_column_5_input_error = true;
          properly_filled = false;
          missing_fields.push(this.$t("Total Guns Column 5"));
        }
        if (this.num_pistol_columns_mixed_layout >= 6 && this.total_pistols_column_6 == null) {
          this.total_pistols_column_6_input_error = true;
          properly_filled = false;
          missing_fields.push(this.$t("Total Guns Column 6"));
        }
        if (this.num_pistol_columns_mixed_layout >= 7 && this.total_pistols_column_7 == null) {
          this.total_pistols_column_7_input_error = true;
          properly_filled = false;
          missing_fields.push(this.$t("Total Guns Column 7"));
        }
        if (this.num_pistol_columns_mixed_layout >= 8 && this.total_pistols_column_8 == null) {
          this.total_pistols_column_8_input_error = true;
          properly_filled = false;
          missing_fields.push(this.$t("Total Guns Column 8"));
        }
        if (this.num_pistol_columns_mixed_layout >= 9 && this.total_pistols_column_9 == null) {
          this.total_pistols_column_9_input_error = true;
          properly_filled = false;
          missing_fields.push(this.$t("Total Guns Column 9"));
        }
        if (this.num_pistol_columns_mixed_layout >= 10 && this.total_pistols_column_10 == null) {
          this.total_pistols_column_10_input_error = true;
          properly_filled = false;
          missing_fields.push(this.$t("Total Guns Column 10"));
        }
        if (this.pistol_column_distance_1 == null || this.pistol_column_distance_1 === "") {
          this.pistol_column_distance_1_input_error = true;
          properly_filled = false;
          missing_fields.push(this.$t("Gun Column Distance 1"));
        }
        if (this.pistol_column_distance_2 == null || this.pistol_column_distance_2 === "") {
          this.pistol_column_distance_2_input_error = true;
          properly_filled = false;
          missing_fields.push(this.$t("Gun Column Distance 2"));
        }
        if (
          this.num_pistol_columns_mixed_layout >= 3 &&
          (this.pistol_column_distance_3 == null || this.pistol_column_distance_3 === "")
        ) {
          this.pistol_column_distance_3_input_error = true;
          properly_filled = false;
          missing_fields.push(this.$t("Gun Column Distance 3"));
        }
        if (
          this.num_pistol_columns_mixed_layout >= 4 &&
          (this.pistol_column_distance_4 == null || this.pistol_column_distance_4 === "")
        ) {
          this.pistol_column_distance_4_input_error = true;
          properly_filled = false;
          missing_fields.push(this.$t("Gun Column Distance 4"));
        }
        if (
          this.num_pistol_columns_mixed_layout >= 5 &&
          (this.pistol_column_distance_5 == null || this.pistol_column_distance_5 === "")
        ) {
          this.pistol_column_distance_5_input_error = true;
          properly_filled = false;
          missing_fields.push(this.$t("Gun Column Distance 5"));
        }
        if (
          this.num_pistol_columns_mixed_layout >= 6 &&
          (this.pistol_column_distance_6 == null || this.pistol_column_distance_6 === "")
        ) {
          this.pistol_column_distance_6_input_error = true;
          properly_filled = false;
          missing_fields.push(this.$t("Gun Column Distance 6"));
        }
        if (
          this.num_pistol_columns_mixed_layout >= 7 &&
          (this.pistol_column_distance_7 == null || this.pistol_column_distance_7 === "")
        ) {
          this.pistol_column_distance_7_input_error = true;
          properly_filled = false;
          missing_fields.push(this.$t("Gun Column Distance 7"));
        }
        if (
          this.num_pistol_columns_mixed_layout >= 8 &&
          (this.pistol_column_distance_8 == null || this.pistol_column_distance_8 === "")
        ) {
          this.pistol_column_distance_8_input_error = true;
          properly_filled = false;
          missing_fields.push(this.$t("Gun Column Distance 8"));
        }
        if (
          this.num_pistol_columns_mixed_layout >= 9 &&
          (this.pistol_column_distance_9 == null || this.pistol_column_distance_9 === "")
        ) {
          this.pistol_column_distance_9_input_error = true;
          properly_filled = false;
          missing_fields.push(this.$t("Gun Column Distance 9"));
        }
        if (
          this.num_pistol_columns_mixed_layout >= 10 &&
          (this.pistol_column_distance_10 == null || this.pistol_column_distance_10 === "")
        ) {
          this.pistol_column_distance_10_input_error = true;
          properly_filled = false;
          missing_fields.push(this.$t("Gun Column Distance 10"));
        }
      }
      if (this.isMixedLayout) {
        if (this.pistol_to_pistol_distance_column_1 == null || this.pistol_to_pistol_distance_column_1 === "") {
          this.pistol_to_pistol_distance_column_1_input_error = true;
          properly_filled = false;
          missing_fields.push(this.$t("Gun to Gun Distance Column 1"));
        }
        if (this.pistol_to_pistol_distance_column_2 == null || this.pistol_to_pistol_distance_column_2 === "") {
          this.pistol_to_pistol_distance_column_2_input_error = true;
          properly_filled = false;
          missing_fields.push(this.$t("Gun to Gun Distance Column 2"));
        }
        if (
          this.num_pistol_columns_mixed_layout >= 3 &&
          (this.pistol_to_pistol_distance_column_3 == null || this.pistol_to_pistol_distance_column_3 === "")
        ) {
          this.pistol_to_pistol_distance_column_3_input_error = true;
          properly_filled = false;
          missing_fields.push(this.$t("Gun to Gun Distance Column 3"));
        }
        if (
          this.num_pistol_columns_mixed_layout >= 4 &&
          (this.pistol_to_pistol_distance_column_4 == null || this.pistol_to_pistol_distance_column_4 === "")
        ) {
          this.pistol_to_pistol_distance_column_4_input_error = true;
          properly_filled = false;
          missing_fields.push(this.$t("Gun to Gun Distance Column 4"));
        }
        if (
          this.num_pistol_columns_mixed_layout >= 5 &&
          (this.pistol_to_pistol_distance_column_5 == null || this.pistol_to_pistol_distance_column_5 === "")
        ) {
          this.pistol_to_pistol_distance_column_5_input_error = true;
          properly_filled = false;
          missing_fields.push(this.$t("Gun to Gun Distance Column 5"));
        }
        if (
          this.num_pistol_columns_mixed_layout >= 6 &&
          (this.pistol_to_pistol_distance_column_6 == null || this.pistol_to_pistol_distance_column_6 === "")
        ) {
          this.pistol_to_pistol_distance_column_6_input_error = true;
          properly_filled = false;
          missing_fields.push(this.$t("Gun to Gun Distance Column 6"));
        }
        if (
          this.num_pistol_columns_mixed_layout >= 7 &&
          (this.pistol_to_pistol_distance_column_7 == null || this.pistol_to_pistol_distance_column_7 === "")
        ) {
          this.pistol_to_pistol_distance_column_7_input_error = true;
          properly_filled = false;
          missing_fields.push(this.$t("Gun to Gun Distance Column 7"));
        }
        if (
          this.num_pistol_columns_mixed_layout >= 8 &&
          (this.pistol_to_pistol_distance_column_8 == null || this.pistol_to_pistol_distance_column_8 === "")
        ) {
          this.pistol_to_pistol_distance_column_8_input_error = true;
          properly_filled = false;
          missing_fields.push(this.$t("Gun to Gun Distance Column 8"));
        }
        if (
          this.num_pistol_columns_mixed_layout >= 9 &&
          (this.pistol_to_pistol_distance_column_9 == null || this.pistol_to_pistol_distance_column_9 === "")
        ) {
          this.pistol_to_pistol_distance_column_9_input_error = true;
          properly_filled = false;
          missing_fields.push(this.$t("Gun to Gun Distance Column 9"));
        }
        if (
          this.num_pistol_columns_mixed_layout >= 10 &&
          (this.pistol_to_pistol_distance_column_10 == null || this.pistol_to_pistol_distance_column_10 === "")
        ) {
          this.pistol_to_pistol_distance_column_10_input_error = true;
          properly_filled = false;
          missing_fields.push(this.$t("Gun to Gun Distance Column 10"));
        }
        if (this.pistol_column_vertical_offset_2 == null || this.pistol_column_vertical_offset_2 === "") {
          this.pistol_column_vertical_offset_2_input_error = true;
          properly_filled = false;
          missing_fields.push(this.$t("Gun Column Vertical Offset 2"));
        }
        if (
          this.num_pistol_columns_mixed_layout >= 3 &&
          (this.pistol_column_vertical_offset_3 == null || this.pistol_column_vertical_offset_3 === "")
        ) {
          this.pistol_column_vertical_offset_3_input_error = true;
          properly_filled = false;
          missing_fields.push(this.$t("Gun Column Vertical Offset 3"));
        }
        if (
          this.num_pistol_columns_mixed_layout >= 4 &&
          (this.pistol_column_vertical_offset_4 == null || this.pistol_column_vertical_offset_4 === "")
        ) {
          this.pistol_column_vertical_offset_4_input_error = true;
          properly_filled = false;
          missing_fields.push(this.$t("Gun Column Vertical Offset 4"));
        }
        if (
          this.num_pistol_columns_mixed_layout >= 5 &&
          (this.pistol_column_vertical_offset_5 == null || this.pistol_column_vertical_offset_5 === "")
        ) {
          this.pistol_column_vertical_offset_5_input_error = true;
          properly_filled = false;
          missing_fields.push(this.$t("Gun Column Vertical Offset 5"));
        }
        if (
          this.num_pistol_columns_mixed_layout >= 6 &&
          (this.pistol_column_vertical_offset_6 == null || this.pistol_column_vertical_offset_6 === "")
        ) {
          this.pistol_column_vertical_offset_6_input_error = true;
          properly_filled = false;
          missing_fields.push(this.$t("Gun Column Vertical Offset 6"));
        }
        if (
          this.num_pistol_columns_mixed_layout >= 7 &&
          (this.pistol_column_vertical_offset_7 == null || this.pistol_column_vertical_offset_7 === "")
        ) {
          this.pistol_column_vertical_offset_7_input_error = true;
          properly_filled = false;
          missing_fields.push(this.$t("Gun Column Vertical Offset 7"));
        }
        if (
          this.num_pistol_columns_mixed_layout >= 8 &&
          (this.pistol_column_vertical_offset_8 == null || this.pistol_column_vertical_offset_8 === "")
        ) {
          this.pistol_column_vertical_offset_8_input_error = true;
          properly_filled = false;
          missing_fields.push(this.$t("Gun Column Vertical Offset 8"));
        }
        if (
          this.num_pistol_columns_mixed_layout >= 9 &&
          (this.pistol_column_vertical_offset_9 == null || this.pistol_column_vertical_offset_9 === "")
        ) {
          this.pistol_column_vertical_offset_9_input_error = true;
          properly_filled = false;
          missing_fields.push(this.$t("Gun Column Vertical Offset 9"));
        }
        if (
          this.num_pistol_columns_mixed_layout >= 10 &&
          (this.pistol_column_vertical_offset_10 == null || this.pistol_column_vertical_offset_10 === "")
        ) {
          this.pistol_column_vertical_offset_10_input_error = true;
          properly_filled = false;
          missing_fields.push(this.$t("Gun Column Vertical Offset 10"));
        }
      }
      return [properly_filled, missing_fields];
    },
    CheckDynamicLineMaxPistols() {
      if (this.total_pistols_vertical_or_horizontal > 10) {
        this.total_pistols_vertical_or_horizontal = 10;
      }
    },
    AdjustNumberOfColumns() {
      if (this.grid_form.number_of_columns < 1) {
        this.grid_form.number_of_columns = 1;
      }
    },
    AdjustColumnShift() {
      if (this.grid_form.column_shift === null || this.grid_form.column_shift === "") {
        this.grid_form.column_shift = 0;
      }
    },
    MaxNumberOfColumnsAllowed() {
      if (
        this.grid_form.number_of_columns > this.$store.state.constraints.max_number_of_columns_allowed &&
        this.grid_form.number_of_rows > 1
      ) {
        this.$swal({
          title: this.$t("Input value out of range"),
          text: `${this.$t("Number of columns cannot be greater than")} ${this.$store.state.constraints.max_number_of_columns_allowed}`,
          icon: "warning",
          confirmButtonText: "OK",
        });
        this.grid_form.number_of_columns = this.$store.state.constraints.max_number_of_columns_allowed;
      } else if (
        this.grid_form.number_of_columns > this.$store.state.constraints.max_horizontal_line_guns &&
        this.grid_form.number_of_rows == 1
      ) {
        this.$swal({
          title: this.$t("Input value out of range"),
          text: `${this.$t("Number of columns cannot be greater than")} ${this.$store.state.constraints.max_horizontal_line_guns}`,
          icon: "warning",
          confirmButtonText: "OK",
        });
        this.grid_form.number_of_columns = this.$store.state.constraints.max_horizontal_line_guns;
      }
    },
    AdjustNumberOfRows() {
      if (this.grid_form.number_of_rows < 1) {
        this.grid_form.number_of_rows = 1;
      }
    },
    AdjustRowShift() {
      if (this.grid_form.row_shift == null || this.grid_form.row_shift == "") {
        this.grid_form.row_shift = 0;
      }
    },
    MaxNumberOfRowsAllowedCheck() {
      if (this.grid_form.number_of_rows > this.$store.state.constraints.max_number_of_rows_allowed) {
        this.$swal({
          title: this.$t("Input value out of range"),
          text: `${this.$t("Number of rows cannot be greater than")} ${this.$store.state.constraints.max_number_of_rows_allowed}`,
          icon: "warning",
          confirmButtonText: "OK",
        });
        this.grid_form.number_of_rows = this.$store.state.constraints.max_number_of_rows_allowed;
      }
    },
    createCirclesLayout() {
      let circles = [];
      const cols = parseInt(this.grid_form.number_of_columns) || 1;
      const rows = parseInt(this.grid_form.number_of_rows) || 1;
      const baseCircleSize = 10;
      const colDistance = Math.max(parseInt(this.grid_form.column_distance), 10);
      const rowDistance = Math.max(parseInt(this.grid_form.row_distance), 10);
      const colShift = parseInt(this.grid_form.column_shift);
      const rowShift = parseInt(this.grid_form.row_shift);

      const totalWidth = cols * (baseCircleSize + colDistance) - colDistance + Math.abs(rowShift);
      const totalHeight = rows * (baseCircleSize + rowDistance) - rowDistance + Math.abs(colShift);

      let gridDimensions = this.gridElementDimensions;
      let drawingAreaWidth = gridDimensions.width;
      let drawingAreaHeight = gridDimensions.height;

      let scale_factor = 0.5;

      if (rows + cols <= 3) {
        scale_factor = 0.5;
      } else {
        let gun_circle_diameter = 10;
        let max_scale_factor = 0.85;
        let min_scale_factor = 0.4;
        let max_row_distance = 120;
        let max_col_distance = 150;

        let row_distance_to_max_distance_ratio = rowDistance / (max_row_distance - gun_circle_diameter);
        let col_distance_to_max_distance_ratio = colDistance / (max_col_distance - gun_circle_diameter);
        let max_distance_ratio = Math.max(row_distance_to_max_distance_ratio, col_distance_to_max_distance_ratio);

        scale_factor = (max_scale_factor - min_scale_factor) * max_distance_ratio + min_scale_factor;
      }

      let scale = Math.min(
        (drawingAreaWidth * scale_factor) / totalWidth,
        (drawingAreaHeight * scale_factor) / totalHeight,
      );

      const offsetX = (drawingAreaWidth - totalWidth * scale) / 2;
      const offsetY = (drawingAreaHeight - totalHeight * scale) / 2;

      for (let row = 0; row < rows; row++) {
        for (let col = 0; col < cols; col++) {
          let top = offsetY + row * (baseCircleSize + rowDistance) * scale;
          let left = offsetX + col * (baseCircleSize + colDistance) * scale;

          if (row % 2 === 1) {
            left += rowShift * scale;
            if (rowShift > 0) {
              left += ((baseCircleSize * rowShift) / colDistance) * scale;
            } else if (rowShift < 0) {
              left -= ((baseCircleSize * -rowShift) / colDistance) * scale;
            }
          }

          if (col % 2 === 1) {
            top += colShift * scale;
            if (colShift > 0) {
              top += ((baseCircleSize * colShift) / rowDistance) * scale;
            } else if (colShift < 0) {
              top -= ((baseCircleSize * -colShift) / rowDistance) * scale;
            }
          }
          circles.push({
            style: {
              left: `${left}px`,
              top: `${top}px`,
              transform: `scale(${scale})`,
            },
          });
        }
      }
      return circles;
    },
    createCirclesCoordinates() {
      let coordinates = [];
      const cols = parseInt(this.grid_form.number_of_columns);
      const rows = parseInt(this.grid_form.number_of_rows);
      const colDistance = this.grid_form.column_distance;
      const rowDistance = this.grid_form.row_distance;
      const colShift = this.grid_form.column_shift;
      const rowShift = this.grid_form.row_shift;

      for (let row = 0; row < rows; row++) {
        for (let col = 0; col < cols; col++) {
          let x = col * colDistance;
          let y = row * rowDistance;

          if (row % 2 === 1) {
            x += rowShift;
          }

          if (col % 2 === 1) {
            y -= colShift;
          }
          coordinates.push({
            x: x,
            y: y,
          });
        }
      }
      return coordinates;
    },

    checkForGridElement() {
      const gridElement = document.getElementById("grid");
      if (gridElement) {
        const rect = gridElement.getBoundingClientRect();
        this.gridElementStatus = { width: rect.width, height: rect.height };
      } else {
        setTimeout(() => {
          this.checkForGridElement();
        }, 500);
      }
    },
    onWindowResize() {
      this.checkForGridElement();
    },
    WithinRangeRowToRowDistAlertCheck() {
      const distanceUnit = this.$store.state.units_system[this.$store.state.user_data.unit_system].distance;
      let isOutOfRange = false;
      let message = "";
      if (this.gunLayout != "h") {
        if (this.rowDistance < this.minColumnToColumnDistanceAllowed) {
          isOutOfRange = true;
          message = `${this.$t("Row distance must be greater than")} ${this.minColumnToColumnDistanceAllowed}`;
          this.rowDistance = this.minColumnToColumnDistanceAllowed;
        } else if (this.rowDistance > this.maxColumnToColumnDistanceAllowed) {
          isOutOfRange = true;
          message = `${this.$t("Row distance must be smaller than")} ${this.maxColumnToColumnDistanceAllowed}`;
          this.rowDistance = this.maxColumnToColumnDistanceAllowed;
        }
      }

      if (isOutOfRange) {
        this.$swal({
          title: this.$t("Input value out of range"),
          text: `${message} [${distanceUnit}]`,
          icon: "warning",
          confirmButtonText: "OK",
        });
      }
    },
    WithinRangeColumnToColumnDistanceAlertCheck() {
      const distanceUnit = this.$store.state.units_system[this.$store.state.user_data.unit_system].distance;
      let isOutOfRange = false;
      let message = "";
      if (this.gunLayout != "v") {
        if (this.columnDistance < this.minColumnToColumnDistanceAllowed) {
          isOutOfRange = true;
          message = `${this.$t("Column distance must be greater than")} ${this.minColumnToColumnDistanceAllowed}`;
          this.columnDistance = this.minColumnToColumnDistanceAllowed;
        } else if (this.columnDistance > this.maxColumnToColumnDistanceAllowed) {
          isOutOfRange = true;
          message = `${this.$t("Column distance must be smaller than")} ${this.maxColumnToColumnDistanceAllowed}`;
          this.columnDistance = this.maxColumnToColumnDistanceAllowed;
        }
      }

      if (isOutOfRange) {
        this.$swal({
          title: this.$t("Input value out of range"),
          text: `${message} [${distanceUnit}]`,
          icon: "warning",
          confirmButtonText: "OK",
        });
      }
    },
    WithinRangeColumnShiftAlertCheck() {
      const distanceUnit = this.$store.state.units_system[this.$store.state.user_data.unit_system].distance;
      let isOutOfRange = false;
      let message = "";
      if (this.columnShift < -this.maxColumnShiftAllowed) {
        isOutOfRange = true;
        message = `${this.$t("Column Shift must be greater than")} ${-this.maxColumnShiftAllowed}`;
        this.columnShift = -this.maxColumnShiftAllowed;
      } else if (this.columnShift > this.maxColumnShiftAllowed) {
        isOutOfRange = true;
        message = `${this.$t("Column Shift must be smaller than")} ${this.maxColumnShiftAllowed}`;
        this.columnShift = this.maxColumnShiftAllowed;
      }

      if (isOutOfRange) {
        this.$swal({
          title: this.$t("Input value out of range"),
          text: `${message} [${distanceUnit}]`,
          icon: "warning",
          confirmButtonText: "OK",
        });
      }
    },
    WithinRangeRowShiftAlertCheck() {
      const distanceUnit = this.$store.state.units_system[this.$store.state.user_data.unit_system].distance;
      let isOutOfRange = false;
      let message = "";
      if (this.rowShift < -this.maxRowShiftAllowed) {
        isOutOfRange = true;
        message = `${this.$t("Row Shift must be greater than")} ${-this.maxRowShiftAllowed}`;
        this.rowShift = -this.maxRowShiftAllowed;
      } else if (this.rowShift > this.maxRowShiftAllowed) {
        isOutOfRange = true;
        message = `${this.$t("Row Shift must be smaller than")} ${this.maxRowShiftAllowed}`;
        this.rowShift = this.maxRowShiftAllowed;
      }

      if (isOutOfRange) {
        this.$swal({
          title: this.$t("Input value out of range"),
          text: `${message} [${distanceUnit}]`,
          icon: "warning",
          confirmButtonText: "OK",
        });
      }
    },
    GridFormToLineFormTransform() {
      let total_reciprocator_array = [];
      let reciprocator_to_reciprocator_distance_array = [];
      let reciprocator_columns_distances_array = [];
      let reciprocator_columns_vertical_offsets_array = [];

      let grid_coordinates = this.createCirclesCoordinates();
      let x_dictionary = {};
      for (let i = 0; i < grid_coordinates.length; i++) {
        let x = grid_coordinates[i].x;
        let y = grid_coordinates[i].y;
        if (x_dictionary[x] === undefined) {
          x_dictionary[x] = [];
        }
        x_dictionary[x].push(y);
      }

      let x_keys = Object.keys(x_dictionary);
      let x_keys_sorted = x_keys.sort(function (a, b) {
        return a - b;
      });

      if (this.grid_form.number_of_rows == 1 && this.grid_form.column_shift == 0) {
        total_reciprocator_array.push(this.grid_form.number_of_columns);
        reciprocator_to_reciprocator_distance_array.push(this.grid_form.column_distance / 100);
        reciprocator_columns_distances_array.push(0);
        reciprocator_columns_vertical_offsets_array.push(0);
      } else if (this.grid_form.number_of_columns == 1 && this.grid_form.row_shift == 0) {
        total_reciprocator_array.push(this.grid_form.number_of_rows);
        reciprocator_to_reciprocator_distance_array.push(this.grid_form.row_distance / 100);
        reciprocator_columns_distances_array.push(0);
        reciprocator_columns_vertical_offsets_array.push(0);
      } else {
        for (let i = 0; i < x_keys_sorted.length; i++) {
          let length_y = x_dictionary[x_keys_sorted[i]].length;
          total_reciprocator_array.push(length_y);
          let y_sorted_array = x_dictionary[x_keys_sorted[i]].sort(function (a, b) {
            return a - b;
          });
          if (y_sorted_array.length > 1) {
            let reciprocator_to_reciprocator_distance = Math.abs(y_sorted_array[1] - y_sorted_array[0]) / 100;
            reciprocator_to_reciprocator_distance_array.push(reciprocator_to_reciprocator_distance);
          } else {
            let reciprocator_to_reciprocator_distance = 0;
            reciprocator_to_reciprocator_distance_array.push(reciprocator_to_reciprocator_distance);
          }
          if (i > 0) {
            let reciprocator_columns_distance = Math.abs(x_keys_sorted[i] - x_keys_sorted[i - 1]) / 100;
            reciprocator_columns_distances_array.push(reciprocator_columns_distance);
            let previous_y_sorted_array = x_dictionary[x_keys_sorted[i - 1]].sort(function (a, b) {
              return a - b;
            });
            let column_offset = (previous_y_sorted_array.at(-1) - y_sorted_array.at(-1)) / 100;
            reciprocator_columns_vertical_offsets_array.push(column_offset);
          } else {
            reciprocator_columns_distances_array.push(0);
            reciprocator_columns_vertical_offsets_array.push(0);
          }
        }
      }

      return {
        total_reciprocator_array,
        reciprocator_to_reciprocator_distance_array,
        reciprocator_columns_distances_array,
        reciprocator_columns_vertical_offsets_array,
      };
    },
    UpdateAdvancedLineForm() {
      this.line_form.pistols_layout = this.gunLayout;
      if (this.isFixedLayout) {
        if (this.gunLayout == "v") {
          this.line_form.pistols_layout = "f";
        } else if (this.gunLayout == "h") {
          this.line_form.pistols_layout = "fixedmixed";
        }
      }
      let {
        total_reciprocator_array,
        reciprocator_to_reciprocator_distance_array,
        reciprocator_columns_distances_array,
        reciprocator_columns_vertical_offsets_array,
      } = this.GridFormToLineFormTransform();
      if (
        this.line_form.pistols_layout == "v" ||
        this.line_form.pistols_layout == "h" ||
        this.line_form.pistols_layout == "f"
      ) {
        this.total_pistols_vertical_or_horizontal = total_reciprocator_array[0];
        this.pistolToPistolDistanceColumn1 = reciprocator_to_reciprocator_distance_array[0] * 100;
        this.line_form.pistol_to_pistol_distance = [reciprocator_to_reciprocator_distance_array[0] * 100];
        this.pistol_column_distance_1 = reciprocator_columns_distances_array[0] * 100;
        this.pistol_column_vertical_offset_1 = reciprocator_columns_vertical_offsets_array[0] * 100;
      } else if (this.line_form.pistols_layout == "m" || this.line_form.pistols_layout == "fixedmixed") {
        this.num_pistol_columns_mixed_layout = total_reciprocator_array.length;
        this.total_pistols_column_1 = total_reciprocator_array[0];
        this.total_pistols_column_2 = total_reciprocator_array[1];
        this.total_pistols_column_3 = total_reciprocator_array[2];
        this.total_pistols_column_4 = total_reciprocator_array[3];
        this.total_pistols_column_5 = total_reciprocator_array[4];
        this.total_pistols_column_6 = total_reciprocator_array[5];
        this.total_pistols_column_7 = total_reciprocator_array[6];
        this.total_pistols_column_8 = total_reciprocator_array[7];
        this.total_pistols_column_9 = total_reciprocator_array[8];
        this.total_pistols_column_10 = total_reciprocator_array[9];
        this.pistol_to_pistol_distance_column_1 = reciprocator_to_reciprocator_distance_array[0] * 100;
        this.pistol_to_pistol_distance_column_2 = reciprocator_to_reciprocator_distance_array[1] * 100;
        this.pistol_to_pistol_distance_column_3 = reciprocator_to_reciprocator_distance_array[2] * 100;
        this.pistol_to_pistol_distance_column_4 = reciprocator_to_reciprocator_distance_array[3] * 100;
        this.pistol_to_pistol_distance_column_5 = reciprocator_to_reciprocator_distance_array[4] * 100;
        this.pistol_to_pistol_distance_column_6 = reciprocator_to_reciprocator_distance_array[5] * 100;
        this.pistol_to_pistol_distance_column_7 = reciprocator_to_reciprocator_distance_array[6] * 100;
        this.pistol_to_pistol_distance_column_8 = reciprocator_to_reciprocator_distance_array[7] * 100;
        this.pistol_to_pistol_distance_column_9 = reciprocator_to_reciprocator_distance_array[8] * 100;
        this.pistol_to_pistol_distance_column_10 = reciprocator_to_reciprocator_distance_array[9] * 100;
        this.pistol_column_distance_1 = reciprocator_columns_distances_array[0] * 100;
        this.pistol_column_distance_2 = reciprocator_columns_distances_array[1] * 100;
        this.pistol_column_distance_3 = reciprocator_columns_distances_array[2] * 100;
        this.pistol_column_distance_4 = reciprocator_columns_distances_array[3] * 100;
        this.pistol_column_distance_5 = reciprocator_columns_distances_array[4] * 100;
        this.pistol_column_distance_6 = reciprocator_columns_distances_array[5] * 100;
        this.pistol_column_distance_7 = reciprocator_columns_distances_array[6] * 100;
        this.pistol_column_distance_8 = reciprocator_columns_distances_array[7] * 100;
        this.pistol_column_distance_9 = reciprocator_columns_distances_array[8] * 100;
        this.pistol_column_distance_10 = reciprocator_columns_distances_array[9] * 100;
        this.pistol_column_vertical_offset_1 = reciprocator_columns_vertical_offsets_array[0] * 100;
        this.pistol_column_vertical_offset_2 = reciprocator_columns_vertical_offsets_array[1] * 100;
        this.pistol_column_vertical_offset_3 = reciprocator_columns_vertical_offsets_array[2] * 100;
        this.pistol_column_vertical_offset_4 = reciprocator_columns_vertical_offsets_array[3] * 100;
        this.pistol_column_vertical_offset_5 = reciprocator_columns_vertical_offsets_array[4] * 100;
        this.pistol_column_vertical_offset_6 = reciprocator_columns_vertical_offsets_array[5] * 100;
        this.pistol_column_vertical_offset_7 = reciprocator_columns_vertical_offsets_array[6] * 100;
        this.pistol_column_vertical_offset_8 = reciprocator_columns_vertical_offsets_array[7] * 100;
        this.pistol_column_vertical_offset_9 = reciprocator_columns_vertical_offsets_array[8] * 100;
        this.pistol_column_vertical_offset_10 = reciprocator_columns_vertical_offsets_array[9] * 100;
      }
    },
    UpdateStandardLineForm() {
      if (this.line_form.pistols_layout == "v" || this.line_form.pistols_layout == "f") {
        this.grid_form.number_of_rows = this.total_pistols_vertical_or_horizontal;
        this.grid_form.row_distance = this.pistol_to_pistol_distance_column_1;
        this.grid_form.row_shift = 0;
        this.grid_form.number_of_columns = 1;
        this.grid_form.column_distance = 0;
        this.grid_form.column_shift = 0;
      } else if (this.line_form.pistols_layout == "h") {
        this.grid_form.number_of_columns = this.total_pistols_vertical_or_horizontal;
        this.grid_form.column_distance = this.pistol_to_pistol_distance_column_1;
        this.grid_form.column_shift = 0;
        this.grid_form.number_of_rows = 1;
        this.grid_form.row_distance = 0;
        this.grid_form.row_shift = 0;
      } else if (this.line_form.pistols_layout == "m" || this.line_form.pistols_layout == "fixedmixed") {
        this.grid_form.number_of_columns = this.num_pistol_columns_mixed_layout;
        this.grid_form.column_distance = this.pistol_column_distance_2;
        this.grid_form.column_shift = 0;
        this.grid_form.number_of_rows = this.total_pistols_column_1;
        this.grid_form.row_distance = this.pistol_to_pistol_distance_column_1;
        this.grid_form.row_shift = 0;
      }
    },
    UpdateLineForm() {
      let total_pistols_array = null;
      let pistol_to_pistol_distance_array = null;
      let pistol_columns_distances_array = null;
      let pistol_columns_vertical_offsets_array = null;

      if (this.isVerticalOrHorizontalLayout || this.isStaticVerticalLayout) {
        total_pistols_array = [this.total_pistols_vertical_or_horizontal].filter(function (element) {
          return element == 0 || element != null;
        });
        pistol_to_pistol_distance_array = [this.pistol_to_pistol_distance_column_1 / 100];
      } else if (this.isMixedLayout || this.isStaticMixedLayout) {
        total_pistols_array = [
          this.total_pistols_column_1,
          this.total_pistols_column_2,
          this.total_pistols_column_3,
          this.total_pistols_column_4,
          this.total_pistols_column_5,
          this.total_pistols_column_6,
          this.total_pistols_column_7,
          this.total_pistols_column_8,
          this.total_pistols_column_9,
          this.total_pistols_column_10,
        ]
          .filter(function (element) {
            return element == 0 || element != null;
          })
          .slice(0, this.num_pistol_columns_mixed_layout);

        pistol_to_pistol_distance_array = [
          this.pistol_to_pistol_distance_column_1,
          this.pistol_to_pistol_distance_column_2,
          this.pistol_to_pistol_distance_column_3,
          this.pistol_to_pistol_distance_column_4,
          this.pistol_to_pistol_distance_column_5,
          this.pistol_to_pistol_distance_column_6,
          this.pistol_to_pistol_distance_column_7,
          this.pistol_to_pistol_distance_column_8,
          this.pistol_to_pistol_distance_column_9,
          this.pistol_to_pistol_distance_column_10,
        ]
          .filter((element, index) => {
            return element !== null || (element === 0 && this.total_pistols_array[index] === 1);
          })
          .map(element => element / 100)
          .slice(0, this.num_pistol_columns_mixed_layout);

        pistol_columns_distances_array = [
          this.pistol_column_distance_1,
          this.pistol_column_distance_2,
          this.pistol_column_distance_3,
          this.pistol_column_distance_4,
          this.pistol_column_distance_5,
          this.pistol_column_distance_6,
          this.pistol_column_distance_7,
          this.pistol_column_distance_8,
          this.pistol_column_distance_9,
          this.pistol_column_distance_10,
        ]
          .filter(function (element) {
            return element == 0 || element != null;
          })
          .map(function (element) {
            return element / 100;
          })
          .slice(0, this.num_pistol_columns_mixed_layout);

        pistol_columns_vertical_offsets_array = [
          this.pistol_column_vertical_offset_1,
          this.pistol_column_vertical_offset_2,
          this.pistol_column_vertical_offset_3,
          this.pistol_column_vertical_offset_4,
          this.pistol_column_vertical_offset_5,
          this.pistol_column_vertical_offset_6,
          this.pistol_column_vertical_offset_7,
          this.pistol_column_vertical_offset_8,
          this.pistol_column_vertical_offset_9,
          this.pistol_column_vertical_offset_10,
        ]
          .filter(function (element) {
            return element == 0 || element != null;
          })
          .map(function (element) {
            return element / 100;
          })
          .slice(0, this.num_pistol_columns_mixed_layout);
      }

      this.line_form.total_pistols = total_pistols_array;
      this.line_form.pistol_columns_distances = pistol_columns_distances_array;
      this.line_form.pistol_to_pistol_distance = pistol_to_pistol_distance_array;
      this.line_form.pistol_columns_vertical_offsets = pistol_columns_vertical_offsets_array;

      return {
        total_pistols_array,
        pistol_to_pistol_distance_array,
        pistol_columns_distances_array,
        pistol_columns_vertical_offsets_array,
      };
    },
    GridFormProperlyFilled() {
      let missing_fields = [];
      let properly_filled = true;
      if (this.grid_form.number_of_columns === null || this.grid_form.number_of_columns === "") {
        this.number_of_columns_input_error = true;
        properly_filled = false;
        missing_fields.push(this.$t("Number of Columns"));
      }
      if (this.grid_form.number_of_rows === null || this.grid_form.number_of_rows === "") {
        this.number_of_rows_input_error = true;
        properly_filled = false;
        missing_fields.push(this.$t("Number of Rows"));
      }
      if (this.grid_form.column_distance === null || this.grid_form.column_distance === "") {
        this.column_distance_input_error = true;
        properly_filled = false;
        missing_fields.push(this.$t("Column Distance"));
      }
      if (this.grid_form.row_distance === null || this.grid_form.row_distance === "") {
        this.row_distance_input_error = true;
        properly_filled = false;
        missing_fields.push(this.$t("Row Distance"));
      }
      if (this.grid_form.column_shift === null || this.grid_form.column_shift === "") {
        this.column_shift_input_error = true;
        properly_filled = false;
        missing_fields.push(this.$t("Column Shift"));
      }
      if (this.grid_form.row_shift === null || this.grid_form.row_shift === "") {
        this.row_shift_input_error = true;
        properly_filled = false;
        missing_fields.push(this.$t("Row Shift"));
      }
      return [properly_filled, missing_fields];
    },
    ProperlyFilledForms() {
      let properly_filled = true;
      let missing_fields = [];
      let line_form_properly_filled = this.LineFormProperlyFilled();
      let guns_layout_properly_filled = [];

      if (this.AdvancedConfiguration) {
        guns_layout_properly_filled = this.AdvancedFormProperlyFilled();
      } else {
        guns_layout_properly_filled = this.GridFormProperlyFilled();
      }

      if (!line_form_properly_filled[0]) {
        properly_filled = false;
        missing_fields = missing_fields.concat(line_form_properly_filled[1]);
      }
      if (!guns_layout_properly_filled[0]) {
        properly_filled = false;
        missing_fields = missing_fields.concat(guns_layout_properly_filled[1]);
      }
      if (!properly_filled) {
        this.$swal({
          title: this.$t("Missing Required Fields"),
          text: this.$t("The following fields are required:") + " " + missing_fields.join(", "),
          icon: "warning",
          button: "OK",
        });
      }
      if (this.line_form.reciprocator_min_velocity >= this.line_form.reciprocator_max_velocity) {
        this.$swal({
          title: this.$t("Input value out of range"),
          text: this.$t("Reciprocator Min Velocity cannot be greater than or equal to the Reciprocator Max Velocity"),
          icon: "warning",
          confirmButtonText: "OK",
        });
        this.line_form.pistols_min_velocity = this.line.pistols_min_velocity;
        this.line_form.pistols_max_velocity = this.line.pistols_max_velocity;
        properly_filled = false;
      }

      return properly_filled;
    },
    async createUpdateLine() {
      if (!this.ProperlyFilledForms()) {
        return;
      }

      let total_pistols_array = [];
      let pistol_to_pistol_distance_array = [];
      let pistol_columns_distances_array = [];
      let pistol_columns_vertical_offsets_array = [];

      if (this.AdvancedConfiguration) {
        total_pistols_array = this.UpdateLineForm().total_pistols_array;
        pistol_to_pistol_distance_array = this.UpdateLineForm().pistol_to_pistol_distance_array;
        pistol_columns_distances_array = this.UpdateLineForm().pistol_columns_distances_array;
        pistol_columns_vertical_offsets_array = this.UpdateLineForm().pistol_columns_vertical_offsets_array;
      } else {
        this.line_form.pistols_layout = this.gunLayout;
        total_pistols_array = this.GridFormToLineFormTransform().total_reciprocator_array;
        pistol_to_pistol_distance_array =
          this.GridFormToLineFormTransform().reciprocator_to_reciprocator_distance_array;
        pistol_columns_distances_array = this.GridFormToLineFormTransform().reciprocator_columns_distances_array;
        pistol_columns_vertical_offsets_array =
          this.GridFormToLineFormTransform().reciprocator_columns_vertical_offsets_array;
      }

      if (this.isFixedLayout) {
        this.line_form.pistols_max_movement_range = 0;
        this.line_form.pistols_min_velocity = 0;
        this.line_form.pistols_max_velocity = 0;
      }

      let total_pistols_json = JSON.stringify(total_pistols_array);

      let pistol_columns_distances_json = null;
      let pistol_to_pistol_distance_json = null;
      let pistol_columns_vertical_offsets_json = null;

      if (pistol_columns_distances_array) {
        pistol_columns_distances_json = JSON.stringify(pistol_columns_distances_array);
      }

      if (pistol_to_pistol_distance_array != null && pistol_to_pistol_distance_array != [null]) {
        pistol_to_pistol_distance_json = JSON.stringify(pistol_to_pistol_distance_array);
      }

      if (pistol_columns_vertical_offsets_array) {
        pistol_columns_vertical_offsets_json = JSON.stringify(pistol_columns_vertical_offsets_array);
      }

      let body = {
        name: this.line_form.name,
        total_pistols: total_pistols_json,
        pistol_to_pistol_distance: pistol_to_pistol_distance_json,
        pistol_columns_distances: pistol_columns_distances_json,
        pistol_columns_vertical_offsets: pistol_columns_vertical_offsets_json,
        line_manufacturer: this.line_form.line_manufacturer,
        pump_type: this.line_form.pump_type,
        pistols_layout: this.line_form.pistols_layout,
        air_parameter_name: this.line_form.air_parameter_name,
        air_parameter_units: this.line_form.air_parameter_units,
        pistols_min_velocity: this.line_form.pistols_min_velocity,
        pistols_max_velocity: this.line_form.pistols_max_velocity,
        pistols_max_acceleration: this.line_form.pistols_max_acceleration,
        pistols_max_movement_range: this.line_form.pistols_max_movement_range / 100,
        powder_recycling_efficiency_percentage: this.line_form.recycling_system
          ? this.line_form.powder_recycling_efficiency_percentage
          : 0,
      };
      this.$store.state.isLoading = true;

      if (this.LineIsCreated) {
        await axios
          .put("/api/v1/fp/line/" + this.lineId + "/", body)
          .then(() => {
            this.$swal({
              title: this.$t("Line changes saved"),
              text: this.line_form.name + " " + this.$t("was saved successfully"),
              icon: "success",
              confirmButtonText: "OK",
            }).then(this.getData(this.lineId));
          })
          .catch(error => console.error(error));
      } else {
        await axios
          .post("/api/v1/fp/line/", body)
          .then(response => {
            this.$swal({
              title: this.$t("Line created"),
              text: this.line_form.name + " " + this.$t("was saved successfully"),
              icon: "success",
              confirmButtonText: "OK",
            }).then(() => {
              this.lineId = response.data.id;
              this.getData(this.lineId);
            });
          })
          .catch(error => console.error(error));
      }
    },
    async fillCanvas() {
      if (this.isFormFilled && !this.isFixedLayout) {
        var layout = await this.getLineImage();
        this.line_image = new ImageData(Uint8ClampedArray.from(layout.values()), this.canvas_width, this.canvas_height);

        this.linecanvas.putImageData(this.line_image, 0, 0);
        this.image_preview_not_computed = false;
      }
    },
    async getLineImage() {
      let {
        total_pistols_array,
        pistol_to_pistol_distance_array,
        pistol_columns_distances_array,
        pistol_columns_vertical_offsets_array,
      } = this.UpdateLineForm();

      var body = {
        pistols_layout: this.line_form.pistols_layout,
        total_pistols: total_pistols_array,
        pistol_to_pistol_distance: pistol_to_pistol_distance_array,
        pistols_columns_distance: pistol_columns_distances_array == null ? [0] : pistol_columns_distances_array,
        pistol_columns_offset:
          pistol_columns_vertical_offsets_array == null ? [0] : pistol_columns_vertical_offsets_array,
        canvas_width: this.canvas_width,
        canvas_height: this.canvas_height,
      };

      var response = await axios.post("/api/v1/fp/computelineimage/", body);
      return response.data["layout"];
    },
    createPowderModel() {
      let modalWindowOverlay = document.getElementById("modal-overlay");
      modalWindowOverlay.style.display = "flex";
    },

    dismissPopUp() {
      let modalWindowOverlay = document.getElementById("modal-overlay");
      modalWindowOverlay.style.display = "none";
    },
    getGridDimensions() {
      const gridElement = document.getElementById("grid");
      if (!gridElement) {
        return { width: 0, height: 0 };
      }
      const rect = gridElement.getBoundingClientRect();
      return { width: rect.width, height: rect.height };
    },
  },
};
</script>
<style lang="scss" scoped>
.container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.column-controls {
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
}

.main-area {
  display: flex;
}

.grid-container {
  border: 2px solid black;
  width: 100%;
  height: 100%;
  padding-top: 60%;
  overflow: auto;
  position: relative;
}

#grid {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.circle {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background-color: #333;
  position: absolute;
}

.row-controls {
  display: flex;
  flex-direction: column;
  margin-left: 20px;
}
.hidden-div {
  display: none;
}
</style>
<i18n>
  {
    "EN": {
      "Horizontal distance between two adjacent guns or stacks of guns.": "Horizontal distance between two adjacent guns or stacks of guns.",
      "Number of guns or vertical stacks of guns, next to each other.": "Number of guns or vertical stacks of guns, next to each other.",
      "Height offset between two adjacent guns or stacks of guns.": "Height offset between two adjacent guns or stacks of guns.",
      "Number of guns on top of each other.": "Number of guns on top of each other.",
      "Vertical distance between two guns on top of each other.": "Vertical distance between two guns on top of each other.",
      "Horizontal distance offset between even and odd rows.": "Horizontal distance offset between even and odd rows.",
      "Minimum gun velocity with activated reciprocator": "Minimum gun velocity with activated reciprocator",
      "Maximum gun velocity of the reciprocator": "Maximum gun velocity of the reciprocator",
      "Maximum gun acceleration of the reciprocator": "Maximum gun acceleration of the reciprocator",
      "The minimum is": "The minimum is",
      "and the maximum is": "and the maximum is",
      "Static Calibration": "Static Calibration",
      "Gun Max Velocity must be greater than Gun Min Velocity by at least": "Gun Max Velocity must be greater than Gun Min Velocity by at least",
      "Gun column height offsets": "Gun column height offsets",
      "Nozzle rotation angle": "Nozzle rotation angle",
      "Total Guns per column": "Total Guns per column",
      "Fixed Mixed (non moving with multiple columns of guns)": "Fixed Mixed (non moving with multiple columns of guns)",
      "Line changes saved": "Line changes saved",
      "Line created": "Line created",
      "was saved successfully": "was saved successfully",
      "The following fields are required:": "The following fields are required:",
      "Air Parameter Name": "Air Parameter Name",
      "Air Parameter Units": "Air Parameter Units",
      "Number of Gun Columns": "Number of Gun Columns",
      "Total Guns Column 1": "Total Guns Column 1",
      "Total Guns Column 2": "Total Guns Column 2",
      "Total Guns Column 3": "Total Guns Column 3",
      "Total Guns Column 4": "Total Guns Column 4",
      "Total Guns Column 5": "Total Guns Column 5",
      "Gun to Gun Distance Column 1": "Gun to Gun Distance Column 1",
      "Gun to Gun Distance Column 2": "Gun to Gun Distance Column 2",
      "Gun to Gun Distance Column 3": "Gun to Gun Distance Column 3",
      "Gun to Gun Distance Column 4": "Gun to Gun Distance Column 4",
      "Gun to Gun Distance Column 5": "Gun to Gun Distance Column 5",
      "Gun Column Distance 1": "Gun Column Distance 1",
      "Gun Column Distance 2": "Gun Column Distance 2",
      "Gun Column Distance 3": "Gun Column Distance 3",
      "Gun Column Distance 4": "Gun Column Distance 4",
      "Gun Column Distance 5": "Gun Column Distance 5",
      "Gun Column Vertical Offset 1": "Gun Column Vertical Offset 1",
      "Gun Column Vertical Offset 2": "Gun Column Vertical Offset 2",
      "Gun Column Vertical Offset 3": "Gun Column Vertical Offset 3",
      "Gun Column Vertical Offset 4": "Gun Column Vertical Offset 4",
      "Gun Column Vertical Offset 5": "Gun Column Vertical Offset 5",
      "Input value out of range": "Input value out of range",
      "Gun to gun distance cannot be 0": "Gun to gun distance cannot be 0",
      "Gun to Gun distance must be greater than": "Gun to Gun distance must be greater than",
      "Gun to Gun distance must be smaller than": "Gun to Gun distance must be smaller than",
      "Gun max movement range cannot be 0": "Gun max movement range cannot be 0",
      "Gun max movement range must be greater than": "Gun max movement range must be greater than",
      "Gun max movement range must be smaller than": "Gun max movement range must be smaller than",
      "Gun Min Velocity cannot be 0 for Horizontal Gun Layout.": "Gun Min Velocity cannot be 0 for Horizontal Gun Layout.",
      "Gun Min Velocity must be greater than": "Gun Min Velocity must be greater than",
      "Gun Max Velocity cannot be 0": "Gun Max Velocity cannot be 0",
      "Gun Min Velocity cannot be greater than or equal to the Gun Max Velocity": "Gun Min Velocity cannot be greater than or equal to the Gun Max Velocity",
      "Gun max acceleration": "Gun max acceleration",
      "Missing Required Fields": "Missing Required Fields",
      "Vertical": "Vertical",
      "Horizontal": "Horizontal",
      "Mixed (Vertical and Horizontal Hybrid)": "Mixed (Vertical and Horizontal Hybrid)",
      "Simple": "Simple",
      "Fixed": "Fixed",
      "Advanced": "Advanced",
      "Which type of Powder Model do you want to create?": "Which type of Powder Model do you want to create?",
      "Please select one": "Please select one",
      "Add new Line": "Add new Line",
      "Edit Line": "Edit Line",
      "Line Details": "Line Details",
      "Name": "Name",
      "Line Properties": "Line Properties",
      "Line Manufacturer": "Line Manufacturer",
      "Air Parameter Name e.g. [DosageAir]": "Air Parameter Name e.g. [DosageAir]",
      "Air Parameter Units e.g. [bar]": "Air Parameter Units e.g. [bar]",
      "Pump Type": "Pump Type",
      "Gun min velocity": "Gun min velocity",
      "Gun max velocity": "Gun max velocity",
      "Gun Layout": "Gun Layout",
      "Gun max movement range": "Gun max movement range",
      "Total Guns": "Total Guns",
      "Gun to gun distance": "Gun to gun distance",
      "Total number of vertical gun stacks or columns": "Total number of vertical gun stacks or columns",
      "columns of guns": "columns of guns",
      "1st guns column": "1st guns column",
      "Total number of guns of the 1st vertical stack": "Total number of guns of the 1st vertical stack",
      "Gun to gun distance of the 1st vertical stack": "Gun to gun distance of the 1st vertical stack",
      "2nd guns column": "2nd guns column",
      "Total number of guns of the 2nd vertical stack": "Total number of guns of the 2nd vertical stack",
      "Gun to gun distance of the 2nd vertical stack": "Gun to gun distance of the 2nd vertical stack",
      "Horizontal distance between 1st and 2nd vertical stacks": "Horizontal distance between 1st and 2nd vertical stacks",
      "2nd vertical guns stack height offset": "2nd vertical guns stack height offset",
      "3rd guns column": "3rd guns column",
      "Total number of guns of the 3rd vertical stack": "Total number of guns of the 3rd vertical stack",
      "Gun to gun distance of the 3rd vertical stack": "Gun to gun distance of the 3rd vertical stack",
      "Horizontal distance between 2nd and 3rd vertical stacks": "Horizontal distance between 2nd and 3rd vertical stacks",
      "3rd vertical guns stack height offset": "3rd vertical guns stack height offset",
      "4th guns column": "4th guns column",
      "Total number of guns of the 4th vertical stack": "Total number of guns of the 4th vertical stack",
      "Gun to gun distance of the 4th vertical stack": "Gun to gun distance of the 4th vertical stack",
      "Horizontal distance between 3rd and 4th vertical stacks": "Horizontal distance between 3rd and 4th vertical stacks",
      "4th vertical guns stack height offset": "4th vertical guns stack height offset",
      "5th guns column": "5th guns column",
      "Total number of guns of the 5th vertical stack": "Total number of guns of the 5th vertical stack",
      "Gun to gun distance of the 5th vertical stack": "Gun to gun distance of the 5th vertical stack",
      "Horizontal distance between the 4th and 5th stacks": "Horizontal distance between the 4th and 5th stacks",
      "5th vertical guns stack height offset": "5th vertical guns stack height offset",
      "Line Details are not editable. If you want to make changes to your line, contact us at": "Line Details are not editable. If you want to make changes to your line, contact us at",
      "Create Line": "Create Line",
      "Line Preview": "Line Preview",
      "New Powder Model": "New Powder Model",
      "Go Back Home": "Go Back Home",
      "Select Vertical if the guns are arranged in one on top of each other in a single column. Select Horizontal if they are arranged one next to each other in a single row. Select Mixed for more complex arrangements that combine multiple vertical stacks or columns.": "Select Vertical if the guns are arranged in one on top of each other in a single column. Select Horizontal if they are arranged one next to each other in a single row. Select Mixed for more complex arrangements that combine multiple vertical stacks or columns.",
      "The maximum movement range in the vertical axis that one gun can move, i.e. distance that one gun can move from its highest to its lowest position. By default is set to the gun to gun distance.": "The maximum movement range in the vertical axis that one gun can move, i.e. distance that one gun can move from its highest to its lowest position. By default is set to the gun to gun distance.",
      "A mixed hybrid line is composed of multiple vertical stacks or columns of guns. If your Line has only 1 vertical gun column, then select Vertical instead of Mixed on the Gun Layout option.": "A mixed hybrid line is composed of multiple vertical stacks or columns of guns. If your Line has only 1 vertical gun column, then select Vertical instead of Mixed on the Gun Layout option.",
      "Input the vertical distance between guns of the 1st column of guns, the column that is most to the left.": "Input the vertical distance between guns of the 1st column of guns, the column that is most to the left.",
      "Input the vertical distance between guns of the second column of guns, the second column from the left.": "Input the vertical distance between guns of the second column of guns, the second column from the left.",
      "Input the horizontal distance measured between the first column and the second column of guns.": "Input the horizontal distance measured between the first column and the second column of guns.",
      "Input the vertical guns stack height offset as the difference in respect to the previous one on the left. If the gun stacks are at equal height leave the default 0 offset. A positive value means the column is higher than the previous one, and a negative means the second stack is shorter than the first one.": "Input the vertical guns stack height offset as the difference in respect to the previous one on the left. If the gun stacks are at equal height leave the default 0 offset. A positive value means the column is higher than the previous one, and a negative means the second stack is shorter than the first one.",
      "Input the vertical distance between guns of the third column of guns, the third column from the left.": "Input the vertical distance between guns of the third column of guns, the third column from the left.",
      "Input the horizontal distance measured between the second column and the third column of guns": "Input the horizontal distance measured between the second column and the third column of guns",
      "Input the vertical distance between guns of the fourth column of guns, i.e the fourth column from the left.": "Input the vertical distance between guns of the fourth column of guns, i.e the fourth column from the left.",
      "Input the horizontal distance measured between the third column and the fourth column of guns": "Input the horizontal distance measured between the third column and the fourth column of guns",
      "Input the vertical distance between guns of the fifth column of guns, i.e the fifth column from the left": "Input the vertical distance between guns of the fifth column of guns, i.e the fifth column from the left",
      "Input the horizontal distance measured between the fourth column and the fifth column of guns": "Input the horizontal distance measured between the fourth column and the fifth column of guns",
      "Other":"Other",
      "Dense Phase":"Dense Phase",
      "Injector / Venturi":"Injector / Venturi",
      "Save Changes":"Save Changes",
      "No Changes to Save":"No Changes to Save",
      "Wagner":"Wagner",
      "MS-Carlisle":"MS-Carlisle",
      "GEMA":"GEMA",
      "Nordson":"Nordson",
      "Fixed": "Fixed",
      "Dynamic": "Dynamic",
      "Line Type": "Line Type",
      "Select": "Select",
      "Select moving or fixed line. A fixed line has a static reciprocator that does not move, hence guns have 0 movement range and 0 velocity.": "Select moving or fixed line. A fixed line has a static reciprocator that does not move, hence guns have 0 movement range and 0 velocity.",
      "guns": "guns",
      "Do you have a recycling powder system?":"Do you have a recycling powder system?",
      "Yes":"Yes",
      "No":"No",
      "Recycling System Efficiency":"Recycling System Efficiency",
      "Number of Columns":"Number of Columns",
      "Column Distance":"Column Distance",
      "Column Shift": "Column Shift",
      "Number of Rows": "Number of Rows",
      "Row Distance": "Row Distance",
      "Row Shift": "Row Shift",
      "Advanced Gun Layout": "Advanced Gun Layout",
      "6th guns column":"6th guns column",
      "7th guns column":"7th guns column",
      "8th guns column":"8th guns column",
      "9th guns column":"9th guns column",
      "10th guns column":"10th guns column",
      "Total number of guns of the 6th vertical stack":"Total number of guns of the 6th vertical stack",
      "Total number of guns of the 7th vertical stack":"Total number of guns of the 7th vertical stack",
      "Total number of guns of the 8th vertical stack":"Total number of guns of the 8th vertical stack",
      "Total number of guns of the 9th vertical stack":"Total number of guns of the 9th vertical stack",
      "Total number of guns of the 10th vertical stack":"Total number of guns of the 10th vertical stack",
      "Gun to gun distance of the 6th vertical stack":"Gun to gun distance of the 6th vertical stack",
      "Gun to gun distance of the 7th vertical stack":"Gun to gun distance of the 7th vertical stack",
      "Gun to gun distance of the 8th vertical stack":"Gun to gun distance of the 8th vertical stack",
      "Gun to gun distance of the 9th vertical stack":"Gun to gun distance of the 9th vertical stack",
      "Gun to gun distance of the 10th vertical stack":"Gun to gun distance of the 10th vertical stack",
      "Input the vertical distance between guns of the sixth column of guns, i.e the sixth column from the left":"Input the vertical distance between guns of the sixth column of guns, i.e the sixth column from the left",
      "Input the vertical distance between guns of the seventh column of guns, i.e the seventh column from the left":"Input the vertical distance between guns of the seventh column of guns, i.e the seventh column from the left",
      "Input the vertical distance between guns of the eighth column of guns, i.e the eighth column from the left":"Input the vertical distance between guns of the eighth column of guns, i.e the eighth column from the left",
      "Input the vertical distance between guns of the ninth column of guns, i.e the ninth column from the left":"Input the vertical distance between guns of the ninth column of guns, i.e the ninth column from the left",
      "Input the vertical distance between guns of the tenth column of guns, i.e the tenth column from the left":"Input the vertical distance between guns of the tenth column of guns, i.e the tenth column from the left",
      "Horizontal distance between the 5th and 6th stacks":"Horizontal distance between the 5th and 6th stacks",
      "Horizontal distance between the 6th and 7th stacks":"Horizontal distance between the 6th and 7th stacks",
      "Horizontal distance between the 7th and 8th stacks":"Horizontal distance between the 7th and 8th stacks",
      "Horizontal distance between the 8th and 9th stacks":"Horizontal distance between the 8th and 9th stacks",
      "Horizontal distance between the 9th and 10th stacks":"Horizontal distance between the 9th and 10th stacks",
      "Input the horizontal distance measured between the fifth column and the sixth column of guns":"Input the horizontal distance measured between the fifth column and the sixth column of guns",
      "Input the horizontal distance measured between the sixth column and the seventh column of guns":"Input the horizontal distance measured between the sixth column and the seventh column of guns",
      "Input the horizontal distance measured between the seventh column and the eighth column of guns":"Input the horizontal distance measured between the seventh column and the eighth column of guns",
      "Input the horizontal distance measured between the eighth column and the ninth column of guns":"Input the horizontal distance measured between the eighth column and the ninth column of guns",
      "Input the horizontal distance measured between the ninth column and the tenth column of guns":"Input the horizontal distance measured between the ninth column and the tenth column of guns",
      "6th vertical guns stack height offset":"6th vertical guns stack height offset",
      "7th vertical guns stack height offset":"7th vertical guns stack height offset",
      "8th vertical guns stack height offset":"8th vertical guns stack height offset",
      "9th vertical guns stack height offset":"9th vertical guns stack height offset",
      "10th vertical guns stack height offset":"10th vertical guns stack height offset",
      "Switch to advanced Line configurator": "Switch to advanced Line configurator",
      "Switch to standard Line configurator": "Switch to standard Line configurator",
      "Horizontal layout is not allowed for Fixed Lines": "Horizontal layout is not allowed for Fixed Lines",
      "For Mixed Lines the number of gun columns must be smaller than":"For Mixed Lines the number of gun columns must be smaller than",
      "Attention!":"Attention!",
      "Changing to standard configuration might modify your line. Do you want to continue?":"Changing to standard configuration might modify your line. Do you want to continue?",
      "Cancel":"Cancel",
      "Total Guns Column 6": "Total Guns Column 6",
      "Total Guns Column 7": "Total Guns Column 7",
      "Total Guns Column 8": "Total Guns Column 8",
      "Total Guns Column 9": "Total Guns Column 9",
      "Total Guns Column 10": "Total Guns Column 10",
      "Gun to Gun Distance Column 6": "Gun to Gun Distance Column 6",
      "Gun to Gun Distance Column 7": "Gun to Gun Distance Column 7",
      "Gun to Gun Distance Column 8": "Gun to Gun Distance Column 8",
      "Gun to Gun Distance Column 9": "Gun to Gun Distance Column 9",
      "Gun to Gun Distance Column 10": "Gun to Gun Distance Column 10",
      "Gun Column Distance 6": "Gun Column Distance 6",
      "Gun Column Distance 7": "Gun Column Distance 7",
      "Gun Column Distance 8": "Gun Column Distance 8",
      "Gun Column Distance 9": "Gun Column Distance 9",
      "Gun Column Distance 10": "Gun Column Distance 10",
      "Gun Column Vertical Offset 6": "Gun Column Vertical Offset 6",
      "Gun Column Vertical Offset 7": "Gun Column Vertical Offset 7",
      "Gun Column Vertical Offset 8": "Gun Column Vertical Offset 8",
      "Gun Column Vertical Offset 9": "Gun Column Vertical Offset 9",
      "Gun Column Vertical Offset 10": "Gun Column Vertical Offset 10",
      "Number of columns cannot be greater than": "Number of columns cannot be greater than",
      "Number of rows cannot be greater than": "Number of rows cannot be greater than",
      "Row distance must be greater than": "Row distance must be greater than",
      "Row distance must be smaller than": "Row distance must be smaller than",
      "Column distance must be greater than": "Column distance must be greater than",
      "Column distance must be smaller than": "Column distance must be smaller than",
      "Column Shift must be greater than": "Column Shift must be greater than",
      "Column Shift must be smaller than": "Column Shift must be smaller than",
      "Row Shift must be greater than": "Row Shift must be greater than",
      "Row Shift must be smaller than": "Row Shift must be smaller than",
      "Reciprocator Min Velocity cannot be greater than or equal to the Reciprocator Max Velocity": "Reciprocator Min Velocity cannot be greater than or equal to the Reciprocator Max Velocity",
      "The number of guns must be smaller than": "The number of guns must be smaller than",
      "Lines with 1 gun per column are not allowed": "Lines with 1 gun per column are not allowed",
      "Advanced Gun Parameters": "Advanced Gun Parameters",
      "The gun's maximum acceleration value is outside the permissible range. Please enter a value within the allowed limits.":  "The gun's maximum acceleration value is outside the permissible range. Please enter a value within the allowed limits.",
      "New Optimization": "New Optimization",
    },
    "DE": {
      "Horizontal distance between two adjacent guns or stacks of guns.": "Horizontaler Abstand zwischen zwei benachbarten Pistolen oder Pistolenstapeln.",
      "Number of guns or vertical stacks of guns, next to each other.": "Anzahl der Pistolen oder vertikalen Pistolenstapel, die nebeneinander liegen.",
      "Height offset between two adjacent guns or stacks of guns.": "Höhenversatz zwischen zwei benachbarten Pistolen oder Pistolenstapeln.",
      "Number of guns on top of each other.": "Anzahl der Pistolen übereinander.",
      "Vertical distance between two guns on top of each other.": "Vertikaler Abstand zwischen zwei Pistolen übereinander.",
      "Horizontal distance offset between even and odd rows.": "Horizontaler Abstand zwischen geraden und ungeraden Reihen.",
      "Minimum gun velocity with activated reciprocator": "Mindestgeschwindigkeit des Geschützes mit aktiviertem Reziprokator",
      "Maximum gun velocity of the reciprocator": "Maximale Geschwindigkeit des Reziprokators",
      "Maximum gun acceleration of the reciprocator": "Maximale Beschleunigung des Reziprokators",
      "The minimum is": "Das Minimum ist",
      "and the maximum is": "und das Maximum ist",
      "Static Calibration": "Statische Kalibrierung",
      "Gun Max Velocity must be greater than Gun Min Velocity by at least": "Die maximale Geschwindigkeit des Geschützes muss mindestens so hoch sein wie die minimale Geschwindigkeit des Geschützes.",
      "Gun column height offsets": "Höhenversatz der Pistolensäule",
      "Nozzle rotation angle": "Drehwinkel der Düse",
      "Total Guns per column": "Gesamtpistolen pro Spalte",
      "Fixed Mixed (non moving with multiple columns of guns)": "Statisch Gemischt (nicht beweglich mit mehreren Pistolenkolonnen)",
      "Line changes saved": "Zeilenänderungen gespeichert",
      "Line created": "Zeile erstellt",
      "was saved successfully": "wurde erfolgreich gespeichert",
      "The following fields are required:": "Die folgenden Felder sind erforderlich:",
      "Air Parameter Name": "Name des Luftparameters",
      "Air Parameter Units": "Einheiten der Luftparameter",
      "Number of Gun Columns": "Anzahl der Pistolenspalten",
      "Total Guns Column 1": "Gesamtpistolen Spalte 1",
      "Total Guns Column 2": "Gesamtpistolen Spalte 2",
      "Total Guns Column 3": "Pistolen gesamt Spalte 3",
      "Total Guns Column 4": "Pistolen gesamt Spalte 4",
      "Total Guns Column 5": "Pistolen gesamt Spalte 5",
      "Gun to Gun Distance Column 1": "Abstand von Pistole zu Pistole Spalte 1",
      "Gun to Gun Distance Column 2": "Abstand von Pistole zu Pistole Spalte 2",
      "Gun to Gun Distance Column 3": "Abstand Pistole zu Pistole Spalte 3",
      "Gun to Gun Distance Column 4": "Abstand Pistole zu Pistole Spalte 4",
      "Gun to Gun Distance Column 5": "Abstand Pistole zu Pistole Spalte 5",
      "Gun Column Distance 1": "Pistolensäule Abstand 1",
      "Gun Column Distance 2": "Pistolensäule Abstand 2",
      "Gun Column Distance 3": "Pistolensäule Abstand 3",
      "Gun Column Distance 4": "Pistolensäule Abstand 4",
      "Gun Column Distance 5": "Pistolensäule Abstand 5",
      "Gun Column Vertical Offset 1": "Pistolensäule Vertikaler Versatz 1",
      "Gun Column Vertical Offset 2": "Pistolensäule Vertikaler Versatz 2",
      "Gun Column Vertical Offset 3": "Pistolensäule Vertikaler Versatz 3",
      "Gun Column Vertical Offset 4": "Pistolensäule Vertikaler Versatz 4",
      "Gun Column Vertical Offset 5": "Pistolensäule Vertikaler Versatz 5",
      "Input value out of range": "Eingabewert außerhalb des Bereichs",
      "Gun to gun distance cannot be 0": "Abstand von Pistole zu Pistole kann nicht 0 sein",
      "Gun to Gun distance must be greater than": "Abstand Pistole zu Pistole muss größer sein als",
      "Gun to Gun distance must be smaller than": "Abstand von Pistole zu Pistole muss kleiner sein als",
      "Gun max movement range cannot be 0": "Maximale Bewegungsreichweite der Pistole kann nicht 0 sein",
      "Gun max movement range must be greater than": "Maximaler Bewegungsbereich der Pistole muss größer sein als",
      "Gun max movement range must be smaller than": "Maximaler Bewegungsbereich der Pistole muss kleiner sein als",
      "Gun Min Velocity cannot be 0 for Horizontal Gun Layout.": "Pistolen-Min-Geschwindigkeit kann bei horizontalem Pistolen-Layout nicht 0 sein.",
      "Gun Min Velocity must be greater than": "Gun Min Velocity muss größer sein als",
      "Gun Max Velocity cannot be 0": "Pistole Max Velocity kann nicht größer als 0 sein",
      "Gun Min Velocity cannot be greater than or equal to the Gun Max Velocity": "Pistolen-Min-Geschwindigkeit kann nicht größer oder gleich der Pistolen-Max-Geschwindigkeit sein",
      "Gun max acceleration": "Pistolen-Max-Beschleunigung",
      "Missing Required Fields": "Fehlende erforderliche Felder",
      "Vertical": "Vertikal",
      "Horizontal": "Horizontal",
      "Mixed (Vertical and Horizontal Hybrid)": "Gemischt (Vertikal und Horizontal Hybrid)",
      "Simple": "Einfach",
      "Fixed": "Statisch",
      "Advanced": "Erweitert",
      "Which type of Powder Model do you want to create?": "Welche Art von Pulvermodell möchten Sie erstellen?",
      "Please select one": "Bitte wählen Sie eine",
      "Add new Line": "Neue Zeile hinzufügen",
      "Edit Line": "Zeile bearbeiten",
      "Line Details": "Anlagendetails",
      "Name": "Name",
      "Line Properties": "Anlageneigenschaften",
      "Line Manufacturer": "Anlagenhersteller",
      "Air Parameter Name e.g. [DosageAir]": "Luftparameter-Name z.B. [DosierLuft]",
      "Air Parameter Units e.g. [bar]": "Luftparameter Einheiten z.B. [bar]",
      "Pump Type": "Pumpentyp",
      "Gun min velocity": "Pistolen minimale Geschwindigkeit",
      "Gun max velocity": "Pistolen maximale Geschwindigkeit",
      "Gun Layout": "Pistolen Layout",
      "Gun max movement range": "Pistolen maximale Bewegungsreichweite",
      "Total Guns": "Pistolen gesamt",
      "Gun to gun distance": "Abstand von Pistole zu Pistole",
      "Total number of vertical gun stacks or columns": "Gesamtzahl der vertikalen Pistolenstapel oder -säulen",
      "columns of guns": "Pistolensäulen",
      "1st guns column": "1. Pistolenspalte",
      "Total number of guns of the 1st vertical stack": "Gesamtzahl der Pistolen des 1. vertikalen Stapels",
      "Gun to gun distance of the 1st vertical stack": "Abstand von Pistole zu Pistole des 1. vertikalen Stapels",
      "2nd guns column": "Spalte 2. Pistolen",
      "Total number of guns of the 2nd vertical stack": "Gesamtzahl der Pistolen des 2. vertikalen Stapels",
      "Gun to gun distance of the 2nd vertical stack": "Abstand von Pistole zu Pistole des 2. vertikalen Stapels",
      "Horizontal distance between 1st and 2nd vertical stacks": "Horizontaler Abstand zwischen dem 1. und 2. vertikalen Stapel",
      "2nd vertical guns stack height offset": "Höhenversatz des 2. vertikalen Pistolenstapels",
      "3rd guns column": "3. Pistolenspalte",
      "Total number of guns of the 3rd vertical stack": "Gesamtzahl der Pistolen des 3. vertikalen Stapels",
      "Gun to gun distance of the 3rd vertical stack": "Abstand von Pistole zu Pistole des 3. vertikalen Stapels",
      "Horizontal distance between 2nd and 3rd vertical stacks": "Horizontaler Abstand zwischen dem 2. und 3. vertikalen Stapel",
      "3rd vertical guns stack height offset": "Höhenversatz des 3. vertikalen Pistolenstapels",
      "4th guns column": "4. Pistolensäule",
      "Total number of guns of the 4th vertical stack": "Gesamtzahl der Pistolen des 4. vertikalen Stapels",
      "Gun to gun distance of the 4th vertical stack": "Abstand von Pistole zu Pistole des 4. vertikalen Stapels",
      "Horizontal distance between 3rd and 4th vertical stacks": "Horizontaler Abstand zwischen dem 3. und 4. vertikalen Stapel",
      "4th vertical guns stack height offset": "Höhenversatz des 4. vertikalen Pistolenstapels",
      "5th guns column": "5. Pistolensäule",
      "Total number of guns of the 5th vertical stack": "Gesamtzahl der Pistolen des 5. vertikalen Stapels",
      "Gun to gun distance of the 5th vertical stack": "Abstand von Pistole zu Pistole des 5. vertikalen Stapels",
      "Horizontal distance between the 4th and 5th stacks": "Horizontaler Abstand zwischen dem 4. und 5",
      "5th vertical guns stack height offset": "Höhenversatz des 5. vertikalen Pistolenstapels",
      "Line Details are not editable. If you want to make changes to your line, contact us at": "Liniendetails sind nicht editierbar. Wenn Sie Änderungen an Ihrer Linie vornehmen möchten, kontaktieren Sie uns unter",
      "Create Line": "Linie erstellen",
      "Line Preview": "Anlagenvorschau",
      "New Powder Model": "Neues Pulvermodell",
      "Go Back Home": "Zurück zur Startseite",
      "Select Vertical if the guns are arranged in one on top of each other in a single column. Select Horizontal if they are arranged one next to each other in a single row. Select Mixed for more complex arrangements that combine multiple vertical stacks or columns.": "Wählen Sie Vertikal, wenn die Pistolen in einer einzigen Spalte übereinander angeordnet sind. Wählen Sie Horizontal, wenn sie in einer Reihe nebeneinander angeordnet sind. Wählen Sie Gemischt für komplexere Anordnungen, die mehrere vertikale Stapel oder Spalten kombinieren.",
      "The maximum movement range in the vertical axis that one gun can move, i.e. distance that one gun can move from its highest to its lowest position. By default is set to the gun to gun distance.": "Der maximale Bewegungsbereich in der vertikalen Achse, den ein Geschütz bewegen kann, d. h. die Entfernung, die ein Geschütz von seiner höchsten bis zu seiner niedrigsten Position zurücklegen kann. Standardmäßig ist dieser Wert auf die Entfernung von Geschütz zu Geschütz eingestellt.",
      "A mixed hybrid line is composed of multiple vertical stacks or columns of guns. If your Line has only 1 vertical gun column, then select Vertical instead of Mixed on the Gun Layout option.": "Eine gemischte Hybridlinie besteht aus mehreren vertikalen Pistolenstapeln oder -säulen. Wenn Ihre Linie nur eine vertikale Pistolensäule hat, wählen Sie bei der Option Pistolenlayout die Option Vertikal anstelle von Gemischt.",
      "Input the vertical distance between guns of the 1st column of guns, the column that is most to the left.": "Geben Sie den vertikalen Abstand zwischen den Pistolen der ersten Pistolenspalte ein, also der Spalte, die am weitesten links liegt.",
      "Input the vertical distance between guns of the second column of guns, the second column from the left.": "Geben Sie den vertikalen Abstand zwischen den Pistolen der zweiten Pistolenspalte ein, der zweiten Spalte von links.",
      "Input the horizontal distance measured between the first column and the second column of guns.": "Geben Sie den horizontalen Abstand ein, der zwischen der ersten und der zweiten Pistolenspalte gemessen wird.",
      "Input the vertical guns stack height offset as the difference in respect to the previous one on the left. If the gun stacks are at equal height leave the default 0 offset. A positive value means the column is higher than the previous one, and a negative means the second stack is shorter than the first one.": "Geben Sie den vertikalen Höhenversatz des Pistolenstapels als Differenz zum vorherigen Stapel auf der linken Seite ein. Wenn die Pistolenstapel auf gleicher Höhe sind, lassen Sie den Standardwert 0 stehen. Ein positiver Wert bedeutet, dass die Spalte höher ist als die vorherige, ein negativer Wert bedeutet, dass der zweite Stapel kürzer ist als der erste.",
      "Input the vertical distance between guns of the third column of guns, the third column from the left.": "Geben Sie den vertikalen Abstand zwischen den Pistolen der dritten Pistolenspalte, der dritten Spalte von links, ein.",
      "Input the horizontal distance measured between the second column and the third column of guns": "Geben Sie den horizontalen Abstand ein, der zwischen der zweiten und der dritten Pistolenspalte gemessen wird.",
      "Input the vertical distance between guns of the fourth column of guns, i.e the fourth column from the left.": "Eingabe des vertikalen Abstands zwischen den Pistolen der vierten Pistolenspalte, d.h. der vierten Spalte von links.",
      "Input the horizontal distance measured between the third column and the fourth column of guns": "Eingabe des horizontalen Abstands, gemessen zwischen der dritten und der vierten Pistolenspalte",
      "Input the vertical distance between guns of the fifth column of guns, i.e the fifth column from the left": "Eingabe des vertikalen Abstands zwischen den Pistolen der fünften Pistolenspalte, d. h. der fünften Spalte von links",
      "Input the horizontal distance measured between the fourth column and the fifth column of guns": "Eingabe des horizontalen Abstands, gemessen zwischen der vierten und der fünften Pistolenspalte",
      "Other": "Andere",
      "Dense Phase":"Dense Phase",
      "Injector / Venturi":"Injector / Venturi",
      "Save Changes":"Änderungen speichern",
      "No Changes to Save":"Keine Änderungen zum Speichern",
      "Wagner":"Wagner",
      "MS-Carlisle":"MS-Carlisle",
      "GEMA":"GEMA",
      "Nordson":"Nordson",
      "Fixed": "Statisch",
      "Dynamic": "Dynamisch",
      "Line Type": "Anlagentyp",
      "Select": "Wählen",
      "Select moving or fixed line. A fixed line has a static reciprocator that does not move, hence guns have 0 movement range and 0 velocity.": "Wählen Sie bewegliche oder statische Linie. Statische Linie hat den Reziprokator fixiert, daher haben die Pistolen einen Bewegungsbereich von 0 und eine Geschwindigkeit von 0.",
      "guns": "Pistolen",
      "Do you have a recycling powder system?":"Haben Sie ein Pulver-Rückgewinnungssystem?",
      "Yes":"Ja",
      "No":"Nein",
      "Recycling System Efficiency":"Effizienz des Rückgewinnungssystems",
      "Number of Columns":"Anzahl der Spalten",
      "Column Distance":"Spaltenabstand",
      "Column Shift": "Spaltenverschiebung",
      "Number of Rows": "Anzahl der Reihen",
      "Row Distance": "Reihenabstand",
      "Row Shift": "Reihenverschiebung",
      "Advanced Gun Layout": "Erweitertes Pistolenlayout",
      "6th guns column":"6. Pistolensäule",
      "7th guns column":"7. Pistolensäule",
      "8th guns column":"8. Pistolensäule",
      "9th guns column":"9. Pistolensäule",
      "10th guns column":"10. Pistolensäule",
      "Total number of guns of the 6th vertical stack":"Gesamtzahl der Pistolen des 6. vertikalen Stapels",
      "Total number of guns of the 7th vertical stack":"Gesamtzahl der Pistolen des 7. vertikalen Stapels",
      "Total number of guns of the 8th vertical stack":"Gesamtzahl der Pistolen des 8. vertikalen Stapels",
      "Total number of guns of the 9th vertical stack":"Gesamtzahl der Pistolen des 9. vertikalen Stapels",
      "Total number of guns of the 10th vertical stack":"Gesamtzahl der Pistolen des 10. vertikalen Stapels",
      "Gun to gun distance of the 6th vertical stack":"Abstand von Pistole zu Pistole des 6. vertikalen Stapels",
      "Gun to gun distance of the 7th vertical stack":"Abstand von Pistole zu Pistole des 7. vertikalen Stapels",
      "Gun to gun distance of the 8th vertical stack":"Abstand von Pistole zu Pistole des 8. vertikalen Stapels",
      "Gun to gun distance of the 9th vertical stack":"Abstand von Pistole zu Pistole des 9. vertikalen Stapels",
      "Gun to gun distance of the 10th vertical stack":"Abstand von Pistole zu Pistole des 10. vertikalen Stapels",
      "Input the vertical distance between guns of the sixth column of guns, i.e the sixth column from the left":"Geben Sie den vertikalen Abstand zwischen den Pistolen der sechsten Pistolenspalte, d.h. der sechsten Spalte von links, ein",
      "Input the vertical distance between guns of the seventh column of guns, i.e the seventh column from the left":"Geben Sie den vertikalen Abstand zwischen den Pistolen der siebten Pistolenspalte, d.h. der siebten Spalte von links, ein",
      "Input the vertical distance between guns of the eighth column of guns, i.e the eighth column from the left":"Geben Sie den vertikalen Abstand zwischen den Pistolen der achten Pistolenspalte, d.h. der achten Spalte von links, ein",
      "Input the vertical distance between guns of the ninth column of guns, i.e the ninth column from the left":"Geben Sie den vertikalen Abstand zwischen den Pistolen der neunten Pistolenspalte, d.h. der neunten Spalte von links, ein",
      "Input the vertical distance between guns of the tenth column of guns, i.e the tenth column from the left":"Geben Sie den vertikalen Abstand zwischen den Pistolen der zehnten Pistolenspalte, d.h. der zehnten Spalte von links, ein",
      "Horizontal distance between the 5th and 6th stacks":"Horizontaler Abstand zwischen dem 5. und 6. Stapel",
      "Horizontal distance between the 6th and 7th stacks":"Horizontaler Abstand zwischen dem 6. und 7. Stapel",
      "Horizontal distance between the 7th and 8th stacks":"Horizontaler Abstand zwischen dem 7. und 8. Stapel",
      "Horizontal distance between the 8th and 9th stacks":"Horizontaler Abstand zwischen dem 8. und 9. Stapel",
      "Horizontal distance between the 9th and 10th stacks":"Horizontaler Abstand zwischen dem 9. und 10. Stapel",
      "Input the horizontal distance measured between the fifth column and the sixth column of guns":"Geben Sie den horizontalen Abstand ein, der zwischen der fünften und der sechsten Pistolenspalte gemessen wird",
      "Input the horizontal distance measured between the sixth column and the seventh column of guns":"Geben Sie den horizontalen Abstand ein, der zwischen der sechsten und der siebten Pistolenspalte gemessen wird",
      "Input the horizontal distance measured between the seventh column and the eighth column of guns":"Geben Sie den horizontalen Abstand ein, der zwischen der siebten und der achten Pistolenspalte gemessen wird",
      "Input the horizontal distance measured between the eighth column and the ninth column of guns":"Geben Sie den horizontalen Abstand ein, der zwischen der achten und der neunten Pistolenspalte gemessen wird",
      "Input the horizontal distance measured between the ninth column and the tenth column of guns":"Geben Sie den horizontalen Abstand ein, der zwischen der neunten und der zehnten Pistolenspalte gemessen wird",
      "6th vertical guns stack height offset":"Höhenversatz des 6. vertikalen Pistolenstapels",
      "7th vertical guns stack height offset":"Höhenversatz des 7. vertikalen Pistolenstapels",
      "8th vertical guns stack height offset":"Höhenversatz des 8. vertikalen Pistolenstapels",
      "9th vertical guns stack height offset":"Höhenversatz des 9. vertikalen Pistolenstapels",
      "10th vertical guns stack height offset":"Höhenversatz des 10. vertikalen Pistolenstapels",
      "Switch to advanced Line configurator": "Wechseln Sie zum erweiterten Linienkonfigurator",
      "Switch to standard Line configurator": "Wechseln Sie zum Standard-Linienkonfigurator",
      "Horizontal layout is not allowed for Fixed Lines": "Horizontales Layout ist für statische Linien nicht zulässig",
      "For Mixed Lines the number of gun columns must be smaller than":"Bei Mixed Lines muss die Anzahl der Pistolenspalten kleiner sein als",
      "Attention!":"Achtung!",
      "Changing to standard configuration might modify your line. Do you want to continue?":"Eine Änderung auf die Standardkonfiguration könnte Ihre Linie verändern. Möchten Sie fortfahren?",
      "Cancel":"Abbrechen",
      "Total Guns Column 6": "Gesamtpistolen Spalte 6",
      "Total Guns Column 7": "Gesamtpistolen Spalte 7",
      "Total Guns Column 8": "Gesamtpistolen Spalte 8",
      "Total Guns Column 9": "Gesamtpistolen Spalte 9",
      "Total Guns Column 10": "Gesamtpistolen Spalte 10",
      "Gun to Gun Distance Column 6": "Abstand von Pistole zu Pistole Spalte 6",
      "Gun to Gun Distance Column 7": "Abstand von Pistole zu Pistole Spalte 7",
      "Gun to Gun Distance Column 8": "Abstand von Pistole zu Pistole Spalte 8",
      "Gun to Gun Distance Column 9": "Abstand von Pistole zu Pistole Spalte 9",
      "Gun to Gun Distance Column 10": "Abstand von Pistole zu Pistole Spalte 10",
      "Gun Column Distance 6": "Pistolensäule Abstand 6",
      "Gun Column Distance 7": "Pistolensäule Abstand 7",
      "Gun Column Distance 8": "Pistolensäule Abstand 8",
      "Gun Column Distance 9": "Pistolensäule Abstand 9",
      "Gun Column Distance 10": "Pistolensäule Abstand 10",
      "Gun Column Vertical Offset 6": "Pistolensäule Vertikaler Versatz 6",
      "Gun Column Vertical Offset 7": "Pistolensäule Vertikaler Versatz 7",
      "Gun Column Vertical Offset 8": "Pistolensäule Vertikaler Versatz 8",
      "Gun Column Vertical Offset 9": "Pistolensäule Vertikaler Versatz 9",
      "Gun Column Vertical Offset 10": "Pistolensäule Vertikaler Versatz 10",
      "Number of columns cannot be greater than": "Anzahl der Spalten darf nicht größer sein als",
      "Number of rows cannot be greater than": "Anzahl der Reihen darf nicht größer sein als",
      "Row distance must be greater than": "Reihenabstand muss größer sein als",
      "Row distance must be smaller than": "Reihenabstand muss kleiner sein als",
      "Column distance must be greater than": "Spaltenabstand muss größer sein als",
      "Column distance must be smaller than": "Spaltenabstand muss kleiner sein als",
      "Column Shift must be greater than": "Spaltenverschiebung muss größer sein als",
      "Column Shift must be smaller than": "Spaltenverschiebung muss kleiner sein als",
      "Row Shift must be greater than": "Reihenverschiebung muss größer sein als",
      "Row Shift must be smaller than": "Reihenverschiebung muss kleiner sein als",
      "Reciprocator Min Velocity cannot be greater than or equal to the Reciprocator Max Velocity": "Reziprokator Min Velocity darf nicht größer oder gleich der Reziprokator Max Velocity sein",
      "The number of guns must be smaller than": "Die Anzahl der Pistolen muss kleiner sein als",
      "Lines with 1 gun per column are not allowed": "Linien mit 1 Pistole pro Spalte sind nicht zulässig",
      "Advanced Gun Parameters": "Erweiterte Pistolenparameter",  
      "The gun's maximum acceleration value is outside the permissible range. Please enter a value within the allowed limits.":  "Der maximale Beschleunigungswert der Pistole liegt außerhalb des zulässigen Bereichs. Bitte geben Sie einen Wert innerhalb der zulässigen Grenzen ein.",
      "New Optimization": "Neue Optimierung",
    },
    "ES": {
      "Horizontal distance between two adjacent guns or stacks of guns.": "Distancia horizontal entre dos pistolas adyacentes o pilas de pistolas.",
      "Number of guns or vertical stacks of guns, next to each other.": "Número de pistolas o pilas verticales de pistolas, una al lado de la otra.",
      "Height offset between two adjacent guns or stacks of guns.": "Cambio de altura entre dos pistolas adyacentes o pilas de pistolas de lado.",
      "Number of guns on top of each other.": "Número de pistolas una encima de la otra.",
      "Vertical distance between two guns on top of each other.": "Distancia vertical entre dos pistolas una encima de la otra.",
      "Horizontal distance offset between even and odd rows.": "Desplazamiento de la distancia horizontal entre filas pares e impares.",
      "Minimum gun velocity with activated reciprocator": "Velocidad mínima de la pistola con recíproco activado",
      "Maximum gun velocity of the reciprocator": "Velocidad máxima de la pistola del recíproco",
      "Maximum gun acceleration of the reciprocator": "Aceleración máxima de la pistola del recíproco",
      "The minimum is": "El mínimo es",
      "and the maximum is": "y el máximo es",
      "Static Calibration": "Calibración estática",
      "Gun Max Velocity must be greater than Gun Min Velocity by at least": "La velocidad máxima de la pistola debe ser mayor que la velocidad mínima por un margen de al menos",
      "Gun column height offsets": "Diferencia de altura entre columnas de pistolas",
      "Nozzle rotation angle": "Ángulo de la boquilla",
      "Total Guns per column": "Numero de pistolas por columna",
      "Fixed Mixed (non moving with multiple columns of guns)": "Estática Mixta (sin movimiento con varias columnas de pistolas)",
      "Line changes saved": "Línea actualizada",
      "Line created": "Línea creada",
      "was saved successfully": "se ha guardado correctamente",
      "The following fields are required:": "Los siguientes campos son obligatorios:",
      "Air Parameter Name": "Nombre del parámetro del aire",
      "Air Parameter Units": "Unidades del parámetro del aire",
      "Number of Gun Columns": "Número de columnas de pistolas",
      "Total Guns Column 1": "Total Pistolas Columna 1",
      "Total Guns Column 2": "Total Pistolas Columna 2",
      "Total Guns Column 3": "Total Pistolas Columna 3",
      "Total Guns Column 4": "Total Pistolas Columna 4",
      "Total Guns Column 5": "Total Pistolas Columna 5",
      "Gun to Gun Distance Column 1": "Distancia de pistola a pistola Columna 1",
      "Gun to Gun Distance Column 2": "Distancia de pistola a pistola Columna 2",
      "Gun to Gun Distance Column 3": "Distancia de pistola a pistola Columna 3",
      "Gun to Gun Distance Column 4": "Distancia de pistola a pistola Columna 4",
      "Gun to Gun Distance Column 5": "Pistola a Pistola Distancia Columna 5",
      "Gun Column Distance 1": "Pistola Columna Distancia 1",
      "Gun Column Distance 2": "Pistola Columna Distancia 2",
      "Gun Column Distance 3": "Columna Pistola Distancia 3",
      "Gun Column Distance 4": "Columna Pistola Distancia 4",
      "Gun Column Distance 5": "Columna Pistola Distancia 5",
      "Gun Column Vertical Offset 1": "Desplazamiento vertical de la columna de la pistola 1",
      "Gun Column Vertical Offset 2": "Desplazamiento vertical de la columna de la pistola 2",
      "Gun Column Vertical Offset 3": "Desplazamiento vertical de la columna de la pistola 3",
      "Gun Column Vertical Offset 4": "Desplazamiento vertical de la columna de la pistola 4",
      "Gun Column Vertical Offset 5": "Desplazamiento Vertical Columna Pistola 5",
      "Input value out of range": "Valor fuera de rango",
      "Gun to gun distance cannot be 0": "La distancia de pistola a pistola no puede ser 0",
      "Gun to Gun distance must be greater than": "La distancia entre pistola y pistola debe ser mayor que",
      "Gun to Gun distance must be smaller than": "La distancia entre pistola y pistola debe ser menor que",
      "Gun max movement range cannot be 0": "El rango de movimiento máximo de la pistola no puede ser 0",
      "Gun max movement range must be greater than": "El rango de movimiento máximo de la pistola debe ser mayor que",
      "Gun max movement range must be smaller than": "El rango de movimiento máximo de la pistola debe ser menor que",
      "Gun Min Velocity cannot be 0 for Horizontal Gun Layout.": "La velocidad mínima de la pistola no puede ser 0 para una linea horizontal.",
      "Gun Min Velocity must be greater than": "La velocidad mínima de las pistolas debe ser mayor que",
      "Gun Max Velocity cannot be 0": "La velocidad máxima de las pistolas no puede ser 0",
      "Gun Min Velocity cannot be greater than or equal to the Gun Max Velocity": "La velocidad mínima de la pistola no puede ser mayor ni igual que la velocidad máxima de la pistola.",
      "Gun max acceleration": "Aceleración máxima de la pistola",
      "Missing Required Fields": "Faltan campos obligatorios",
      "Vertical": "Vertical",
      "Horizontal": "Horizontal",
      "Mixed (Vertical and Horizontal Hybrid)": "Mixto (híbrido de vertical y horizontal)",
      "Simple": "Simple",
      "Fixed": "Estática",
      "Advanced": "Avanzado",
      "Which type of Powder Model do you want to create?": "¿Qué tipo de Modelo de Polvo desea crear?",
      "Please select one": "Seleccione una opción",
      "Add new Line": "Añadir nueva línea",
      "Edit Line": "Editar línea",
      "Line Details": "Detalles de línea",
      "Name": "Nombre",
      "Line Properties": "Propiedades de la línea",
      "Line Manufacturer": "Fabricante de la línea",
      "Air Parameter Name e.g. [DosageAir]": "Nombre del parámetro de aire p. ej. [DosageAir]",
      "Air Parameter Units e.g. [bar]": "Unidades del parámetro de aire, p. ej. [bar]",
      "Pump Type": "Tipo de bomba",
      "Gun min velocity": "Velocidad mínima de las pistolas",
      "Gun max velocity": "Velocidad máxima de las pistolas",
      "Gun Layout": "Disposición de las pistolas",
      "Gun max movement range": "Rango de movimiento máximo de las pistolas",
      "Total Guns": "Numero total de pistolas",
      "Gun to gun distance": "Distancia de pistola a pistola",
      "Total number of vertical gun stacks or columns": "Número total de columnas de pistolas",
      "columns of guns": "Columnas de pistolas",
      "1st guns column": "1ª columna de pistolas",
      "Total number of guns of the 1st vertical stack": "Número total de pistolas de la 1ª columna",
      "Gun to gun distance of the 1st vertical stack": "Distancia entre pistolas de la 1ª columna",
      "2nd guns column": "2ª columna de pistolas",
      "Total number of guns of the 2nd vertical stack": "Número total de pistolas de la 2ª columna",
      "Gun to gun distance of the 2nd vertical stack": "Distancia pistola a pistola de la 2ª columna",
      "Horizontal distance between 1st and 2nd vertical stacks": "Distancia horizontal entre la 1ª y la 2ª columna",
      "2nd vertical guns stack height offset": "Diferencia de altura de la 2ª columna de pistolas",
      "3rd guns column": "3ª columna de pistolas",
      "Total number of guns of the 3rd vertical stack": "Número total de pistolas en la 3ª columna",
      "Gun to gun distance of the 3rd vertical stack": "Distancia entre pistolas de la 3ª columna",
      "Horizontal distance between 2nd and 3rd vertical stacks": "Distancia horizontal entre la 2ª y 3ª columna",
      "3rd vertical guns stack height offset": "Diferencia de altura de la 3ª columna de pistolas",
      "4th guns column": "4ª columna de pistolas",
      "Total number of guns of the 4th vertical stack": "Número total de pistolas en la 4ª columna",
      "Gun to gun distance of the 4th vertical stack": "Distancia entre pistolas de la 4ª columna",
      "Horizontal distance between 3rd and 4th vertical stacks": "Distancia horizontal entre la 3ª y 4ª columna",
      "4th vertical guns stack height offset": "Diferencia de altura de la 4ª columna de pistolas",
      "5th guns column": "5ª columna de pistolas",
      "Total number of guns of the 5th vertical stack": "Número total de pistolas en la 5ª columna",
      "Gun to gun distance of the 5th vertical stack": "Distancia entre pistolas de la 5ª columna",
      "Horizontal distance between the 4th and 5th stacks": "Distancia horizontal entre la 4ª y 5ª columna",
      "5th vertical guns stack height offset": "Diferencia de la altura de la 5ª columna de pistolas",
      "Line Details are not editable. If you want to make changes to your line, contact us at": "Los detalles de la línea no son editables. Si desea realizar cambios en su línea, póngase en contacto con nosotros a",
      "Create Line": "Crear línea",
      "Line Preview": "Vista previa de la línea",
      "New Powder Model": "Nuevo modelo de polvo",
      "Go Back Home": "Volver a Inicio",
      "Select Vertical if the guns are arranged in one on top of each other in a single column. Select Horizontal if they are arranged one next to each other in a single row. Select Mixed for more complex arrangements that combine multiple vertical stacks or columns.": "Seleccione Vertical si las pistolas están dispuestas una encima de otra en una sola columna. Seleccione Horizontal si están dispuestas una al lado de la otra en una sola fila. Seleccione Mixto para disposiciones más complejas que combinen varias columnas verticales.",
      "The maximum movement range in the vertical axis that one gun can move, i.e. distance that one gun can move from its highest to its lowest position. By default is set to the gun to gun distance.": "El rango máximo de movimiento en el eje vertical que una pistola se puede mover, es decir, la distancia que una pistola recorre desde su posición más alta a su posición más baja. Por defecto se establece como la distancia vertical de pistola a pistola.",
      "A mixed hybrid line is composed of multiple vertical stacks or columns of guns. If your Line has only 1 vertical gun column, then select Vertical instead of Mixed on the Gun Layout option.": "Una línea mixta se compone de múltiples columnas de pistolas. Si su Línea tiene sólo 1 columna vertical de pistolas, entonces seleccione Vertical en lugar de Mixta en la opción Disposición de Pistolas.",
      "Input the vertical distance between guns of the 1st column of guns, the column that is most to the left.": "Introduzca la distancia vertical entre pistolas de la 1ª columna de pistolas, la columna que está más a la izquierda.",
      "Input the vertical distance between guns of the second column of guns, the second column from the left.": "Introduzca la distancia vertical entre las pistolas de la segunda columna de pistolas.",
      "Input the horizontal distance measured between the first column and the second column of guns.": "Introduzca la distancia horizontal medida entre la primera columna y la segunda columna de pistolas.",
      "Input the vertical guns stack height offset as the difference in respect to the previous one on the left. If the gun stacks are at equal height leave the default 0 offset. A positive value means the column is higher than the previous one, and a negative means the second stack is shorter than the first one.": "Introduzca la diferencia de altura con respecto a la anterior columna a la izquierda. Si las pilas de pistolas están a la misma altura, deje el valor 0 por defecto. Un valor positivo significa que la columna es más alta que la anterior.",
      "Input the vertical distance between guns of the third column of guns, the third column from the left.": "Introduzca la distancia entre pistolas de la tercera columna.",
      "Input the horizontal distance measured between the second column and the third column of guns": "Introduzca la distancia horizontal medida entre la segunda columna y la tercera columna de pistolas.",
      "Input the vertical distance between guns of the fourth column of guns, i.e the fourth column from the left.": "Introduzca la distancia vertical entre las pistolas de la cuarta columna.",
      "Input the horizontal distance measured between the third column and the fourth column of guns": "Introduzca la distancia horizontal medida entre la tercera columna y la cuarta columna.",
      "Input the vertical distance between guns of the fifth column of guns, i.e the fifth column from the left": "Introduzca la distancia vertical entre las pistolas de la quinta columna.",
      "Input the horizontal distance measured between the fourth column and the fifth column of guns": "Introduzca la distancia horizontal medida entre la cuarta columna y la quinta columna.",
      "Other": "Otro",
      "Dense Phase":"Dense Phase",
      "Injector / Venturi":"Injector / Venturi",
      "Save Changes":"Guardar cambios",
      "No Changes to Save":"No hay cambios que guardar",
      "Wagner":"Wagner",
      "MS-Carlisle":"MS-Carlisle",
      "GEMA":"GEMA",
      "Nordson":"Nordson",
      "Dynamic": "Dinámica",
      "Line Type": "Tipo de línea",
      "Select": "Seleccionar",
      "Select moving or fixed line. A fixed line has a static reciprocator that does not move, hence guns have 0 movement range and 0 velocity.": "Seleccione línea móvil o estática. La línea estática tiene el reciprocador fijo, por lo que las pistolas tienen un rango de movimiento de 0 y una velocidad de 0.",
      "guns": "pistolas",
      "Do you have a recycling powder system?":"¿Tiene un sistema de reciclaje de polvo?",
      "Yes":"Sí",
      "No":"No",
      "Recycling System Efficiency":"Eficiencia del sistema de reciclaje",
      "Number of Columns":"Número de columnas",
      "Column Distance":"Distancia entre columnas",
      "Column Shift": "Desplazamiento de columna",
      "Number of Rows": "Número de filas",
      "Row Distance": "Distancia entre filas",
      "Row Shift": "Desplazamiento de fila",
      "Advanced Gun Layout": "Diseño avanzado de pistolas",
      "6th guns column":"6ª columna de pistolas",
      "7th guns column":"7ª columna de pistolas",
      "8th guns column":"8ª columna de pistolas",
      "9th guns column":"9ª columna de pistolas",
      "10th guns column":"10ª columna de pistolas",
      "Total number of guns of the 6th vertical stack":"Número total de pistolas de la 6ª columna",
      "Total number of guns of the 7th vertical stack":"Número total de pistolas de la 7ª columna",
      "Total number of guns of the 8th vertical stack":"Número total de pistolas de la 8ª columna",
      "Total number of guns of the 9th vertical stack":"Número total de pistolas de la 9ª columna",
      "Total number of guns of the 10th vertical stack":"Número total de pistolas de la 10ª columna",
      "Gun to gun distance of the 6th vertical stack":"Distancia de pistola a pistola de la 6ª columna",
      "Gun to gun distance of the 7th vertical stack":"Distancia de pistola a pistola de la 7ª columna",
      "Gun to gun distance of the 8th vertical stack":"Distancia de pistola a pistola de la 8ª columna",
      "Gun to gun distance of the 9th vertical stack":"Distancia de pistola a pistola de la 9ª columna",
      "Gun to gun distance of the 10th vertical stack":"Distancia de pistola a pistola de la 10ª columna",
      "Input the vertical distance between guns of the sixth column of guns, i.e the sixth column from the left":"Introduzca la distancia vertical entre las pistolas de la 6ª columna.",
      "Input the vertical distance between guns of the seventh column of guns, i.e the seventh column from the left":"Introduzca la distancia vertical entre las pistolas de la 7ª columna.",
      "Input the vertical distance between guns of the eighth column of guns, i.e the eighth column from the left":"Introduzca la distancia vertical entre las pistolas de la 8ª columna.",
      "Input the vertical distance between guns of the ninth column of guns, i.e the ninth column from the left":"Introduzca la distancia vertical entre las pistolas de la 9ª columna.",
      "Input the vertical distance between guns of the tenth column of guns, i.e the tenth column from the left":"Introduzca la distancia vertical entre las pistolas de la 10ª columna.",
      "Horizontal distance between the 5th and 6th stacks":"Distancia horizontal entre la 5ª y 6ª columna",
      "Horizontal distance between the 6th and 7th stacks":"Distancia horizontal entre la 6ª y 7ª columna",
      "Horizontal distance between the 7th and 8th stacks":"Distancia horizontal entre la 7ª y 8ª columna",
      "Horizontal distance between the 8th and 9th stacks":"Distancia horizontal entre la 8ª y 9ª columna",
      "Horizontal distance between the 9th and 10th stacks":"Distancia horizontal entre la 9ª y 10ª columna",
      "Input the horizontal distance measured between the fifth column and the sixth column of guns":"Introduzca la distancia horizontal medida entre la quinta columna y la sexta columna de pistolas.",
      "Input the horizontal distance measured between the sixth column and the seventh column of guns":"Introduzca la distancia horizontal medida entre la sexta columna y la séptima columna de pistolas.",
      "Input the horizontal distance measured between the seventh column and the eighth column of guns":"Introduzca la distancia horizontal medida entre la séptima columna y la octava columna de pistolas.",
      "Input the horizontal distance measured between the eighth column and the ninth column of guns":"Introduzca la distancia horizontal medida entre la octava columna y la novena columna de pistolas.",
      "Input the horizontal distance measured between the ninth column and the tenth column of guns":"Introduzca la distancia horizontal medida entre la novena columna y la décima columna de pistolas.",
      "6th vertical guns stack height offset":"Diferencia de altura de la 6ª columna de pistolas",
      "7th vertical guns stack height offset":"Diferencia de altura de la 7ª columna de pistolas",
      "8th vertical guns stack height offset":"Diferencia de altura de la 8ª columna de pistolas",
      "9th vertical guns stack height offset":"Diferencia de altura de la 9ª columna de pistolas",
      "10th vertical guns stack height offset":"Diferencia de altura de la 10ª columna de pistolas",
      "Switch to advanced Line configurator": "Cambiar al configurador de línea avanzado",
      "Switch to standard Line configurator": "Cambiar al configurador de línea estándar",
      "Horizontal layout is not allowed for Fixed Lines": "El diseño horizontal no está permitido para líneas estáticas",
      "For Mixed Lines the number of gun columns must be smaller than":"Para líneas mixtas, el número de columnas de pistolas debe ser menor que",
      "Attention!":"¡Atención!",
      "Changing to standard configuration might modify your line. Do you want to continue?":"Cambiar a la configuración estándar podría modificar su línea. ¿Desea continuar?",
      "Cancel":"Cancelar",
      "Total Guns Column 6": "Total Pistolas Columna 6",
      "Total Guns Column 7": "Total Pistolas Columna 7",
      "Total Guns Column 8": "Total Pistolas Columna 8",
      "Total Guns Column 9": "Total Pistolas Columna 9",
      "Total Guns Column 10": "Total Pistolas Columna 10",
      "Gun to Gun Distance Column 6": "Distancia de pistola a pistola Columna 6",
      "Gun to Gun Distance Column 7": "Distancia de pistola a pistola Columna 7",
      "Gun to Gun Distance Column 8": "Distancia de pistola a pistola Columna 8",
      "Gun to Gun Distance Column 9": "Distancia de pistola a pistola Columna 9",
      "Gun to Gun Distance Column 10": "Distancia de pistola a pistola Columna 10",
      "Gun Column Distance 6": "Distancia de columna de pistola 6",
      "Gun Column Distance 7": "Distancia de columna de pistola 7",
      "Gun Column Distance 8": "Distancia de columna de pistola 8",
      "Gun Column Distance 9": "Distancia de columna de pistola 9",
      "Gun Column Distance 10": "Distancia de columna de pistola 10",
      "Gun Column Vertical Offset 6": "Desplazamiento vertical de la columna de pistola 6",
      "Gun Column Vertical Offset 7": "Desplazamiento vertical de la columna de pistola 7",
      "Gun Column Vertical Offset 8": "Desplazamiento vertical de la columna de pistola 8",
      "Gun Column Vertical Offset 9": "Desplazamiento vertical de la columna de pistola 9",
      "Gun Column Vertical Offset 10": "Desplazamiento vertical de la columna de pistola 10",
      "Number of columns cannot be greater than": "El número de columnas no puede ser mayor que",
      "Number of rows cannot be greater than": "El número de filas no puede ser mayor que",
      "Row distance must be greater than": "La distancia entre filas debe ser mayor que",
      "Row distance must be smaller than": "La distancia entre filas debe ser menor que",
      "Column distance must be greater than": "La distancia entre columnas debe ser mayor que",
      "Column distance must be smaller than": "La distancia entre columnas debe ser menor que",
      "Column Shift must be greater than": "El desplazamiento de columna debe ser mayor que",
      "Column Shift must be smaller than": "El desplazamiento de columna debe ser menor que",
      "Row Shift must be greater than": "El desplazamiento de fila debe ser mayor que",
      "Row Shift must be smaller than": "El desplazamiento de fila debe ser menor que",
      "Reciprocator Min Velocity cannot be greater than or equal to the Reciprocator Max Velocity": "La velocidad mínima del reciprocador no puede ser mayor o igual que la velocidad máxima del reciprocador",
      "The number of guns must be smaller than": "El número de pistolas debe ser menor que",
      "Lines with 1 gun per column are not allowed": "No se puedes crear líneas con 1 pistola por columna",
      "Advanced Gun Parameters": "Parámetros avanzados de pistolas",  
      "The gun's maximum acceleration value is outside the permissible range. Please enter a value within the allowed limits.":  "El valor de aceleración máxima de la pistola está fuera del rango permitido. Por favor, introduzca un valor dentro de los límites permitidos.",
      "New Optimization": "Nueva Optimización",
    },
    "FR": {
      "Horizontal distance between two adjacent guns or stacks of guns.": "Distance horizontale entre deux canons ou piles de canons adjacents.",
      "Number of guns or vertical stacks of guns, next to each other.": "Nombre de canons ou de piles verticales de canons les uns à côté des autres.",
      "Height offset between two adjacent guns or stacks of guns.": "Décalage en hauteur entre deux canons ou piles de canons adjacents.",
      "Number of guns on top of each other.": "Nombre de canons superposés.",
      "Vertical distance between two guns on top of each other.": "Distance verticale entre deux canons superposés.",
      "Horizontal distance offset between even and odd rows.": "Distance horizontale entre les rangées paires et impaires.",
      "Minimum gun velocity with activated reciprocator": "Vitesse minimale du canon avec le réciprocateur activé",
      "Maximum gun velocity of the reciprocator": "Vitesse maximale des canons avec le réciprocateur activé",
      "Maximum gun acceleration of the reciprocator": "Accélération maximale du pistolet avec le réciprocateur",
      "The minimum is": "Le minimum est de",
      "and the maximum is": "et le maximum est de",
      "Static Calibration": "Calibration statique",
      "Gun Max Velocity must be greater than Gun Min Velocity by at least": "La vitesse maximale du pistolet doit être supérieure à la vitesse minimale du pistolet d'au moins",
      "Gun column height offsets": "Décalage de la hauteur de la colonne du pistolet",
      "Nozzle rotation angle": "Angle de rotation de la buse",
      "Total Guns per column": "Total des pistolets par colonne",
      "Fixed Mixed (non moving with multiple columns of guns)": "Statique Mixte (sans mouvement avec plusieurs colonnes de pistolets)",
      "Line changes saved": "Modifications de la ligne sauvegardées",
      "Line created": "Ligne créée",
      "was saved successfully": "a été sauvegardée avec succès",
      "The following fields are required:": "Les champs suivants sont obligatoires :",
      "Air Parameter Name": "Nom du paramètre air",
      "Air Parameter Units": "Unités du paramètre air",
      "Number of Gun Columns": "Nombre de colonnes de pistolets",
      "Total Guns Column 1": "Total des pistolets Colonne 1",
      "Total Guns Column 2": "Total des pistolets Colonne 2",
      "Total Guns Column 3": "Nombre total de pistolets Colonne 3",
      "Total Guns Column 4": "Nombre total de pistolets Colonne 4",
      "Total Guns Column 5": "Nombre total de pistolets Colonne 5",
      "Gun to Gun Distance Column 1": "Distance entre les pistolets Colonne 1",
      "Gun to Gun Distance Column 2": "Distance entre les pistolets Colonne 2",
      "Gun to Gun Distance Column 3": "Distance entre les pistolets Colonne 3",
      "Gun to Gun Distance Column 4": "Distance pistolet-pistolet Colonne 4",
      "Gun to Gun Distance Column 5": "Distance pistolet-pistolet Colonne 5",
      "Gun Column Distance 1": "Pistolet Colonne Distance 1",
      "Gun Column Distance 2": "Distance entre le pistolet et la colonne 2",
      "Gun Column Distance 3": "Distance de la colonne de pistolet 3",
      "Gun Column Distance 4": "Colonne de pistolet Distance 4",
      "Gun Column Distance 5": "Distance de la colonne du pistolet 5",
      "Gun Column Vertical Offset 1": "Décalage vertical de la colonne du pistolet 1",
      "Gun Column Vertical Offset 2": "Décalage vertical de la colonne du pistolet 2",
      "Gun Column Vertical Offset 3": "Décalage vertical de la colonne du pistolet 3",
      "Gun Column Vertical Offset 4": "Décalage vertical de la colonne du pistolet 4",
      "Gun Column Vertical Offset 5": "Décalage vertical de la colonne du pistolet 5",
      "Input value out of range": "Valeur d'entrée hors plage",
      "Gun to gun distance cannot be 0": "La distance pistolet-pistolet ne peut pas être égale à 0",
      "Gun to Gun distance must be greater than": "La distance pistolet-pistolet doit être supérieure à",
      "Gun to Gun distance must be smaller than": "La distance pistolet-pistolet doit être inférieure à",
      "Gun max movement range cannot be 0": "La distance de mouvement maximale du pistolet ne peut pas être égale à 0",
      "Gun max movement range must be greater than": "La plage de mouvement maximale du pistolet doit être supérieure à",
      "Gun max movement range must be smaller than": "La plage de mouvement maximale du pistolet doit être inférieure à",
      "Gun Min Velocity cannot be 0 for Horizontal Gun Layout.": "La vitesse minimale du pistolet ne peut pas être égale à 0 pour la disposition horizontale des pistolets.",
      "Gun Min Velocity must be greater than": "La vitesse minimale du pistolet doit être supérieure à",
      "Gun Max Velocity cannot be 0": "La vitesse maximale du pistolet ne peut pas être égale à 0",
      "Gun Min Velocity cannot be greater than or equal to the Gun Max Velocity": "La vitesse minimale du pistolet ne peut pas être supérieure ou égale à la vitesse maximale du pistolet.",
      "Gun max acceleration": "Accélération maximale du pistolet",
      "Missing Required Fields": "Champs obligatoires manquants",
      "Vertical": "Vertical",
      "Horizontal": "Horizontal",
      "Mixed (Vertical and Horizontal Hybrid)": "Mixte (hybride vertical et horizontal)",
      "Simple": "Simple",
      "Fixed": "Statique",
      "Advanced": "Avancé",
      "Which type of Powder Model do you want to create?": "Quel type de modèle de poudre voulez-vous créer ?",
      "Please select one": "Sélectionner un",
      "Add new Line": "Ajouter une nouvelle ligne",
      "Edit Line": "Modifier la ligne",
      "Line Details": "Détails de la ligne",
      "Name": "Nom",
      "Line Properties": "Propriétés de la ligne",
      "Line Manufacturer": "Fabricant de la ligne",
      "Air Parameter Name e.g. [DosageAir]": "Nom du paramètre d'air, par exemple [DosageAir]",
      "Air Parameter Units e.g. [bar]": "Unité du paramètre air, par exemple [bar]",
      "Pump Type": "Type de pompe",
      "Gun min velocity": "Vitesse minimale des pistolets",
      "Gun max velocity": "Vitesse maximale des pistolets",
      "Gun Layout": "Gun Layout",
      "Gun max movement range": "Gun max movement range",
      "Total Guns": "Nombre total de pistolets",
      "Gun to gun distance": "Distance pistolet à pistolet",
      "Total number of vertical gun stacks or columns": "Nombre total de colonnes ou de piles verticales de pistolets",
      "columns of guns": "colonnes de pistolets",
      "1st guns column": "1ère colonne de pistolets",
      "Total number of guns of the 1st vertical stack": "Nombre total de pistolets de la première pile verticale",
      "Gun to gun distance of the 1st vertical stack": "Distance pistolet à pistolet de la première pile verticale",
      "2nd guns column": "2ème colonne de pistolets",
      "Total number of guns of the 2nd vertical stack": "Nombre total de pistolets de la 2ème pile verticale",
      "Gun to gun distance of the 2nd vertical stack": "Distance pistolet à pistolet de la 2ème pile verticale",
      "Horizontal distance between 1st and 2nd vertical stacks": "Distance horizontale entre les 1ère et 2ème piles verticales",
      "2nd vertical guns stack height offset": "décalage de la hauteur de la 2e pile verticale de pistolets",
      "3rd guns column": "3ème colonne de pistolets",
      "Total number of guns of the 3rd vertical stack": "Nombre total de pistolets de la 3ème pile verticale",
      "Gun to gun distance of the 3rd vertical stack": "Distance pistolet à pistolet de la 3ème pile verticale",
      "Horizontal distance between 2nd and 3rd vertical stacks": "Distance horizontale entre les 2e et 3e piles verticales",
      "3rd vertical guns stack height offset": "décalage de la hauteur de la 3e pile verticale de pistolets",
      "4th guns column": "4ème colonne de pistolets",
      "Total number of guns of the 4th vertical stack": "Nombre total de pistolets de la 4ème pile verticale",
      "Gun to gun distance of the 4th vertical stack": "Distance pistolet à pistolet de la 4ème pile verticale",
      "Horizontal distance between 3rd and 4th vertical stacks": "Distance horizontale entre les 3ème et 4ème piles verticales",
      "4th vertical guns stack height offset": "décalage de la hauteur de la 4e pile verticale de pistolets",
      "5th guns column": "5ème colonne de pistolets",
      "Total number of guns of the 5th vertical stack": "Nombre total de pistolets de la 5ème pile verticale",
      "Gun to gun distance of the 5th vertical stack": "Distance pistolet à pistolet de la 5e pile verticale",
      "Horizontal distance between the 4th and 5th stacks": "Distance horizontale entre les 4ème et 5ème piles",
      "5th vertical guns stack height offset": "Décalage de la hauteur de la pile du 5e pistolet vertical",
      "Line Details are not editable. If you want to make changes to your line, contact us at": "Les détails des lignes ne sont pas modifiables. Si vous souhaitez apporter des modifications à votre ligne, contactez-nous à",
      "Create Line": "Créer une ligne",
      "Line Preview": "Aperçu de la ligne",
      "New Powder Model": "Nouveau modèle de poudre",
      "Go Back Home": "Retour à la page d'accueil",
      "Select Vertical if the guns are arranged in one on top of each other in a single column. Select Horizontal if they are arranged one next to each other in a single row. Select Mixed for more complex arrangements that combine multiple vertical stacks or columns.": "Sélectionnez Vertical si les pistolets sont disposés les uns au-dessus des autres dans une seule colonne. Sélectionnez Horizontal si les pistolets sont disposés les uns à côté des autres sur une seule ligne. Sélectionnez Mixte pour des dispositions plus complexes combinant plusieurs piles ou colonnes verticales.",
      "The maximum movement range in the vertical axis that one gun can move, i.e. distance that one gun can move from its highest to its lowest position. By default is set to the gun to gun distance.": "L'amplitude maximale de mouvement dans l'axe vertical qu'un pistolet peut effectuer, c'est-à-dire la distance qu'un pistolet peut parcourir de sa position la plus haute à sa position la plus basse. La valeur par défaut est la distance de pistolet à pistolet.",
      "A mixed hybrid line is composed of multiple vertical stacks or columns of guns. If your Line has only 1 vertical gun column, then select Vertical instead of Mixed on the Gun Layout option.": "Une ligne hybride mixte est composée de plusieurs piles ou colonnes verticales de pistolets. Si votre ligne ne comporte qu'une seule colonne verticale de pistolets, sélectionnez Vertical au lieu de Mixte dans l'option Disposition des pistolets.",
      "Input the vertical distance between guns of the 1st column of guns, the column that is most to the left.": "Saisissez la distance verticale entre les pistolets de la première colonne de pistolets, la colonne la plus à gauche.",
      "Input the vertical distance between guns of the second column of guns, the second column from the left.": "Introduisez la distance verticale entre les pistolets de la deuxième colonne de pistolets, la deuxième colonne à partir de la gauche.",
      "Input the horizontal distance measured between the first column and the second column of guns.": "Introduire la distance horizontale mesurée entre la première colonne et la deuxième colonne de pistolets.",
      "Input the vertical guns stack height offset as the difference in respect to the previous one on the left. If the gun stacks are at equal height leave the default 0 offset. A positive value means the column is higher than the previous one, and a negative means the second stack is shorter than the first one.": "Introduire le décalage vertical de la hauteur de la pile de pistolets comme la différence par rapport à la pile précédente sur la gauche. Si les piles de pistolets sont de même hauteur, laissez le décalage par défaut de 0. Une valeur positive signifie que la colonne est plus haute que la précédente, et une valeur négative signifie que la deuxième pile est plus courte que la première.",
      "Input the vertical distance between guns of the third column of guns, the third column from the left.": "Introduire la distance verticale entre les pistolets de la troisième colonne de pistolets, la troisième colonne en partant de la gauche.",
      "Input the horizontal distance measured between the second column and the third column of guns": "Introduire la distance horizontale mesurée entre la deuxième colonne et la troisième colonne de pistolets.",
      "Input the vertical distance between guns of the fourth column of guns, i.e the fourth column from the left.": "Introduire la distance verticale entre les pistolets de la quatrième colonne de pistolets, c'est-à-dire la quatrième colonne à partir de la gauche.",
      "Input the horizontal distance measured between the third column and the fourth column of guns": "Introduire la distance horizontale mesurée entre la troisième colonne et la quatrième colonne de pistolets.",
      "Input the vertical distance between guns of the fifth column of guns, i.e the fifth column from the left": "Introduire la distance verticale entre les pistolets de la cinquième colonne de pistolets, c'est-à-dire la cinquième colonne à partir de la gauche.",
      "Input the horizontal distance measured between the fourth column and the fifth column of guns": "Introduire la distance horizontale mesurée entre la quatrième colonne et la cinquième colonne de pistolets.",
      "Other": "Autre",
      "Dense Phase":"Dense Phase",
      "Injector / Venturi":"Injector / Venturi",
      "Save Changes":"Enregistrer les modifications",
      "No Changes to Save":"Aucune modification à enregistrer",
      "Wagner":"Wagner",
      "MS-Carlisle":"MS-Carlisle",
      "GEMA":"GEMA",
      "Nordson":"Nordson",
      "Fixed": "Statique",
      "Dynamic": "Dynamique",
      "Line Type": "Type de ligne",
      "Select": "Sélectionner",
      "Select moving or fixed line. A fixed line has a static reciprocator that does not move, hence guns have 0 movement range and 0 velocity.": "Sélectionnez une ligne mobile ou statique. La ligne statique a le réciprocateur fixe, donc les pistolets ont une plage de mouvement de 0 et une vitesse de 0.",
      "guns": "pistolets",
      "Do you have a recycling powder system?":"Avez-vous un système de recyclage de poudre ?",
      "Yes":"Oui",
      "No":"Non",
      "Recycling System Efficiency":"Efficacité du système de recyclage",
      "Number of Columns":"Nombre de colonnes",
      "Column Distance":"Distance entre colonnes",
      "Column Shift": "Décalage de colonne",
      "Number of Rows": "Nombre de rangées",
      "Row Distance": "Distance entre rangées",
      "Row Shift": "Décalage de rangée",
      "Advanced Gun Layout": "Disposition avancée des pistolets",
      "6th guns column":"6ème colonne de pistolets",
      "7th guns column":"7ème colonne de pistolets",
      "8th guns column":"8ème colonne de pistolets",
      "9th guns column":"9ème colonne de pistolets",
      "10th guns column":"10ème colonne de pistolets",
      "Total number of guns of the 6th vertical stack":"Nombre total de pistolets de la 6ème pile verticale",
      "Total number of guns of the 7th vertical stack":"Nombre total de pistolets de la 7ème pile verticale",
      "Total number of guns of the 8th vertical stack":"Nombre total de pistolets de la 8ème pile verticale",
      "Total number of guns of the 9th vertical stack":"Nombre total de pistolets de la 9ème pile verticale",
      "Total number of guns of the 10th vertical stack":"Nombre total de pistolets de la 10ème pile verticale",
      "Gun to gun distance of the 6th vertical stack":"Distance pistolet à pistolet de la 6ème pile verticale",
      "Gun to gun distance of the 7th vertical stack":"Distance pistolet à pistolet de la 7ème pile verticale",
      "Gun to gun distance of the 8th vertical stack":"Distance pistolet à pistolet de la 8ème pile verticale",
      "Gun to gun distance of the 9th vertical stack":"Distance pistolet à pistolet de la 9ème pile verticale",
      "Gun to gun distance of the 10th vertical stack":"Distance pistolet à pistolet de la 10ème pile verticale",
      "Input the vertical distance between guns of the sixth column of guns, i.e the sixth column from the left":"Introduisez la distance verticale entre les pistolets de la sixième colonne de pistolets, c'est-à-dire la sixième colonne à partir de la gauche",
      "Input the vertical distance between guns of the seventh column of guns, i.e the seventh column from the left":"Introduisez la distance verticale entre les pistolets de la septième colonne de pistolets, c'est-à-dire la septième colonne à partir de la gauche",
      "Input the vertical distance between guns of the eighth column of guns, i.e the eighth column from the left":"Introduisez la distance verticale entre les pistolets de la huitième colonne de pistolets, c'est-à-dire la huitième colonne à partir de la gauche",
      "Input the vertical distance between guns of the ninth column of guns, i.e the ninth column from the left":"Introduisez la distance verticale entre les pistolets de la neuvième colonne de pistolets, c'est-à-dire la neuvième colonne à partir de la gauche",
      "Input the vertical distance between guns of the tenth column of guns, i.e the tenth column from the left":"Introduisez la distance verticale entre les pistolets de la dixième colonne de pistolets, c'est-à-dire la dixième colonne à partir de la gauche",
      "Horizontal distance between the 5th and 6th stacks":"Distance horizontale entre les 5ème et 6ème piles",
      "Horizontal distance between the 6th and 7th stacks":"Distance horizontale entre les 6ème et 7ème piles",
      "Horizontal distance between the 7th and 8th stacks":"Distance horizontale entre les 7ème et 8ème piles",
      "Horizontal distance between the 8th and 9th stacks":"Distance horizontale entre les 8ème et 9ème piles",
      "Horizontal distance between the 9th and 10th stacks":"Distance horizontale entre les 9ème et 10ème piles",
      "Input the horizontal distance measured between the fifth column and the sixth column of guns":"Introduisez la distance horizontale mesurée entre la cinquième colonne et la sixième colonne de pistolets",
      "Input the horizontal distance measured between the sixth column and the seventh column of guns":"Introduisez la distance horizontale mesurée entre la sixième colonne et la septième colonne de pistolets",
      "Input the horizontal distance measured between the seventh column and the eighth column of guns":"Introduisez la distance horizontale mesurée entre la septième colonne et la huitième colonne de pistolets",
      "Input the horizontal distance measured between the eighth column and the ninth column of guns":"Introduisez la distance horizontale mesurée entre la huitième colonne et la neuvième colonne de pistolets",
      "Input the horizontal distance measured between the ninth column and the tenth column of guns":"Introduisez la distance horizontale mesurée entre la neuvième colonne et la dixième colonne de pistolets",
      "6th vertical guns stack height offset":"Décalage de la hauteur de la 6ème pile verticale de pistolets",
      "7th vertical guns stack height offset":"Décalage de la hauteur de la 7ème pile verticale de pistolets",
      "8th vertical guns stack height offset":"Décalage de la hauteur de la 8ème pile verticale de pistolets",
      "9th vertical guns stack height offset":"Décalage de la hauteur de la 9ème pile verticale de pistolets",
      "10th vertical guns stack height offset":"Décalage de la hauteur de la 10ème pile verticale de pistolets",
      "Switch to advanced Line configurator": "Passer au configurateur de ligne avancé",
      "Switch to standard Line configurator": "Passer au configurateur de ligne standard",
      "Horizontal layout is not allowed for Fixed Lines": "La disposition horizontale n'est pas autorisée pour les lignes statiques",
      "For Mixed Lines the number of gun columns must be smaller than":"Pour les lignes mixtes, le nombre de colonnes de canon doit être inférieur à",
      "Attention!":"Attention!",
      "Changing to standard configuration might modify your line. Do you want to continue?":"Le passage à la configuration standard pourrait modifier votre ligne. Voulez-vous continuer ?",
      "Cancel":"Annuler",
      "Total Guns Column 6": "Nombre total de pistolets Colonne 6",
      "Total Guns Column 7": "Nombre total de pistolets Colonne 7",
      "Total Guns Column 8": "Nombre total de pistolets Colonne 8",
      "Total Guns Column 9": "Nombre total de pistolets Colonne 9",
      "Total Guns Column 10": "Nombre total de pistolets Colonne 10",
      "Gun to Gun Distance Column 6": "Distance pistolet à pistolet Colonne 6",
      "Gun to Gun Distance Column 7": "Distance pistolet à pistolet Colonne 7",
      "Gun to Gun Distance Column 8": "Distance pistolet à pistolet Colonne 8",
      "Gun to Gun Distance Column 9": "Distance pistolet à pistolet Colonne 9",
      "Gun to Gun Distance Column 10": "Distance pistolet à pistolet Colonne 10",
      "Gun Column Distance 6": "Distance de colonne de pistolet 6",
      "Gun Column Distance 7": "Distance de colonne de pistolet 7",
      "Gun Column Distance 8": "Distance de colonne de pistolet 8",
      "Gun Column Distance 9": "Distance de colonne de pistolet 9",
      "Gun Column Distance 10": "Distance de colonne de pistolet 10",
      "Gun Column Vertical Offset 6": "Décalage vertical de la colonne de pistolet 6",
      "Gun Column Vertical Offset 7": "Décalage vertical de la colonne de pistolet 7",
      "Gun Column Vertical Offset 8": "Décalage vertical de la colonne de pistolet 8",
      "Gun Column Vertical Offset 9": "Décalage vertical de la colonne de pistolet 9",
      "Gun Column Vertical Offset 10": "Décalage vertical de la colonne de pistolet 10",
      "Number of columns cannot be greater than": "Le nombre de colonnes ne peut pas être supérieur à",
      "Number of rows cannot be greater than": "Le nombre de rangées ne peut pas être supérieur à",
      "Row distance must be greater than": "La distance entre les rangées doit être supérieure à",
      "Row distance must be smaller than": "La distance entre les rangées doit être inférieure à",
      "Column distance must be greater than": "La distance entre les colonnes doit être supérieure à",
      "Column distance must be smaller than": "La distance entre les colonnes doit être inférieure à",
      "Column Shift must be greater than": "Le décalage de colonne doit être supérieur à",
      "Column Shift must be smaller than": "Le décalage de colonne doit être inférieur à",
      "Row Shift must be greater than": "Le décalage de rangée doit être supérieur à",
      "Row Shift must be smaller than": "Le décalage de rangée doit être inférieur à",
      "Reciprocator Min Velocity cannot be greater than or equal to the Reciprocator Max Velocity": "La vitesse minimale du réciprocateur ne peut pas être supérieure ou égale à la vitesse maximale du réciprocateur",
      "The number of guns must be smaller than": "Le nombre de pistolets doit être inférieur à",
      "Lines with 1 gun per column are not allowed": "Les lignes avec 1 pistolet par colonne ne sont pas autorisées",
      "Advanced Gun Parameters": "Paramètres avancés des pistolets",  
      "The gun's maximum acceleration value is outside the permissible range. Please enter a value within the allowed limits.":   "L'accélération maximale peut être comprise entre ",
      "New Optimization": "Nouvelle Optimisation",
    },
    "IT": {
      "Horizontal distance between two adjacent guns or stacks of guns.": "Distanza orizzontale tra due cannoni o pile di cannoni adiacenti.",
      "Number of guns or vertical stacks of guns, next to each other.": "Numero di cannoni o pile verticali di cannoni, uno accanto all'altro.",
      "Height offset between two adjacent guns or stacks of guns.": "Sfalsamento in altezza tra due cannoni o pile di cannoni adiacenti.",
      "Number of guns on top of each other.": "Numero di cannoni sovrapposti.",
      "Vertical distance between two guns on top of each other.": "Distanza verticale tra due cannoni sovrapposti.",
      "Horizontal distance offset between even and odd rows.": "Distanza orizzontale sfalsata tra file pari e dispari.",
      "Minimum gun velocity with activated reciprocator": "Velocità minima del cannone con reciprocatore attivato",
      "Maximum gun velocity of the reciprocator": "Velocità massima del cannone con reciprocatore attivato",
      "Maximum gun acceleration of the reciprocator": "Accelerazione massima del reciprocatore",
      "The minimum is": "Il minimo è",
      "and the maximum is": "e la massima è",
      "Static Calibration": "Calibrazione statica",
      "Gun Max Velocity must be greater than Gun Min Velocity by at least": "La velocità massima dell'arma deve essere maggiore della velocità minima dell'arma di almeno",
      "Gun column height offsets": "Disassamento dell'altezza della colonna della pistola",
      "Nozzle rotation angle": "Angolo di rotazione dell'ugello",
      "Total Guns per column": "Numero di pistole per colonna",
      "Fixed Mixed (non moving with multiple columns of guns)": "Mista statica (non in movimento con colonne multiple di pistole)",
      "Line changes saved": "Modifiche alla linea salvate",
      "Line created": "Linea creata",
      "was saved successfully": "è stata salvata con successo",
      "The following fields are required:": "I seguenti campi sono obbligatori:",
      "Air Parameter Name": "Nome del parametro aria",
      "Air Parameter Units": "Unità del parametro aria",
      "Number of Gun Columns": "Numero di colonne di pistole",
      "Total Guns Column 1": "Totale pistole colonna 1",
      "Total Guns Column 2": "Totale pistole Colonna 2",
      "Total Guns Column 3": "Totale pistole Colonna 3",
      "Total Guns Column 4": "Totale pistole Colonna 4",
      "Total Guns Column 5": "Totale pistole Colonna 5",
      "Gun to Gun Distance Column 1": "Distanza pistola-pistola Colonna 1",
      "Gun to Gun Distance Column 2": "Distanza da pistola a pistola Colonna 2",
      "Gun to Gun Distance Column 3": "Distanza da pistola a pistola Colonna 3",
      "Gun to Gun Distance Column 4": "Distanza da pistola a pistola Colonna 4",
      "Gun to Gun Distance Column 5": "Distanza da pistola a pistola Colonna 5",
      "Gun Column Distance 1": "Colonna Pistola Distanza 1",
      "Gun Column Distance 2": "Distanza colonna pistola 2",
      "Gun Column Distance 3": "Distanza colonna pistola 3",
      "Gun Column Distance 4": "Distanza colonna pistola 4",
      "Gun Column Distance 5": "Distanza colonna pistola 5",
      "Gun Column Vertical Offset 1": "Offset verticale della colonna della pistola 1",
      "Gun Column Vertical Offset 2": "Offset verticale della colonna della pistola 2",
      "Gun Column Vertical Offset 3": "Offset verticale della colonna della pistola 3",
      "Gun Column Vertical Offset 4": "Offset verticale della colonna della pistola 4",
      "Gun Column Vertical Offset 5": "Offset verticale della colonna della pistola 5",
      "Input value out of range": "Valore di ingresso fuori campo",
      "Gun to gun distance cannot be 0": "La distanza pistola-pistola non può essere 0",
      "Gun to Gun distance must be greater than": "La distanza pistola-pistola deve essere maggiore di",
      "Gun to Gun distance must be smaller than": "La distanza pistola-pistola deve essere inferiore a",
      "Gun max movement range cannot be 0": "La distanza massima di movimento della pistola non può essere 0",
      "Gun max movement range must be greater than": "L'intervallo di movimento massimo della pistola deve essere maggiore di",
      "Gun max movement range must be smaller than": "L'intervallo di movimento massimo della pistola deve essere inferiore a",
      "Gun Min Velocity cannot be 0 for Horizontal Gun Layout.": "La velocità minima della pistola non può essere 0 per il layout orizzontale delle pistole.",
      "Gun Min Velocity must be greater than": "La velocità minima della pistola deve essere maggiore di",
      "Gun Max Velocity cannot be 0": "La velocità massima della pistola non può essere 0",
      "Gun Min Velocity cannot be greater than or equal to the Gun Max Velocity": "La velocità minima della pistola non può essere maggiore o uguale alla velocità massima della pistola.",
      "Gun max acceleration": "Accelerazione massima della pistola",
      "Missing Required Fields": "Campi obbligatori mancanti",
      "Vertical": "Verticale",
      "Horizontal": "Orizzontale",
      "Mixed (Vertical and Horizontal Hybrid)": "Misto (ibrido verticale e orizzontale)",
      "Simple": "Semplice",
      "Fixed": "Statico",
      "Advanced": "Avanzato",
      "Which type of Powder Model do you want to create?": "Quale tipo di Modello di polvere si desidera creare?",
      "Please select one": "Selezionare uno",
      "Add new Line": "Aggiungi nuova riga",
      "Edit Line": "Modifica riga",
      "Line Details": "Dettagli linea",
      "Name": "Nome",
      "Line Properties": "Proprietà della linea",
      "Line Manufacturer":"Produttore della linea",
      "Air Parameter Name e.g. [DosageAir]": "Nome del parametro d'aria, ad esempio [DosageAir]",
      "Air Parameter Units e.g. [bar]": "Unità del parametro d'aria, ad esempio [bar]",
      "Pump Type": "Tipo di pompa",
      "Gun min velocity": "Velocità minima pistole",
      "Gun max velocity": "Velocità massima delle pistole",
      "Gun Layout": "Disposizione pistole",
      "Gun max movement range": "Rango massimo di movimento delle pistole",
      "Total Guns": "Totale pistole",
      "Gun to gun distance": "Distanza pistola-pistola",
      "Total number of vertical gun stacks or columns": "Numero totale di colonne di pistole verticali",
      "columns of guns": "colonne di pistole",
      "1st guns column": "1º colonna di pistole",
      "Total number of guns of the 1st vertical stack": "Numero totale di pistole della 1ª colonna",
      "Gun to gun distance of the 1st vertical stack": "Distanza pistola-pistola della 1ª colonna di pistole",
      "2nd guns column": "2º colonna di pistole",
      "Total number of guns of the 2nd vertical stack": "Numero totale di pistole della 2ª colonna",
      "Gun to gun distance of the 2nd vertical stack": "Distanza pistola-pistola della 2ª colonna di pistole",
      "Horizontal distance between 1st and 2nd vertical stacks": "Distanza orizzontale tra la 1ª e la 2ª colonna di pistole",
      "2nd vertical guns stack height offset": "Sfalsamento dell'altezza della 2ª colonna di pistole",
      "3rd guns column": "3° colonna di pistole",
      "Total number of guns of the 3rd vertical stack": "Numero totale di pistole della 3ª colonna",
      "Gun to gun distance of the 3rd vertical stack": "Distanza pistola-pistola della 3ª colonna di pistole",
      "Horizontal distance between 2nd and 3rd vertical stacks": "Distanza orizzontale tra la 2ª e la 3ª colonna di pistole",
      "3rd vertical guns stack height offset": "Sfalsamento dell'altezza della 3º colonna di pistole",
      "4th guns column": "4º colonna di pistole",
      "Total number of guns of the 4th vertical stack": "Numero totale di pistole della 4º colonna",
      "Gun to gun distance of the 4th vertical stack": "Distanza pistola-pistola della 4º colonna di pistole",
      "Horizontal distance between 3rd and 4th vertical stacks": "Distanza orizzontale tra la 3º e la 4º colonna di pistole",
      "4th vertical guns stack height offset": "Sfalsamento dell'altezza della 4º colonna di pistole",
      "5th guns column": "5° colonna di pistole",
      "Total number of guns of the 5th vertical stack": "Numero totale di pistole della 5º colonna",
      "Gun to gun distance of the 5th vertical stack": "Distanza pistola-pistola della 5º colonna di pistole",
      "Horizontal distance between the 4th and 5th stacks": "Distanza orizzontale tra la 4º e la 5º colonna di pistole",
      "5th vertical guns stack height offset": "Sfalsamento dell'altezza della 5º colonna di pistole",
      "Line Details are not editable. If you want to make changes to your line, contact us at": "I dettagli delle linee non sono modificabili. Se si desidera apportare modifiche alla linea, contattare l'azienda al seguente indirizzo",
      "Create Line": "Crea linea",
      "Line Preview": "Visualizzazione della Linea",
      "New Powder Model": "Nuovo Modello di Polvere",
      "Go Back Home": "Torna a Home",
      "Select Vertical if the guns are arranged in one on top of each other in a single column. Select Horizontal if they are arranged one next to each other in a single row. Select Mixed for more complex arrangements that combine multiple vertical stacks or columns.": "Selezionare Verticale se le pistole sono disposte in colonna. Selezionare Orizzontale se le pistole sono disposte in riga. Selezionare Mista per disposizioni più complesse che combinano più colonne verticali.",
      "The maximum movement range in the vertical axis that one gun can move, i.e. distance that one gun can move from its highest to its lowest position. By default is set to the gun to gun distance.": "La distanza massima di movimento sull'asse verticale che una pistola può percorrere, ossia la distanza che una pistola può percorrere dalla posizione più alta a quella più bassa. Per impostazione predefinita è impostata sulla distanza pistola-pistola.",
      "A mixed hybrid line is composed of multiple vertical stacks or columns of guns. If your Line has only 1 vertical gun column, then select Vertical instead of Mixed on the Gun Layout option.": "Una linea ibrida mista è composta da più colonne verticali di pistole. Se la linea ha solo una colonna verticale di pistole, selezionare Verticale invece di Mista nell'opzione Layout pistole.",
      "Input the vertical distance between guns of the 1st column of guns, the column that is most to the left.": "Immettere la distanza verticale tra le pistole della prima colonna di pistole, quella più a sinistra.",
      "Input the vertical distance between guns of the second column of guns, the second column from the left.": "Inserire la distanza verticale tra le pistole della seconda colonna di pistole, la seconda colonna da sinistra.",
      "Input the horizontal distance measured between the first column and the second column of guns.": "Immettere la distanza orizzontale misurata tra la prima colonna e la seconda colonna di pistole.",
      "Input the vertical guns stack height offset as the difference in respect to the previous one on the left. If the gun stacks are at equal height leave the default 0 offset. A positive value means the column is higher than the previous one, and a negative means the second stack is shorter than the first one.": "Immettere l'offset verticale dell'altezza della pila di pistole come differenza rispetto a quella precedente a sinistra. Se le pile di pistole hanno un'altezza uguale, lasciare l'offset predefinito a 0. Un valore positivo significa che la colonna è più alta della precedente, mentre un valore negativo significa che la seconda pila è più bassa della prima.",
      "Input the vertical distance between guns of the third column of guns, the third column from the left.": "Immettere la distanza verticale tra le pistole della terza colonna di pistole, la terza colonna da sinistra.",
      "Input the horizontal distance measured between the second column and the third column of guns": "Immettere la distanza orizzontale misurata tra la seconda colonna e la terza colonna di pistole.",
      "Input the vertical distance between guns of the fourth column of guns, i.e the fourth column from the left.": "Immettere la distanza verticale tra le pistole della quarta colonna di pistole, cioè la quarta colonna da sinistra.",
      "Input the horizontal distance measured between the third column and the fourth column of guns": "Immettere la distanza orizzontale misurata tra la terza colonna e la quarta colonna di pistole.",
      "Input the vertical distance between guns of the fifth column of guns, i.e the fifth column from the left": "Immettere la distanza verticale tra le pistole della quinta colonna di pistole, cioè la quinta colonna da sinistra.",
      "Input the horizontal distance measured between the fourth column and the fifth column of guns": "Immettere la distanza orizzontale misurata tra la quarta colonna e la quinta colonna di pistole.",
      "Other": "Altro",
      "Dense Phase":"Dense Phase",
      "Injector / Venturi":"Injector / Venturi",
      "Save Changes":"Salva modifiche",
      "No Changes to Save":"Nessuna modifica da salvare",
      "Wagner":"Wagner",
      "MS-Carlisle":"MS-Carlisle",
      "GEMA":"GEMA",
      "Nordson":"Nordson",
      "Fixed": "Estatico",
      "Dynamic": "Dinamica",
      "Line Type": "Tipo di linea",
      "Select": "Selezionare",
      "Select moving or fixed line. A fixed line has a static reciprocator that does not move, hence guns have 0 movement range and 0 velocity.": "Selezionare una linea mobile o statica. La linea statica ha il reciprocatore fisso, quindi le pistole hanno un intervallo di movimento e una velocità pari a 0.",
      "guns": "pistole",
      "Do you have a recycling powder system?":"Hai un sistema di riciclaggio della polvere?",
      "Yes":"Sì",
      "No":"No",
      "Recycling System Efficiency":"Efficienza del sistema di riciclaggio",
      "Number of Columns":"Numero di colonne",
      "Column Distance":"Distanza tra colonne",
      "Column Shift": "Spostamento di colonna",
      "Number of Rows": "Numero di righe",
      "Row Distance": "Distanza tra le righe",
      "Row Shift": "Spostamento di riga",
      "Advanced Gun Layout": "Layout avanzato delle pistole",
      "6th guns column":"6ª colonna di pistole",
      "7th guns column":"7ª colonna di pistole",
      "8th guns column":"8ª colonna di pistole",
      "9th guns column":"9ª colonna di pistole",
      "10th guns column":"10ª colonna di pistole",
      "Total number of guns of the 6th vertical stack":"Numero totale di pistole della 6ª pila verticale",
      "Total number of guns of the 7th vertical stack":"Numero totale di pistole della 7ª pila verticale",
      "Total number of guns of the 8th vertical stack":"Numero totale di pistole della 8ª pila verticale",
      "Total number of guns of the 9th vertical stack":"Numero totale di pistole della 9ª pila verticale",
      "Total number of guns of the 10th vertical stack":"Numero totale di pistole della 10ª pila verticale",
      "Gun to gun distance of the 6th vertical stack":"Distanza pistola-pistola della 6ª pila verticale",
      "Gun to gun distance of the 7th vertical stack":"Distanza pistola-pistola della 7ª pila verticale",
      "Gun to gun distance of the 8th vertical stack":"Distanza pistola-pistola della 8ª pila verticale",
      "Gun to gun distance of the 9th vertical stack":"Distanza pistola-pistola della 9ª pila verticale",
      "Gun to gun distance of the 10th vertical stack":"Distanza pistola-pistola della 10ª pila verticale",
      "Input the vertical distance between guns of the sixth column of guns, i.e the sixth column from the left":"Immettere la distanza verticale tra le pistole della sesta colonna di pistole, cioè la sesta colonna da sinistra",
      "Input the vertical distance between guns of the seventh column of guns, i.e the seventh column from the left":"Immettere la distanza verticale tra le pistole della settima colonna di pistole, cioè la settima colonna da sinistra",
      "Input the vertical distance between guns of the eighth column of guns, i.e the eighth column from the left":"Immettere la distanza verticale tra le pistole dell'ottava colonna di pistole, cioè l'ottava colonna da sinistra",
      "Input the vertical distance between guns of the ninth column of guns, i.e the ninth column from the left":"Immettere la distanza verticale tra le pistole della nona colonna di pistole, cioè la nona colonna da sinistra",
      "Input the vertical distance between guns of the tenth column of guns, i.e the tenth column from the left":"Immettere la distanza verticale tra le pistole della decima colonna di pistole, cioè la decima colonna da sinistra",
      "Horizontal distance between the 5th and 6th stacks":"Distanza orizzontale tra la 5ª e la 6ª pila",
      "Horizontal distance between the 6th and 7th stacks":"Distanza orizzontale tra la 6ª e la 7ª pila",
      "Horizontal distance between the 7th and 8th stacks":"Distanza orizzontale tra la 7ª e la 8ª pila",
      "Horizontal distance between the 8th and 9th stacks":"Distanza orizzontale tra la 8ª e la 9ª pila",
      "Horizontal distance between the 9th and 10th stacks":"Distanza orizzontale tra la 9ª e la 10ª pila",
      "Input the horizontal distance measured between the fifth column and the sixth column of guns":"Immettere la distanza orizzontale misurata tra la quinta colonna e la sesta colonna di pistole",
      "Input the horizontal distance measured between the sixth column and the seventh column of guns":"Immettere la distanza orizzontale misurata tra la sesta colonna e la settima colonna di pistole",
      "Input the horizontal distance measured between the seventh column and the eighth column of guns":"Immettere la distanza orizzontale misurata tra la settima colonna e l'ottava colonna di pistole",
      "Input the horizontal distance measured between the eighth column and the ninth column of guns":"Immettere la distanza orizzontale misurata tra l'ottava colonna e la nona colonna di pistole",
      "Input the horizontal distance measured between the ninth column and the tenth column of guns":"Immettere la distanza orizzontale misurata tra la nona colonna e la decima colonna di pistole",
      "6th vertical guns stack height offset":"Sfalsamento dell'altezza della 6ª pila verticale di pistole",
      "7th vertical guns stack height offset":"Sfalsamento dell'altezza della 7ª pila verticale di pistole",
      "8th vertical guns stack height offset":"Sfalsamento dell'altezza della 8ª pila verticale di pistole",
      "9th vertical guns stack height offset":"Sfalsamento dell'altezza della 9ª pila verticale di pistole",
      "10th vertical guns stack height offset":"Sfalsamento dell'altezza della 10ª pila verticale di pistole",
      "Switch to advanced Line configurator": "Passa al configuratore di linea avanzato",
      "Switch to standard Line configurator": "Passa al configuratore di linea standard",
      "Horizontal layout is not allowed for Fixed Lines": "Il layout orizzontale non è consentito per le linee statiche",
      "For Mixed Lines the number of gun columns must be smaller than":"Per le Linee miste il numero di colonne di cannoni deve essere inferiore a",
      "Attention!":"Attenzione!",
      "Changing to standard configuration might modify your line. Do you want to continue?":"Il passaggio alla configurazione standard potrebbe modificare la tua linea. Vuoi continuare?",
      "Cancel":"Annulla",
      "Total Guns Column 6": "Totale pistole Colonna 6",
      "Total Guns Column 7": "Totale pistole Colonna 7",
      "Total Guns Column 8": "Totale pistole Colonna 8",
      "Total Guns Column 9": "Totale pistole Colonna 9",
      "Total Guns Column 10": "Totale pistole Colonna 10",
      "Gun to Gun Distance Column 6": "Distanza pistola-pistola Colonna 6",
      "Gun to Gun Distance Column 7": "Distanza pistola-pistola Colonna 7",
      "Gun to Gun Distance Column 8": "Distanza pistola-pistola Colonna 8",
      "Gun to Gun Distance Column 9": "Distanza pistola-pistola Colonna 9",
      "Gun to Gun Distance Column 10": "Distanza pistola-pistola Colonna 10",
      "Gun Column Distance 6": "Distanza colonna pistola 6",
      "Gun Column Distance 7": "Distanza colonna pistola 7",
      "Gun Column Distance 8": "Distanza colonna pistola 8",
      "Gun Column Distance 9": "Distanza colonna pistola 9",
      "Gun Column Distance 10": "Distanza colonna pistola 10",
      "Gun Column Vertical Offset 6": "Offset verticale della colonna della pistola 6",
      "Gun Column Vertical Offset 7": "Offset verticale della colonna della pistola 7",
      "Gun Column Vertical Offset 8": "Offset verticale della colonna della pistola 8",
      "Gun Column Vertical Offset 9": "Offset verticale della colonna della pistola 9",
      "Gun Column Vertical Offset 10": "Offset verticale della colonna della pistola 10",
      "Number of columns cannot be greater than": "Il numero di colonne non può essere maggiore di",
      "Number of rows cannot be greater than": "Il numero di righe non può essere maggiore di",
      "Row distance must be greater than": "La distanza tra le righe deve essere maggiore di",
      "Row distance must be smaller than": "La distanza tra le righe deve essere inferiore a",
      "Column distance must be greater than": "La distanza tra le colonne deve essere maggiore di",
      "Column distance must be smaller than": "La distanza tra le colonne deve essere inferiore a",
      "Column Shift must be greater than": "Lo spostamento di colonna deve essere maggiore di",
      "Column Shift must be smaller than": "Lo spostamento di colonna deve essere inferiore a",
      "Row Shift must be greater than": "Lo spostamento di riga deve essere maggiore di",
      "Row Shift must be smaller than": "Lo spostamento di riga deve essere inferiore a",
      "Reciprocator Min Velocity cannot be greater than or equal to the Reciprocator Max Velocity": "La velocità minima del reciprocatore non può essere maggiore o uguale alla velocità massima del reciprocatore",
      "The number of guns must be smaller than": "Il numero di pistole deve essere inferiore a",
      "Lines with 1 gun per column are not allowed": "Non sono ammesse righe con 1 pistola per colonna.",
      "Advanced Gun Parameters": "Parametri avanzati delle pistole",  
      "The gun's maximum acceleration value is outside the permissible range. Please enter a value within the allowed limits.":  "Il valore massimo di accelerazione della pistola è al di fuori del range consentito. Inserire un valore entro i limiti consentiti.",
      "New Optimization": "Nuova ottimizzazione",
    },
    "PL": {
      "Horizontal distance between two adjacent guns or stacks of guns.": "Pozioma odległość między dwoma sąsiadującymi działami lub stosami dział.",
      "Number of guns or vertical stacks of guns, next to each other.": "Liczba dział lub pionowych stosów dział znajdujących się obok siebie.",
      "Height offset between two adjacent guns or stacks of guns.": "Przesunięcie wysokości między dwoma sąsiednimi działami lub stosami dział.",
      "Number of guns on top of each other.": "Liczba dział ustawionych jedno na drugim.",
      "Vertical distance between two guns on top of each other.": "Pionowa odległość między dwoma działami na sobie.",
      "Horizontal distance offset between even and odd rows.": "Przesunięcie w poziomie między rzędami parzystymi i nieparzystymi.",
      "Minimum gun velocity with activated reciprocator": "Minimalna prędkość pistoletu z aktywnym przeciwsobnikiem",
      "Maximum gun velocity of the reciprocator": "Maksymalna prędkość działa z przeciwsobnikiem",
      "Maximum gun acceleration of the reciprocator": "Maksymalne przyspieszenie działa przeciwsobnego",
      "The minimum is": "Minimum wynosi",
      "and the maximum is": "a maksymalne wynosi",
      "Static Calibration": "Kalibracja statyczna",
      "Gun Max Velocity must be greater than Gun Min Velocity by at least": "Maksymalna prędkość pistoletu musi być większa od minimalnej prędkości pistoletu o co najmniej",
      "Gun column height offsets": "Przesunięcie wysokości kolumny pistoletu",
      "Nozzle rotation angle": "Kąt obrotu dyszy",
      "Total Guns per column": "Liczba pistoletów na kolumnę",
      "Fixed Mixed (non moving with multiple columns of guns)": "Statyczny mieszany (nieporuszający się z wieloma kolumnami pistoletów)",
      "Line changes saved": "Zapisane zmiany linii",
      "Line created": "Utworzona linia",
      "was saved successfully": "została pomyślnie zapisana",
      "The following fields are required:": "Wymagane są następujące pola:",
      "Air Parameter Name": "Nazwa parametru powietrza",
      "Air Parameter Units": "Jednostki parametru powietrza",
      "Number of Gun Columns": "Liczba kolumn pistoletów",
      "Total Guns Column 1": "Całkowita liczba pistoletów Kolumna 1",
      "Total Guns Column 2": "Całkowita liczba pistoletów Kolumna 2",
      "Total Guns Column 3": "Całkowita liczba pistoletów Kolumna 3",
      "Total Guns Column 4": "Całkowita liczba pistoletów Kolumna 4",
      "Total Guns Column 5": "Całkowita liczba pistoletów Kolumna 5",
      "Gun to Gun Distance Column 1": "Odległość od pistoletu do pistoletu Kolumna 1",
      "Gun to Gun Distance Column 2": "Odległość od pistoletu do pistoletu Kolumna 2",
      "Gun to Gun Distance Column 3": "Odległość od pistoletu do pistoletu Kolumna 3",
      "Gun to Gun Distance Column 4": "Odległość od pistoletu do pistoletu Kolumna 4",
      "Gun to Gun Distance Column 5": "Odległość od pistoletu do pistoletu Kolumna 5",
      "Gun Column Distance 1": "Odległość między kolumnami pistoletów 1",
      "Gun Column Distance 2": "Odległość kolumny pistoletów 2",
      "Gun Column Distance 3": "Odległość kolumny pistoletów 3",
      "Gun Column Distance 4": "Odległość kolumny pistoletów 4",
      "Gun Column Distance 5": "Odległość kolumny pistoletu 5",
      "Gun Column Vertical Offset 1": "Przesunięcie pionowe kolumny pistoletu 1",
      "Gun Column Vertical Offset 2": "Przesunięcie pionowe kolumny pistoletu 2",
      "Gun Column Vertical Offset 3": "Przesunięcie pionowe kolumny pistoletu 3",
      "Gun Column Vertical Offset 4": "Przesunięcie pionowe kolumny pistoletu 4",
      "Gun Column Vertical Offset 5": "Przesunięcie pionowe kolumny pistoletu 5",
      "Input value out of range": "Wartość wejściowa poza zakresem",
      "Gun to gun distance cannot be 0": "Odległość między pistoletami nie może wynosić 0",
      "Gun to Gun distance must be greater than": "Odległość od pistoletu do pistoletu musi być większa niż",
      "Gun to Gun distance must be smaller than": "Odległość między pistoletem a pistoletem musi być mniejsza niż",
      "Gun max movement range cannot be 0": "Maksymalny zakres ruchu pistoletu nie może wynosić 0",
      "Gun max movement range must be greater than": "Maksymalny zakres ruchu pistoletu musi być większy niż",
      "Gun max movement range must be smaller than": "Maksymalny zakres ruchu pistoletu musi być mniejszy niż",
      "Gun Min Velocity cannot be 0 for Horizontal Gun Layout.": "Minimalna prędkość pistoletu nie może wynosić 0 dla poziomego układu pistoletów.",
      "Gun Min Velocity must be greater than": "Minimalna prędkość pistoletu musi być większa niż",
      "Gun Max Velocity cannot be 0": "Maksymalna prędkość pistoletu nie może wynosić 0",
      "Gun Min Velocity cannot be greater than or equal to the Gun Max Velocity": "Minimalna prędkość pistoletu nie może być większa lub równa maksymalnej prędkości pistoletu.",
      "Gun max acceleration": "Maksymalne przyspieszenie pistoletu",
      "Missing Required Fields": "Brakujące wymagane pola",
      "Vertical": "Pionowo",
      "Horizontal": "Poziomy",
      "Mixed (Vertical and Horizontal Hybrid)": "Mieszany (hybryda pionowa i pozioma)",
      "Simple": "Prosty",
      "Fixed": "Statyczny",
      "Advanced": "Zaawansowany",
      "Which type of Powder Model do you want to create?": "Który typ modelu proszku chcesz utworzyć?",
      "Please select one": "Wybierz jedną",
      "Add new Line": "Dodaj nową linię",
      "Edit Line": "Edytuj linię",
      "Line Details": "Szczegóły linii",
      "Name": "Nazwa",
      "Line Properties": "Właściwości linii",
      "Line Manufacturer":"Producent linii",
      "Air Parameter Name e.g. [DosageAir]": "Nazwa parametru powietrza np. [DosageAir]",
      "Air Parameter Units e.g. [bar]": "Jednostki parametrów powietrza, np. [bar]",
      "Pump Type": "Typ pompy",
      "Gun min velocity": "Minimalna prędkość pistoletów",
      "Gun max velocity": "Maksymalna prędkość pistoletów",
      "Gun Layout": "Układ pistoletów",
      "Gun max movement range": "Maksymalny zasięg ruchu pistoletów",
      "Total Guns": "Łączna liczba pistoletów",
      "Gun to gun distance": "Odległość między pistoletami",
      "Total number of vertical gun stacks or columns": "Łączna liczba pionowych stosów lub kolumn pistoletów",
      "columns of guns": "kolumny pistoletów",
      "1st guns column": "1. kolumna pistoletów",
      "Total number of guns of the 1st vertical stack": "Łączna liczba pistoletów w 1. pionowym stosie",
      "Gun to gun distance of the 1st vertical stack": "Odległość między pistoletami w 1. pionowym stosie",
      "2nd guns column": "2. kolumna pistoletów",
      "Total number of guns of the 2nd vertical stack": "Całkowita liczba pistoletów w 2. pionowym stosie",
      "Gun to gun distance of the 2nd vertical stack": "Odległość między pistoletami w 2. pionowym stosie",
      "Horizontal distance between 1st and 2nd vertical stacks": "Odległość pozioma między 1. i 2. stosem pionowym",
      "2nd vertical guns stack height offset": "Przesunięcie wysokości 2. pionowego stosu pistoletów",
      "3rd guns column": "3. kolumna pistoletów",
      "Total number of guns of the 3rd vertical stack": "Całkowita liczba pistoletów w 3. pionowym stosie",
      "Gun to gun distance of the 3rd vertical stack": "Odległość między pistoletami w 3. pionowym stosie",
      "Horizontal distance between 2nd and 3rd vertical stacks": "Pozioma odległość między 2. i 3. pionowym stosem",
      "3rd vertical guns stack height offset": "Przesunięcie wysokości 3. pionowego stosu pistoletów",
      "4th guns column": "4. kolumna pistoletów",
      "Total number of guns of the 4th vertical stack": "Całkowita liczba pistoletów w 4. pionowym stosie",
      "Gun to gun distance of the 4th vertical stack": "Odległość między pistoletami w 4. pionowym stosie",
      "Horizontal distance between 3rd and 4th vertical stacks": "Pozioma odległość między 3. i 4. pionowym stosem",
      "4th vertical guns stack height offset": "Przesunięcie wysokości 4. pionowego stosu pistoletów",
      "5th guns column": "5. kolumna pistoletów",
      "Total number of guns of the 5th vertical stack": "Całkowita liczba pistoletów w 5. pionowym stosie",
      "Gun to gun distance of the 5th vertical stack": "Odległość między pistoletami w 5. pionowym stosie",
      "Horizontal distance between the 4th and 5th stacks": "Pozioma odległość między 4. i 5. stosem",
      "5th vertical guns stack height offset": "Przesunięcie wysokości piątego pionowego stosu pistoletów",
      "Line Details are not editable. If you want to make changes to your line, contact us at": "Szczegółów linii nie można edytować. Jeśli chcesz wprowadzić zmiany w linii, skontaktuj się z nami pod adresem",
      "Create Line": "Utwórz linię",
      "Line Preview": "Podgląd linii",
      "New Powder Model": "Nowy model proszku",
      "Go Back Home": "Wróć do domu",
      "Select Vertical if the guns are arranged in one on top of each other in a single column. Select Horizontal if they are arranged one next to each other in a single row. Select Mixed for more complex arrangements that combine multiple vertical stacks or columns.": "Wybierz opcję Pionowo, jeśli pistolety są ułożone jeden na drugim w jednej kolumnie. Wybierz Poziomo, jeśli pistolety są ułożone jeden obok drugiego w jednym rzędzie. Wybierz opcję Mieszane dla bardziej złożonych układów, które łączą wiele pionowych stosów lub kolumn.",
      "The maximum movement range in the vertical axis that one gun can move, i.e. distance that one gun can move from its highest to its lowest position. By default is set to the gun to gun distance.": "Maksymalny zakres ruchu w osi pionowej, jaki może wykonać jeden pistolet, tj. odległość, na jaką jeden pistolet może przesunąć się z najwyższej do najniższej pozycji. Domyślnie jest to odległość między pistoletami.",
      "A mixed hybrid line is composed of multiple vertical stacks or columns of guns. If your Line has only 1 vertical gun column, then select Vertical instead of Mixed on the Gun Layout option.": "Mieszana linia hybrydowa składa się z wielu pionowych stosów lub kolumn pistoletów. Jeśli linia ma tylko 1 pionową kolumnę pistoletów, wybierz opcję Pionowy zamiast Mieszany w opcji Układ pistoletów.",
      "Input the vertical distance between guns of the 1st column of guns, the column that is most to the left.": "Wprowadź pionową odległość między pistoletami w 1. kolumnie pistoletów, kolumnie znajdującej się najbardziej po lewej stronie.",
      "Input the vertical distance between guns of the second column of guns, the second column from the left.": "Wprowadź odległość w pionie między pistoletami w drugiej kolumnie pistoletów, drugiej kolumnie od lewej.",
      "Input the horizontal distance measured between the first column and the second column of guns.": "Wprowadź odległość w poziomie między pierwszą a drugą kolumną pistoletów.",
      "Input the vertical guns stack height offset as the difference in respect to the previous one on the left. If the gun stacks are at equal height leave the default 0 offset. A positive value means the column is higher than the previous one, and a negative means the second stack is shorter than the first one.": "Wprowadź przesunięcie wysokości stosu pistoletów w pionie jako różnicę w stosunku do poprzedniego stosu po lewej stronie. Jeśli stosy pistoletów znajdują się na równej wysokości, pozostaw domyślne przesunięcie 0. Wartość dodatnia oznacza, że kolumna jest wyższa niż poprzednia, a wartość ujemna oznacza, że drugi stos jest krótszy niż pierwszy.",
      "Input the vertical distance between guns of the third column of guns, the third column from the left.": "Wprowadź pionową odległość między pistoletami w trzeciej kolumnie pistoletów, trzeciej kolumnie od lewej.",
      "Input the horizontal distance measured between the second column and the third column of guns": "Wprowadź odległość w poziomie między drugą a trzecią kolumną pistoletów.",
      "Input the vertical distance between guns of the fourth column of guns, i.e the fourth column from the left.": "Wprowadź odległość w pionie między pistoletami w czwartej kolumnie pistoletów, tj. w czwartej kolumnie od lewej.",
      "Input the horizontal distance measured between the third column and the fourth column of guns": "Wprowadź odległość w poziomie zmierzoną między trzecią a czwartą kolumną pistoletów.",
      "Input the vertical distance between guns of the fifth column of guns, i.e the fifth column from the left": "Wprowadź pionową odległość między pistoletami w piątej kolumnie pistoletów, tj. w piątej kolumnie od lewej.",
      "Input the horizontal distance measured between the fourth column and the fifth column of guns": "Wprowadź odległość poziomą zmierzoną między czwartą kolumną a piątą kolumną pistoletów.",
      "Other": "Inne",
      "Dense Phase":"Dense Phase",
      "Injector / Venturi":"Injector / Venturi",
      "Save Changes":"Zapisz zmiany",
      "No Changes to Save":"Brak zmian do zapisania",
      "Wagner":"Wagner",
      "MS-Carlisle":"MS-Carlisle",
      "GEMA":"GEMA",
      "Nordson":"Nordson",
      "Fixed": "Statyczny",
      "Dynamic": "Dynamiczny",
      "Line Type": "Typ linii",
      "Select": "Wybierz",
      "Select moving or fixed line. A fixed line has a static reciprocator that does not move, hence guns have 0 movement range and 0 velocity.": "Wybierz linię ruchomą lub statyczną. Linia statyczna ma nieruchomy reciprocator, dlatego pistolety mają zakres ruchu 0 i prędkość 0.",
      "guns": "pistolety",
      "Do you have a recycling powder system?":"Czy masz system recyklingu proszku?",
      "Yes":"Tak",
      "No":"Nie",
      "Recycling System Efficiency":"Wydajność systemu recyklingu",
      "Number of Columns":"Liczba kolumn",
      "Column Distance":"Odległość między kolumnami",
      "Column Shift": "Przesunięcie kolumny",
      "Number of Rows": "Liczba wierszy",
      "Row Distance": "Odległość między wierszami",
      "Row Shift": "Przesunięcie wiersza",
      "Advanced Gun Layout": "Zaawansowany układ pistoletów",
      "6th guns column":"6. kolumna pistoletów",
      "7th guns column":"7. kolumna pistoletów",
      "8th guns column":"8. kolumna pistoletów",
      "9th guns column":"9. kolumna pistoletów",
      "10th guns column":"10. kolumna pistoletów",
      "Total number of guns of the 6th vertical stack":"Całkowita liczba pistoletów 6. pionowego stosu",
      "Total number of guns of the 7th vertical stack":"Całkowita liczba pistoletów 7. pionowego stosu",
      "Total number of guns of the 8th vertical stack":"Całkowita liczba pistoletów 8. pionowego stosu",
      "Total number of guns of the 9th vertical stack":"Całkowita liczba pistoletów 9. pionowego stosu",
      "Total number of guns of the 10th vertical stack":"Całkowita liczba pistoletów 10. pionowego stosu",
      "Gun to gun distance of the 6th vertical stack":"Odległość między pistoletami 6. pionowego stosu",
      "Gun to gun distance of the 7th vertical stack":"Odległość między pistoletami 7. pionowego stosu",
      "Gun to gun distance of the 8th vertical stack":"Odległość między pistoletami 8. pionowego stosu",
      "Gun to gun distance of the 9th vertical stack":"Odległość między pistoletami 9. pionowego stosu",
      "Gun to gun distance of the 10th vertical stack":"Odległość między pistoletami 10. pionowego stosu",
      "Input the vertical distance between guns of the sixth column of guns, i.e the sixth column from the left":"Wprowadź pionową odległość między pistoletami w szóstej kolumnie pistoletów, tj. w szóstej kolumnie od lewej",
      "Input the vertical distance between guns of the seventh column of guns, i.e the seventh column from the left":"Wprowadź pionową odległość między pistoletami w siódmej kolumnie pistoletów, tj. w siódmej kolumnie od lewej",
      "Input the vertical distance between guns of the eighth column of guns, i.e the eighth column from the left":"Wprowadź pionową odległość między pistoletami w ósmej kolumnie pistoletów, tj. w ósmej kolumnie od lewej",
      "Input the vertical distance between guns of the ninth column of guns, i.e the ninth column from the left":"Wprowadź pionową odległość między pistoletami w dziewiątej kolumnie pistoletów, tj. w dziewiątej kolumnie od lewej",
      "Input the vertical distance between guns of the tenth column of guns, i.e the tenth column from the left":"Wprowadź pionową odległość między pistoletami w dziesiątej kolumnie pistoletów, tj. w dziesiątej kolumnie od lewej",
      "Horizontal distance between the 5th and 6th stacks":"Odległość pozioma między 5. a 6. stosami",
      "Horizontal distance between the 6th and 7th stacks":"Odległość pozioma między 6. a 7. stosami",
      "Horizontal distance between the 7th and 8th stacks":"Odległość pozioma między 7. a 8. stosami",
      "Horizontal distance between the 8th and 9th stacks":"Odległość pozioma między 8. a 9. stosami",
      "Horizontal distance between the 9th and 10th stacks":"Odległość pozioma między 9. a 10. stosami",
      "Input the horizontal distance measured between the fifth column and the sixth column of guns":"Wprowadź odległość poziomą zmierzoną między piątą a szóstą kolumną pistoletów",
      "Input the horizontal distance measured between the sixth column and the seventh column of guns":"Wprowadź odległość poziomą zmierzoną między szóstą a siódmą kolumną pistoletów",
      "Input the horizontal distance measured between the seventh column and the eighth column of guns":"Wprowadź odległość poziomą zmierzoną między siódmą a ósmą kolumną pistoletów",
      "Input the horizontal distance measured between the eighth column and the ninth column of guns":"Wprowadź odległość poziomą zmierzoną między ósmą a dziewiątą kolumną pistoletów",
      "Input the horizontal distance measured between the ninth column and the tenth column of guns":"Wprowadź odległość poziomą zmierzoną między dziewiątą a dziesiątą kolumną pistoletów",
      "6th vertical guns stack height offset":"Przesunięcie wysokości 6. pionowego stosu pistoletów",
      "7th vertical guns stack height offset":"Przesunięcie wysokości 7. pionowego stosu pistoletów",
      "8th vertical guns stack height offset":"Przesunięcie wysokości 8. pionowego stosu pistoletów",
      "9th vertical guns stack height offset":"Przesunięcie wysokości 9. pionowego stosu pistoletów",
      "10th vertical guns stack height offset":"Przesunięcie wysokości 10. pionowego stosu pistoletów",
      "Switch to advanced Line configurator": "Przełącz się na zaawansowany konfigurator linii",
      "Switch to standard Line configurator": "Przełącz się na standardowy konfigurator linii",
      "Horizontal layout is not allowed for Fixed Lines": "Układ poziomy nie jest dozwolony dla linii statycznych",
      "For Mixed Lines the number of gun columns must be smaller than":"W przypadku linii mieszanych liczba kolumn pistoletu musi być mniejsza niż",
      "Attention!":"Uwaga!",
      "Changing to standard configuration might modify your line. Do you want to continue?":"Zmiana na standardową konfigurację może zmodyfikować Twoją linię. Czy chcesz kontynuować?",
      "Cancel":"Anuluj",
      "Total Guns Column 6": "Całkowita liczba pistoletów Kolumna 6",
      "Total Guns Column 7": "Całkowita liczba pistoletów Kolumna 7",
      "Total Guns Column 8": "Całkowita liczba pistoletów Kolumna 8",
      "Total Guns Column 9": "Całkowita liczba pistoletów Kolumna 9",
      "Total Guns Column 10": "Całkowita liczba pistoletów Kolumna 10",
      "Gun to Gun Distance Column 6": "Odległość od pistoletu do pistoletu Kolumna 6",
      "Gun to Gun Distance Column 7": "Odległość od pistoletu do pistoletu Kolumna 7",
      "Gun to Gun Distance Column 8": "Odległość od pistoletu do pistoletu Kolumna 8",
      "Gun to Gun Distance Column 9": "Odległość od pistoletu do pistoletu Kolumna 9",
      "Gun to Gun Distance Column 10": "Odległość od pistoletu do pistoletu Kolumna 10",
      "Gun Column Distance 6": "Odległość między kolumnami pistoletów 6",
      "Gun Column Distance 7": "Odległość między kolumnami pistoletów 7",
      "Gun Column Distance 8": "Odległość między kolumnami pistoletów 8",
      "Gun Column Distance 9": "Odległość między kolumnami pistoletów 9",
      "Gun Column Distance 10": "Odległość między kolumnami pistoletów 10",
      "Gun Column Vertical Offset 6": "Przesunięcie pionowe kolumny pistoletu 6",
      "Gun Column Vertical Offset 7": "Przesunięcie pionowe kolumny pistoletu 7",
      "Gun Column Vertical Offset 8": "Przesunięcie pionowe kolumny pistoletu 8",
      "Gun Column Vertical Offset 9": "Przesunięcie pionowe kolumny pistoletu 9",
      "Gun Column Vertical Offset 10": "Przesunięcie pionowe kolumny pistoletu 10",
      "Number of columns cannot be greater than": "Liczba kolumn nie może być większa niż",
      "Number of rows cannot be greater than": "Liczba wierszy nie może być większa niż",
      "Row distance must be greater than": "Odległość między wierszami musi być większa niż",
      "Row distance must be smaller than": "Odległość między wierszami musi być mniejsza niż",
      "Column distance must be greater than": "Odległość między kolumnami musi być większa niż",
      "Column distance must be smaller than": "Odległość między kolumnami musi być mniejsza niż",
      "Column Shift must be greater than": "Przesunięcie kolumny musi być większe niż",
      "Column Shift must be smaller than": "Prezunięcie kolumny musi być mniejsze niż",
      "Row Shift must be greater than": "Przesunięcie wiersza musi być większe niż",
      "Row Shift must be smaller than": "Przesunięcie wiersza musi być mniejsze niż",
      "Reciprocator Min Velocity cannot be greater than or equal to the Reciprocator Max Velocity": "Minimalna prędkość reciprokatorka nie może być większa lub równa maksymalnej prędkości reciprokatorka",
      "The number of guns must be smaller than": "Liczba pistoletów musi być mniejsza niż",
      "Lines with 1 gun per column are not allowed": "Linie z 1 pistoletem na kolumnę nie są dozwolone",
      "Advanced Gun Parameters": "Zaawansowane parametry pistoletów", 
      "The gun's maximum acceleration value is outside the permissible range. Please enter a value within the allowed limits.":   "Maksymalna wartość przyspieszenia pistoletu jest poza dopuszczalnym zakresem. Proszę wprowadzić wartość w granicach dopuszczalnych.",
      "New Optimization": "Nowa optymalizacja",
    },
    "TR": {
      "Horizontal distance between two adjacent guns or stacks of guns.": "İki bitişik silah veya silah yığını arasındaki yatay mesafe.",
      "Number of guns or vertical stacks of guns, next to each other.": "Yan yana bulunan silahların veya dikey silah yığınlarının sayısı.",
      "Height offset between two adjacent guns or stacks of guns.": "İki bitişik silah veya silah yığını arasındaki yükseklik mesafesi.",
      "Number of guns on top of each other.": "Birbirinin üstündeki silah sayısı.",
      "Vertical distance between two guns on top of each other.": "Üst üste iki silah arasındaki dikey mesafe.",
      "Horizontal distance offset between even and odd rows.": "Çift ve tek sıralar arasında yatay mesafe ofseti.",
      "Minimum gun velocity with activated reciprocator": "Aktif reciprocator ile minimum tabanca hızı",
      "Maximum gun velocity of the reciprocator": "Resiprokatörün maksimum tabanca hızı",
      "Maximum gun acceleration of the reciprocator": "Resiprokatörün maksimum tabanca ivmesi",
      "The minimum is": "Minimum değer",
      "and the maximum is": "ve maksimum değer",
      "Static Calibration": "Statik Kalibrasyon",
      "Gun Max Velocity must be greater than Gun Min Velocity by at least": "Tabanca Maksimum Hızı, Tabanca Minimum Hızından en az şu kadar büyük olmalıdır",
      "Gun column height offsets": "Tabanca kolon yüksekliği ofsetleri",
      "Nozzle rotation angle": "Nozul dönüş açısı",
      "Total Guns per column": "Sütun başına Tabanca Sayısı",
      "Fixed Mixed (non moving with multiple columns of guns)": "Statik Karma (birden fazla tabanca sütunu ile hareket etmeyen)",
      "Line changes saved": "Hat değişiklikleri kaydedildi",
      "Line created": "Oluşturulan hat",
      "was saved successfully": "Hat başarıyla kaydedildi",
      "The following fields are required:": "Aşağıdaki alanlar gereklidir:",
      "Air Parameter Name": "Hava Parametresi Adı",
      "Air Parameter Units": "Hava Parametresi Birimleri",
      "Number of Gun Columns": "Tabanca Kolon Sayısı",
      "Total Guns Column 1": "Toplam Tabanca Sütun 1",
      "Total Guns Column 2": "Toplam Tabanca Sütun 2",
      "Total Guns Column 3": "Toplam Tabanca Sütun 3",
      "Total Guns Column 4": "Toplam Tabanca Sütun 4",
      "Total Guns Column 5": "Toplam Tabanca Sütun 5",
      "Gun to Gun Distance Column 1": "Tabancadan Tabancaya Mesafe Sütun 1",
      "Gun to Gun Distance Column 2": "Tabancadan Tabancaya Mesafe Sütun 2",
      "Gun to Gun Distance Column 3": "Tabancadan Tabancaya Mesafe Sütun 3",
      "Gun to Gun Distance Column 4": "Tabancadan Tabancaya Mesafe Sütun 4",
      "Gun to Gun Distance Column 5": "Tabancadan Tabancaya Mesafe Sütun 5",
      "Gun Column Distance 1": "Tabanca Kolon Mesafesi 1",
      "Gun Column Distance 2": "Tabanca Kolon Mesafesi 2",
      "Gun Column Distance 3": "Tabanca Kolon Mesafesi 3",
      "Gun Column Distance 4": "Tabanca Kolon Mesafesi 4",
      "Gun Column Distance 5": "Tabanca Kolon Mesafesi 5",
      "Gun Column Vertical Offset 1": "Tabanca Kolonu Dikey Ofset 1",
      "Gun Column Vertical Offset 2": "Tabanca Kolonu Dikey Ofset 2",
      "Gun Column Vertical Offset 3": "Tabanca Kolonu Dikey Ofset 3",
      "Gun Column Vertical Offset 4": "Tabanca Kolonu Dikey Ofset 4",
      "Gun Column Vertical Offset 5": "Tabanca Kolonu Dikey Ofset 5",
      "Input value out of range": "Giriş değeri aralık dışında",
      "Gun to gun distance cannot be 0": "Tabancadan tabancaya mesafe 0 olamaz",
      "Gun to Gun distance must be greater than": "Tabanca-Tabanca mesafesi aşağıdakilerden daha büyük olmalıdır",
      "Gun to Gun distance must be smaller than": "Tabanca-Tabanca mesafesi aşağıdakilerden daha küçük olmalıdır",
      "Gun max movement range cannot be 0": "Tabanca maksimum hareket aralığı 0 olamaz",
      "Gun max movement range must be greater than": "Tabanca maksimum hareket menzili aşağıdakilerden daha büyük olmalıdır",
      "Gun max movement range must be smaller than": "Tabanca maksimum hareket menzili aşağıdakilerden daha küçük olmalıdır",
      "Gun Min Velocity cannot be 0 for Horizontal Gun Layout.": "Yatay Tabanca Düzeni için Tabanca Min Hızı 0 olamaz.",
      "Gun Min Velocity must be greater than": "Tabanca Min Hızı aşağıdakilerden büyük olmalıdır",
      "Gun Max Velocity cannot be 0": "Tabanca Maksimum Hızı 0 olamaz",
      "Gun Min Velocity cannot be greater than or equal to the Gun Max Velocity": "Tabanca Minimum Hızı, Tabanca Maksimum Hızından büyük veya eşit olamaz",
      "Gun max acceleration": "Tabanca maksimum ivme",
      "Missing Required Fields": "Eksik Gerekli Alanlar",
      "Vertical": "Dikey",
      "Horizontal": "Yatay",
      "Mixed (Vertical and Horizontal Hybrid)": "Karma (Dikey ve Yatay Hibrit)",
      "Simple": "Basit",
      "Fixed": "Statik",
      "Advanced": "Gelişmiş",
      "Which type of Powder Model do you want to create?": "Ne tür bir Toz Modeli oluşturmak istiyorsunuz?",
      "Please select one": "Lütfen birini seçin",
      "Add new Line": "Yeni Satır Ekle",
      "Edit Line": "Hattı Düzenle",
      "Line Details": "Hat Detayları",
      "Name": "İsim",
      "Line Properties": "Hat Özellikleri",
      "Line Manufacturer":"Hat Üreticisi",
      "Air Parameter Name e.g. [DosageAir]": "Hava Parametresi Adı, örneğin [DosageAir]",
      "Air Parameter Units e.g. [bar]": "Hava Parametresi Birimleri, örneğin [bar]",
      "Pump Type": "Pompa Tipi",
      "Gun min velocity": "Tabanca min hızı",
      "Gun max velocity": "Tabanca maksimum hızı",
      "Gun Layout": "Tabanca Düzeni",
      "Gun max movement range": "Tabancaların maksimum hareket aralığı",
      "Total Guns": "Toplam Tabanca",
      "Gun to gun distance": "Tabancadan tabancaya mesafe",
      "Total number of vertical gun stacks or columns": "Toplam dikey tabanca yığını veya sütunu sayısı",
      "columns of guns": "tabanca sütunları",
      "1st guns column": "1. tabanca sütunu",
      "Total number of guns of the 1st vertical stack": "1. dikey yığının toplam tabanca sayısı",
      "Gun to gun distance of the 1st vertical stack": "1. dikey yığının tabancadan tabancaya mesafesi",
      "2nd guns column": "2. tabanca sütunu",
      "Total number of guns of the 2nd vertical stack": "2. dikey yığının toplam tabanca sayısı",
      "Gun to gun distance of the 2nd vertical stack": "2. dikey istifin tabancadan tabancaya mesafesi",
      "Horizontal distance between 1st and 2nd vertical stacks": "1. ve 2. dikey yığınlar arasındaki yatay mesafe",
      "2nd vertical guns stack height offset": "2. dikey tabanca yığını yükseklik ofseti",
      "3rd guns column": "3. tabanca sütunu",
      "Total number of guns of the 3rd vertical stack": "3. dikey istifin toplam tabanca sayısı",
      "Gun to gun distance of the 3rd vertical stack": "3. dikey yığının tabancadan tabancaya mesafesi",
      "Horizontal distance between 2nd and 3rd vertical stacks": "2. ve 3. dikey yığınlar arasındaki yatay mesafe",
      "3rd vertical guns stack height offset": "3. dikey tabanca yığını yükseklik ofseti",
      "4th guns column": "4. tabanca sütunu",
      "Total number of guns of the 4th vertical stack": "4. dikey istifin toplam tabanca sayısı",
      "Gun to gun distance of the 4th vertical stack": "4. dikey yığının tabancadan tabancaya mesafesi",
      "Horizontal distance between 3rd and 4th vertical stacks": "3. ve 4. dikey yığınlar arasındaki yatay mesafe",
      "4th vertical guns stack height offset": "4. dikey tabanca yığını yükseklik ofseti",
      "5th guns column": "5. tabanca sütunu",
      "Total number of guns of the 5th vertical stack": "5. dikey istifin toplam tabanca sayısı",
      "Gun to gun distance of the 5th vertical stack": "5. dikey yığının tabancadan tabancaya mesafesi",
      "Horizontal distance between the 4th and 5th stacks": "4. ve 5. yığınlar arasındaki yatay mesafe",
      "5th vertical guns stack height offset": "5. dikey tabanca yığını yükseklik ofseti",
      "Line Details are not editable. If you want to make changes to your line, contact us at": "Satır Ayrıntıları düzenlenemez. Hattınızda değişiklik yapmak istiyorsanız, bizimle iletişime geçin",
      "Create Line": "Çizgi Oluştur",
      "Line Preview": "Hat Önizlemesi",
      "New Powder Model": "Yeni Toz Model",
      "Go Back Home": "Ana Sayfaya Dön",
      "Select Vertical if the guns are arranged in one on top of each other in a single column. Select Horizontal if they are arranged one next to each other in a single row. Select Mixed for more complex arrangements that combine multiple vertical stacks or columns.": "Tabancalar tek bir sütunda üst üste dizilmişse Dikey'i seçin. Tek bir sıra halinde yan yana dizilmişlerse Yatay'ı seçin. Birden fazla dikey yığın veya sütunu birleştiren daha karmaşık düzenlemeler için Karışık'ı seçin.",
      "The maximum movement range in the vertical axis that one gun can move, i.e. distance that one gun can move from its highest to its lowest position. By default is set to the gun to gun distance.": "Bir tabancanın dikey eksende hareket edebileceği maksimum hareket aralığı, yani bir tabancanın en yüksek konumundan en düşük konumuna hareket edebileceği mesafe. Varsayılan olarak tabancadan tabancaya mesafeye ayarlanmıştır.",
      "A mixed hybrid line is composed of multiple vertical stacks or columns of guns. If your Line has only 1 vertical gun column, then select Vertical instead of Mixed on the Gun Layout option.": "Karma bir hibrit hat, birden fazla dikey tabanca yığını veya sütunundan oluşur. Hattınızda yalnızca 1 dikey tabanca sütunu varsa, Tabanca Düzeni seçeneğinde Karışık yerine Dikey'i seçin.",
      "Input the vertical distance between guns of the 1st column of guns, the column that is most to the left.": "En soldaki sütun olan 1. tabanca sütununun tabancaları arasındaki dikey mesafeyi girin.",
      "Input the vertical distance between guns of the second column of guns, the second column from the left.": "Tabancaların ikinci sütununun tabancaları arasındaki dikey mesafeyi girin, soldan ikinci sütun.",
      "Input the horizontal distance measured between the first column and the second column of guns.": "Tabancaların birinci sütunu ile ikinci sütunu arasında ölçülen yatay mesafeyi girin.",
      "Input the vertical guns stack height offset as the difference in respect to the previous one on the left. If the gun stacks are at equal height leave the default 0 offset. A positive value means the column is higher than the previous one, and a negative means the second stack is shorter than the first one.": "Dikey tabanca yığını yüksekliği ofsetini soldaki bir öncekine göre fark olarak girin. Tabanca yığınları eşit yükseklikte ise varsayılan 0 ofseti bırakın. Pozitif bir değer, sütunun bir öncekinden daha yüksek olduğu anlamına gelir ve negatif, ikinci yığının birinciden daha kısa olduğu anlamına gelir.",
      "Input the vertical distance between guns of the third column of guns, the third column from the left.": "Üçüncü tabanca sütununun tabancaları arasındaki dikey mesafeyi girin, soldan üçüncü sütun.",
      "Input the horizontal distance measured between the second column and the third column of guns": "İkinci sütun ile üçüncü tabanca sütunu arasında ölçülen yatay mesafeyi girin",
      "Input the vertical distance between guns of the fourth column of guns, i.e the fourth column from the left.": "Dördüncü tabanca sütununun tabancaları arasındaki dikey mesafeyi girin, yani soldan dördüncü sütun.",
      "Input the horizontal distance measured between the third column and the fourth column of guns": "Üçüncü sütun ile dördüncü tabanca sütunu arasında ölçülen yatay mesafeyi girin",
      "Input the vertical distance between guns of the fifth column of guns, i.e the fifth column from the left": "Beşinci tabanca sütununun tabancaları arasındaki dikey mesafeyi girin, yani soldan beşinci sütun",
      "Input the horizontal distance measured between the fourth column and the fifth column of guns": "Tabancaların dördüncü sütunu ile beşinci sütunu arasında ölçülen yatay mesafeyi girin",
      "Other": "Diğer",
      "Dense Phase":"Dense Phase",
      "Injector / Venturi":"Injector / Venturi",
      "Save Changes":"Değişiklikleri Kaydet",
      "No Changes to Save":"Kaydedilecek Değişiklik Yok",
      "Wagner":"Wagner",
      "MS-Carlisle":"MS-Carlisle",
      "GEMA":"GEMA",
      "Nordson":"Nordson",
      "Fixed": "Statik",
      "Dynamic": "Dinamik",
      "Line Type": "Hat Tipi",
      "Select": "Seç",
      "Select moving or fixed line. A fixed line has a static reciprocator that does not move, hence guns have 0 movement range and 0 velocity.": "Hareketli veya statik hat seçin. Statik hat, reciprocator sabit olduğundan, tabancaların 0 hareket aralığı ve 0 hızı vardır.",
      "guns": "tabancalar",
      "Do you have a recycling powder system?":"Geri dönüşüm toz sisteminiz var mı?",
      "Yes":"Evet",
      "No":"Hayır",
      "Recycling System Efficiency":"Geri Dönüşüm Sistemi Verimliliği",
      "Number of Columns":"Sütun Sayısı",
      "Column Distance":"Sütun Mesafesi",
      "Column Shift": "Sütun Kaydırma",
      "Number of Rows": "Satır Sayısı",
      "Row Distance": "Satır Mesafesi",
      "Row Shift": "Satır Kaydırma",
      "Advanced Gun Layout": "Gelişmiş Tabanca Düzeni",
      "6th guns column":"6. tabanca sütunu",
      "7th guns column":"7. tabanca sütunu",
      "8th guns column":"8. tabanca sütunu",
      "9th guns column":"9. tabanca sütunu",
      "10th guns column":"10. tabanca sütunu",
      "Total number of guns of the 6th vertical stack":"6. dikey yığının toplam tabanca sayısı",
      "Total number of guns of the 7th vertical stack":"7. dikey yığının toplam tabanca sayısı",
      "Total number of guns of the 8th vertical stack":"8. dikey yığının toplam tabanca sayısı",
      "Total number of guns of the 9th vertical stack":"9. dikey yığının toplam tabanca sayısı",
      "Total number of guns of the 10th vertical stack":"10. dikey yığının toplam tabanca sayısı",
      "Gun to gun distance of the 6th vertical stack":"6. dikey yığının tabancadan tabancaya mesafesi",
      "Gun to gun distance of the 7th vertical stack":"7. dikey yığının tabancadan tabancaya mesafesi",
      "Gun to gun distance of the 8th vertical stack":"8. dikey yığının tabancadan tabancaya mesafesi",
      "Gun to gun distance of the 9th vertical stack":"9. dikey yığının tabancadan tabancaya mesafesi",
      "Gun to gun distance of the 10th vertical stack":"10. dikey yığının tabancadan tabancaya mesafesi",
      "Input the vertical distance between guns of the sixth column of guns, i.e the sixth column from the left":"Altıncı tabanca sütununun tabancaları arasındaki dikey mesafeyi girin, yani soldan altıncı sütun",
      "Input the vertical distance between guns of the seventh column of guns, i.e the seventh column from the left":"Yedinci tabanca sütununun tabancaları arasındaki dikey mesafeyi girin, yani soldan yedinci sütun",
      "Input the vertical distance between guns of the eighth column of guns, i.e the eighth column from the left":"Sekizinci tabanca sütununun tabancaları arasındaki dikey mesafeyi girin, yani soldan sekizinci sütun",
      "Input the vertical distance between guns of the ninth column of guns, i.e the ninth column from the left":"Dokuzuncu tabanca sütununun tabancaları arasındaki dikey mesafeyi girin, yani soldan dokuzuncu sütun",
      "Input the vertical distance between guns of the tenth column of guns, i.e the tenth column from the left":"Onuncu tabanca sütununun tabancaları arasındaki dikey mesafeyi girin, yani soldan onuncu sütun",
      "Horizontal distance between the 5th and 6th stacks":"5. ve 6. yığınlar arasındaki yatay mesafe",
      "Horizontal distance between the 6th and 7th stacks":"6. ve 7. yığınlar arasındaki yatay mesafe",
      "Horizontal distance between the 7th and 8th stacks":"7. ve 8. yığınlar arasındaki yatay mesafe",
      "Horizontal distance between the 8th and 9th stacks":"8. ve 9. yığınlar arasındaki yatay mesafe",
      "Horizontal distance between the 9th and 10th stacks":"9. ve 10. yığınlar arasındaki yatay mesafe",
      "Input the horizontal distance measured between the fifth column and the sixth column of guns":"Beşinci sütun ile altıncı tabanca sütunu arasında ölçülen yatay mesafeyi girin",
      "Input the horizontal distance measured between the sixth column and the seventh column of guns":"Altıncı sütun ile yedinci tabanca sütunu arasında ölçülen yatay mesafeyi girin",
      "Input the horizontal distance measured between the seventh column and the eighth column of guns":"Yedinci sütun ile sekizinci tabanca sütunu arasında ölçülen yatay mesafeyi girin",
      "Input the horizontal distance measured between the eighth column and the ninth column of guns":"Sekizinci sütun ile dokuzuncu tabanca sütunu arasında ölçülen yatay mesafeyi girin",
      "Input the horizontal distance measured between the ninth column and the tenth column of guns":"Dokuzuncu sütun ile onuncu tabanca sütunu arasında ölçülen yatay mesafeyi girin",
      "6th vertical guns stack height offset":"6. dikey tabanca yığını yükseklik ofseti",
      "7th vertical guns stack height offset":"7. dikey tabanca yığını yükseklik ofseti",
      "8th vertical guns stack height offset":"8. dikey tabanca yığını yükseklik ofseti",
      "9th vertical guns stack height offset":"9. dikey tabanca yığını yükseklik ofseti",
      "10th vertical guns stack height offset":"10. dikey tabanca yığını yükseklik ofseti",
      "Switch to advanced Line configurator": "Gelişmiş Konfigürasyona Geç",
      "Switch to standard Line configurator": "Standart Konfigürasyona Geç",
      "Horizontal layout is not allowed for Fixed Lines": "Statik Hatlar için Yatay düzen izin verilmez",
      "For Mixed Lines the number of gun columns must be smaller than":"Karışık Hatlar için tabanca sütunlarının sayısı aşağıdakilerden küçük olmalıdır",
      "Attention!":"Dikkat!",
      "Changing to standard configuration might modify your line. Do you want to continue?":"Standart konfigürasyona geçmek hatınızı değiştirebilir. Devam etmek istiyor musunuz?",
      "Cancel":"İptal",
      "Total Guns Column 6": "Toplam Tabanca Sütun 6",
      "Total Guns Column 7": "Toplam Tabanca Sütun 7",
      "Total Guns Column 8": "Toplam Tabanca Sütun 8",
      "Total Guns Column 9": "Toplam Tabanca Sütun 9",
      "Total Guns Column 10": "Toplam Tabanca Sütun 10",
      "Gun to Gun Distance Column 6": "Tabancadan Tabancaya Mesafe Sütun 6",
      "Gun to Gun Distance Column 7": "Tabancadan Tabancaya Mesafe Sütun 7",
      "Gun to Gun Distance Column 8": "Tabancadan Tabancaya Mesafe Sütun 8",
      "Gun to Gun Distance Column 9": "Tabancadan Tabancaya Mesafe Sütun 9",
      "Gun to Gun Distance Column 10": "Tabancadan Tabancaya Mesafe Sütun 10",
      "Gun Column Distance 6": "Tabanca Kolon Mesafesi 6",
      "Gun Column Distance 7": "Tabanca Kolon Mesafesi 7",
      "Gun Column Distance 8": "Tabanca Kolon Mesafesi 8",
      "Gun Column Distance 9": "Tabanca Kolon Mesafesi 9",
      "Gun Column Distance 10": "Tabanca Kolon Mesafesi 10",
      "Gun Column Vertical Offset 6": "Tabanca Kolon Dikey Ofset 6",
      "Gun Column Vertical Offset 7": "Tabanca Kolon Dikey Ofset 7",
      "Gun Column Vertical Offset 8": "Tabanca Kolon Dikey Ofset 8",
      "Gun Column Vertical Offset 9": "Tabanca Kolon Dikey Ofset 9",
      "Gun Column Vertical Offset 10": "Tabanca Kolon Dikey Ofset 10",
      "Number of columns cannot be greater than": "Sütun sayısı aşağıdakilerden daha büyük olamaz",
      "Number of rows cannot be greater than": "Satır sayısı aşağıdakilerden daha büyük olamaz",
      "Row distance must be greater than": "Satır mesafesi aşağıdakilerden daha büyük olmalıdır",
      "Row distance must be smaller than": "Satır mesafesi aşağıdakilerden daha küçük olmalıdır",
      "Column distance must be greater than": "Sütun mesafesi aşağıdakilerden daha büyük olmalıdır",
      "Column distance must be smaller than": "Sütun mesafesi aşağıdakilerden daha küçük olmalıdır",
      "Column Shift must be greater than": "Sütun kaydırma aşağıdakilerden daha büyük olmalıdır",
      "Column Shift must be smaller than": "Sütun kaydırma aşağıdakilerden daha küçük olmalıdır",
      "Row Shift must be greater than": "Satır kaydırma aşağıdakilerden daha büyük olmalıdır",
      "Row Shift must be smaller than": "Satır kaydırma aşağıdakilerden daha küçük olmalıdır",
      "Reciprocator Min Velocity cannot be greater than or equal to the Reciprocator Max Velocity": "Reciprocator Min Hızı, Reciprocator Maksimum Hızından büyük veya eşit olamaz",
      "The number of guns must be smaller than": "Tabanca sayısı aşağıdakilerden daha küçük olmalıdır",
      "Lines with 1 gun per column are not allowed": "Sütun başına 1 tabanca içeren satırlara izin verilmez",
      "Advanced Gun Parameters": "Gelişmiş Tabanca Parametreleri",  
      "The gun's maximum acceleration value is outside the permissible range. Please enter a value within the allowed limits.":  "Tabancanın maksimum ivme değeri izin verilen aralığın dışındadır. Lütfen izin verilen sınırlar içinde bir değer girin.",
      "New Optimization": "Yeni Optimizasyon",
    },
    "ZH": {
      "Horizontal distance between two adjacent guns or stacks of guns.": "两个相邻火炮或火炮组之间的水平距离。",
      "Number of guns or vertical stacks of guns, next to each other.": "相邻火炮或垂直火炮堆的数量。",
      "Height offset between two adjacent guns or stacks of guns.": "两个相邻火炮或火炮组之间的高度偏移。",
      "Number of guns on top of each other.": "相邻火炮顶部的火炮数量。",
      "Vertical distance between two guns on top of each other.": "相邻两门火炮之间的垂直距离。",
      "Horizontal distance offset between even and odd rows.": "偶数行和奇数行之间的水平距离偏移。",
      "Minimum gun velocity with activated reciprocator": "启动往复机时的最小喷枪速度",
      "Maximum gun velocity of the reciprocator": "往复机的最大炮速",
      "Maximum gun acceleration of the reciprocator": "往复机的最大喷枪加速度",
      "The minimum is": "最小值为",
      "and the maximum is": "，最大值为",
      "Static Calibration": "静态校准",
      "Gun Max Velocity must be greater than Gun Min Velocity by at least": "喷枪最大速度必须大于喷枪最小速度至少",
      "Gun column height offsets": "枪柱高度偏移",
      "Nozzle rotation angle": "喷嘴旋转角度",
      "Total Guns per column": "每列手枪数量",
      "Fixed Mixed (non moving with multiple columns of guns)": "静态混合（多列手枪，不移动）",
      "Line changes saved": "保存的线路更改",
      "Line created": "创建线路",
      "was saved successfully": "已成功保存行",
      "The following fields are required:": "以下字段为必填字段：",
      "Air Parameter Name": "空气参数名称",
      "Air Parameter Units": "空气参数单位",
      "Number of Gun Columns": "手枪列数",
      "Total Guns Column 1": "手枪总数 第 1 列",
      "Total Guns Column 2": "手枪总数 第 2 列",
      "Total Guns Column 3": "手枪总数 第 3 列",
      "Total Guns Column 4": "手枪总数 第 4 栏",
      "Total Guns Column 5": "手枪总数 第 5 栏",
      "Gun to Gun Distance Column 1": "手枪到手枪距离 第 1 栏",
      "Gun to Gun Distance Column 2": "手枪到手枪距离 第 2 栏",
      "Gun to Gun Distance Column 3": "手枪到手枪距离第 3 栏",
      "Gun to Gun Distance Column 4": "手枪至手枪距离 第 4 栏",
      "Gun to Gun Distance Column 5": "手枪至手枪距离 第5列",
      "Gun Column Distance 1": "手枪列距离 1",
      "Gun Column Distance 2": "手枪列距离 2",
      "Gun Column Distance 3": "手枪列距离 3",
      "Gun Column Distance 4": "手枪列距离 4",
      "Gun Column Distance 5": "手枪列距离 5",
      "Gun Column Vertical Offset 1": "手枪立柱垂直偏移 1",
      "Gun Column Vertical Offset 2": "手枪列垂直偏移 2",
      "Gun Column Vertical Offset 3": "手枪列垂直偏移 3",
      "Gun Column Vertical Offset 4": "手枪立柱垂直偏移 4",
      "Gun Column Vertical Offset 5": "手枪立柱垂直偏移 5",
      "Input value out of range": "输入值超出范围",
      "Gun to gun distance cannot be 0": "手枪到手枪的距离不能为 0",
      "Gun to Gun distance must be greater than": "手枪到手枪的距离必须大于",
      "Gun to Gun distance must be smaller than": "手枪到手枪的距离必须小于",
      "Gun max movement range cannot be 0": "手枪最大移动范围不能为 0",
      "Gun max movement range must be greater than": "手枪最大移动范围必须大于",
      "Gun max movement range must be smaller than": "手枪最大移动范围必须小于",
      "Gun Min Velocity cannot be 0 for Horizontal Gun Layout.": "水平手枪布局的手枪最小速度不能为 0。",
      "Gun Min Velocity must be greater than": "手枪最小速度必须大于",
      "Gun Max Velocity cannot be 0": "手枪最大速度不能为 0",
      "Gun Min Velocity cannot be greater than or equal to the Gun Max Velocity": "手枪最小速度不能大于或等于手枪最大速度",
      "Gun max acceleration": "手枪最大加速度",
      "Missing Required Fields": "缺少必填字段",
      "Vertical": "垂直",
      "Horizontal": "横向",
      "Mixed (Vertical and Horizontal Hybrid)": "混合（纵向和横向混合）",
      "Simple": "简单",
      "Fixed": "静态",
      "Advanced": "高级",
      "Which type of Powder Model do you want to create?": "您想创建哪种类型的粉末模型？",
      "Please select one": "请选择一项",
      "Add new Line": "添加新",
      "Edit Line": "编辑线",
      "Line Details": "线路详细信",
      "Name": "名",
      "Line Properties": "生产线属",
      "Line Manufacturer": "生产线制造商",
      "Air Parameter Name e.g. [DosageAir]": "空气参数名称，例如 [DosageAir]",
      "Air Parameter Units e.g. [bar]": "空气参数单位，例如 [bar]",
      "Pump Type": "泵类型",
      "Gun min velocity": "手枪最大速度",
      "Gun max velocity": "手枪布",
      "Gun Layout": "手枪布",
      "Gun max movement range": "手枪最大移动范",
      "Total Guns": "手枪总数",
      "Gun to gun distance": "手枪与手枪之间的距",
      "Total number of vertical gun stacks or columns": "垂直手枪堆叠或列的总数",
      "columns of guns": "手枪列数",
      "1st guns column": "第一列手",
      "Total number of guns of the 1st vertical stack": "第 1 纵列的手枪总数 手枪到手枪的距",
      "Gun to gun distance of the 1st vertical stack": "第 1 层垂直叠加的手枪到手枪距离",
      "2nd guns column": "第 2 列手枪",
      "Total number of guns of the 2nd vertical stack": "第 2 垂直叠加手枪总数",
      "Gun to gun distance of the 2nd vertical stack": "第 2 层垂直叠加的手枪到手枪距离",
      "Horizontal distance between 1st and 2nd vertical stacks": "第 1 层和第 2 层垂直堆垛之间的水平距离",
      "2nd vertical guns stack height offset": "第 2 组垂直手枪高度偏移",
      "3rd guns column": "第 3 列手",
      "Total number of guns of the 3rd vertical stack": "第 3 层垂直叠放的手枪总数",
      "Gun to gun distance of the 3rd vertical stack": "第 3 层垂直叠放手枪与手枪之间的距",
      "Horizontal distance between 2nd and 3rd vertical stacks": "第 2 和第 3 纵列之间的水平距",
      "3rd vertical guns stack height offset": "第 3 层垂直叠放手枪高度偏移",
      "4th guns column": "第 4 列手",
      "Total number of guns of the 4th vertical stack": "第 4 垂直叠放手枪总数",
      "Gun to gun distance of the 4th vertical stack": "第 4 层垂直叠加的手枪到手枪距离",
      "Horizontal distance between 3rd and 4th vertical stacks": "第 3 和第 4 纵向枪堆之间的水平距离",
      "4th vertical guns stack height offset": "第 4 层垂直叠放手枪高度偏",
      "5th guns column": "第 5 列手",
      "Total number of guns of the 5th vertical stack": "第 5 层垂直叠加的手枪总数",
      "Gun to gun distance of the 5th vertical stack": "第 5 层垂直叠加的手枪到手枪距离",
      "Horizontal distance between the 4th and 5th stacks": "第 4 层和第 5 层之间的水平距离",
      "5th vertical guns stack height offset": "第 5 垂直手枪堆叠高度偏移",
      "Line Details are not editable. If you want to make changes to your line, contact us at": "线条细节 不可编辑。如果要更改线路，请联系我们",
      "Create Line": "创建线条",
      "Line Preview": "线条预览",
      "New Powder Model": "新粉末模型",
      "Go Back Home": "返回主页",
      "Select Vertical if the guns are arranged in one on top of each other in a single column. Select Horizontal if they are arranged one next to each other in a single row. Select Mixed for more complex arrangements that combine multiple vertical stacks or columns.": "如果手枪在一列中一个挨着一个排列，请选择 竖排。如果手枪在单行中一个挨着一个排列，则选择水平排列。如果要组合多个垂直堆叠或列的更复杂排列，请选择 混合。",
      "The maximum movement range in the vertical axis that one gun can move, i.e. distance that one gun can move from its highest to its lowest position. By default is set to the gun to gun distance.": "一把手枪在垂直轴上的最大移动范围，即一把手枪从最高位置移动到最低位置的距离。默认设置为手枪到手枪的距离。",
      "A mixed hybrid line is composed of multiple vertical stacks or columns of guns. If your Line has only 1 vertical gun column, then select Vertical instead of Mixed on the Gun Layout option.": "混合混合型防线由多个垂直叠放或纵列的手枪组成。如果您的生产线只有一列垂直手枪，则在 手枪布局 选项中选择 垂直 而不是 混合。",
      "Input the vertical distance between guns of the 1st column of guns, the column that is most to the left.": "输入第一列手枪（最靠左的一列）之间的垂直距离。",
      "Input the vertical distance between guns of the second column of guns, the second column from the left.": "输入第二列手枪（从左数第二列）之间的垂直距离。",
      "Input the horizontal distance measured between the first column and the second column of guns.": "输入第一列和第二列手枪之间的水平距离。",
      "Input the vertical guns stack height offset as the difference in respect to the previous one on the left. If the gun stacks are at equal height leave the default 0 offset. A positive value means the column is higher than the previous one, and a negative means the second stack is shorter than the first one.": "输入垂直手枪堆叠高度偏移，即与左侧前一列手枪堆叠高度的差值。如果手枪堆高度相等，则默认偏移量为 0。正值表示该列比前一列高，负值表示第二列比第一列矮。",
      "Input the vertical distance between guns of the third column of guns, the third column from the left.": "输入从左数起第三列手枪之间的垂直距离。",
      "Input the horizontal distance measured between the second column and the third column of guns": "输入第二列和第三列手枪之间的水平距离",
      "Input the vertical distance between guns of the fourth column of guns, i.e the fourth column from the left.": "输入第四列手枪之间的垂直距离，即左起第四列。",
      "Input the horizontal distance measured between the third column and the fourth column of guns": "输入在第三列和第四列手枪之间测得的水平距离",
      "Input the vertical distance between guns of the fifth column of guns, i.e the fifth column from the left": "输入第五列手枪之间的垂直距离，即第五列从左起的距离",
      "Input the horizontal distance measured between the fourth column and the fifth column of guns": "输入第四列和第五列手枪之间的水平距离",
      "Other": "其他",
      "Dense Phase":"Dense Phase",
      "Injector / Venturi":"Injector / Venturi",
      "Save Changes":"保存更改",
      "No Changes to Save":"没有更改保存",
      "Wagner":"Wagner",
      "MS-Carlisle":"MS-Carlisle",
      "GEMA":"GEMA",
      "Nordson":"Nordson",
      "Fixed": "静态",
      "Dynamic": "动态",
      "Line Type": "线路类型",
      "Select": "选择",
      "Select moving or fixed line. A fixed line has a static reciprocator that does not move, hence guns have 0 movement range and 0 velocity.": "选择移动或静态线。静态线具有固定的往复器，因此手枪的移动范围和速度为 0。",
      "guns": "手枪",
      "Do you have a recycling powder system?":"您有回收粉末系统吗？",
      "Yes":"是",
      "No":"不",
      "Recycling System Efficiency":"回收系统效率",
      "Number of Columns":"列数",
      "Column Distance":"列距离",
      "Column Shift": "列移位",
      "Number of Rows": "行数",
      "Row Distance": "行距离",
      "Row Shift": "行移位",
      "Advanced Gun Layout": "高级手枪布局",
      "6th guns column":"第 6 列手枪",
      "7th guns column":"第 7 列手枪",
      "8th guns column":"第 8 列手枪",
      "9th guns column":"第 9 列手枪",
      "10th guns column":"第 10 列手枪",
      "Total number of guns of the 6th vertical stack":"第 6 垂直叠放手枪总数",
      "Total number of guns of the 7th vertical stack":"第 7 垂直叠放手枪总数",
      "Total number of guns of the 8th vertical stack":"第 8 垂直叠放手枪总数",
      "Total number of guns of the 9th vertical stack":"第 9 垂直叠放手枪总数",
      "Total number of guns of the 10th vertical stack":"第 10 垂直叠放手枪总数",
      "Gun to gun distance of the 6th vertical stack":"第 6 垂直叠放手枪到手枪的距离",
      "Gun to gun distance of the 7th vertical stack":"第 7 垂直叠放手枪到手枪的距离",
      "Gun to gun distance of the 8th vertical stack":"第 8 垂直叠放手枪到手枪的距离",
      "Gun to gun distance of the 9th vertical stack":"第 9 垂直叠放手枪到手枪的距离",
      "Gun to gun distance of the 10th vertical stack":"第 10 垂直叠放手枪到手枪的距离",
      "Input the vertical distance between guns of the sixth column of guns, i.e the sixth column from the left":"输入第六列手枪之间的垂直距离，即从左起第六列",
      "Input the vertical distance between guns of the seventh column of guns, i.e the seventh column from the left":"输入第七列手枪之间的垂直距离，即从左起第七列",
      "Input the vertical distance between guns of the eighth column of guns, i.e the eighth column from the left":"输入第八列手枪之间的垂直距离，即从左起第八列",
      "Input the vertical distance between guns of the ninth column of guns, i.e the ninth column from the left":"输入第九列手枪之间的垂直距离，即从左起第九列",
      "Input the vertical distance between guns of the tenth column of guns, i.e the tenth column from the left":"输入第十列手枪之间的垂直距离，即从左起第十列",
      "Horizontal distance between the 5th and 6th stacks":"第 5 和第 6 堆之间的水平距离",
      "Horizontal distance between the 6th and 7th stacks":"第 6 和第 7 堆之间的水平距离",
      "Horizontal distance between the 7th and 8th stacks":"第 7 和第 8 堆之间的水平距离",
      "Horizontal distance between the 8th and 9th stacks":"第 8 和第 9 堆之间的水平距离",
      "Horizontal distance between the 9th and 10th stacks":"第 9 和第 10 堆之间的水平距离",
      "Input the horizontal distance measured between the fifth column and the sixth column of guns":"输入第五列和第六列手枪之间测得的水平距离",
      "Input the horizontal distance measured between the sixth column and the seventh column of guns":"输入第六列和第七列手枪之间测得的水平距离",
      "Input the horizontal distance measured between the seventh column and the eighth column of guns":"输入第七列和第八列手枪之间测得的水平距离",
      "Input the horizontal distance measured between the eighth column and the ninth column of guns":"输入第八列和第九列手枪之间测得的水平距离",
      "Input the horizontal distance measured between the ninth column and the tenth column of guns":"输入第九列和第十列手枪之间测得的水平距离",
      "6th vertical guns stack height offset":"第 6 垂直手枪堆叠高度偏移",
      "7th vertical guns stack height offset":"第 7 垂直手枪堆叠高度偏移",
      "8th vertical guns stack height offset":"第 8 垂直手枪堆叠高度偏移",
      "9th vertical guns stack height offset":"第 9 垂直手枪堆叠高度偏移",
      "10th vertical guns stack height offset":"第 10 垂直手枪堆叠高度偏移",
      "Switch to advanced Line configurator": "切换到高级配置",
      "Switch to standard Line configurator": "切换到标准配置",
      "Horizontal layout is not allowed for Fixed Lines": "静态线不允许水平布局",
      "For Mixed Lines the number of gun columns must be smaller than":"对于混合行，炮列数必须小于",
      "Attention!":"注意！",
      "Changing to standard configuration might modify your line. Do you want to continue?":"更改为标准配置可能会修改您的线路。您要继续吗？",
      "Cancel":"取消",
      "Total Guns Column 6": "第 6 列手枪总数",
      "Total Guns Column 7": "第 7 列手枪总数",
      "Total Guns Column 8": "第 8 列手枪总数",
      "Total Guns Column 9": "第 9 列手枪总数",
      "Total Guns Column 10": "第 10 列手枪总数",
      "Gun to Gun Distance Column 6": "第 6 列手枪到手枪的距离",
      "Gun to Gun Distance Column 7": "第 7 列手枪到手枪的距离",
      "Gun to Gun Distance Column 8": "第 8 列手枪到手枪的距离",
      "Gun to Gun Distance Column 9": "第 9 列手枪到手枪的距离",
      "Gun to Gun Distance Column 10": "第 10 列手枪到手枪的距离",
      "Gun Column Distance 6": "第 6 列手枪列距离",
      "Gun Column Distance 7": "第 7 列手枪列距离",
      "Gun Column Distance 8": "第 8 列手枪列距离",
      "Gun Column Distance 9": "第 9 列手枪列距离",
      "Gun Column Distance 10": "第 10 列手枪列距离",
      "Gun Column Vertical Offset 6": "第 6 列手枪列垂直偏移",
      "Gun Column Vertical Offset 7": "第 7 列手枪列垂直偏移",
      "Gun Column Vertical Offset 8": "第 8 列手枪列垂直偏移",
      "Gun Column Vertical Offset 9": "第 9 列手枪列垂直偏移",
      "Gun Column Vertical Offset 10": "第 10 列手枪列垂直偏移",
      "Number of columns cannot be greater than": "列数不能大于",
      "Number of rows cannot be greater than": "行数不能大于",
      "Row distance must be greater than": "行距离必须大于",
      "Row distance must be smaller than": "行距离必须小于",
      "Column distance must be greater than": "列距离必须大于",
      "Column distance must be smaller than": "列距离必须小于",
      "Column Shift must be greater than": "列移位必须大于",
      "Column Shift must be smaller than": "列移位必须小于",
      "Row Shift must be greater than": "行移位必须大于",
      "Row Shift must be smaller than": "行移位必须小于",
      "Reciprocator Min Velocity cannot be greater than or equal to the Reciprocator Max Velocity": "往复器最小速度不能大于或等于往复器最大速度",
      "The number of guns must be smaller than": "手枪数量必须小于",
      "Lines with 1 gun per column are not allowed": "不允许每列有 1 支枪的行",
      "Advanced Gun Parameters": "高级枪支参数",  
      "The gun's maximum acceleration value is outside the permissible range. Please enter a value within the allowed limits.":  "枪支的最大加速度值超出了允许的范围。请在允许的限制范围内输入一个值。",
      "New Optimization": "新优化",
    },
  }
</i18n>
