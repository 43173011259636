<template>
  <div class="row justify-content-between mt-2">
    <div
      v-if="showStatistics(benchmarkThicknessMeasurements)"
      class="card col mt-2 text-center"
      style="height: auto;"
    >
      <h6 class="font-weight-bolder">Initial Benchmark</h6>
      <p class="small mt-0 mb-0 px-0">Std Dev: {{ getThicknessMeasurementsStd(benchmarkThicknessMeasurements) }}</p>
      <p class="small mt-0 mb-0 px-0">Mean: {{ getThicknessMeasurementsMean(benchmarkThicknessMeasurements) }}</p>
      <p class="small mt-0 mb-0 px-0">Min: {{ measurementsMin(benchmarkThicknessMeasurements) }}</p>
      <p class="small mt-0 mb-0 px-0">Max: {{ measurementsMax(benchmarkThicknessMeasurements) }}</p>
    </div>
    <div
      v-if="showStatistics(powderoutputThicknessMeasurements)"
      class="card col text-center m-1 mt-2"
      style="height: auto;"
    >
      <h6 class="font-weight-bolder">Guns Equalization</h6>
      <p
        class="small mb-0 px-0"
        :class="{
          'font-weight-bolder':
        getStdDifferencePercentage(powderoutputThicknessMeasurements, benchmarkThicknessMeasurements) !== null &&
        !reciprocatorThicknessMeasurements &&
        !powdersavingThicknessMeasurements,
        }"
      >
        Std Dev: {{ getThicknessMeasurementsStd(powderoutputThicknessMeasurements) }}
        <span
          v-if="
        getStdDifferencePercentage(powderoutputThicknessMeasurements, benchmarkThicknessMeasurements) !== null &&
        !reciprocatorThicknessMeasurements &&
        !powdersavingThicknessMeasurements
          "
        >
          <span class="badge bg-success text-dark">
        {{ getStdDifferencePercentage(powderoutputThicknessMeasurements, benchmarkThicknessMeasurements) }}%
          </span>
        </span>
      </p>
      <p class="small mt-0 mb-0 px-0">Mean: {{ getThicknessMeasurementsMean(powderoutputThicknessMeasurements) }}</p>
      <p class="small mt-0 mb-0 px-0">Min: {{ measurementsMin(powderoutputThicknessMeasurements) }}</p>
      <p class="small mt-0 mb-0 px-0">Max: {{ measurementsMax(powderoutputThicknessMeasurements) }}</p>
    </div>
    <div
      v-if="showStatistics(reciprocatorThicknessMeasurements)"
      class="card col mt-2 text-center m-1"
      style="height: auto;"
    >
      <h6 class="font-weight-bolder">Reciprocator Optimization</h6>
      <p
        class="small mt-0 mb-0 px-0"
        :class="{
          'font-weight-bolder':
        getStdDifferencePercentage(reciprocatorThicknessMeasurements, benchmarkThicknessMeasurements) !== null &&
        powderoutputThicknessMeasurements &&
        !powdersavingThicknessMeasurements,
        }"
      >
        Std Dev: {{ getThicknessMeasurementsStd(reciprocatorThicknessMeasurements) }}
        <span
          v-if="
        getStdDifferencePercentage(reciprocatorThicknessMeasurements, benchmarkThicknessMeasurements) !== null &&
        powderoutputThicknessMeasurements &&
        !powdersavingThicknessMeasurements
          "
        >
          <span class="badge bg-success text-dark">
        {{ getStdDifferencePercentage(reciprocatorThicknessMeasurements, benchmarkThicknessMeasurements) }}%
          </span>
        </span>
      </p>
      <p class="small mt-0 mb-0 px-0">Mean: {{ getThicknessMeasurementsMean(reciprocatorThicknessMeasurements) }}</p>
      <p class="small mt-0 mb-0 px-0">Min: {{ measurementsMin(reciprocatorThicknessMeasurements) }}</p>
      <p class="small mt-0 mb-0 px-0">Max: {{ measurementsMax(reciprocatorThicknessMeasurements) }}</p>
    </div>
    <div
      v-if="showStatistics(powdersavingThicknessMeasurements)"
      class="card col mt-2 text-center m-1"
      style="height: auto;"
    >
      <h6 class="font-weight-bolder mb-1">Powder Saving</h6>
      <p
        class="small mt-0 mb-0 px-0"
        :class="{
          'font-weight-bolder':
        getStdDifferencePercentage(powdersavingThicknessMeasurements, benchmarkThicknessMeasurements) !== null,
        }"
      >
        Std Dev: {{ getThicknessMeasurementsStd(powdersavingThicknessMeasurements) }}
        <span
          v-if="
        getStdDifferencePercentage(powdersavingThicknessMeasurements, benchmarkThicknessMeasurements) !== null &&
        powderoutputThicknessMeasurements &&
        reciprocatorThicknessMeasurements
          "
        >
          <span class="badge bg-success text-dark">
        {{ getStdDifferencePercentage(powdersavingThicknessMeasurements, benchmarkThicknessMeasurements) }}%
          </span>
        </span>
      </p>
      <p class="small mt-0 mb-0 px-0">Mean: {{ getThicknessMeasurementsMean(powdersavingThicknessMeasurements) }}</p>
      <p class="small mt-0 mb-0 px-0">Min: {{ measurementsMin(powdersavingThicknessMeasurements) }}</p>
      <p class="small mt-0 mb-0 px-0">Max: {{ measurementsMax(powdersavingThicknessMeasurements) }}</p>
    </div>
  </div>
</template>

<script>
import {
  getThicknessMeasurementsMean,
  getThicknessMeasurementsStd,
  measurementsMin,
  measurementsMax,
  showStatistics,
  getStdDifferencePercentage,
} from "../utils/utils";

export default {
  name: "ThicknessMeasurementStatistic",

  props: {
    benchmarkThicknessMeasurements: {
      type: Array,
      required: true,
      default: null,
    },
    powderoutputThicknessMeasurements: {
      type: Array,
      required: false,
      default: null,
    },
    reciprocatorThicknessMeasurements: {
      type: Array,
      required: false,
      default: null,
    },
    powdersavingThicknessMeasurements: {
      type: Array,
      required: false,
      default: null,
    },
  },
  methods: {
    getThicknessMeasurementsMean,
    getThicknessMeasurementsStd,
    measurementsMin,
    measurementsMax,
    showStatistics,
    getStdDifferencePercentage,
  },
};
</script>
