<template>
    <td
      class="font-weight-bold text-center custom-border"
      >
      <span class="my-2 text-xs">{{ dateTime }}</span>
    </td>
    <td
      class="text-xs font-weight-bold text-center custom-border"
      style="cursor: pointer"
      @click="handleClick('/applications/onsite/benchmarkandtarget/' + lineId +  '/' + visitId)"
    >
      <div class="d-flex align-items-center justify-content-center">
        <soft-button
          :color="colorize(benchmarkChecked)"
          variant="outline"
          class="btn-icon-only btn-rounded mb-0 btn-sm d-flex align-items-center justify-content-center"
        >
          <i :class="setIcon(benchmarkChecked)" aria-hidden="true"></i>
        </soft-button>
      </div>
    </td>
    <td
      class="text-xs font-weight-bold text-center custom-border"
      style="cursor: pointer"
      @click="handleClick('/applications/onsite/healthcheck/' + lineId + '/' + visitId)"
      >
      <div class="d-flex justify-content-center">
        <soft-button
          :color="colorize(healthChecked)"
          variant="outline"
          class="btn-icon-only btn-rounded mb-0 btn-sm d-flex align-items-center justify-content-center"
        >
          <i :class="setIcon(healthChecked)" aria-hidden="true"></i>
        </soft-button>
      </div>
    </td>
    <td
      class="text-xs font-weight-bold text-center custom-border"
      style="cursor: pointer"
      @click="handleClick('/applications/onsite/powderoutput/' + lineId + '/' + visitId)"
      >
      <div class="d-flex align-items-center justify-content-center">
        <soft-button
          :color="colorize(powderOutputChecked)"
          variant="outline"
          class="btn-icon-only btn-rounded mb-0 btn-sm d-flex align-items-center justify-content-center"
        >
          <i :class="setIcon(powderOutputChecked)" aria-hidden="true"></i>
        </soft-button>
      </div>
    </td>
    <td
      class="text-xs font-weight-bold text-center custom-border"
      style="cursor: pointer"
      @click="handleClick(`/applications/onsite/powdermodels/${lineId}/${visitId}`)"
      >
      <div class="d-flex align-items-center justify-content-center">
        <soft-button
          :color="colorize(sprayPatternChecked)"
          variant="outline"
          class="btn-icon-only btn-rounded mb-0 btn-sm d-flex align-items-center justify-content-center"
        >
          <i :class="setIcon(sprayPatternChecked)" aria-hidden="true"></i>
        </soft-button>
      </div>
    </td>
    <td
      class="text-xs font-weight-bold text-center custom-border"
      style="cursor: pointer"
      @click="handleClick('/applications/onsite/reciprocatoroptimizationslibrary/' + lineId + '/' + visitId)"
      >
      <div class="d-flex align-items-center justify-content-center">
        <soft-button
          :color="colorize(reciprocatorChecked)"
          variant="outline"
          class="btn-icon-only btn-rounded mb-0 btn-sm d-flex align-items-center justify-content-center"
        >
          <i :class="setIcon(reciprocatorChecked)" aria-hidden="true"></i>
        </soft-button>
      </div>
    </td>
    <td
      class="text-xs font-weight-bold text-center"
      style="cursor: pointer"
      @click="handleClick('/applications/onsite/powdersavingonsite/' + lineId + '/' + visitId)"
    >
      <div class="d-flex align-items-center justify-content-center">
        <soft-button
          :color="colorize(powdersavingChecked)"
          variant="outline"
          class="btn-icon-only btn-rounded mb-0 btn-sm d-flex align-items-center justify-content-center"
        >
          <i :class="setIcon(powdersavingChecked)" aria-hidden="true"></i>
        </soft-button>
      </div>
    </td>
</template>

<script>
import SoftButton from "@/components/SoftButton.vue";

export default {
  name: "VisitRow",
  components: {
    SoftButton,
  },
  props: {
    dateTime: {
      type: String,
      default: "",
    },
    benchmarkChecked: {
      type: String,
      default: "Pending",
    },
    healthChecked: {
      type: String,
      default: "Pending",
    },
    powderOutputChecked: {
      type: String,
      default: "Pending",
    },
    sprayPatternChecked: {
      type: String,
      default: "Pending",
    },
    reciprocatorChecked: {
      type: String,
      default: "Pending",
    },
    powdersavingChecked: {
      type: String,
      default: "Pending",
    },
    lineId: {
      type: String,
      default: "",
    },
    visitId: {
      type: Number,
      default: null,
    },
    powderId: {
      type: Number,
      default: null,
    },
  },
  data() {
    this.$i18n.locale = this.$store.state.user_data.language;
    return {};
  },
  watch: {
    "$store.state.user_data.language"() {
      this.$i18n.locale = this.$store.state.user_data.language;
    },
  },
  methods: {
    handleClick(targetPath) {
      if (this.$router.currentRoute._value.path !== targetPath) {
        this.$store.state.isLoading = true;
        this.$router.push(targetPath);
      } 
    },
    colorize(value) {
      if (value == "Completed") {
        return "success";
      } else if (value == "In Progress") {
        return "warning";
      } else {
        return "danger";
      }
    },
    setIcon(value) {
      if (value == "Completed") {
        return "fas fa-check";
      } else if (value == "In Progress") {
        return "fas fa-spinner";
      } else {
        return "fas fa-times";
      }
    },
  },
};
</script>

<style scoped>
td {
  text-align: center;
  vertical-align: middle;
}
.custom-border {
    position: relative;
  }
  .custom-border::after {
    content: '';
    position: absolute;
    right: 0;
    top: 50%;
    height: 50%; /* Adjust the height to make the border shorter */
    border-right: 1px solid rgba(128, 128, 128, 0.3); /* Light gray with low opacity */
    transform: translateY(-50%);
  }
</style>
