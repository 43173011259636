import { createStore } from "vuex";
import bootstrap from "bootstrap/dist/js/bootstrap.min.js";
import axios from "axios";

export default createStore({
  state: {
    isAuthenticated: false,
    token: "",
    user_data: {
      id: "",
      email: "",
      first_name: "",
      last_name: "",
      flightpath_license: "",
      powder_savings_calculator_license: "",
      onsite_service_license: "",
      blueprint_light_license: "",
      username: "",
      unit_system: "metric",
      language: "EN",
      changed_password: true,
      internal_user: false,
    },
    user_data_loaded: false,
    flightpath_deactivated_license: null,
    flightpath_pro_license: null,
    flightpath_standard_license: null,
    copilot_deactivated_license: null,
    copilot_license: null,
    powder_savings_calculator_license: null,
    onsite_service_license: null,
    blueprint_light_license: null,
    total_active_licenses: 0,
    isPinned: true,
    showConfig: false,
    isTransparent: "",
    color: "",
    isLoading: true,
    isNavFixed: false,
    isAbsolute: false,
    isPublicInfo: false,
    showNavs: true,
    showSidenav: true,
    showNavbar: true,
    showFooter: true,
    showMain: true,
    navbarFixed: "position-sticky blur shadow-blur left-auto top-1 z-index-sticky px-0 mx-4",
    absolute: "position-absolute px-4 mx-0 w-100 z-index-2",
    bootstrap,
    optimal_settings_calculation_in_progress_flightpath_ids: [],
    current_settings_calculation_in_progress_flightpath_ids: [],
    powder_savings_calculation_in_progress_email_ids: [],
    last_message_type: "completed",
    units_system: {
      metric: {
        distance: "cm",
        distance_major: "m",
        velocity_seconds: "m/s",
        velocity_minutes: "m/min",
        acceleration: "m/s²",
        thickness: "μm",
        mass: "kg",
        grams: "g",
        air_flow: "m³/h",
        powder_output: "g/min",
        temperature: "°C",
        tonnes: "tonnes",
      },
      imperial: {
        distance: "in",
        distance_major: "ft",
        velocity_seconds: "ft/s",
        velocity_minutes: "ft/min",
        acceleration: "ft/s²",
        thickness: "μm",
        mass: "lb",
        grams: "oz",
        air_flow: "cfm",
        powder_output: "oz/min",
        temperature: "°F",
        tonnes: "tons",
      },
    },
    conversion_factors: {
      cm_to_in: 0.393701,
      m_to_ft: 3.28084,
      um_to_mil: 5 / 127,
      kg_to_lb: 2.2046,
      m3h_to_cfm: 0.589,
      g_to_oz: 0.035274,
      c_to_f: 9 / 5,
      c_to_f_offset: 32,
      tonnes_to_tons: 1.10231,
    },

    //onsite

    minTargetThickness: 0,
    maxTargetThickness: 0,

    gaussianBenchmarkChart: {
      labels: [],
      datasets: [],
    },

    powderOutputChart: {
      labels: [],
      datasets: [],
    },

    gaussianPowderOutputChart: {
      labels: [],
      datasets: [],
    },

    gaussianPowderSavingChart: {
      labels: [],
      datasets: [],
    },

    gaussianReciprocatorChart: {
      labels: [],
      datasets: [],
    },
    gaussianReciprocatorMu: null,
    gaussianReciprocatorSigma: null,

    constraints: {
      min_pistol_to_pistol_distance_allowed: 5,
      max_pistol_to_pistol_distance_allowed: 300,
      max_pistol_to_pistol_distance_allowed_fixed_lines: 50,
      min_pistol_to_pistol_distance_allowed_fixed_lines: 5,
      min_column_to_column_distance_allowed: 1,
      max_column_to_column_distance_allowed: 300,
      min_row_to_row_distance_allowed: 1,
      max_row_to_row_distance_allowed: 300,
      min_column_shift_allowed: -80,
      max_column_shift_allowed: 80,
      max_row_shift_allowed: 200,
      max_horizontal_line_guns: 20,
      max_number_of_rows_allowed: 20,
      max_total_line_guns_allowed: 30,
      min_pistols_max_movement_range_allowed: 5,
      max_pistols_max_movement_range_allowed: 2000,
      min_powder_output_allowed: 10,
      max_powder_output_allowed: 2000,
      min_pistols_velocity_allowed: 0.02,
      max_pistols_velocity_allowed: 0.8,
      min_line_speed_allowed: 0.1,
      max_line_speed_allowed: 12,
      min_pistol_to_substrate_distance_allowed: 5,
      max_coated_height_allowed: 2000,
      min_coated_height_allowed: 10,
      max_coated_width_allowed: 200,
      min_coated_width_allowed: 20,
      min_gun_movement_range_allowed: 0,
      min_desired_thickness_allowed: 20,
      max_desired_thickness_allowed: 150,
      min_pistols_max_acceleration_allowed: 0.1,
      max_pistols_max_acceleration_allowed: 1,
      max_number_of_columns_allowed: 7,
    },
  },
  mutations: {
    initializeStore(state) {
      if (localStorage.getItem("token")) {
        state.token = localStorage.getItem("token");
        state.isAuthenticated = true;
      } else {
        state.token = "";
        state.isAuthenticated = false;
      }
    },

    removeToken(state) {
      state.token = null;
      state.isAuthenticated = false;
      localStorage.setItem("token", "");
      localStorage.setItem("isAuthenticated", false);
      localStorage.clear();
      state.user_data = {
        id: "",
        email: "",
        first_name: "",
        last_name: "",
        flightpath_license: "",
        username: "",
        unit_system: "metric",
        language: "EN",
        changed_password: true,
        internal_user: false,
      };
    },

    setToken(state, token) {
      state.token = token;
      state.isAuthenticated = true;
    },

    setUserData(state, user_data) {
      state.user_data = user_data;

      if (user_data.flightpath_license == 0) {
        state.flightpath_deactivated_license = true;
        state.flightpath_pro_license = false;
        state.flightpath_standard_license = false;
      } else if (user_data.flightpath_license == 1) {
        state.flightpath_deactivated_license = false;
        state.flightpath_pro_license = true;
        state.flightpath_standard_license = false;
        state.total_active_licenses++;
      } else if (user_data.flightpath_license == 2) {
        state.flightpath_deactivated_license = false;
        state.flightpath_pro_license = false;
        state.flightpath_standard_license = true;
        state.total_active_licenses++;
      } else {
        state.flightpath_deactivated_license = null;
        state.flightpath_pro_license = null;
        state.flightpath_standard_license = null;
      }

      if (user_data.copilot_license == 0) {
        state.copilot_license = false;
        state.copilot_deactivated_license = true;
      } else if (user_data.copilot_license == 1) {
        state.copilot_license = true;
        state.copilot_deactivated_license = false;
        state.total_active_licenses++;
      } else {
        state.copilot_license = null;
        state.copilot_deactivated_license = null;
      }

      if (user_data.powder_savings_calculator_license == 0) {
        state.powder_savings_calculator_license = false;
      } else if (user_data.powder_savings_calculator_license == 1) {
        state.powder_savings_calculator_license = true;
      } else {
        state.powder_savings_calculator_license = null;
      }

      if (user_data.onsite_service_license == 0) {
        state.onsite_service_license = false;
      } else if (user_data.onsite_service_license == 1) {
        state.onsite_service_license = true;
        state.total_active_licenses++;
      }

      if (user_data.blueprint_light_license == 0) {
        state.blueprint_light_license = false;
      } else if (user_data.blueprint_light_license == 1) {
        state.blueprint_light_license = true;
        state.total_active_licenses++;
      } else {
        state.powder_savings_calculator_license = null;
      }
      state.user_data_loaded = true;
    },

    setLoading(state, isLoading) {
      state.isLoading = isLoading;
    },

    setPublicInfo(state, isPublicInfo) {
      state.isPublicInfo = isPublicInfo;
    },

    setLastMessageType(state, value) {
      state.last_message_type = value;
    },

    addOptimalSettingsCalculationInProgress(state, flightpathid) {
      state.optimal_settings_calculation_in_progress_flightpath_ids.push(flightpathid);
    },

    addCurrentSettingsCalculationInProgress(state, flightpathid) {
      state.current_settings_calculation_in_progress_flightpath_ids.push(flightpathid);
    },

    removeOptimalSettingsCalculationInProgress(state, flightpathid) {
      if (state.optimal_settings_calculation_in_progress_flightpath_ids.includes(flightpathid)) {
        state.optimal_settings_calculation_in_progress_flightpath_ids =
          state.optimal_settings_calculation_in_progress_flightpath_ids.filter(id => id != flightpathid);
      }
    },

    removeCurrentSettingsCalculationInProgress(state, flightpathid) {
      if (state.current_settings_calculation_in_progress_flightpath_ids.includes(flightpathid)) {
        state.current_settings_calculation_in_progress_flightpath_ids =
          state.current_settings_calculation_in_progress_flightpath_ids.filter(id => id != flightpathid);
      }
    },

    addPowderSavingsCalculationInProgress(state, emailid) {
      state.powder_savings_calculation_in_progress_email_ids.push(emailid);
    },

    removePowderSavingsCalculationInProgress(state, emailid) {
      if (state.powder_savings_calculation_in_progress_email_ids.includes(emailid)) {
        state.powder_savings_calculation_in_progress_email_ids =
          state.powder_savings_calculation_in_progress_email_ids.filter(id => id != emailid);
      }
    },

    navbarMinimize(state) {
      const sidenav_show = document.querySelector(".g-sidenav-show");
      if (sidenav_show.classList.contains("g-sidenav-hidden")) {
        sidenav_show.classList.remove("g-sidenav-hidden");
        sidenav_show.classList.add("g-sidenav-pinned");
        state.isPinned = true;
      } else {
        sidenav_show.classList.add("g-sidenav-hidden");
        sidenav_show.classList.remove("g-sidenav-pinned");
        state.isPinned = false;
      }
    },

    sidebarType(state, payload) {
      state.isTransparent = payload;
    },

    cardBackground(state, payload) {
      state.color = payload;
    },

    navbarFixed(state) {
      if (state.isNavFixed === false) {
        state.isNavFixed = true;
      } else {
        state.isNavFixed = false;
      }
    },

    toggleEveryDisplay(state) {
      state.showNavbar = !state.showNavbar;
      state.showSidenav = !state.showSidenav;
      state.showFooter = !state.showFooter;
    },

    toggleHideConfig(state) {
      state.hideConfigButton = !state.hideConfigButton;
    },
  },
  actions: {
    toggleSidebarColor({ commit }, payload) {
      commit("sidebarType", payload);
    },
    setCardBackground({ commit }, payload) {
      commit("cardBackground", payload);
    },
    async getUserData({ commit, state }) {
      if (state.isAuthenticated) {
        try {
          const response = await axios.get("/api/v1/users/me/");
          commit("setUserData", response.data);
        } catch (error) {
          console.log(error);
        }
      }
    },
  },
  getters: {},
});
