<template>
  <div>
    <h5 class="mb-1">{{ title }}</h5>
    <div
      v-if="editableGunThroughput"
      class="mb-2 px-2"
    >
      <div class="row">
        <div class="col-6">
          <label>
            Gun throughput
            {{ `[${$store.state.units_system[$store.state.user_data.unit_system].grams}/min]` }}
          </label>
          <input
          v-model="gun_throughput"
          type="number"
          class="form-control form-control-lg w-30"
          :placeholder="`${$store.state.units_system[$store.state.user_data.unit_system].grams}/min`"
          style="font-size: 1.25rem;"
          @change="$emit('update-gun-throughput', gun_throughput)"
          />
        </div>
        <div v-if="updatedGunThroughput" class="col-6 mt-4 p-3">
          <button
            class="btn btn-sm btn-primary"
            @click="$emit('save-gun-throughput', gun_throughput); initialGunOutput = gun_throughput"
          >
            Save new throughput
          </button>
        </div>
      </div>
    </div>
    <div
      v-else
      class="d-flex px-2"
    >
      <mini-statistics-card
        title="Gun throughput"
        :value="`${gunExpectedOutput} [${$store.state.units_system[$store.state.user_data.unit_system].grams}/min]`"
        percentage=""
        :icon="{ component: 'ni ni ni-support-16', background: 'bg-gradient-info' }"
        class-content="px-2"
      />
    </div>
    <h6 class="px-2">Powder Amount Parameters to equalize all guns:</h6>
    <div class="row">
      <div
        v-for="(n, index) in line.total_pistols[0]"
        :key="n"
        class="col-lg-4 col-md-4 col-sm-3"
        style="min-width: 40px"
      >
        <mini-statistics-card
          :title="` Gun ${index + 1}`"
          :value="`${powderAmountParameters[index] < 0 ? 0 : powderAmountParameters[index]}`"
          :percentage="{ value: '200', color: 'text-success' }"
          :icon="{ component: 'ni ni-settings', background: 'bg-gradient-info' }"
          :class-content="powderAmountParameters[index] < 0 ? 'px-1' : 'px-1'"
        />
      </div>
    </div>
  </div>
</template>

<script>
import MiniStatisticsCard from "@/components/MiniStatisticsCard.vue";

export default {
  name: "GunThroughput",
  components: {
    MiniStatisticsCard,
  },
  props: {
    title: {
      type: String,
      required: true,
    },
    gunExpectedOutput: {
      type: Number,
      required: true,
    },
    line: {
      type: Object,
      required: true,
    },
    powderAmountParameters: {
      type: Array,
      required: true,
    },
    editableGunThroughput: {
      type: Boolean,
      required: false,
      default: false,
    },
    iterationZeroGunOutput: {
      type: Number,
      required: false,
      default: 0,
    },
  },
  emits: [
    "update-gun-throughput",
    "save-gun-throughput",
  ],
  data() {
    return {
      gun_throughput: 0,
      initialGunOutput: 0,
    };
  },
  computed: {
    updatedGunThroughput() {
      return this.gun_throughput !== this.initialGunOutput;
    },
  },
  watch: {
    'gunExpectedOutput' () {
      this.gun_throughput = this.gunExpectedOutput;

      if (
        this.initialGunOutput == 0 ||
        this.initialGunOutput == null ||
        this.initialGunOutput == undefined ||
        this.initialGunOutput == "" ||
        this.initialGunOutput == this.iterationZeroGunOutput
      ) {
        this.initialGunOutput = JSON.parse(JSON.stringify(this.gunExpectedOutput));
      }
    },
  },
  mounted() {
    this.gun_throughput = this.gunExpectedOutput;
    this.initialGunOutput = JSON.parse(JSON.stringify(this.gunExpectedOutput));
  },
};
</script>
